import Pledge from '@/models/treasury/pledges/Pledge';
import { Button, Modal, NumberInput, Stack, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';

export interface PledgeEditFormProps {
    visible: boolean;
    closed: () => void;
    pledge : Pledge,
    edit: (pledge: Pledge, backerKitEmail: string, claimedQuantity : number, quantity: number) => void;
}

export default function PledgeEditForm({ visible, closed, pledge, edit }: PledgeEditFormProps) {
   
    useEffect(() => {
        if(!!pledge){
            setBackerKitEmail(pledge.backerkit_email);
            setQuantity(pledge.quantity);
            setClaimedQuantity(pledge.claimed_quantity);
        }
       
    }, [pledge]);
    
    const [backerKitEmail, setBackerKitEmail] = useState(pledge?.backerkit_email??"");
    const [quantity, setQuantity] = useState(pledge?.quantity??0);
    const [claimedQuantity, setClaimedQuantity] = useState(pledge?.claimed_quantity??0)

    const editHandler = () => {
        edit(pledge, backerKitEmail, claimedQuantity, quantity);
    };

    return (
        <Modal opened={visible} onClose={closed} centered title="Edit Pledge">
            <Stack>
                <TextInput
                    label="Reward"
                    value={pledge?.reward?.reward_name??"Not Available"}
                    readOnly
                    disabled
                />
                <TextInput
                    label="BackerKit Email"
                    value={backerKitEmail}
                    onChange={(e) => {
                        e.preventDefault();
                        setBackerKitEmail(e.target.value);
                    }}
                />
                <NumberInput
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => {
                        if (!!e) setQuantity(e);
                    }}
                />
                 <NumberInput
                    label="Claimed Quantity"
                    value={claimedQuantity}
                    onChange={(e) => {
                        if (!!e) setClaimedQuantity(e);
                    }}
                />
                <Button onClick={editHandler}>Save Pledge</Button>
            </Stack>
        </Modal>
    );
}

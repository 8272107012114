import { Skeleton, Alert, Box, Text } from '@mantine/core';
import { Chart, GoogleChartWrapper } from 'react-google-charts';

import { DashboardChart } from '@/models/monitoring/server-activity/Dashboard';
import { DashboardTile } from '@/models/monitoring/server-activity/MonitoringDashboardList';
import { useInjection } from 'inversify-react';
import IDateTimeService from '@/services/IDateTimeService';

interface IExampleGoogleDashboard {
    metaData: DashboardTile | undefined;
    chartData: DashboardChart | undefined;
    setChartWrapper: (chartWrapper: GoogleChartWrapper) => void;
    dateRange: [Date | null, Date | null];
}

export function MonitoringChart({ metaData, chartData, setChartWrapper, dateRange }: IExampleGoogleDashboard) {
    const datetimeService = useInjection<IDateTimeService>('IDateTimeService');
    const gameAndPlayerChartVAxisProps = () => {
        if (metaData?.title.includes('Game Room Activity') || metaData?.title.includes('Player Activity')) {
            return {
                viewWindow: { min: 0 },
                format: '#',
            };
        } else {
            return {};
        }
    };

    const emptyData = [[null, 1]];

    const data = chartData ? chartData : emptyData;

    return (
        <Box pos="relative">
            <Chart
                chartType="LineChart"
                data={[['Time', 'Value'], ...data]}
                loader={<Skeleton m="auto" h={300} w="95%" />}
                errorElement={<Alert color="red">Error loading chart</Alert>}
                options={{
                    curveType: 'none',
                    legend: 'none',
                    pointsVisible: true,
                    pointSize: 1,
                    chartArea: { width: '85%', left: '5%', top: '10%', bottom: '20%' },
                    vAxis: {
                        ...gameAndPlayerChartVAxisProps(),
                        textStyle: {
                            fontSize: 12,
                            color: chartData ? '#6E6E6E' : 'transparent',
                        },
                    },
                    hAxis: {
                        viewWindow: {
                            min: dateRange[0],
                            max: dateRange[1],
                        },
                        textStyle: {
                            fontSize: 10,
                            color: chartData ? '#6E6E6E' : 'transparent',
                        },
                    },
                    series: {
                        0: { targetAxisIndex: 1, color: chartData ? '#1E88E5' : 'transparent' },
                    },
                    crosshair: {
                        trigger: 'selection',
                        orientation: 'both',
                    },
                }}
                width="100%"
                height="320px"
                getChartWrapper={setChartWrapper}
            />
            <Box pos="absolute" bottom={5} left={5}>
                <Text size={10}>{datetimeService.getLocalTimezone()}</Text>
            </Box>
        </Box>
    );
}

import { inject, injectable } from "inversify";
import ProductVersionModel from "../../models/devops/ProductVersionModel";
import VersionList from "../../models/devops/VersionList";
import type { IAuthGateRESTClient } from "../../rest-clients/IAuthGateRESTClient";
import type { IDevOpsRESTClient } from "../../rest-clients/IDevOpsRESTClient";
import type IGDMRESTClient from "../../rest-clients/IGDMRESTClient";
import type { IAuthService } from "../IAuthService";
import IDevOpsService from "../IDevOpsService";

@injectable()
export default class DevOpsService implements IDevOpsService {
   
    @inject('IDevOpsRESTClient')
    private devopsRESTClient!: IDevOpsRESTClient;

    @inject('IGDMRESTClient')
    private gdmRESTClient!: IGDMRESTClient;

    @inject('IAuthService')
    private authService!: IAuthService;

    @inject('IAuthGateRESTClient')
    private authGateRESTClient!: IAuthGateRESTClient;



    async getProductVersionList(product: string, page: number): Promise<VersionList | null> {
        return await this.devopsRESTClient.getProductVersionList(product, page);
    }

    async getProductVersionInfo(id: number): Promise<ProductVersionModel | null> {
        const versionInfo = await this.devopsRESTClient.getProductVersionInfo(id);

        if (!!versionInfo) {
            if (!versionInfo.sha || versionInfo.sha === '')
                versionInfo.sha = "N/A";
            if (!!versionInfo.artifacts) {
                versionInfo.artifacts.forEach(x => {
                    if (!!x && !x.size) x.size = 0;
                })
            }
        }


        return versionInfo;
    }
    async breakCompatibility(pipeline: string): Promise<boolean> {
        return await this.gdmRESTClient.breakCompatibility(pipeline);
    }
    async createProductVersion(version: string, product: string, buildAll : boolean): Promise<boolean> {
        return await this.devopsRESTClient.createProductVersion(version, product, buildAll);
    }
    async deleteProductVersion(id: number): Promise<boolean> {
        return await this.devopsRESTClient.deleteProductVersion(id);
    }
    async updateProductVersion(version: ProductVersionModel): Promise<boolean> {
        return await this.devopsRESTClient.updateProductVersion(version);
    }


    async issueRebuild(bundleBuildId: number): Promise<boolean> {
        return await this.devopsRESTClient.issueRebuild(bundleBuildId);
    }

    async issueRetransfer(bundleBuildId: number): Promise<boolean> {
        return await this.devopsRESTClient.issueRetransfer(bundleBuildId);
    }

}
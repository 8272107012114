import { inject, injectable } from 'inversify';
import { IScopeService } from '../IScopeService';

import type IUserManagementRESTClient from '@/rest-clients/IUserManagementRESTClient';
import { ICreateScopeModel, ScopeAccessLevelModel } from '@/models/scope/ScopeModel';
import { ScopeList } from '@/models/scope/ScopeList';

@injectable()
export default class ScopeService implements IScopeService {
    @inject('IUserManagementRESTClient')
    private userManagementRESTClient!: IUserManagementRESTClient;
    private readonly scopeAccessLevelLabels: { label: string; value: string }[];

    constructor() {
        this.scopeAccessLevelLabels = [
            { label: 'Unauthorized', value: '-1' },
            { label: 'Read Only', value: '0' },
            { label: 'Create', value: '1' },
            { label: 'Update', value: '2' },
            { label: 'Delete', value: '3' },
        ];
    }

    getAccessLevelOptions(): { label: string; value: string }[] {
        return this.scopeAccessLevelLabels;
    }

    async getScopes(scopeType: string): Promise<ScopeList | null> {
        return await this.userManagementRESTClient.getScopes(scopeType);
    }

    async getScope(scopeId: string): Promise<ScopeAccessLevelModel | null> {
        return await this.userManagementRESTClient.getScope(scopeId);
    }

    async createScope(scope: ICreateScopeModel): Promise<boolean> {
        return await this.userManagementRESTClient.createScope(scope);
    }

    async updateScopeAccessLevels(scopeId: string, accessLevels: ScopeAccessLevelModel): Promise<boolean> {
        return await this.userManagementRESTClient.updateScopeAccessLevels(scopeId, accessLevels);
    }

    async deleteScope(scope: string): Promise<boolean> {
        return await this.userManagementRESTClient.deleteScope(scope);
    }
}

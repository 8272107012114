import AzureBranchModel from "@/models/devops/azure/AzureBranchModel";
import AzureRepositoryModel from "@/models/devops/azure/AzureRepositoryModel";
import type IAzureRESTClient from "@/rest-clients/IAzureRESTClient";
import { injectable,inject } from "inversify";
import IAzureService from "../IAzureService";

@injectable()
export default class AzureService implements IAzureService{
    @inject('IAzureRESTClient')
    private azureRESTClient!: IAzureRESTClient;

    getRepositories(personalToken: string): Promise<AzureRepositoryModel[]> {
        return this.azureRESTClient.getRepositories(personalToken);
    }
    async getRepositoryBranch(personalToken: string, repoUrl: string): Promise<AzureBranchModel[]> {
        const branches = await this.azureRESTClient.getRepositoryBranch(personalToken, repoUrl);

        branches.forEach(b=>{
            b.name = this.getBranchNameFormatted(b.name)
        });

        return branches;
    }

    getBranchNameFormatted(name: string){
        if(name.startsWith('refs/heads')){
            return name.replace('refs/heads/', '');
        }
        return name;
    }

}
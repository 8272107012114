import { inject, injectable } from "inversify";
import FailedTransaction from "../../models/trans/failedTransactions/FailedTransaction";
import FailedTransactionList from "../../models/trans/failedTransactions/FailedTransactionList";
import type ITransRESTClient from "../../rest-clients/ITransRESTClient";
import IFailedTransactionService from "../IFailedTransactionService";

@injectable()
export default class FailedTransactionService implements IFailedTransactionService{
    @inject('ITransRESTClient')
    private readonly transRESTClient! : ITransRESTClient;
    
    async getAllFailedTransactions(page: number, query: string): Promise<FailedTransactionList | null> {
        return await this.transRESTClient.getAllFailedTransactions(page, query);
    }
    async getFailedTransaction(orderId: number): Promise<FailedTransaction | null> {
        return await this.transRESTClient.getFailedTransaction(orderId);
    }
    async updateFailedTransaction(failedTransaction: FailedTransaction): Promise<boolean> {
        return await this.transRESTClient.updateFailedTransaction(failedTransaction);
    }

}
import GithubBranch from "@/models/devops/github/GithubBranch";
import RepositoryModelGithub from "@/models/devops/github/RepositoryModel";
import { JWTAccessToken } from "@/models/JWTAccessToken";
import type { IDevOpsRESTClient } from "@/rest-clients/IDevOpsRESTClient";
import type IGithubRESTClient from "@/rest-clients/IGithubRESTClient";
import { inject, injectable } from "inversify";
import IGithubService from "../IGithubService";

@injectable()
export default class GithubService implements IGithubService {
    @inject('IGithubRESTClient')
    private githubRESTClient!: IGithubRESTClient;

    @inject('IDevOpsRESTClient')
    private devopsRESTClient!: IDevOpsRESTClient;

    private accessToken: JWTAccessToken | null = null;

    async getToken(): Promise<JWTAccessToken | null> {

        const authenticateAppCode = await this.githubRESTClient.authenticateApp();
        if (!authenticateAppCode)
            return null;

        const jwtToken = await this.devopsRESTClient.getGithubBearerToken(authenticateAppCode);

        if(!jwtToken)
        return null;

        this.accessToken = jwtToken;

        return jwtToken;
    }

    getRefreshToken(): string | null {
        if (!!this.accessToken)
            return this.accessToken.refreshToken;
        return null;
    }

    async getRepos(): Promise<RepositoryModelGithub[]> {
        return await this.githubRESTClient.getRepos(this.accessToken?.jwt);
    }

    async getBranches(name: string): Promise<GithubBranch[]> {
        return await this.githubRESTClient.getBranches(name,this.accessToken?.jwt);
    }

    getFullUrl(repo:string):string{
        return `https://api.github.com/repos/${repo}`;
    }
}
import { MotdTemplate } from '../../../models/devops/MOTD/MotdTemplate';
import IMotdService from '@/services/IMotdService';
import IUIService from '@/services/IUIService';
import { ActionIcon, Affix, Box, Button, Flex, Modal, Stack, TextInput, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { MdOutlineCreate } from 'react-icons/md';

interface CreateMotdProps {
    motdService: IMotdService;
    uiService: IUIService;
    getData: (forceSelectFirst?: boolean) => Promise<void>;
}

export const CreateMotdTemplate = ({ motdService, uiService, getData }: CreateMotdProps) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [isCreating, setIsCreating] = useState(false);
    const [name, setName] = useState('');

    const isDisabled = !name;

    const onCreateMotd = async () => {
        if (isDisabled) return;
        setIsCreating(true);

        const today = new Date(Date.now());
        const fromDate = new Date();
        fromDate.setDate(today.getDate() + 1);
        const toDate = new Date();
        toDate.setTime(fromDate.getTime() + 30 * 60000);

        // const variablesData: MotdTemplateVariable[] = templateData.variables.map((variable: MotdTemplateVariable) =>
        //     motdService.getMotdDefaultVariable(variable)
        // );

        // console.log(variablesData);

        const data: MotdTemplate = {
            id: 0,
            name: name,
            pattern: '',
            variables: [],
        };

        const response = await motdService.createMotdTemplate(data);
        uiService.hideLoading();
        setIsCreating(false);

        if (response) {
            uiService.showSuccessNotification('Message created successfully!');
            getData(true);
            close();
            return;
        } else {
            uiService.showErrorNotification('Error when creating message!');
            close();
        }
    };

    const content = (
        <Stack spacing="xs">
            <TextInput label="Name" value={name} onChange={(e) => setName(e.target.value)} />

            <Flex gap="sm" justify="stretch" align="stretch" mt="sm">
                <Button fullWidth color="red" variant="outline" onClick={close} disabled={isCreating}>
                    Cancel
                </Button>
                <Button fullWidth disabled={isDisabled} onClick={onCreateMotd} loading={isCreating}>
                    Create
                </Button>
            </Flex>
        </Stack>
    );

    return (
        <Box>
            <Modal opened={opened} onClose={close} title="Create new Motd Template" centered>
                {content}
            </Modal>
            <Affix zIndex={1} position={{ bottom: 20, right: 20 }}>
                <Tooltip label="Create new Motd" withArrow>
                    <ActionIcon size={50} radius="xl" variant="filled" color="blue" onClick={open}>
                        <MdOutlineCreate size={25} />
                    </ActionIcon>
                </Tooltip>
            </Affix>
        </Box>
    );
};

import BundleDependencyForm, { BundleGetProxy } from '../bundle-dependency-form/bundle-dependency-form';
import styles from './bundle-dependency-directory.module.scss';
import BundleDependencyTopBar from '../bundle-dependency-topbar/bundle-dependency-top-bar';
import { useEffect, useRef, useState } from 'react';
import Nullable from '@/dataTypes/Nullable';
import DependencyBundles from '@/models/devops/build/dependencyBundles/DependencyBundles';
import { useInjection } from 'inversify-react';
import IBundleService from '../../../../services/IBundleService';
import IBuildRepositoryService from '../../../../services/IBuildRepositoryService';
import IUIService from '@/services/IUIService';
import BundleConnection from '@/models/devops/build/ui/BundleConnection';
import BundleConnectionSidebar from '../bundle-connection-sidebar/BundleConnectionSidebar';

export default function BundleDependencyDirectory() {
    const [bundleGetProxy, setBundleGetProxy] = useState<BundleGetProxy | undefined>(new BundleGetProxy());
    const [product, setProduct] = useState('kd-sim');
    const [products, setProducts] = useState<Nullable<string[]>>([]);
    const [currentSelection, setCurrentSelection] = useState<Nullable<BundleConnection[]>>(null);

    const bundleService = useInjection<IBundleService>('IBundleService');
    const buildRepositoryService = useInjection<IBuildRepositoryService>('IBuildRepositoryService');
    const uiService = useInjection<IUIService>('IUIService');

    const [dependencyBundles, setDependencyBundles] = useState<Nullable<DependencyBundles>>(null);

    useEffect(() => {
        if (!bundleGetProxy) {
            setBundleGetProxy(new BundleGetProxy());
        }

        buildRepositoryService.getRepositories().then((x) => {
            if (!x) return;
            setProducts(x);
            setProduct(x[0]);
        });
    }, []);

    useEffect(() => {
        bundleService.getBundleDependencies(product).then((x) => {
            setDependencyBundles(x);
        });
    }, [product]);

    return (
        <div className={styles.column}>
            {products && (
                <BundleDependencyTopBar
                    saveClick={async () => {
                        if (!!bundleGetProxy && !!bundleGetProxy.getBundles) {
                            uiService.showLoading();
                            const result = await bundleService.updateBundleDependencies(
                                product,
                                bundleGetProxy.getBundles()
                            );
                            uiService.hideLoading();

                            if (!!result) {
                                uiService.showSuccessNotification('Saved bundle dependency update');
                                
                                bundleService.getBundleDependencies(product).then((x) => {
                                    setDependencyBundles(x);
                                });

                            } else {
                                uiService.showErrorNotification('Failed to save bundle dependencies');
                            }
                        }
                    }}
                    updateProduct={(p) => setProduct(p)}
                    addClick={() => {}}
                    products={products}
                    product={product}
                />
            )}
            {!!dependencyBundles && (
                <BundleDependencyForm
                    bundleGetProxy={bundleGetProxy}
                    bundles={dependencyBundles}
                    selected={(s) => {
                        console.log('selection');
                        console.log(s);
                        setCurrentSelection(s);
                    }}
                    unSelected={() => {
                        setCurrentSelection(null);
                    }}
                />
            )}
            <BundleConnectionSidebar
                selection={currentSelection}
                remove={(id) => {
                    if (!!bundleGetProxy && !!bundleGetProxy.removeConnection)
                     bundleGetProxy.removeConnection(id);
                }}
            />
        </div>
    );
}

import React from 'react';
import BundleBuild from '../../../models/devops/BundleBuild';
import BundleBuildView from '../bundle-build/bundle-build.component';
import styles from './bundle-build-list.module.scss';
import { BuildState } from '../../../models/devops/BuildState';

interface BundleBuildListProps {
    bundles: BundleBuild[];
    expanded?: boolean;
    refreshArtifacts: () => void;
}

export default function BundleBuildList(props: BundleBuildListProps) {
    const { bundles, expanded } = props;

    const getSortedIndex = (state: BuildState) => {
        switch (state) {
            case BuildState.Queued:
                return 2;
            case BuildState.Started:
                return 1;
            case BuildState.Success:
                return 3;
            case BuildState.Failure:
                return 0;
            case BuildState.Transferred:
                return 4;
        }
        return 8;
    };

    return (
        <React.Fragment>
            <div className={styles.bundleList}>
                {!!bundles &&
                    bundles
                        .sort((x, y) =>
                            `${getSortedIndex(x.state)}_${x.storage_path.toLowerCase()}`.localeCompare(
                                `${getSortedIndex(y.state)}_${y.storage_path.toLowerCase()}`
                            )
                        )
                        .map((b) => {
                            return (
                                <React.Fragment key={b.id}>
                                    <BundleBuildView bundle={b} refreshArtifacts={props.refreshArtifacts} />
                                </React.Fragment>
                            );
                        })}
            </div>
        </React.Fragment>
    );
}

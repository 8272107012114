import { IServerActivityService } from '@/services/IServerActivityService';
import { Alert, Box, Card, Skeleton, Stack, Table, Text, useMantineTheme } from '@mantine/core';
import { useInjection } from 'inversify-react';
import { useQuery } from '@tanstack/react-query';
import IDateTimeService from '@/services/IDateTimeService';

interface IGameRoomDetails {
    roomID: string;
    refreshOn: boolean;
}

export const GameRoomDetails = (props: IGameRoomDetails) => {
    const serverActivityService = useInjection<IServerActivityService>('IServerActivityService');
    const dtService = useInjection<IDateTimeService>('IDateTimeService');
    const theme = useMantineTheme();

    const { isLoading, isError, data } = useQuery({
        queryKey: ['gameRoomInfo', props.roomID],
        queryFn: () => serverActivityService.getGameRoomInfo(props.roomID),
        refetchInterval: props.refreshOn ? 1000 * 10 : false,
        enabled: props.refreshOn,
    });

    if (isError) {
        return (
            <Alert color="red" title="Error">
                Could not load game room details
            </Alert>
        );
    }

    if (isLoading) {
        return (
            <Stack spacing="md">
                <Skeleton height={120} />
                <Skeleton height={200} />
            </Stack>
        );
    }

    const rows = data?.players.map((player) => (
        <Box component="tr" key={player.email}>
            <td>{player.email}</td>
            <td>
                {dtService.formatDate(dtService.stringToDate(player.joined))}
                <Text span ml="xs">
                    {dtService.getLocalTimezone()}
                </Text>
            </td>
            <td>{player.elapsed_time}</td>
        </Box>
    ));

    return (
        <Card sx={{ border: '1px solid #66666630' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '200px auto' }}>
                <Text size="sm">Room ID</Text>
                <Text size="sm">{data?.guid}</Text>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '200px auto' }}>
                <Text size="sm">Access Level</Text>
                <Text size="sm">{data?.access}</Text>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '200px auto' }}>
                <Text size="sm">Access Token</Text>
                <Text size="sm">{data?.access_token}</Text>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '200px auto' }}>
                <Text size="sm">Port</Text>
                <Text size="sm">{data?.port}</Text>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '200px auto' }}>
                <Text size="sm">Creation Time</Text>
                <Text size="sm">
                    {dtService.formatDate(dtService.stringToDate(data?.created))}
                    <Text span ml="xs">
                        {dtService.getLocalTimezone()}
                    </Text>
                </Text>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '200px auto' }}>
                <Text size="sm">Uptime</Text>
                <Text size="sm">{data?.elapsed_time}</Text>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '200px auto' }}>
                <Text size="sm">Player Count</Text>
                <Text size="sm">{data?.player_count}</Text>
            </Box>

            <Table fontSize="xs" verticalSpacing={6} mt="md" withColumnBorders withBorder>
                <Box component="thead" bg={theme.colors.gray[0]}>
                    <tr>
                        <th>Player Email</th>
                        <th>Join Date</th>
                        <th>Uptime</th>
                    </tr>
                </Box>
                <tbody>{rows}</tbody>
            </Table>
        </Card>
    );
};

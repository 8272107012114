import IList from "@/models/IList";
import StoreProductEntry from "./StoreProductEntry";

export default class StoreProductList implements IList<StoreProductEntry>{
    public max_results!: number;
    public items! : StoreProductEntry[];
    
    getItems(): StoreProductEntry[] {
        return this.items;
    }
}
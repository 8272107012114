import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface WizardState {
    showWizard : boolean
}

const initialState: WizardState = {
    showWizard : false
}

export const wizardSlice = createSlice({
    name: 'show',
    initialState,
    reducers: {
        setShowWizard: (state, action: PayloadAction<boolean>) => {
            state.showWizard = action.payload
        }
    },
})

export const { setShowWizard } = wizardSlice.actions

export const selectShowWizard = (state: RootState) => state.wizard.showWizard

export default wizardSlice.reducer

import StoreProductEntry from '@/models/trans/storeProducts/StoreProductEntry';
import StoreProductList from '@/models/trans/storeProducts/StoreProductList';
import { Avatar, Group, Badge, Container, Box } from '@mantine/core';
import DefaultListOptionView, {
    DefaultListOptionWithImageView,
} from '../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../general/list-view/list-view.component';
import StoreProductCreateButton from '../store-product-create/store-product-create.component';
import { SearchBox } from '../../general/search-box/search-box';

interface StoreItemListProps {
    create?: (name: string) => void;
    selectionChange: (id: number) => void;
    selectedId: number;
    versionList: StoreProductList | null;
    page: number;
    onPageChange: (page: number) => void;
    searchChange?: (query: string) => void;
    canCreate?: boolean;
}
export default function StoreItemList({
    searchChange,
    selectedId,
    selectionChange,
    versionList,
    page,
    onPageChange,
    create,
    canCreate,
}: StoreItemListProps) {
    const onSelectionChange = (id: number) => {
        selectionChange(id);
    };

    const getImage = (productEntry: StoreProductEntry) => {
        if (!!productEntry.shopify_id && productEntry.shopify_id !== '' && productEntry.shopify_id !== '0') {
            return (
                <Badge
                    color="teal"
                    sx={{
                        backgroundColor: '#79b259',
                        color: 'white',
                        textTransform: 'lowercase',
                        fontSize: '8px',
                        height: '13px',
                        padding: '0 7px',
                        paddingBottom: '1px',
                    }}
                >
                    Shopify
                </Badge>
            );
        }
        return undefined;
    };

    return (
        <Container px={0} pt="md">
            <Box px="xs">
                <SearchBox
                    setSearchQuery={(q) => {
                        if (!!searchChange) {
                            searchChange(q);
                        }
                    }}
                />
            </Box>
            <ListViewComponent
                elements={versionList}
                page={page}
                selectedValue={selectedId}
                pageChanged={onPageChange}
                selectionChanged={onSelectionChange}
                listElement={(x, selectionChange, inSelect) => {
                    return (
                        <DefaultListOptionWithImageView
                            key={x.id}
                            id={x.id}
                            value={x.title.length>30?x.title.substring(0,27)+'...':x.title}
                            selectionChange={selectionChange}
                            selected={selectedId === x.id}
                            inSelectContainer={inSelect}
                            rightIcon={getImage(x)}
                            tooltip={x.title.length>30?x.title:undefined}
                        />
                    );
                }}
            >
                <ListViewComponent.Header>
                    {!!canCreate && (
                        <StoreProductCreateButton
                            create={(name) => {
                                if (!!create) create(name);
                            }}
                        />
                    )}
                </ListViewComponent.Header>
            </ListViewComponent>
        </Container>
    );
}

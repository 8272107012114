import { ServerLogList } from '@/models/monitoring/server-logs/ServerLogList';
import type IDateTimeService from '../IDateTimeService';
import type { IMonitoringRESTClient } from '@/rest-clients/IMonitoringRESTClient';
import { inject, injectable } from 'inversify';
import { format } from 'date-format-parse';
import { IServerLogService, IStartEndDate } from '../IServerLogService';
import { ExportToCsv, Options } from 'export-to-csv';
import { ServerLog } from '@/models/monitoring/server-logs/ServerLog';
import dayjs from 'dayjs';

@injectable()
export default class ServerLogService implements IServerLogService {
    @inject('IMonitoringRESTClient')
    private monitoringRESTClient!: IMonitoringRESTClient;
    @inject('IDateTimeService')
    private dtService!: IDateTimeService;

    private readonly dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

    dateToString(date: Date): string {
        return format(date, this.dateFormat);
    }

    dateFromLocalToUTC(date: Date): Date {
        return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    }

    exportAsCSV(data: ServerLogList): void {
        const options: Options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Server Logs',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: `death-logs  ${new Date().toLocaleDateString()}`,
        };

        const csvToExport = new ExportToCsv(options);

        csvToExport.generateCsv(data.items);
    }

    getServerLogsFilter(startDate: Date, endDate: Date, search: string): string {
        const startDateString = this.dateToString(this.dateFromLocalToUTC(startDate));
        const endDateString = this.dateToString(this.dateFromLocalToUTC(endDate));
        return `timestamp > "${startDateString}" timestamp < "${endDateString}" ${search}`;
    }

    private getIntervalFromDifference(start: Date, end: Date) {
        const differenceInHours = dayjs(end).diff(dayjs(start), 'hour');
        const differenceInDays = dayjs(end).diff(dayjs(start), 'day');
        const differenceInMonths = dayjs(end).diff(dayjs(start), 'month');

        if (differenceInHours < 1) {
            return 2;
        } else if (differenceInHours <= 24) {
            return 15;
        } else if (differenceInDays <= 7) {
            return 60;
        } else if (differenceInDays <= 30) {
            return 6 * 60;
        } else if (differenceInMonths <= 3) {
            return 24 * 60;
        } else if (differenceInMonths <= 6) {
            return 7 * 24 * 60;
        } else if (differenceInMonths <= 12) {
            return 15 * 24 * 60;
        } else {
            return 30 * 24 * 60;
        }
    }

    groupLogsByInterval(logs: ServerLog[], startEndDates: IStartEndDate): Map<any, ServerLog[]> {
        const { startDate, endDate } = startEndDates;
        const intervalInMinutes = this.getIntervalFromDifference(startDate, endDate);
        const groupedLogs = new Map();

        logs.forEach((log) => {
            const timestamp = new Date(log.timestamp);
            const roundedTimestamp = new Date(
                Math.floor(timestamp.getTime() / (intervalInMinutes * 60 * 1000)) * (intervalInMinutes * 60 * 1000)
            );

            const roundedTimestampKey = roundedTimestamp.toISOString();

            if (groupedLogs.has(roundedTimestampKey)) {
                groupedLogs.get(roundedTimestampKey).push(log);
            } else {
                groupedLogs.set(roundedTimestampKey, [log]);
            }
        });

        return groupedLogs;
    }

    prepareHistogramData(logs: ServerLog[], startEndDates: IStartEndDate) {
        const groupedLogs = this.groupLogsByInterval(logs, startEndDates);
        const chartData: (string | Date | number)[][] = [
            ['Date', 'Default', 'Info', 'Notice', 'Warning', 'Error', 'Critical', 'Alert', 'Emergency', 'Debug'],
        ];

        for (const [time, logs] of groupedLogs) {
            const severityCounts = {
                Default: 0,
                Info: 0,
                Notice: 0,
                Warning: 0,
                Error: 0,
                Critical: 0,
                Alert: 0,
                Emergency: 0,
                Debug: 0,
            };

            logs.forEach((log) => {
                severityCounts[log.severity as keyof typeof severityCounts]++;
            });

            chartData.push([
                new Date(time),
                severityCounts.Default,
                severityCounts.Info,
                severityCounts.Notice,
                severityCounts.Warning,
                severityCounts.Error,
                severityCounts.Critical,
                severityCounts.Alert,
                severityCounts.Emergency,
                severityCounts.Debug,
            ]);
        }

        return chartData;
    }

    async getGoogleCloudLogs(
        shardName: string,
        startDate: Date,
        endDate: Date,
        search: string
    ): Promise<ServerLogList> {
        const filter = this.getServerLogsFilter(startDate, endDate, search);
        const logs = await this.monitoringRESTClient.getGoogleCloudLogs(shardName, filter);
        return logs;
    }
}

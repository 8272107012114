import { inject, injectable } from 'inversify';
import UserList from '../../models/auth/users/UserList';
import UserModel from '../../models/auth/users/UserModel';
import type IUserManagementRESTClient from '../../rest-clients/IUserManagementRESTClient';
import type IDateTimeService from '../IDateTimeService';
import IUserManagementService from '../IUserManagementService';

@injectable()
export default class UserManagementService implements IUserManagementService {
    @inject('IDateTimeService')
    private dtService!: IDateTimeService;
    @inject('IUserManagementRESTClient')
    private readonly userRESTClient!: IUserManagementRESTClient;
    private readonly userRoles: string[];
    private readonly userRoleIndices: Map<string, number>;
    private readonly indexToUserRole: Map<number, string>;

    constructor() {
        this.userRoles = ['Guest', 'Jr Developer', 'Developer'];

        this.userRoleIndices = new Map<string, number>();
        this.userRoleIndices.set('guest', 0);
        this.userRoleIndices.set('kdm-jrdev', 1);
        this.userRoleIndices.set('kdm-dev', 2);

        this.indexToUserRole = new Map<number, string>();
        this.userRoleIndices.forEach((v, k) => {
            this.indexToUserRole.set(v, k);
        });
    }

    private processBEUser(user: UserModel): UserModel {
        user.registration_date = this.dtService.utcToLocalDateString(user.registration_date, 'MM/DD/YYYY HH:mm:ss');
        return user;
    }

    getUserLevelIndex(level: string): number {
        return this.userRoleIndices.get(level)!;
    }

    getIndexUserLevel(index: number): string {
        return this.indexToUserRole.get(index)!;
    }

    getUserLevels(): string[] {
        return this.userRoles;
    }
    async getUsers(page: number): Promise<UserList | null> {
        return await this.userRESTClient.getUsers(page);
    }

    async getUser(email: string): Promise<UserModel | null> {
        const user = await this.userRESTClient.getUser(email);
        return user ? this.processBEUser(user) : null;
    }

    async updateUserScope(user: UserModel): Promise<boolean> {
        return await this.userRESTClient.updateUserScope(user);
    }

    async deleteUser(user: UserModel): Promise<boolean> {
        return await this.userRESTClient.deleteUser(user);
    }

    async searchUser(search: string, page: number): Promise<UserList | null> {
        return await this.userRESTClient.searchUser(search, page);
    }

    async forceActivateUser(email: string): Promise<boolean> {
        return await this.userRESTClient.forceActivateUser(email);
    }
}

import axios, { AxiosError } from "axios";
import { inject, injectable } from "inversify";
import type { IAuthService } from "../../services/IAuthService";
import IGDMRESTClient from "../IGDMRESTClient";
import { RESTClient } from "./RESTClient";
import HouseSave from "@/models/gdm/HouseSave";
import OwnerList from "../../models/gdm/OwnersList";
import IncompatibilityType from "@/models/gdm/IncompatibilityType";

@injectable()
export default class GDMRESTClient extends RESTClient implements IGDMRESTClient {

    @inject('IAuthService')
    private readonly authService!: IAuthService;

    private readonly baseURLv2: string;

    constructor() {
        super();
        this.setBaseURL('https://wod-gdm-uux56memxa-uc.a.run.app/api/v1/');
        this.baseURLv2 = 'https://wod-gdm-uux56memxa-uc.a.run.app/api/v2/';
    }
   
    protected getFullURLV2(
        url: string,
        params: Map<string, string> = new Map<string, string>()
    ): string {
        let targetUrl = this.baseURLv2 + url;
        let currentParam = 0;
        params.forEach((value: string, key: string) => {
            if (currentParam === 0) {
                targetUrl += '?';
            } else targetUrl += '&';

            targetUrl += key;
            targetUrl += '=';
            targetUrl += value;

            currentParam++;
        });
        return targetUrl;
    }

    async breakCompatibility(
        pipeline: string
    ): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURLV2(
            `saves/bulk/pipeline/${pipeline}`
        );

        try {
            await axios.put(path, null, this.getHeader(devopsToken));
            return true;
        } catch {
            return false;
        }
    }

    async getUserSaveList(user: string) : Promise<HouseSave[]>{
        const token = await this.authService.getToken();
        if (token === null) return [];

        const devopsToken = await this.getToken(token);

        const path = this.getFullURLV2(
            `saves/${user}`
        );

        try {
            const response = await axios.get(path, this.getHeader(devopsToken));
            return response.data as HouseSave[]
        } catch {
            return [];
        }
    }

    async deleteSave(id : number) : Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURLV2(
            `save/${id}`
        );

        try {
            await axios.delete(path, this.getHeader(devopsToken));
            return true
        } catch {
            return false;
        }
    }

    async deleteSaveBinary(id: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURLV2(
            `save/${id}/binary`
        );

        try {
            await axios.delete(path, this.getHeader(devopsToken));
            return true
        } catch {
            return false;
        }
    }

    async copySaveFileToUserProfile(id: number, targetPipeline : string, iss : string): Promise<number> {
        const token = await this.authService.getToken();
        if (token === null) return -1;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURLV2(
            `save/${id}/move/${iss}?newPipeline=${targetPipeline}`
        );

        try {
            const response = await axios.put(path, null, this.getHeader(devopsToken));
            return response.data.saveID as number;
        } catch {
            return -1;
        }
    }

    async copyPolaroidsToUserProfile(owner:string, iss:string){
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(
            `polaroids/${owner}/move/${iss}`
        );

        try {
            await axios.put(path, null, this.getHeader(devopsToken));
            return true
        } catch {
            return false;
        }
    }

    async getCompatibility(oldVersion : string, newVersion: string) : Promise<IncompatibilityType[]>{
        const token = await this.authService.getToken();
        if (token === null) return [];

        const devopsToken = await this.getToken(token);

        const path = this.getFullURLV2(
            `kernel/from/${oldVersion}/to/${newVersion}`
        );

        try {
            const response = await axios.get(path, this.getHeader(devopsToken));
            return [];
        } catch (err) {
            if(axios.isAxiosError(err)){
                const error = err as AxiosError;
                if(!!error.response)
                return error.response.data as IncompatibilityType[]
            }
            return [];
        }
    }



    async getOwnerList(page:number, perPage: number, query:string) : Promise<OwnerList>{
        const token = await this.authService.getToken();
        if (token === null) return new OwnerList();

        const gdmToken = await this.getToken(token);

        const path = this.getFullURL(
            `owners?page=${page}&perPage=${perPage}&search=${query}`
        );

        try {
            const response = await axios.get(path, this.getHeader(gdmToken));
            const parsedList = response.data as OwnerList;
            const returnList = new OwnerList();
            returnList.max_results = parsedList.max_results;
            returnList.owners = parsedList.owners;
            return returnList;
        } catch {
            return new OwnerList();
        }
    }

}
import Nullable from "@/dataTypes/Nullable";
import { Flex } from "@mantine/core";
import CustomerGiftCodes from "./customer-gift-code.component";
import CustomerSucceededTransactions from "./customer-succeeded-transactions.component";
import GiveCustomerGift from "./give-customer-gift.component";
import { useInjection } from "inversify-react";
import ITransService from "@/services/ITransService";
import { useEffect, useState } from "react";
import SucceededTransactionTitle from "@/models/trans/succeeded-transactions/SucceededTransactionsWithTitle";
import ProductKeyNameModel from "@/models/trans/productKeys/ProductKeyNameModel";
import IProductKeyService from "@/services/IProductKeyService";
import IUIService from "@/services/IUIService";

export default function CustomerTransactionForm({ email }: { email: Nullable<string> }) {
    const transService = useInjection<ITransService>('ITransService')
    const productKeyService = useInjection<IProductKeyService>('IProductKeyService')
    const uiService = useInjection<IUIService>('IUIService')

    const [succeededTransactions, setSucceededTransactions] = useState<SucceededTransactionTitle[]>([])
    const [giftCodes, setGiftCodes] = useState<ProductKeyNameModel[]>([])
    

    const getData = async ()=>{
        if (!email)
        return;
        uiService.showLoading();
        transService.getUserPurchases(email).then(x => {
            setSucceededTransactions([...x!]);
            uiService.hideLoading();
        })

        transService.getUserGiftCodes(email).then(x => {
            setGiftCodes([...x!]);
            uiService.hideLoading();
        })
    }

    useEffect(() => {
        if (!email)
            return;
        getData();
    }, [email])

    const activateKey = async (productKey : string)=>{
        if(!email)
        return;
        uiService.showLoading();
        const response = await productKeyService.activateCustomerProductKey(email, productKey)
        uiService.hideLoading();

        if(!!response){
            uiService.showSuccessNotification('Key succesfully activated');
        }
        else{
            uiService.showErrorNotification('Failed to activate the key');
        }

        getData();
    }

    return (
    <Flex direction='column'>
        <CustomerSucceededTransactions email={email} succeededTransactions={succeededTransactions} deleteElement={async (id: number)=>{
            uiService.showLoading();
            const removed = await transService.removePurchase(id);
            uiService.hideLoading();
            if(!!removed){
                uiService.showSuccessNotification('Purchase deleted successfuly');
            }
            else{
                uiService.showErrorNotification('Failed to delete the purchase');
            }
            getData();
        }}/>
        <CustomerGiftCodes giftCodes={giftCodes} email={email} activateKey={activateKey} removeKey={async (id: number)=>{
            uiService.showLoading();
            const removed = await transService.removeCustomerProductKey(id);
            uiService.hideLoading();
            if(!!removed){
                uiService.showSuccessNotification('Product Key deleted successfuly');
            }
            else{
                uiService.showErrorNotification('Failed to delete the product key');
            }
            getData();
        }}/>
       
        <GiveCustomerGift onGifted={()=>{
            getData()
        }} email={email}/>
    </Flex>
    );
}
import AccessLevelSecurityProvider from '../../../models/security/Implementations/SecurityProvider/AccessLevelSecurityProvider'
import ScopeSecurityProvider from "../../../models/security/Implementations/SecurityProvider/ScopeLevelSecurityProvider";
import ISecurityProvider from "@/models/security/ISecurityProvider";
import type { IAuthService } from "@/services/IAuthService";
import type IScopeRepositoryService from "@/services/repositories/IScopeRepositoryService";
import { inject, injectable } from "inversify";
import ISecurityProviderFactoryService from "../ISecurityProviderFactoryService";

@injectable()
export default class SecurityProviderFactoryService implements ISecurityProviderFactoryService{
    @inject('IScopeRepositoryService')
    private scopeRepositoryService!:IScopeRepositoryService;

    @inject('IAuthService')
    private authService!: IAuthService;

    createAccessLevel(access:string, accessLevel:number) : ISecurityProvider{
        return new AccessLevelSecurityProvider(access, accessLevel, this.authService);
    }

    createScope(scope:string[]) : ISecurityProvider{
        return new ScopeSecurityProvider(scope, this.scopeRepositoryService);
    }
}
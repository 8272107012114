import IUIService from '@/services/IUIService';
import { ActionIcon, Button, Flex, Table } from '@mantine/core';
import { useInjection } from 'inversify-react';
import { MdOutlineDelete } from 'react-icons/md';

interface MailTopicFormProps {
    subscribers: string[];
    deleteSubscriber: (subscriberEmail: string) => void;
    addSubscriber: (email: string) => void;
}

export default function MailTopicForm({ subscribers, deleteSubscriber, addSubscriber }: MailTopicFormProps) {
    const uiService = useInjection<IUIService>('IUIService');

    return (
        <Flex direction="column">
            <h3>Subscribers</h3>
           
            <Table highlightOnHover>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {subscribers.map((x) => (
                        <Subscriber email={x} onDelete={deleteSubscriber} />
                    ))}
                </tbody>
            </Table>
            <hr/>
            <Flex align={'center'} justify={'flex-end'} sx={{marginTop: '10px'}}>
                <Button
                    onClick={async (e) => {
                        e.preventDefault();
                        const email = await uiService.showNewVersionDialog(
                            'New Subscriber',
                            'Subsriber Email',
                            'Add Subscriber'
                        );
                        if (!!email) {
                            addSubscriber(email);
                        }
                    }}
                >
                    + Add Subscriber
                </Button>
            </Flex>
        </Flex>
    );
}

export function Subscriber({ email, onDelete }: { email: string; onDelete: (email: string) => void }) {
    return (
        <tr>
            <td>{email}</td>
            <td>
                <Flex direction='row' justify='flex-end'>
                    <ActionIcon
                        variant="filled"
                        color="red"
                        onClick={(e) => {
                            e.preventDefault();
                            onDelete(email);
                        }}
                    >
                        <MdOutlineDelete color="white" />
                    </ActionIcon>
                </Flex>
            </td>
        </tr>
    );
}

import IList from '../IList';
import { ServiceManagementEntry } from './ServiceManagementEntry';

export class ServiceManagementList implements IList<ServiceManagementEntry> {
    public max_results!: number;
    public accounts!: ServiceManagementEntry[];

    getItems(): ServiceManagementEntry[] {
        return this.accounts;
    }
}

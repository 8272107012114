import { Button, Checkbox, Container, Flex, Stack, TextInput } from '@mantine/core';
import ProductVersionModel from '../../../models/devops/ProductVersionModel';
import CommonProductVersion from '../common-product-version/common-product-version.component';

interface LauncherVersionFormProps {
    versionInfo: ProductVersionModel;
    updateModel: (launcherVersion: ProductVersionModel) => void;
    update: () => void;
    delete: () => void;
    updateEnabled: boolean;
    refreshArtifacts: () => void;
}

export default function LauncherVersionForm(props: LauncherVersionFormProps) {

    const { versionInfo, updateModel, updateEnabled, refreshArtifacts } = props;

    const updateVersion = () => {
        props.update();
    };

    const deleteVersion = () => {
        props.delete();
    };

    const getInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        return e.target.value;
    };

    return (
        <Container mt="lg">
            <Stack spacing="md">
                <CommonProductVersion
                    versionInfo={versionInfo}
                    updateModel={updateModel}
                    refreshArtifacts={refreshArtifacts}
                    allVersions={null}
                />
                <TextInput
                    type="text"
                    id="updater_url"
                    name="updater_url"
                    value={versionInfo.updater_url}
                    onChange={(e) => {
                        versionInfo.updater_url = getInputValue(e);
                        updateModel(versionInfo);
                    }}
                    label="Updater URL"
                ></TextInput>
                <Checkbox
                    id="active_pipeline"
                    label="Active Production"
                    checked={versionInfo.active_status.production}
                    value="Active Production"
                    onChange={(e) => {
                        versionInfo.active_status.production = e.currentTarget.checked;
                        updateModel(versionInfo);
                    }}
                />
                <Flex ml="auto" w="max-content" gap="sm">
                    <Button id="update_version" onClick={updateVersion} disabled={!updateEnabled}>
                        Update
                    </Button>
                    <Button color="red" variant="outline" id="delete_version" onClick={deleteVersion}>
                        Delete
                    </Button>
                </Flex>
            </Stack>
        </Container>
    );
}

import 'react-quill/dist/quill.snow.css';
import './store-product-form.styles.scss';
import AssetList from '../../../models/trans/AssetList';
import ShopifyStoreMediaComponent from '../shopify-store-media/shopify-store-media.component';
import StoreProduct from '@/models/trans/storeProducts/StoreProduct';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    Flex,
    Group,
    Text,
    MultiSelect,
    Select,
    SelectItem,
    Stack,
    TextInput,
    NumberInput,
    ScrollArea,
} from '@mantine/core';
import StoreProductVariant from '@/models/trans/storeProducts/StoreProductVariant';
import AssetEntry from '@/models/trans/AssetEntry';
import AssetData from '@/models/trans/storeProducts/AssetData';
import ReactQuill from 'react-quill';
import { forwardRef, useEffect, useRef, useState } from 'react';
import InventoryLocationData from '@/models/trans/storeProducts/Shopify/InventoryLocationData';
import { useInjection } from 'inversify-react';
import IStoreItemService from '@/services/IStoreItemService';
import AssetGroupList from '@/models/trans/asset-group/AssetGroupList';
import { VariantFullmentType } from '../../../models/trans/storeProducts/VariantFulfillmentType';
import { BsPlusCircle, BsTrash } from 'react-icons/bs';
import StoreProductVariantList from '@/models/trans/storeProducts/StoreProductVariantList';
import StoreProductVariantEntry from '@/models/trans/storeProducts/StoreProductVariantEntry';
import StoreProductVariantToVariant from '@/models/trans/storeProducts/StoreProductVariantToVariant';
import Nullable from '@/dataTypes/Nullable';
import StoreProductVariantLinkData from '@/models/trans/storeProducts/StoreProductVariantLinkData';

interface StoreProductFormProps {
    product: StoreProduct;
    updateProduct: (product: StoreProduct) => void;
    assets: AssetList;
    assetGroupList: AssetGroupList;
    saveEnabled: boolean;
    saveProduct: (product: StoreProduct) => void;
    deleteProduct: (productId: number) => void;

    variants: StoreProductVariantList;
}

export default function StoreProductForm({
    product,
    updateProduct,
    assets,
    assetGroupList,
    saveEnabled,
    saveProduct,
    deleteProduct,
    variants,
}: StoreProductFormProps) {
    const [inventoryLocation, setInventoryLocation] = useState<InventoryLocationData[]>([]);
    const storeItemService = useInjection<IStoreItemService>('IStoreItemService');
    useEffect(() => {
        const getData = async () => {
            const result = await storeItemService.getInventoryLocation();
            setInventoryLocation([...result]);
        };
        getData();
    }, []);

    return (
        <Container fluid mt="lg">
            <Stack>
                <TextInput
                    value={product.title}
                    label="Title"
                    onChange={(e) => {
                        e.preventDefault();
                        product.title = e.target.value;
                        updateProduct(product);
                    }}
                />

                <ProductShopifyData product={product} updateProduct={updateProduct} />
                <ProductVariants
                    variants={variants}
                    assets={assets}
                    assetGroups={assetGroupList}
                    product={product}
                    updateProduct={updateProduct}
                    inventoryLocations={inventoryLocation}
                />
            </Stack>
            <Flex w="max-content" ml="auto" mt="lg" gap="md">
                <Button
                    size="md"
                    radius="md"
                    disabled={!saveEnabled}
                    onClick={(e) => {
                        e.preventDefault();
                        saveProduct(product);
                    }}
                >
                    Update
                </Button>

                <Button
                    size="md"
                    radius="md"
                    color="red"
                    variant="outline"
                    onClick={(e) => {
                        e.preventDefault();
                        deleteProduct(product.id);
                    }}
                >
                    Delete
                </Button>
            </Flex>
        </Container>
    );
}

interface IProductUpdateProps {
    product: StoreProduct;
    updateProduct: (product: StoreProduct) => void;
}

interface IProductVariantUpdateProps {
    productVariant: StoreProductVariant;
    updateProductVariant: (product: StoreProductVariant) => void;
}

function ProductShopifyData({ product, updateProduct }: IProductUpdateProps) {
    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ color: [] }, { background: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            [{ align: [false, 'center', 'right', 'justify'] }],
            ['link', 'video'],
            ['clean'],
        ],
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'color',
    ];

    const getCheckboxLabel = () => {
        const storeName = process.env.REACT_APP_STORE_NAME;
        return (
            <Group>
                Is Shopify Item
                <>
                    {is_shopify_linked && product.shopify_product_id > 0 && (
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://${storeName}.myshopify.com/admin/products/${product.shopify_product_id}`}
                        >
                            (View on Shopify)
                        </a>
                    )}
                </>
            </Group>
        );
    };
    const { is_shopify_linked, shopify_product_data } = product;
    return (
        <>
            <Checkbox
                checked={is_shopify_linked}
                label={getCheckboxLabel()}
                onChange={(e) => {
                    product.is_shopify_linked = e.currentTarget.checked;
                    if (product.is_shopify_linked && !product.shopify_product_data) {
                        product.shopify_product_data = {
                            description: '',
                            track_inventory: false,
                            sell_out_of_stock: false,
                            images: [],
                            product_type: 'sim-item',
                        };

                        product.product_tag = 'sim-item';

                        product.variants.forEach((x) => {
                            if (!x.shopify_variant_data) {
                                x.shopify_variant_data = {
                                    charge_tax: false,
                                    price: 0,
                                    inventory_item_id: 0,
                                    shopify_inventory_levels: {
                                        inventories: [],
                                    },
                                };
                            }
                        });
                    }
                    updateProduct(product);
                }}
            />

            {is_shopify_linked && !!shopify_product_data && (
                <Flex gap="md" direction="column">
                    <Select
                        label="Item Type"
                        data={[
                            { value: 'sim-item', label: 'Login To Checkout' },
                            { value: 'sim-email', label: 'Email When Checkout' },
                        ]}
                        value={product.product_tag}
                        onChange={(e) => {
                            if (!e) return;
                            product.product_tag = e;
                            shopify_product_data.product_type = e!;
                            updateProduct(product);
                        }}
                    />
                    <Checkbox
                        label="Track Inventory"
                        checked={shopify_product_data.track_inventory}
                        onChange={(x) => {
                            shopify_product_data.track_inventory = x.currentTarget.checked;
                            product.shopify_product_data = shopify_product_data;
                            updateProduct(product);
                        }}
                    />
                    <Checkbox
                        label="Sell Out Of Stock"
                        checked={shopify_product_data.sell_out_of_stock}
                        onChange={(x) => {
                            shopify_product_data.sell_out_of_stock = x.currentTarget.checked;
                            product.shopify_product_data = shopify_product_data;
                            updateProduct(product);
                        }}
                    />

                    <ReactQuill
                        theme="snow"
                        style={{ background: 'white', color: 'black' }}
                        value={shopify_product_data.description}
                        onChange={(e, d, s) => {
                            if (e === shopify_product_data.description || s === 'api') return;
                            shopify_product_data.description = e;
                            product.shopify_product_data = shopify_product_data;
                            updateProduct(product);
                        }}
                        modules={modules}
                        formats={formats}
                    />

                    <ShopifyStoreMediaComponent
                        images={shopify_product_data.images}
                        onChange={(x) => {
                            shopify_product_data.images = x;
                            product.shopify_product_data = shopify_product_data;
                            updateProduct(product);
                        }}
                    />
                </Flex>
            )}
        </>
    );
}

function DeliveryDetails({ productVariant, updateProductVariant }: IProductVariantUpdateProps) {
    const { instant_delivery, min_delivery_days, max_delivery_days } = productVariant;
    return (
        <>
            <Checkbox
                checked={instant_delivery}
                label="Instant Delivery"
                onChange={(e) => {
                    productVariant.instant_delivery = e.currentTarget.checked;
                    updateProductVariant(productVariant);
                }}
            />

            {!instant_delivery && (
                <Flex gap="md">
                    <TextInput
                        type="number"
                        id="min_delivery_days"
                        onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            productVariant.min_delivery_days = parseInt(value);
                            updateProductVariant(productVariant);
                        }}
                        value={min_delivery_days}
                        label="Min Delivery Days"
                    />
                    <TextInput
                        type="number"
                        id="max_delivery_days"
                        onChange={(e) => {
                            e.preventDefault();
                            const { value } = e.target;
                            productVariant.max_delivery_days = parseInt(value);
                            updateProductVariant(productVariant);
                        }}
                        value={max_delivery_days}
                        label="Max Delivery Days"
                    />
                </Flex>
            )}
        </>
    );
}

function ProductVariantShopifyDataInventory({
    product,
    variant,
    updateVariant,
    isShopifyLinked,
    inventoryLocations,
}: {
    product: StoreProduct;
    variant: StoreProductVariant;
    updateVariant: (variant: StoreProductVariant) => void;
    isShopifyLinked: boolean;
    inventoryLocations: InventoryLocationData[];
}) {
    if (!variant.shopify_variant_data) return <></>;

    if (!product.shopify_product_data) return <></>;

    if (!product.shopify_product_data.track_inventory) return <></>;

    const {
        shopify_variant_data: { shopify_inventory_levels },
    } = variant;

    const inventorySelectData = inventoryLocations.map<SelectItem>((x) => ({
        value: x.id.toString(),
        label: x.name,
        selected: true,
    }));

    return (
        <>
            <Flex direction="column" gap="sm">
                <Divider my="xs" label="Inventory" />
                {shopify_inventory_levels.inventories.map((inventory, i) => {
                    const target = inventorySelectData.find((x) => inventory.location_id.toString() === x.value);
                    console.log(target);
                    if (!!target) target.selected = true;
                    return (
                        <Group key={i}>
                            #{i + 1}
                            <Select
                                value={!!target ? target.value : undefined}
                                data={[...inventorySelectData]}
                                onChange={(e) => {
                                    if (!e) return;
                                    inventory.location_id = parseInt(e)!;
                                    shopify_inventory_levels.inventories[i] = inventory;
                                    variant.shopify_variant_data!.shopify_inventory_levels = shopify_inventory_levels;
                                    updateVariant(variant);
                                }}
                            />
                            <TextInput
                                type="number"
                                value={inventory.count}
                                onChange={(e) => {
                                    e.preventDefault();
                                    const { value } = e.target;
                                    inventory.count = parseInt(value)!;
                                    shopify_inventory_levels.inventories[i] = inventory;
                                    variant.shopify_variant_data!.shopify_inventory_levels = shopify_inventory_levels;
                                    updateVariant(variant);
                                }}
                            />
                            <Button
                                color="red"
                                variant="outline"
                                disabled={shopify_inventory_levels.inventories.length === 1}
                                onClick={(e) => {
                                    e.preventDefault();
                                    shopify_inventory_levels.inventories.splice(i, 1);
                                    variant.shopify_variant_data!.shopify_inventory_levels = shopify_inventory_levels;
                                    updateVariant(variant);
                                }}
                            >
                                Delete
                            </Button>
                        </Group>
                    );
                })}
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        shopify_inventory_levels.inventories.push({
                            count: 0,
                            location_id: inventoryLocations[0].id,
                        });
                        variant.shopify_variant_data!.shopify_inventory_levels = shopify_inventory_levels;
                        updateVariant(variant);
                    }}
                >
                    Add New Inventory
                </Button>
            </Flex>
        </>
    );
}

function ProductVariantShopifyImage({
    product,
    variant,
    updateVariant,
    isShopifyLinked,
}: {
    product: StoreProduct;
    variant: StoreProductVariant;
    updateVariant: (variant: StoreProductVariant) => void;
    isShopifyLinked: boolean;
}) {
    if (!variant.shopify_variant_data) return <></>;

    if (!product.shopify_product_data) return <></>;

    const images = product.shopify_product_data.images.map((x, index) => ({
        value: x.position?.toString(),
        image: !!x.attachment ? x.attachment : x.src,
        label: `Image #${x.position!}`,
        selected: x.position === variant.shopify_variant_data?.image_index,
    }));

    interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
        image: string;
        label: string;
        description: string;
    }

    const SelectItemImage = forwardRef<HTMLDivElement, ItemProps>(({ image, label, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <Avatar src={image} />

                <div>
                    <Text size="sm">{label}</Text>
                </div>
            </Group>
        </div>
    ));

    let image = '';

    if (!!variant.shopify_variant_data.image_index) {
        const imageTarget = product.shopify_product_data.images.find(
            (x) => x.position === variant.shopify_variant_data!.image_index
        );
        if (!!imageTarget) {
            if (!!imageTarget.attachment) image = imageTarget.attachment;

            if (!!imageTarget.src) image = imageTarget.src;
        }
    }

    const imageIndexInt = variant.shopify_variant_data.image_index;

    return (
        <>
            <Divider label="Image" />
            <Group>
                <Avatar src={image} size="xl" />
                <Select
                    itemComponent={SelectItemImage}
                    value={imageIndexInt?.toString()}
                    //@ts-ignore
                    data={images}
                    onChange={(e) => {
                        if (!variant.shopify_variant_data || !e) return;
                        variant.shopify_variant_data.image_index = parseInt(e);
                        updateVariant(variant);
                    }}
                    maxDropdownHeight={400}
                />
            </Group>
        </>
    );
}

function ProductVariantShopifyData({
    product,
    variant,
    updateVariant,
    isShopifyLinked,
    inventoryLocations,
}: {
    product: StoreProduct;
    variant: StoreProductVariant;
    updateVariant: (variant: StoreProductVariant) => void;
    isShopifyLinked: boolean;
    inventoryLocations: InventoryLocationData[];
}) {
    if (!isShopifyLinked) return <></>;

    const { shopify_variant_data } = variant;
    console.log(shopify_variant_data);
    if (!shopify_variant_data) return <></>;

    return (
        <>
            <Select
                label="Distribution Type"
                value={variant.fulfillment_type.toString()}
                data={[
                    {
                        label: 'Add to Account',
                        value: '0',
                    },
                    {
                        label: 'Distribute by Email',
                        value: '1',
                    },
                ]}
                onChange={(e) => {
                    if (!!e) {
                        variant.fulfillment_type = parseInt(e);
                        updateVariant(variant);
                    }
                }}
            />
            <Checkbox
                label="Auto fulfill"
                checked={variant.auto_fulfill}
                onChange={(e) => {
                    e.preventDefault();
                    variant.auto_fulfill = e.target.checked;
                }}
            />
            <TextInput
                type="number"
                value={shopify_variant_data.price}
                label="Price"
                onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    shopify_variant_data.price = parseFloat(value);
                    variant.shopify_variant_data = shopify_variant_data;
                    updateVariant(variant);
                }}
            />
            <Checkbox
                checked={shopify_variant_data.charge_tax}
                label="Charge Tax"
                onChange={(e) => {
                    const { checked } = e.target;
                    shopify_variant_data.charge_tax = checked;
                    variant.shopify_variant_data = shopify_variant_data;
                    updateVariant(variant);
                }}
            />

            <ProductVariantShopifyDataInventory
                product={product}
                variant={variant}
                updateVariant={updateVariant}
                isShopifyLinked={isShopifyLinked}
                inventoryLocations={inventoryLocations}
            />
            <ProductVariantShopifyImage
                product={product}
                variant={variant}
                updateVariant={updateVariant}
                isShopifyLinked={isShopifyLinked}
            />
        </>
    );
}

interface ValueLabel {
    value: string;
    label: string;
}

interface LinkedAssetData {
    value: string;
    quantity: number;
}

function LinkedAsset({
    selectData,
    data,
    index,
    update,
    canEditQuantity,
}: {
    selectData: ValueLabel[];
    data: LinkedAssetData;
    index: number;
    canEditQuantity: boolean;
    update: (index: number, oldData: LinkedAssetData, newData: LinkedAssetData) => void;
}) {
    return (
        <Flex gap="md" justify="stretch" align="center" direction="row" wrap="wrap" mih={50}>
            <Select
                sx={{
                    flex: 0.8,
                }}
                data={selectData}
                value={data.value}
                onChange={(e) => {
                    if (!e) return;
                    update(
                        index,
                        {
                            ...data,
                        },
                        {
                            ...data,
                            value: e,
                        }
                    );
                }}
            ></Select>
            <NumberInput
                disabled={!canEditQuantity}
                min={1}
                max={100}
                sx={{
                    flex: 0.2,
                }}
                value={!!canEditQuantity ? data.quantity : 1}
                onChange={(e) => {
                    if (!e) return;
                    update(
                        index,
                        { ...data },
                        {
                            ...data,
                            quantity: e,
                        }
                    );
                }}
            />
            <Button
                color="red"
                onClick={(e) => {
                    update(
                        index,
                        { ...data },
                        {
                            ...data,
                            quantity: 0,
                        }
                    );
                }}
            >
                <BsTrash />
            </Button>
        </Flex>
    );
}

const AssetIDGenerator = {
    getGroupId: (x: number) => {
        return `gr_${x}`;
    },
    getAssetId: (x: AssetEntry) => {
        return `ia_${x.id}_${x.game_id}`;
    },
    getAssetDataId: (x: AssetData) => {
        return `ia_${x.asset_id}_${x.game_id}`;
    },
    getVariantId: (x: StoreProductVariantEntry) => {
        return `var_${x.variant_id}`;
    },
    getLinkedVariantId: (x: StoreProductVariantToVariant) => {
        return `var_${x.child_variant_id}`;
    },
    isAssetDataId: (id: string) => {
        return id.startsWith('ia_');
    },
    isGroupDataId: (id: string) => {
        return id.startsWith('gr_');
    },
    isVariantDataId: (id: string) => {
        return id.startsWith('var_');
    },
    getLinkedItemId: (item: StoreProductVariantLinkData) => {
        if (!!item.assetData) return AssetIDGenerator.getAssetDataId(item.assetData);

        if (!!item.assetGroupData) return AssetIDGenerator.getGroupId(item.assetGroupData);

        if (!!item.variantData) return AssetIDGenerator.getLinkedVariantId(item.variantData);

        return null;
    },
};

function LinkedAssetPicker({
    variant,
    assets,
    assetGroups,
    index,
    product,
    variants,
    updateProduct,
}: {
    variant: StoreProductVariant;
    assets: AssetList;
    assetGroups: AssetGroupList;
    index: number;
    product: StoreProduct;

    variants: StoreProductVariantList;
    updateProduct: (product: StoreProduct) => void;
}) {
    const updateVariant = () => {
        product.variants[index] = variant;
        updateProduct(product);
    };

    const valueOfAssets: string[] = [];

    const quantities = new Map<string, number>();

    let map = new Map<string, StoreProductVariantLinkData>();

    if (!!variant.linked_items) {
        variant.linked_items.forEach((x) => {
            let key: Nullable<string> = AssetIDGenerator.getLinkedItemId(x);
            if (!key) return;

            map.set(key, x);

            if (!!x.assetData) {
                quantities.set(key, x.assetData.quantity);
            }

            if (!!x.assetGroupData) {
                quantities.set(key, 1);
            }

            if (!!x.variantData) {
                quantities.set(key, x.variantData.quantity);
            }

            valueOfAssets.push(key);
        });
    }

    let assetMap = new Map<string, AssetEntry>();
    assets.assets.forEach((asset) => {
        assetMap.set(AssetIDGenerator.getAssetId(asset), asset);
    });

    let groupMap = new Map<string, number>();

    let variantMap = new Map<string, StoreProductVariantEntry>();

    let selectData = assets.assets.map((asset) => ({
        value: AssetIDGenerator.getAssetId(asset),
        label: asset.display_name,
        disabled: false,
    }));

    assetGroups.items.forEach((x) => {
        selectData.push({
            value: AssetIDGenerator.getGroupId(x.id),
            label: `${x.name} 📁`,
            disabled: false,
        });

        groupMap.set(AssetIDGenerator.getGroupId(x.id), x.id);
    });

    variants.variants.forEach((x) => {
        selectData.push({
            value: AssetIDGenerator.getVariantId(x),
            label: `${x.variant_title} 🛒`,
            disabled: false,
        });

        variantMap.set(AssetIDGenerator.getVariantId(x), x);
    });

    selectData.forEach((x) => (x.disabled = valueOfAssets.findIndex((y) => y === x.value) !== -1));

    const notSelectedAsset: AssetEntry = {
        id: 'notSelect',
        game_id: 'invalid',
        display_name: 'Not Selected',
    };

    assetMap.set(AssetIDGenerator.getAssetId(notSelectedAsset), notSelectedAsset);

    const notSelected = {
        disabled: false,
        label: notSelectedAsset.display_name,
        value: AssetIDGenerator.getAssetId(notSelectedAsset),
    };

    selectData.splice(0, 0, notSelected);

    const changeQuantity = (linkedItem: StoreProductVariantLinkData, quantity: number) => {
        if (!!linkedItem.assetData) {
            linkedItem.assetData.quantity = quantity;
        }
        if (!!linkedItem.assetGroupData) {
            //skip
        }
        if (!!linkedItem.variantData) {
            linkedItem.variantData.quantity = quantity;
        }
    };

    const handleCountChange = (index: number, value: LinkedAssetData) => {
        if (value.quantity !== 0) {
            changeQuantity(variant.linked_items![index], value.quantity);
        } else {
            if (!!variant.linked_items) variant.linked_items.splice(index, 1);
        }

        updateVariant();
    };

    const handleValueChange = (index: number, oldValue: string, newValue: string, quantity: number) => {
        console.log(index);

        if (!!variant.linked_items && !!variant.linked_items[index]) {
            variant.linked_items[index].assetData = undefined;
            variant.linked_items[index].assetGroupData = undefined;
            variant.linked_items[index].variantData = undefined;
        }

        if (!variant.linked_items) {
            variant.linked_items = [];
        }
        if (index >= variant.linked_items.length) {
            variant.linked_items.push({
                assetData: undefined,
                assetGroupData: undefined,
                variantData: undefined,
            });

            index = variant.linked_items.length - 1;
        }

        if (AssetIDGenerator.isAssetDataId(newValue)) {
            const asset = assetMap.get(newValue)!;

            variant.linked_items[index].assetData = {
                asset_id: asset.id,
                game_id: asset.game_id,
                quantity: quantity,
                variant_id: variant.variant_id,
            };
        }
        if (AssetIDGenerator.isGroupDataId(newValue)) {
            variant.linked_items[index].assetGroupData = groupMap.get(newValue)!;
        }
        if (AssetIDGenerator.isVariantDataId(newValue)) {
            const variantEntry = variantMap.get(newValue)!;
            variant.linked_items[index].variantData = {
                parent_variant_id: variant.variant_id,
                child_variant_id: variantEntry.variant_id,
                quantity: 1,
            };
        }

        updateVariant();
    };

    const getRandomAsset = () => {
        return notSelected.value;
    };

    const viewport = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        setTimeout(() => {
            viewport.current?.focus();
            viewport.current?.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
        }, 100);
    };

    return (
        <Flex
            mih={50}
            gap="md"
            justify="stretch"
            align="stretch"
            direction="column"
            sx={{
                width: '100%',
            }}
        >
            <label
                style={{
                    display: 'inline-block',
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: '#212529',
                }}
            >
                Assets
            </label>
            <Flex
                mih={50}
                gap="md"
                justify="stretch"
                align="stretch"
                direction="column"
                sx={{
                    width: '100%',
                    maxHeight: '300px',
                    overflowY : 'auto',

                }}
                className={'assetList'}
                ref={viewport}
            >
                {valueOfAssets.map((x, i) => (
                    <LinkedAsset
                        index={i}
                        selectData={selectData}
                        data={{
                            value: x,
                            quantity: quantities.get(x)!,
                        }}
                        update={(index, oldData, newData) => {
                            if (oldData.value === newData.value) {
                                handleCountChange(index, newData);
                            } else {
                                handleValueChange(index, oldData.value, newData.value, newData.quantity);
                            }
                        }}
                        key={i}
                        canEditQuantity={AssetIDGenerator.isVariantDataId(x)}
                    />
                ))}
            </Flex>

            <Button
                onClick={(e) => {
                    handleValueChange(
                        !!variant.linked_items ? variant.linked_items.length : 1,
                        '',
                        getRandomAsset(),
                        1
                    );
                    scrollToBottom();
                }}
            >
                <Flex gap="md" align={'center'}>
                    <BsPlusCircle size={20} />
                    Add New Linked Item
                </Flex>
            </Button>
        </Flex>
    );
}

function ProductVariant({
    variant,
    index,
    product,
    updateProduct,
    assets,
    assetGroups,
    variants,
    inventoryLocations,
}: {
    variant: StoreProductVariant;
    index: number;
    product: StoreProduct;
    updateProduct: (product: StoreProduct) => void;
    assets: AssetList;
    assetGroups: AssetGroupList;
    variants: StoreProductVariantList;
    inventoryLocations: InventoryLocationData[];
}) {
    const { variant_title } = variant;
    const { is_shopify_linked } = product;

    const updateVariant = () => {
        product.variants[index] = variant;
        updateProduct(product);
    };

    if (is_shopify_linked && !variant.shopify_variant_data) {
        variant.shopify_variant_data = {
            charge_tax: false,
            inventory_item_id: 0,
            price: 0,
            shopify_inventory_levels: {
                inventories: [],
            },
            image_index: 0,
        };
        updateVariant();
    }

    return (
        <Box
            sx={(_) => ({
                padding: '8px',
            })}
        >
            <Stack>
                <Flex justify="space-between" align="center">
                    <h3>Variant #{index + 1}</h3>
                    <Button
                        variant="light"
                        color="red"
                        onClick={(e) => {
                            e.preventDefault();
                            product.variants.splice(index, 1);
                            updateProduct(product);
                        }}
                    >
                        Remove
                    </Button>
                </Flex>
            </Stack>
            <Stack
                sx={{
                    paddingLeft: '16px',
                    borderLeft: '4px solid #228be6',
                }}
            >
                <TextInput
                    label="Variant Title"
                    id={`variant_title_${index + 1}`}
                    value={variant_title}
                    onChange={(e) => {
                        e.preventDefault();
                        variant.variant_title = e.target.value;
                        updateVariant();
                    }}
                />
                <DeliveryDetails productVariant={variant} updateProductVariant={updateVariant} />
                <LinkedAssetPicker
                    assetGroups={assetGroups}
                    assets={assets}
                    index={index}
                    product={product}
                    updateProduct={updateProduct}
                    variant={variant}
                    variants={variants}
                />
                <ProductVariantShopifyData
                    inventoryLocations={inventoryLocations}
                    product={product}
                    variant={variant}
                    updateVariant={updateVariant}
                    isShopifyLinked={is_shopify_linked}
                />
            </Stack>
        </Box>
    );
}

function ProductVariants({
    product,
    updateProduct,
    assets,
    assetGroups,
    variants,
    inventoryLocations,
}: {
    product: StoreProduct;
    updateProduct: (product: StoreProduct) => void;
    assets: AssetList;
    assetGroups: AssetGroupList;
    variants: StoreProductVariantList;
    inventoryLocations: InventoryLocationData[];
}) {
    return (
        <Box>
            <Stack>
                <h2>Variants</h2>

                {product.variants.map((variant, index) => (
                    <ProductVariant
                        key={`variant_${index}`}
                        variant={variant}
                        product={product}
                        updateProduct={updateProduct}
                        assets={assets}
                        assetGroups={assetGroups}
                        index={index}
                        inventoryLocations={inventoryLocations}
                        variants={variants}
                    />
                ))}

                <Button
                    variant="outline"
                    onClick={(e) => {
                        e.preventDefault();
                        product.variants.push({
                            assets: [],
                            shopify_variant_id: 0,
                            store_product_id: product.id,
                            variant_id: 0,
                            variant_title: '',
                            asset_groups: [],
                            fulfillment_type: VariantFullmentType.AutoConsume,
                            linked_variants: [],
                            auto_fulfill: true,
                            instant_delivery: true,
                            min_delivery_days: 0,
                            max_delivery_days: 0,
                        });
                        updateProduct(product);
                    }}
                >
                    + Create Variant
                </Button>
            </Stack>
        </Box>
    );
}

import 'react-quill/dist/quill.snow.css';
import AssetList from '../../../models/trans/AssetList';
import {
    Box,
    Button,
    Container,
    Flex,
    MultiSelect,
    Stack,
    TextInput,
} from '@mantine/core';
import AssetEntry from '@/models/trans/AssetEntry';
import AssetGroup from '../../../models/trans/asset-group/AssetGroup';
import Nullable from '@/dataTypes/Nullable';
import AssetGroupLinkedAsset from '@/models/trans/asset-group/AssetGroupLinkedAsset';
import {  useState } from 'react';
import AssetGroupList from '@/models/trans/asset-group/AssetGroupList';

interface AssetGroupFormProps {
    assetGroup: Nullable<AssetGroup>;
    updateAssetGroup: (assetGroup: AssetGroup) => void;
    assets: AssetList;
    saveEnabled: boolean;
    saveAssetGroup: (product: AssetGroup) => void;
    deleteAssetGroup: (productId: number) => void;

    assetGroupList: AssetGroupList;
}

export default function AssetGroupForm({
    assetGroup,
    updateAssetGroup,
    assets,
    saveEnabled,
    saveAssetGroup,
    deleteAssetGroup,
    assetGroupList,
}: AssetGroupFormProps) {
    const [toggle, setToggle] = useState(false);

    if (!assetGroup) {
        return (
            <Container fluid mt="lg">
                <h3>Loading data</h3>
            </Container>
        );
    }

    return (
        <Container fluid mt="lg">
            <Stack>
                <TextInput
                    value={assetGroup.name}
                    label="Title"
                    onChange={(e) => {
                        e.preventDefault();
                        assetGroup.name = e.target.value;
                        updateAssetGroup(assetGroup);
                        setToggle(!toggle);
                    }}
                />

                <AssetsPicker
                    assetGroups={assetGroupList}
                    assetGroup={assetGroup}
                    assets={assets}
                    updateAssetGroup={(assetGroup) => {
                        updateAssetGroup(assetGroup);
                        setToggle(!toggle);
                    }}
                />
            </Stack>
            <Flex w="max-content" ml="auto" mt="lg" gap="md">
                <Button
                    size="md"
                    radius="md"
                    disabled={!saveEnabled}
                    onClick={(e) => {
                        e.preventDefault();
                        saveAssetGroup(assetGroup);
                    }}
                >
                    Update
                </Button>

                <Button
                    size="md"
                    radius="md"
                    color="red"
                    variant="outline"
                    onClick={(e) => {
                        e.preventDefault();
                        deleteAssetGroup(assetGroup.id);
                    }}
                >
                    Delete
                </Button>
            </Flex>
        </Container>
    );
}

function AssetsPicker({
    assetGroup,
    updateAssetGroup,
    assets,
    assetGroups,
}: {
    assetGroup: AssetGroup;
    updateAssetGroup: (assetGroup: AssetGroup) => void;
    assets: AssetList;
    assetGroups: AssetGroupList;
}) {
    const valueOfAssets = assetGroup.assets.map((x) => `ia_${x.id}_${x.game_id}`);

    if (!!assetGroup.asset_groups) {
        assetGroup.asset_groups.forEach((x) => {
            valueOfAssets.push(`gr_${x}`);
        });
    }

    let map = new Map<string, AssetEntry>();
    assets.assets.forEach((asset) => {
        map.set(`ia_${asset.id}_${asset.game_id}`, asset);
    });

    let groupMap = new Map<string, number>();

    let selectData = assets.assets.map((asset) => ({
        value: `ia_${asset.id}_${asset.game_id}`,
        label: asset.display_name,
    }));

    assetGroups.items.forEach((x) => {
        if(x.id!==assetGroup.id){
            selectData.push({
                value: `gr_${x.id}`,
                label: `${x.name} 📁`,
            });
        }
            
        groupMap.set(`gr_${x.id}`, x.id);
    });

    return (
        <Box
            sx={(_) => ({
                padding: '8px',
            })}
        >
            <Stack>
                <MultiSelect
                    label="Assets"
                    value={valueOfAssets}
                    onChange={(values) => {
                        const assets: AssetGroupLinkedAsset[] = [];
                        for (const value of values) {
                            const entry = map.get(value);
                            if (!entry) continue;
                            const assetData: AssetGroupLinkedAsset = {
                                id: entry.id,
                                game_id: entry.game_id,
                            };
                            assets.push(assetData);
                        }
                        assetGroup.assets = assets;

                        const assetGroups: number[] = [];
                        for (const value of values) {
                            const entry = groupMap.get(value);
                            if (!entry) continue;

                            assetGroups.push(entry);
                        }
                        assetGroup.asset_groups = assetGroups;

                        updateAssetGroup(assetGroup);
                    }}
                    data={selectData}
                />
            </Stack>
        </Box>
    );
}

import dayjs from "dayjs";

export default class Time {
    private innerDate : dayjs.Dayjs;
    

    setValues(hour: number, minutes: number, seconds: number): Time{
        this.innerDate = dayjs(`1978-09-26 ${hour}:${minutes}:${seconds}`);
        return this;
    }

    parse(timeString: string) : Time{
        this.innerDate = dayjs(`1978-09-26 ${timeString}`);
        return this;
    }

    toUTC() : Time{
        const utcOffset = dayjs().utcOffset();
        this.addMinutes(-utcOffset);
        return this;
    }

    toLocal() : Time{
        const utcOffset = dayjs().utcOffset();  
        this.addMinutes(utcOffset);
        return this;
    }

    constructor(){
        this.innerDate = dayjs(Date.now());
    }

    public addMinutes(minutesToAdd : number){
        this.innerDate = this.innerDate.add(minutesToAdd, 'm');
    }
    
    public addHours(hoursToAdd : number){
        this.innerDate = this.innerDate.add(hoursToAdd, 'h');
    }
    
    public addSeconds(secondsToAdd : number){
        this.innerDate = this.innerDate.add(secondsToAdd, 's');
    }

    toString(format: string, timeZone?: string, convertToUTC? : boolean) : string{
        // if(!!timeZone){
        //     console.log('Converting to ' + timeZone);
        //     console.log(this.toString('HH:mm'));
        //     console.log('becomes');
        //     var time = this.innerDate.utc(true).tz(timeZone);
        //     console.log(time.format('HH:mm'));
        // }

        // const date = new Date(Date.now());
        // console.log('extra log');
        // console.log(new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long', timeZone: 'America/Chicago' }).format(date));

        if(!!convertToUTC){
            return this.innerDate.utc(true).tz(timeZone).format(format);
        }
        return this.innerDate.tz(timeZone).format(format);
    }
}
import React, { useState } from 'react';
import { TextInput, Button, Popover, Stack, ScrollArea, Flex, Text } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { IconChevronRight } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { BsCalendar4Week } from 'react-icons/bs';
import { useForm } from '@mantine/form';

const predefinedValues = [
    { value: 15, unit: 's', label: 'last 15 seconds' },
    { value: 30, unit: 's', label: 'last 30 seconds' },
    { value: 1, unit: 'm', label: 'last 1 minute' },
    { value: 5, unit: 'm', label: 'last 5 minutes' },
    { value: 10, unit: 'm', label: 'last 10 minutes' },
    { value: 15, unit: 'm', label: 'last 15 minutes' },
    { value: 30, unit: 'm', label: 'last 30 minutes' },
    { value: 45, unit: 'm', label: 'last 45 minutes' },
    { value: 1, unit: 'h', label: 'last 1 hour' },
    { value: 3, unit: 'h', label: 'last 3 hours' },
    { value: 6, unit: 'h', label: 'last 6 hours' },
    { value: 12, unit: 'h', label: 'last 12 hours' },
    { value: 1, unit: 'd', label: 'last 1 day' },
    { value: 2, unit: 'd', label: 'last 2 days' },
    { value: 7, unit: 'd', label: 'last 7 days' },
    { value: 15, unit: 'd', label: 'last 15 days' },
    { value: 30, unit: 'd', label: 'last 30 days' },
];

interface DateTimeRangePickerForm {
    startDate: Date;
    endDate: Date;
}

interface DateTimeRangePickerProps {
    startDate: Date;
    endDate: Date;
    handleApply: (data: DateTimeRangePickerForm) => void;
    handleCancel: () => void;
}

const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({ startDate, endDate, handleApply, handleCancel }) => {
    const form = useForm<DateTimeRangePickerForm>({
        initialValues: {
            startDate: startDate,
            endDate: endDate,
        },
    });

    return (
        <form onSubmit={form.onSubmit(handleApply)}>
            <Stack w={300}>
                <Text>Start and end times</Text>
                <DateTimePicker radius="md" {...form.getInputProps('startDate')} />
                <DateTimePicker radius="md" {...form.getInputProps('endDate')} />
                <Flex ml="auto">
                    <Button type="button" size="xs" variant="subtle" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button type="submit" size="xs">
                        Apply
                    </Button>
                </Flex>
            </Stack>
        </form>
    );
};

interface IDateTime {
    startDate: Date;
    endDate: Date;
}

interface TimePickerProps {
    startEndDates: IDateTime;
    setStartEndDates: (startEndDates: IDateTime) => void;
    children: (props: { timePickerBtnLabel: string; togglePopover: () => void }) => React.ReactNode;
}

export const TimePicker = ({ startEndDates, setStartEndDates, children }: TimePickerProps) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const [timePickerBtnLabel, setTimePickerBtnLabel] = useState('Last 24 hours');

    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState<string | null>(null);

    const [showDatePicker, setShowDatePicker] = useState(false);

    const validateInput = (value: string) => {
        const regex = /^(\d+)(m|h|d|w)$/i;

        if (!value) {
            return null;
        }

        if (!regex.test(value)) {
            if (/\D+$/.test(value)) {
                return 'Invalid unit. Use "m", "h", "d", or "w".';
            } else {
                return 'Invalid format. Use a number followed by a unit (m, h, d, w).';
            }
        }

        return null;
    };

    const labelFromInput = (input: string) => {
        const match = /^(\d+)(m|h|d|w)$/i.exec(input);

        if (!match) {
            return;
        }

        const value = parseInt(match[1], 10);
        const unit = match[2].toLowerCase();

        let unitLabel = '';

        switch (unit) {
            case 'm':
                unitLabel = value === 1 ? 'minute' : 'minutes';
                break;
            case 'h':
                unitLabel = value === 1 ? 'hour' : 'hours';
                break;
            case 'd':
                unitLabel = value === 1 ? 'day' : 'days';
                break;
            case 'w':
                unitLabel = value === 1 ? 'week' : 'weeks';
                break;
            default:
                return null;
        }

        setTimePickerBtnLabel(`Last ${value} ${unitLabel}`);
    };

    const updateStartEndDates = (input: string) => {
        const match = /^(\d+)(m|h|d|w)$/i.exec(input);

        if (!match) {
            return;
        }

        const value = parseInt(match[1], 10);
        const unit = match[2].toLowerCase();

        let unitLabel: dayjs.ManipulateType | undefined;

        switch (unit) {
            case 'm':
                unitLabel = 'minute';
                break;
            case 'h':
                unitLabel = 'hour';
                break;
            case 'd':
                unitLabel = 'day';
                break;
            case 'w':
                unitLabel = 'week';
                break;
            default:
                return null;
        }

        if (!unitLabel) return;

        setStartEndDates({
            startDate: dayjs().subtract(value, unitLabel).toDate(),
            endDate: dayjs().toDate(),
        });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            const error = validateInput(inputValue);
            if (!error) {
                labelFromInput(inputValue);
                updateStartEndDates(inputValue);
            }
            setInputError(error);
        }
    };

    const handlePredefinedValueClick = (value: number, unit: string) => {
        const label = predefinedValues.find((v) => v.value === value && v.unit === unit)?.label;
        if (label) {
            setTimePickerBtnLabel(label);
        }
        updateStartEndDates(`${value}${unit}`);
    };

    const handleCancel = () => {
        setShowDatePicker(false);
    };

    const handleApply = (data: IDateTime) => {
        const startDateLabel = dayjs(data.startDate).format('YYYY/MM/DD');
        const startTimeLabel = dayjs(data.startDate).format('HH:mm A');
        const endDateLabel = dayjs(data.endDate).format('YYYY/MM/DD');
        const endTimeLabel = dayjs(data.endDate).format('HH:mm A');

        if (startDateLabel === endDateLabel) {
            setTimePickerBtnLabel(`${startDateLabel} ${startTimeLabel} - ${endTimeLabel}`);
        } else {
            setTimePickerBtnLabel(`${startDateLabel} ${startTimeLabel} - ${endDateLabel} ${endTimeLabel}`);
        }
        setStartEndDates(data);
        setPopoverOpen(false);
    };

    const content = (
        <Flex gap="md">
            <Stack w={250}>
                <TextInput
                    label="Relative time"
                    placeholder="E.g., 20m, 3h, 1d, 2w"
                    size="xs"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    error={inputError}
                />
                <ScrollArea h={300} scrollbarSize={5}>
                    <Stack spacing={2}>
                        {predefinedValues.map(({ value, unit, label }, index) => (
                            <Button
                                key={index}
                                onClick={() => handlePredefinedValueClick(value, unit)}
                                variant={timePickerBtnLabel === label ? 'light' : 'subtle'}
                                color={timePickerBtnLabel === label ? 'blue' : 'gray'}
                                size="xs"
                                ta="left"
                                styles={{
                                    label: { display: 'flex', justifyContent: 'space-between', width: '100%' },
                                }}
                                fullWidth
                            >
                                <div>{label}</div>
                                <div>
                                    {value}
                                    {unit}
                                </div>
                            </Button>
                        ))}
                    </Stack>
                </ScrollArea>
                <Button
                    variant="subtle"
                    color="gray"
                    onClick={() => setShowDatePicker(!showDatePicker)}
                    leftIcon={<BsCalendar4Week />}
                    rightIcon={<IconChevronRight size={20} />}
                    styles={{
                        inner: { justifyContent: 'space-between' },
                    }}
                >
                    Start and end times
                </Button>
            </Stack>
            {showDatePicker && (
                <DateTimeRangePicker {...startEndDates} handleApply={handleApply} handleCancel={handleCancel} />
            )}
        </Flex>
    );

    return (
        <>
            <Popover
                opened={popoverOpen}
                onChange={setPopoverOpen}
                width="max-content"
                trapFocus
                position="bottom-start"
                withArrow
                shadow="md"
            >
                <Popover.Target>
                    {children({ timePickerBtnLabel, togglePopover: () => setPopoverOpen(!popoverOpen) })}
                </Popover.Target>
                <Popover.Dropdown>{content}</Popover.Dropdown>
            </Popover>
        </>
    );
};

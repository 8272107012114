import axios from 'axios';
import { inject } from 'inversify';

import type { IAuthService } from '../../services/IAuthService';
import { IMonitoringRESTClient as IMonitoringRESTClient } from '../IMonitoringRESTClient';
import { RESTClient } from './RESTClient';
import { TimeSeriesList } from '../../models/monitoring/server-activity/TimeSeriesList';
import { GameRoom } from '../../models/monitoring/server-activity/gameRoom/GameRoom';
import { GameRoomList } from '@/models/monitoring/server-activity/gameRoom/GameRoomList';
import { Shard } from '@/models/monitoring/server-activity/Shard';
import MonitoringDashboardList from '@/models/monitoring/server-activity/MonitoringDashboardList';
import { ServerLogList } from '@/models/monitoring/server-logs/ServerLogList';

export default class MonitoringRESTClient extends RESTClient implements IMonitoringRESTClient {
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    constructor() {
        super();
        //
        this.setBaseURL('https://wod-monitoring-uux56memxa-uc.a.run.app/api/v1/');
    }

    async getGoogleCloudDashboards(): Promise<MonitoringDashboardList> {
        const token = await this.authService.getToken();
        if (token === null) {
            throw new Error('Unauthorized');
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            throw new Error('Unauthorized');
        }

        const path = this.getFullURL(`monitoring/gcloud/dashboards`);
        const response = await axios.get(path, this.getHeader(authToken));
        return response.data as MonitoringDashboardList;
    }

    async getGoogleCloudDashboardTimeSeries(filter: string): Promise<TimeSeriesList> {
        const token = await this.authService.getToken();
        if (token === null) {
            throw new Error('Unauthorized');
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            throw new Error('Unauthorized');
        }
        
        const path = this.getFullURL(`monitoring/gcloud/timeseries?${filter}`);
        const response = await axios.get(path, this.getHeader(authToken));
        return response.data;
    }

    async getGameRoomInfo(roomID: string): Promise<GameRoom> {
        const token = await this.authService.getToken();
        if (token === null) {
            throw new Error('Unauthorized');
        }
        const authToken = await this.getToken(token);
        
        if (authToken === null) {
            throw new Error('Unauthorized');
        }

        const path = this.getFullURL(`game-room/${roomID}`);
        const response = await axios.get(path, this.getHeader(authToken));
        return response.data;
    }

    async getGameRoomList(shard: string, search: string, page: number): Promise<GameRoomList> {
        const token = await this.authService.getToken();
        if (token === null) {
            throw new Error('Unauthorized');
        }
        const authToken = await this.getToken(token);
        
        if (authToken === null) {
            throw new Error('Unauthorized');
        }

        const path = this.getFullURL(`shard/${shard}/game-rooms?page=${page}&search=${search}`);
        const response = await axios.get(path, this.getHeader(authToken));
        return response.data;
    }

    async getShardStats(shardName: string): Promise<Shard> {
        const token = await this.authService.getToken();
        if (token === null) {
            throw new Error('Unauthorized');
        }
        const authToken = await this.getToken(token);
        
        if (authToken === null) {
            throw new Error('Unauthorized');
        }

        const path = this.getFullURL(`shard/${shardName}`);
        const response = await axios.get(path, this.getHeader(authToken));
        return response.data;
    }

    async getGoogleCloudLogs(shardName: string, filter: string): Promise<ServerLogList> {
        const token = await this.authService.getToken();
        if (token === null) {
            throw new Error('Unauthorized');
        }
        const authToken = await this.getToken(token);
        
        if (authToken === null) {
            throw new Error('Unauthorized');
        }
        
        const path = this.getFullURL(`gcloud/shards/${shardName}/logs`);

        const form = new FormData();
        form.append('filter', filter);

        const response = await axios.post(path, form, this.getHeader(authToken));
        return response.data;
    }

    async deleteRoom(shardName: string, roomId : string): Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) {
            return false;
        }
        const monitoringToken = await this.getToken(token);
        
        if (monitoringToken === null) {
            return false;
        }

        const path = this.getFullURL(`shard/${shardName}/game-room/${roomId}`);
        
        try{
            await axios.delete(path, this.getHeader(monitoringToken))
            return true;
            
        }catch{
            return false;
        }
    }
}

import ITransService from '@/services/ITransService';
import IUIService from '@/services/IUIService';
import { Button, Card, Flex, Table } from '@mantine/core';
import { useInjection } from 'inversify-react';
import { FaHeartbeat } from 'react-icons/fa';
import styles from './rewards-diagnostics-form.module.scss';
import ITreasuryService from '@/services/ITreasuryService';
import { useState } from 'react';
import ShopifyDiagnosticsItem from '@/models/treasury/ShopifyDiagnosticsItem';
import { IoWarning } from 'react-icons/io5';

export default function RewardsDiagnosticsForm() {
    const uiService = useInjection<IUIService>('IUIService');
    const treasuryService = useInjection<ITreasuryService>('ITreasuryService');
    const [diagnostics, setDiagnostics] = useState<ShopifyDiagnosticsItem[]>([]);

    const startDiagnosis = async () => {
        uiService.showLoading();
        const response = await treasuryService.diagnose();
        uiService.hideLoading();

        if (response.length === 0) {
            uiService.showSuccessNotification('No issues found!');
        }

        setDiagnostics(response);
    };

    return (
        <Flex direction="column" sx={{ padding: '10px' }}>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Flex direction="column">
                    <p>Shopify Diagnosis</p>
                    <Flex direction="row" align={'center'} justify={'space-between'}>
                        <div className={styles.hearbeatAnimation}>
                            <FaHeartbeat size={48} />
                        </div>

                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                startDiagnosis();
                            }}
                        >
                            Diagnose
                        </Button>
                    </Flex>
                    {diagnostics.length > 0 && (
                        <>
                            <h3>Issues Found</h3>
                            <Table highlightOnHover>
                                <thead>
                                    <th>
                                        <td>Description</td>
                                        <td></td>
                                    </th>
                                </thead>
                                <tbody>
                                    {diagnostics.map((x) => (
                                        <DiagnosticsItem item={x} />
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Flex>
            </Card>
        </Flex>
    );
}

function DiagnosticsItem({ item }: { item: ShopifyDiagnosticsItem }) {
    return (
        <tr>
            <td>
                <Flex columnGap={'2px'} direction="row" align="center" sx={{ padding : '4px' }}>
                    <IoWarning color='orange' size={24}/>
                    <p>{item.message}</p>
                </Flex>
            </td>
            <td>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        window.open(`https://admin.shopify.com/store/kingdomdeath/products/${item.shopify_id}`);
                    }}
                >
                    Go To Shopify
                </Button>
            </td>
        </tr>
    );
}

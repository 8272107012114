import ShardModel from "@/models/gdm/ShardModel";
import IGDMService from "@/services/IGDMService";
import IUIService from "@/services/IUIService";
import { Box, Button, Card, Container, Flex, Stack } from "@mantine/core";
import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";

export default function DatastoreDashboard() {

    const gdmService = useInjection<IGDMService>('IGDMService')
    const uiService = useInjection<IUIService>('IUIService')

    const [pipelines, setPipelines] = useState<ShardModel[]>([]);

    useEffect(() => {
        uiService.showLoading();
        gdmService.getPipelines().then(response => {
            setPipelines(response);
            uiService.hideLoading();
        })
    }, [])

    const breakCompatibility = async (pipeline: string) => {
        const access = await uiService.showConfirmationDialog('Are you sure you want to break save file compatibility?')
        if (!access)
            return;
        uiService.showLoading();
        const response = await gdmService.breakCompatibility(pipeline)
        uiService.hideLoading();
        if(!!response){
            uiService.showSuccessNotification('Break save file compatibility success.')
        } else{
            uiService.showErrorNotification('Break save file compatibility failed.')
        }
    }

    return (
        <Box>
            <Container px={{ sm: 'xs', xl: 'xl' }} fluid>
                <Card shadow="sm" padding="lg" radius="md" withBorder>
                    <h3>Save File Compatibility</h3>
                    <Stack>
                        {pipelines.map(x => {
                            return (
                                <Flex direction='row' justify='space-between'>{x.name}
                                    <Button 
                                        variant="outline" 
                                        color="orange" 
                                        onClick={async e => {
                                            e.preventDefault();
                                            await breakCompatibility(x.id)
                                        }}>Break Save File Compatibility</Button>
                                </Flex>
                            )
                        })}
                    </Stack>
                </Card>
            </Container>
        </Box>)
}
import { inject, injectable } from 'inversify';
import store from '../../redux/store';
import { setToken, setDataAccess } from '../../redux/slices/authSlice';

import type { ISessionStorageService } from '@/services/ISessionStorageService';
import type { IAuthService } from '@/services/IAuthService';
import type { IAuthRESTClient } from '@/rest-clients/IAuthRESTClient';
import type { IAuthGateRESTClient } from '../../rest-clients/IAuthGateRESTClient';

import { JWTAccessToken } from '@/models/JWTAccessToken';
import type IScopeRepositoryService from '../repositories/IScopeRepositoryService';
import jwtDecode from 'jwt-decode';

@injectable()
export class AuthService implements IAuthService {

    @inject('ISessionStorageService')
    private readonly storageService!: ISessionStorageService;

    @inject('IScopeRepositoryService')
    private readonly scopeRepositoryService!: IScopeRepositoryService;

    @inject('IAuthRESTClient')
    private readonly authRestClient!: IAuthRESTClient;

    @inject('IAuthGateRESTClient')
    private readonly authGateRestClient!: IAuthGateRESTClient;

    setToken(jwt: JWTAccessToken | null): void {
        if (jwt != null) this.setTokenInner(jwt.jwt, jwt.refreshToken, jwt.expires);
        else {
            store.dispatch(setToken(null));
        }
    }

    private setTokenInner(jwt: string, refresh: string, expiration: number): void {
        this.storageService.set('jwt', jwt);
        this.storageService.set('refresh', refresh);
        this.storageService.set('expiration', expiration.toString());
        store.dispatch(setToken(jwt));
    }

    private setAccessLevel(access: string, level: string) {
        this.storageService.set(`${access}:access`, level);
    }

    getAccessLevel(access: string): number {
        const accessLevel = this.storageService.get(`${access}:access`);

        if (accessLevel === null) return -1;

        return parseInt(accessLevel);
    }

    private getExpiration(): number {
        const expirationString = this.storageService.get('expiration');

        if (expirationString === null) return -1;

        return parseInt(expirationString);
    }

    getToken = async (): Promise<string | null> => {
        if (this.getExpiration() < Date.now()) {
            console.log(this.getExpiration() + ' ' + Date.now());
            const refreshToken = this.storageService.get('refresh');
            if (refreshToken === null) return null;
            const authToken = await this.authRestClient.refresh(refreshToken);

            if (authToken === null) return null;

            this.setToken(authToken);
        }
        return this.storageService.get('jwt');
    };

    async getIss(): Promise<string> {
        const token = await this.getToken();
        if (!token)
            return '';
        const iss = (jwtDecode(token) as any).iss;
        return iss;
    }

    isAuthenticated(): boolean {
        return this.storageService.has('jwt');
    }

    activateUser = async (key: string): Promise<string> => {
        const result = await this.authGateRestClient.activateUser(key);
        return result;
    };

    login = async (email: string, password: string): Promise<boolean> => {
        const result = await this.authRestClient.login(email, password);
        if (result === null) return false;

        const loggedInUserScope = this.authRestClient.getLoggedInUserScope(result.jwt)

        const accessLevels = await this.authRestClient.getTokenAccessLevels(loggedInUserScope);

        if (accessLevels === null) return false;

        const accessLevelNumerical: number[] = [];

        accessLevels.forEach((value, key, map) => {
            this.setAccessLevel(key, value);
            accessLevelNumerical.push(parseInt(value));
        });

        this.scopeRepositoryService.setScopeTotal(accessLevelNumerical);
        this.scopeRepositoryService.setLoggedInUserScope(loggedInUserScope)

        store.dispatch(setDataAccess(accessLevelNumerical));

        this.setToken(result)

        return true
    }

    logout() {
        this.storageService.reset();
        this.setToken(null);
    }
}

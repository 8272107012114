import { ActionIcon, Flex } from '@mantine/core';
import { IconZoomIn, IconZoomOut } from '@tabler/icons-react';

export interface IStartEndDate {
    startDate: Date;
    endDate: Date;
}

interface ZoomControlsProps {
    zoomLevel: number;
    setZoomLevel: (zoom: number) => void;
    setStartEndDates: (startEndDates: IStartEndDate) => void;
    startEndDates: IStartEndDate;
}

export const ZoomControls = ({ setZoomLevel, zoomLevel, startEndDates, setStartEndDates }: ZoomControlsProps) => {
    const handleZoom = (zoomIn: boolean) => {
        const MIN_DATE = new Date('2000-01-01').getTime();
        const MIN_RANGE = 60 * 1000;

        const { startDate, endDate } = startEndDates;
        const range = endDate.getTime() - startDate.getTime();
        const zoomFactor = zoomIn ? 0.5 : 2;

        const newRange = Math.max(range * zoomFactor, MIN_RANGE);

        let newStartDate;

        if (zoomIn) {
            if (range <= MIN_RANGE) {
                newStartDate = new Date(endDate.getTime() - MIN_RANGE);
            } else {
                newStartDate = new Date(endDate.getTime() - newRange);
            }
        } else {
            newStartDate = new Date(endDate.getTime() - newRange);

            if (newStartDate.getTime() < MIN_DATE) {
                newStartDate = new Date(MIN_DATE);
            }
        }

        setStartEndDates({ startDate: newStartDate, endDate });
        setZoomLevel(zoomIn ? zoomLevel + 1 : zoomLevel - 1);
    };

    return (
        <Flex>
            <ActionIcon onClick={() => handleZoom(true)}>
                <IconZoomIn size={15} onClick={() => handleZoom(true)} />
            </ActionIcon>
            <ActionIcon onClick={() => handleZoom(false)}>
                <IconZoomOut size={15} onClick={() => handleZoom(false)} />
            </ActionIcon>
        </Flex>
    );
};

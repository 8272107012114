import HouseSave from "@/models/gdm/HouseSave"
import IGDMService from "@/services/IGDMService"
import IUIService from "@/services/IUIService"
import { Alert, Button, Flex, Stack, Table, Tooltip } from "@mantine/core"
import { useInjection } from "inversify-react"
import { useEffect, useState } from "react"
import { MdOutlineDelete } from "react-icons/md"
import { RxReset } from "react-icons/rx"
import { RiFileTransferLine } from "react-icons/ri"
import { AiOutlineExclamationCircle } from "react-icons/ai"
import Nullable from "@/dataTypes/Nullable"
import DataStoreUserSaveCopyConfirmation from "./datastore-user-save-copy-confirmation.component"

interface DataStoreUserSavesFormProps {
    email: string
}

export default function DataStoreUserSavesForm({ email }: DataStoreUserSavesFormProps) {
    const [saves, setSaves] = useState<HouseSave[]>([])
    const uiService = useInjection<IUIService>('IUIService')
    const gdmService = useInjection<IGDMService>('IGDMService')

    const [selectedSave, setSelectedSave] = useState<Nullable<HouseSave>>(null)
    const [modalOpen, setModalOpen] = useState(false)

    const getData = async () => {
        uiService.showLoading();
        setSaves(await gdmService.getUserSaveList(email));
        uiService.hideLoading();
    }
    useEffect(() => {
        getData();
    }, [email])

    const copySaveFile = (save: HouseSave) => {
        setSelectedSave(save);
        setModalOpen(true);
    }
    const deleteSave = async (id: number) => {
        const response = await uiService.showConfirmationDialog('Delete Save File?');
        if (!response) return;
        uiService.showLoading();
        await gdmService.deleteSave(id);
        uiService.hideLoading();
        getData();
    }
    const resetSave = async (id: number) => {
        const response = await uiService.showConfirmationDialog('Reset Save File?');
        if (!response) return;
        uiService.showLoading();
        await gdmService.deleteSaveBinary(id);
        uiService.hideLoading();
        getData();
    }

    return (
        <Flex  direction='column'>
            <Stack>
                <h3>Saves</h3>
                {!!saves && saves.length > 0 &&
                    (<DataStoreUserSavesTable copySaveFile={copySaveFile} deleteSave={deleteSave} resetSave={resetSave} saves={saves} />)}
                {(!saves || saves.length === 0) && <Alert icon={<AiOutlineExclamationCircle />} title="No Saves">User does not have any save files</Alert>}
            </Stack>

            <DataStoreUserSaveCopyConfirmation
                save={selectedSave}
                open={modalOpen}
                onCopy={async (targetPipeline, copyPolaroids, copyUnboxed)=>{
                    if(!selectedSave)
                    return;
                    uiService.showLoading();
                    const copied = await gdmService.copySaveFileToUserProfile(selectedSave.id, email, targetPipeline, copyPolaroids, copyUnboxed);
                    uiService.hideLoading();
                    if(!!copied){
                        uiService.showSuccessNotification('Save copied successfuly');
                    }
                    else{
                        uiService.showErrorNotification('Failed to copy the save')
                    }
                    getData();
                    setSelectedSave(null)
                    setModalOpen(false)

                }}
                onClose={()=>{
                    setModalOpen(false);
                    setSelectedSave(null);
                }}
                />
        </Flex>
       
    );
}

interface DataStoreSaveActionsProps {
    copySaveFile: (save: HouseSave) => void;
    deleteSave: (id: number) => void;
    resetSave: (id: number) => void;
}

interface DataStoreUserSaveTableProps extends DataStoreSaveActionsProps {
    saves: HouseSave[];
}

function DataStoreUserSavesTable({ saves, copySaveFile, deleteSave, resetSave }: DataStoreUserSaveTableProps) {
    return (
        <Table striped highlightOnHover>
            <thead>
                <tr> 
                    <th>
                        ID
                    </th>
                    <th>
                        Name
                    </th>
                    <th>
                        Pipeline
                    </th>
                    <th>
                        Last Modified
                    </th>
                    <th>
                        Size
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {saves.map((save, index) =>
                    (<DataStoreUserSavesRow save={save} key={index + 1} copySaveFile={copySaveFile} deleteSave={deleteSave} resetSave={resetSave} />))}
            </tbody>
        </Table>
    );
}

interface DataStoreUserRowProps extends DataStoreSaveActionsProps {
    save: HouseSave;
}

function DataStoreUserSavesRow({ save, copySaveFile, deleteSave, resetSave }: DataStoreUserRowProps) {
    const dateInGMT = new Date(save.last_modified).toUTCString();

    return (
        <tr>
            <td>
                {save.id}
            </td>
            <td>
                <p style={{maxWidth: '120px', textOverflow:'clip', overflowWrap: 'anywhere'}}>{save.name}</p>
            </td>
            <td>
                {save.pipeline}
            </td>
            <td>
                {dateInGMT}+0
            </td>
            <td>
                {save.size/1024} KB
            </td>
            <td>
                <Flex gap='xs'>
                    <Tooltip label="Copy To Your Profile">
                        <Button onClick={e => {
                            e.preventDefault();
                            copySaveFile(save);
                        }} color="green"><RiFileTransferLine /></Button>
                    </Tooltip>
                    <Tooltip label="Reset">
                        <Button onClick={e => {
                            e.preventDefault();
                            resetSave(save.id);
                        }} color="yellow"><RxReset /></Button>
                    </Tooltip>
                    <Tooltip label="Delete">
                        <Button onClick={e => {
                            e.preventDefault();
                            deleteSave(save.id);
                        }} color="red"><MdOutlineDelete /></Button>
                    </Tooltip>

                </Flex>
            </td>
        </tr>
    )
}
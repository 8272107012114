import axios from 'axios';
import { inject } from 'inversify';

import { ServiceManagementList } from '../../models/serviceManagement/ServiceManagementList';
import { ServiceManagementModel } from '../../models/serviceManagement/ServiceManagementModel';
import type { IAuthService } from '../../services/IAuthService';
import { IServiceManagementRESTClient } from '../IServiceManagementRESTClient';
import { RESTClient } from './RESTClient';

export default class ServiceManagementRESTClient extends RESTClient implements IServiceManagementRESTClient {
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    constructor() {
        super();
        this.setBaseURL('https://wod-auth-uux56memxa-uc.a.run.app/api/v1/');
    }

    async getService(email: string): Promise<ServiceManagementModel | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const authToken = await this.getToken(token);

        const path = this.getFullURL(`service/${email}`);

        try {
            const response = await axios.get(path, this.getHeader(authToken));

            return response.data as ServiceManagementModel;
        } catch {
            return null;
        }
    }

    async getServices(page: number): Promise<ServiceManagementList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const authToken = await this.getToken(token);

        const path = this.getFullURL(`service?page=${page}`);

        try {
            const response = await axios.get(path, this.getHeader(authToken));
            const parsedList = response.data as ServiceManagementList;
            const returnList = new ServiceManagementList();
            returnList.max_results = parsedList.max_results;
            returnList.accounts = parsedList.accounts;
            return returnList;
        } catch {
            return null;
        }
    }

    async deleteService(service: ServiceManagementModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);

        const path = this.getFullURL(`service/${service.email}`);

        try {
            await axios.delete(path, this.getHeader(authToken));
            return true;
        } catch {
            return false;
        }
    }

    async searchService(search: string, page: number): Promise<ServiceManagementList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const authToken = await this.getToken(token);

        const path = this.getFullURL(`service?page=${page}&search=${search}`);

        try {
            const response = await axios.get(path, this.getHeader(authToken));
            const parsedList = response.data as ServiceManagementList;
            const returnList = new ServiceManagementList();
            returnList.max_results = parsedList.max_results;
            returnList.accounts = parsedList.accounts;
            return returnList;
        } catch {
            return null;
        }
    }

    async updateServiceScope(service: ServiceManagementModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);

        const path = this.getFullURL(`service/${service.email}/scope/${service.scope}`);

        try {
            await axios.put(path, null, this.getHeader(authToken));
            return true;
        } catch {
            return false;
        }
    }

    async createService(name: string, password: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);
        const path = this.getFullURL('service');

        const form = new FormData();
        form.append('name', name);
        form.append('password', password);
        
        try {
            await axios.post(path, form, this.getHeader(authToken));
            return true;
        } catch {
            return false;
        }
    }
}

import Nullable from "@/dataTypes/Nullable";
import UserList from "@/models/auth/users/UserList";
import { Box, Container } from "@mantine/core";
import DefaultListOptionView from "../general/list-view-default-option/list-view-default-option.component";
import ListViewComponent from "../general/list-view/list-view.component";
import { SearchBox } from "../general/search-box/search-box";
import CustomerList from "@/models/trans/customer/CustomerList";

interface CustomerTransactionListProps {
    selectionChange: (id: string) => void;
    selectedId: Nullable<string>;
    customerList: CustomerList | null;
    page: number;
    onPageChange: (page: number) => void;
    searchChange: (query:  string) =>void;
}

export default function CustomerTransactionList({ selectionChange, selectedId, customerList, page, onPageChange, searchChange }: CustomerTransactionListProps) {
    return (
        <Container px={0} pt="md">
            <Box px='xs'>
                <SearchBox setSearchQuery={searchChange} />
            </Box>
            <ListViewComponent
                elements={customerList}
                page={page}
                selectedValue={selectedId}
                pageChanged={onPageChange}
                selectionChangedString={selectionChange}
                listElement={(x, selectionChange, inSelect) => {
                    return (
                        <DefaultListOptionView
                            key={x.email}
                            id={x.email}
                            value={x.email.toLowerCase()}
                            selectionChange={selectionChange}
                            selected={selectedId === x.email}
                            inSelectContainer={inSelect}
                        />
                    )
                }}>
            </ListViewComponent>
        </Container>

    );
}
import { Alert, Box, Container, Stack } from '@mantine/core';
import React, { Children } from 'react';

import IList from '../../../models/IList';
import ListViewPagination from '../list-view-pagination/list-view-pagination.component';
import styles from './list-view.module.scss';

export interface ListViewProps<T> {
    elements: IList<T> | null;
    page: number;
    selectionChanged?: (id: number) => void;
    selectionChangedString?: (id: string) => void;
    selectedValue: number | string | null;
    pageChanged: (page: number) => void;
    listElement: (element: T, selectionChanged: (id: number | string) => void, inSelect: boolean) => React.ReactNode;
    containerId?: string;
    listId?: string;
    listClass?: string;
    children?: React.ReactNode;
}

function ListViewComponent<T extends {}>(props: ListViewProps<T>) {
    const onChangeSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        const selectedValue = parseInt(value);
        changeSelection(selectedValue);
    };

    const changeSelection = (selectedValue: number | string) => {
        const selectedValueInt = parseInt(selectedValue.toString());

        if (props.selectionChanged) {
            props.selectionChanged(selectedValueInt);
        } 
        
        if (props.selectionChangedString) {
            props.selectionChangedString?.(selectedValue.toString());
        }
    };

    const changePage = (newPage: number) => {
        props.pageChanged(newPage);
    };

    const { elements, page, selectedValue } = props;

    let _create;

    Children.forEach(props.children, (child) => {
        if (child) {
            if ((child as React.ReactElement).type === ListHeader) {
                return (_create = child);
            }
        }
    });
    return (
        <Container px={0} h="100%" w={300}>
            {_create}
            <Box h="calc(100vh - 36px - 28px - 60px)">
                {elements?.getItems()?.length === 0 ? (
                    <Alert>No items found</Alert>
                ) : (
                    <Stack spacing={0} h="100%" sx={{ overflowY: 'auto' }}>
                        {elements?.getItems()?.map((element) => props.listElement(element, changeSelection, false))}
                    </Stack>
                )}
            </Box>

            <select
                className={styles.selectList}
                value={selectedValue !== null ? selectedValue : undefined}
                onChange={onChangeSelection}
            >
                {elements?.getItems().map((element) => props.listElement(element, changeSelection, true))}
            </select>
            <ListViewPagination onChangePage={changePage} maxCount={elements?.max_results} currentPage={page} />
        </Container>
    );
}

const ListHeader = (props: { children: React.ReactNode }) => props.children;

ListViewComponent.Header = ListHeader as React.ElementType;

export default ListViewComponent;

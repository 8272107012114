import Nullable from '@/dataTypes/Nullable';
import ProductKeyNameModel from '@/models/trans/productKeys/ProductKeyNameModel';
import { Alert, Button, Flex, Switch, Table } from '@mantine/core';
import { useInjection } from 'inversify-react';
import copy from 'copy-to-clipboard';
import IUIService from '@/services/IUIService';
import { MdOutlineCopyAll, MdOutlineDelete } from 'react-icons/md';
import { FiShare } from 'react-icons/fi';
import { useState } from 'react';

export default function CustomerGiftCodes({
    email,
    giftCodes,
    activateKey,
    removeKey,
}: {
    email: Nullable<string>;
    giftCodes: ProductKeyNameModel[];
    activateKey: (productKey: string) => void;
    removeKey: (id: number) => void;
}) {
    const [hideDeleted, setHideDeleted] = useState(true);
    return (
        <Flex gap="md" justify="stretch" direction="column" wrap="wrap" w="calc(100%)">
            <h3>Gift Codes</h3>
            <Flex direction="row" justify="flex-end">
                <Switch
                    labelPosition="left"
                    checked={hideDeleted}
                    onChange={(e) => setHideDeleted(e.currentTarget.checked)}
                    label="Hide Invalidated"
                />
            </Flex>
            {!!giftCodes && giftCodes.length > 0 && (
                <GiftCodesTable hideDeleted={hideDeleted} giftCodes={giftCodes} activateKey={activateKey} removeKey={removeKey} />
            )}
            {(!giftCodes || giftCodes.length === 0) && (
                <Alert title="No Gift Codes!" color="blue">
                    Seems like the customer has no gift codes
                </Alert>
            )}
        </Flex>
    );
}

export function GiftCodesTable({
    giftCodes,
    activateKey,
    removeKey,
    hideDeleted
}: {
    giftCodes: ProductKeyNameModel[];
    activateKey: (productKey: string) => void;
    removeKey: (id: number) => void;
    hideDeleted? : boolean
}) {
    return (
        <Table highlightOnHover>
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Key</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {giftCodes.filter(x=>{
                    if(!!hideDeleted){
                        return !x.invalid;
                    }
                    return true;
                }).map((x, index) => (
                    <GiftCodesComponent {...x} key={index} activateKey={activateKey} removeKey={removeKey} />
                ))}
            </tbody>
        </Table>
    );
}
interface GiftRowProps extends ProductKeyNameModel {
    activateKey: (productKey: string) => void;
    removeKey: (id: number) => void;
}

export function GiftCodesComponent({
    title,
    id,
    invalid: keyInvalid,
    product_key,
    activateKey,
    removeKey,
}: GiftRowProps) {
    const uiService = useInjection<IUIService>('IUIService');
    return (
        <tr style={{ backgroundColor: !!keyInvalid ? '#fa5252' : undefined }}>
            <td>{title}</td>
            <td>{product_key.substring(0, 40)}...</td>
            <td>
                {!keyInvalid && (
                    <Flex gap="sm">
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                activateKey(product_key);
                            }}
                            color="green"
                        >
                            <FiShare />
                        </Button>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                uiService.showSuccessNotification('Copied to clipboard!');
                                copy(product_key);
                            }}
                            color="orange"
                        >
                            <MdOutlineCopyAll />
                        </Button>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                removeKey(id);
                            }}
                            color="red"
                        >
                            <MdOutlineDelete />
                        </Button>
                    </Flex>
                )}
            </td>
        </tr>
    );
}

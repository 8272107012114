import ICreateProductWizardService from "../ICreateProductWizardService";
import store from "../../redux/store";
import { setShowWizard } from "../../redux/slices/wizardSlice";
import { injectable } from "inversify";

@injectable()
export default class CreateProductWizardService implements ICreateProductWizardService{
    showWizard(){
        store.dispatch(setShowWizard(true));
    }
    hideWizard(){
        store.dispatch(setShowWizard(false));
    }
}
import { useEffect, useState } from 'react';
import { NavLink } from '@mantine/core';
import NavigationElement from '@/models/navigation/NavigationElement';
import { Link, useLocation } from 'react-router-dom';

interface AccordionProperties {
    link?: string;
    icon?: string;
    label: string;
    subLinks?: NavigationElement[];
}

interface KDMenuLinkProps {
    label: string;
    route: string;
    regex?: string;
    setOpen: (label: string, active: boolean) => void;
}

const KDMenuLink = (props: KDMenuLinkProps) => {
    const location = useLocation();
    const [active, setActive] = useState(false);
    const { label, route, regex, setOpen } = props;

    useEffect(() => {
        if (regex) {
            setActive(!!location.pathname.match(regex));
        } else {
            setActive(location.pathname === route);
        }
    }, [route, regex, location.pathname]);

    useEffect(() => {
        setOpen(label, active);
    }, [label, active]);

    return <NavLink label={label} active={active} component={Link} to={route} />;
};

export const MenuLink = (props: AccordionProperties) => {
    const location = useLocation();
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});
    const { label, link, icon, subLinks } = props;

    useEffect(() => {
        if (subLinks) {
            subLinks.forEach((element) => {
                const isActive = element.regex
                    ? !!location.pathname.match(element.regex)
                    : location.pathname === element.route;

                if (isActive) {
                    setOpen((prevOpen) => ({ ...prevOpen, [label]: true }));
                }
            });
        }
    }, [label, subLinks, location.pathname]);

    const linkFiltered = (function () {
        if (!!link) return link;
        return '#';
    })();

    const isExternalLink = !!link && link.startsWith('http');

    const handleSetOpen = (label: string, active: boolean) => {
        setOpen((prevOpen) => ({ ...prevOpen, [label]: active }));
    };

    if (isExternalLink) {
        return (
            <NavLink
                icon={!!icon && <span className="material-symbols-outlined">
                    {icon}
                </span>}
                component="a"
                href={linkFiltered}
                label={label}
                childrenOffset={28}
            />
        );
    }

    return (
        <NavLink
            opened={open[label]}
            onClick={() => setOpen({ ...open, [label]: !open[label] })}
            icon={<span className="material-symbols-outlined">
                {icon}
            </span>}
            label={label}
            childrenOffset={36}
        >
            {subLinks?.map((element, index) => (
                <KDMenuLink
                    regex={element.regex}
                    setOpen={handleSetOpen}
                    key={index}
                    label={element.title}
                    route={element.route}
                />
            ))}
        </NavLink>
    );
};

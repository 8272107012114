import Nullable from '@/dataTypes/Nullable';
import CustomerList from '@/models/trans/customer/CustomerList';
import ProductKeyNameModel from '@/models/trans/productKeys/ProductKeyNameModel';
import SucceededTransactionTitle from '@/models/trans/succeeded-transactions/SucceededTransactionsWithTitle';
import { inject, injectable } from 'inversify';
import AssetEntry from '../../models/trans/AssetEntry';
import AssetList from '../../models/trans/AssetList';
import StoreItem from '../../models/trans/storeItems/StoreItem';
import type ITransRESTClient from '../../rest-clients/ITransRESTClient';
import ITransService from '../ITransService';

@injectable()
export default class TransService implements ITransService {
    
    @inject('ITransRESTClient')
    private transRESTClient!: ITransRESTClient;

    async getAllAssets(): Promise<AssetList | null> {
        return await this.transRESTClient.getAllAssets();
    }

    filterAssets(originalAssets: AssetEntry[], excludeAssets : StoreItem[]) : AssetEntry[]{
        return originalAssets.filter((x)=>!this.containsAsset(excludeAssets, x));
    }

    private containsAsset(assets : StoreItem[], asset : AssetEntry) : boolean{
        let result = false;
        assets.forEach(x=>{
            if(this.sameAsset(asset,x))
                result = true;
        })

        return result;
    }

    private sameAsset(asset : AssetEntry, storeItem: StoreItem){
        return asset.id === storeItem.asset_id && asset.game_id === storeItem.game_id;
    }

    async getCustomerList(page: number): Promise<Nullable<CustomerList>>{
        return await this.transRESTClient.getCustomerList(page);
    }

    async searchCustomerList(query:string, page: number): Promise<Nullable<CustomerList>>{
        return await this.transRESTClient.getCustomerList(page, query);
    }

    async getUserPurchases(iss:string) :Promise<Nullable<SucceededTransactionTitle[]>>{
        return await this.transRESTClient.getUserPurchases(iss);
    }
    async getUserGiftCodes(iss:string) :Promise<Nullable<ProductKeyNameModel[]>>{
        return await this.transRESTClient.getUserGiftCodes(iss);
    }

    async removePurchase(id: number): Promise<boolean>{
        return await this.transRESTClient.removePurchase(id);
    }

    async removeCustomerProductKey(id: number): Promise<boolean> {
        return await this.transRESTClient.removeCustomerProductKey(id);
    }
}

import IList from "../IList";
import SecopsReportEntry from "./SecopsReportEntry";

export default class SecopsReportList implements IList<SecopsReportEntry>{
    public max_results!: number;
    public items!:SecopsReportEntry[];


  
    getItems(): SecopsReportEntry[] {
        return this.items;
    }
}
import { Title, MantineProvider, Navbar, Box, ScrollArea } from '@mantine/core';
import { useInjection } from 'inversify-react';

import { MenuLink } from '../general/menu-link/menu-link';
import './main-layout.styles.scss';
import date from '../../data/date.json';
import IViewFactoryService from '@/services/factories/IViewFactoryService';

export interface MainLayoutProps {
    children: React.ReactNode;
}

export default function MainLayout(props: MainLayoutProps) {
    return (
        <Box>
            <NavigationPanel />
            <Box ml={{ sm: 200, xl: 250 }}>{props.children}</Box>
        </Box>
    );
}

function NavigationPanel() {
    const viewFactoryService = useInjection<IViewFactoryService>('IViewFactoryService');

    const navLinks = viewFactoryService.getSideNavRoutes().map((x, i) => {
        return <MenuLink label={x.title} key={i} icon={x.icon} subLinks={x.elements} />;
    });

    return (
        <MantineProvider theme={{ colorScheme: 'dark' }}>
            <Navbar fixed height="100vh" py="xs" zIndex={10} width={{ sm: 200, xl: 250 }}>
                <Navbar.Section px={{ sm: 0, xl: 'xs' }}>
                    <Title
                        order={2}
                        fz={{ sm: 'xl', xl: '1.8rem' }}
                        ml={15}
                        c="white"
                        onDoubleClick={() => {
                            alert('Deployed on ' + new Date(Date.parse(date.date)).toLocaleString());
                        }}
                        onClick={() => {
                            console.log(date.date);
                        }}
                    >
                        Web of Death
                    </Title>
                </Navbar.Section>
                <Navbar.Section grow component={ScrollArea} scrollbarSize={5} mt="md">
                    {navLinks}
                </Navbar.Section>
            </Navbar>
        </MantineProvider>
    );

   
}

import 'reflect-metadata';
import './App.scss';
import React from 'react';
import { MantineProvider } from '@mantine/core';
import { Container } from 'inversify';
import { Provider } from 'inversify-react';
import { Provider as ReduxProvider } from 'react-redux';
import { Notifications } from '@mantine/notifications';
import { mantineTheme } from './styles/theme';
import PageWithHeader from './pages/page-with-header/PageWithHeader';
import store from './redux/store';
import LoadingOverlay from './components/loading-container/loading-container.component';
import { ConfirmDialog } from './components/confirm-dialog/confirm-dialog';
import ZoomImageOverlay from './components/zoom-image-overlay/zoom-image-overlay.component';
import NewVersionDialog from './components/new-version-dialog/new-version-dialog.component';
import RepoCreationWizard from './components/devops-cp/repo-creation-wizard/repo-creation-wizard.component';
import BootstrapApp from './bootstrapper';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            cacheTime: 0,
        },
    },
});

class App extends React.Component {
    private readonly container: Container = new Container();
    constructor(props: {}) {
        super(props);
        BootstrapApp(this.container);
    }

    render() {
        return (
            <ReduxProvider store={store}>
                <Provider container={this.container}>
                    <React.Fragment>
                        <QueryClientProvider client={queryClient}>
                            <MantineProvider withGlobalStyles withNormalizeCSS theme={mantineTheme}>
                                <Notifications position="bottom-right" />
                                <PageWithHeader />
                            </MantineProvider>
                            <ReactQueryDevtools initialIsOpen={false} />
                        </QueryClientProvider>
                        <LoadingOverlay />
                        <ConfirmDialog />
                        <ZoomImageOverlay />
                        <NewVersionDialog />
                        <RepoCreationWizard />
                    </React.Fragment>
                </Provider>
            </ReduxProvider>

            // <FooterComponent/>
        );
    }
}

export default App;

import { ActionIcon, Modal, TextInput } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';

export interface ShopifyProductPickerProps {
    value: number;
    onChange: (newValue: number) => void;
    label: string;
}

export default function ShopifyProductPicker(props: ShopifyProductPickerProps) {
    const { value, onChange, label } = props;

    const [showOverlay, setShowOverlay] = useState(false);

    return (
        <>
            <TextInput
                //readOnly
                value={value}
                label={label}
                sx={{
                    cursor: "pointer"
                }}
                onChange={e=>{
                    e.preventDefault();
                    onChange(parseInt(e.target.value));
                }}
                // onClick={e=>{e.preventDefault(); setShowOverlay(true);}}
                // rightSection={
                //     <ActionIcon
                //         onClick={(e) => {
                //             setShowOverlay(true);
                //         }}
                //     >
                //         <BsSearch />
                //     </ActionIcon>
                // }
            />

            <ShopifyProductPickerOverlay
                show={showOverlay}
                closed={() => {
                    setShowOverlay(false);
                }}
            />
        </>
    );
}

interface ShopifyProductPickerOverlayProps {
    show: boolean;
    closed: () => void;
}

export function ShopifyProductPickerOverlay({ show, closed }: ShopifyProductPickerOverlayProps) {
    const [search, setSearch] = useDebouncedState('', 400)
    
    useEffect(()=>{
        if(show){
            setSearch('')
        }
    }, [show])

    useEffect(()=>{

    }, [search])
    
    return (
        <Modal
            opened={show}
            onClose={() => {
                closed();
            }}
            title={<h3>Select Shopify Product</h3>}
        >
            <TextInput label='Search' value={search} onChange={e=>{
                e.preventDefault();
                setSearch(e.target.value);
            }}/>
        </Modal>
    );
}

import Nullable from '@/dataTypes/Nullable';
import RewardsList from '@/models/treasury/rewards/RewardList';
import ITreasuryService from '@/services/ITreasuryService';
import { Button, Checkbox, Modal, NumberInput, Select, Stack, TextInput, LoadingOverlay, Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { create } from 'domain';
import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';

export interface PledgeAddBackerProps {
    visible: boolean;
    closed: () => void;
    create: (rewardId: number, email: string, backerKitEmail: string, quantity: number) => void;
}

export default function PledgeAddBacker({ visible, closed, create }: PledgeAddBackerProps) {
    const [selectData, setSelectData] = useState<{ label: string; value: string }[]>([]);
    const [selectValue, setSelectValue] = useState<Nullable<string>>(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const treasuryService = useInjection<ITreasuryService>('ITreasuryService');

    useEffect(() => {
        if (!!visible) {
            setLoading(true);

            treasuryService.getAllRewards().then((x) => {
                const tempSelectData = x
                    ?.getItems()
                    .map((it) => ({ label: it.reward_name, value: it.reward_id.toString() }));
                setSelectData(tempSelectData ?? []);
                setLoading(false);
            });
        }
    }, [visible]);

    const [backerKitEmail, setBackerKitEmail] = useState(email);
    const [customEmail, setCustomEmail] = useState(false);
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        if (!customEmail) setBackerKitEmail(email);
    }, [email, customEmail]);

    const createHandler = () => {
        if (!selectValue) {
            closed();
            return;
        }
        const rewardValue = parseInt(selectValue);
        if (Number.isNaN(rewardValue)) {
            closed();
            return;
        }
        create(rewardValue, email, backerKitEmail, quantity);
    };

    return (
        <Modal opened={visible} onClose={closed} centered title="Add Backer and Pledge">
            <Stack>
                <TextInput
                    label="Backer Email"
                    value={email}
                    onChange={(e) => {
                        e.preventDefault();
                        setEmail(e.target.value);
                    }}
                />
                <Checkbox
                    checked={customEmail}
                    onChange={(e) => {
                        setCustomEmail(e.target.checked);
                    }}
                    label="Custom BackerKit Email"
                />
                <TextInput
                    label="BackerKit Email"
                    value={backerKitEmail}
                    disabled={!customEmail}
                    onChange={(e) => {
                        e.preventDefault();
                        setBackerKitEmail(e.target.value);
                    }}
                />
                <Select
                    searchable
                    data={selectData}
                    label="Reward"
                    value={selectValue}
                    onChange={(e) => {
                        setSelectValue(e);
                    }}
                />
                <NumberInput
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => {
                        if (!!e) setQuantity(e);
                    }}
                />
                <Alert variant="light" color="orange" title="Important" icon={<IconInfoCircle />}>
                    At least one pledge is required for the backer to appear in the list
                </Alert>
                <Button disabled={email==='' || backerKitEmail==='' || selectValue==='' || selectValue === null} onClick={createHandler}>Add Backer And Pledge</Button>
            </Stack>
            <LoadingOverlay visible={loading} />
        </Modal>
    );
}

import { useInjection } from 'inversify-react';
import { DatePicker, DatePickerInput, DateTimePicker, TimeInput } from '@mantine/dates';
import {
    Select,
    TextInput,
    Container,
    Stack,
    Textarea,
    Flex,
    Button,
    MultiSelect,
    SimpleGrid,
    LoadingOverlay,
    ActionIcon,
} from '@mantine/core';
import Motd, { MotdVariable } from '../../../models/devops/MOTD/Motd';
import type IMotdService from '../../../services/IMotdService';
import React, { useRef, useState } from 'react';
import IDateTimeService from '@/services/IDateTimeService';
import { useQuery } from '@tanstack/react-query';
import Nullable from '@/dataTypes/Nullable';
import { TimePicker } from '@/components/general/time-picker/time-picker.component';
import { IconClock } from '@tabler/icons-react';
import { AsyncPaginate} from 'react-select-async-paginate';

type MOTDTemplateOptionType = {
    label: string;
    value: number;
  };

interface MOTDFormProps {
    selectedMotdId: number;
    motd: Nullable<Motd>;
    updateMotd: (motd: Motd) => void;
    updateEnabled: boolean;
    updateTemplate: (templateId: number) => void;
    isLoading: boolean;
    saveMotd: () => void;
    generateMessage: (updatedVariables: MotdVariable[]) => string | undefined;
    deleteMotd: () => void;
    forceDeleteMotd: () => void;
}

export const MOTDForm = ({
    selectedMotdId,
    motd,
    deleteMotd,
    updateMotd,
    updateEnabled,
    updateTemplate,
    isLoading,
    saveMotd,
    generateMessage,
    forceDeleteMotd
}: MOTDFormProps) => {
    const motdService = useInjection<IMotdService>('IMotdService');
    const datetimeService = useInjection<IDateTimeService>('IDateTimeService');
    const [motdTemplateOptions, setMOTDTemplateOptions] = useState<MOTDTemplateOptionType[]>([]);
    const [motdTemplateSelectedOption, setMOTDTemplateSelectedOption] = useState<Nullable<MOTDTemplateOptionType>>(null)

    // const { data: templateList } = useQuery({
    //     queryKey: ['motd-template-list'],
    //     queryFn: () => motdService.getMotdTemplatesList(1),
    //     keepPreviousData: true
    // });

    

    if (motd === null) {
        return (
            <Container fluid py="xs" pos="relative">
                <LoadingOverlay visible={isLoading} overlayBlur={2} />
                <Stack>
                    <Textarea
                        onChange={(e) => updateMotdPartial({ message: e.target.value })}
                        value="Failed to load the message!"
                        disabled={true}
                        label="Message"
                        size="md"
                        minRows={3}
                        maxRows={8}
                    />

                    <Flex w="max-content" gap="sm" ml="auto" mt="xl">
                        <Button size="md" radius="md" color="red" variant="outline" onClick={forceDeleteMotd}>
                            Remove Message
                        </Button>
                    </Flex>
                </Stack>
            </Container>
        );
    }

    const isDefaultTemplate = motd.template_pattern === '';

    const updateMotdPartial = (updatedProperties: Partial<Motd>) => {
        console.log('update called');
        updateMotd({ ...motd, ...updatedProperties });
    };

    const onVariableValueChanged = (variableId: number, value: string) => {
        const updatedVariables = motd.variables.map((variable) => {
            if (variable.motd_template_var_id === variableId) {
                return { ...variable, motd_value: value };
            }
            return variable;
        });

        updateMotdPartial({ variables: updatedVariables, message: generateMessage(updatedVariables) });
    };

   
      

    const loadOptions = async (inputValue: string, options: MOTDTemplateOptionType[], additional?: {page: number}) => {

        const page = !!additional? additional.page : 1;

        const response = await motdService.getMotdTemplatesList(page);

        let result = [...options];

        if(!response){
            result = [...result, {
                label : 'No templates!',
                value: 0
            }];
            setMOTDTemplateOptions(result)
            return {
                options: result,
                hasMore: false,
                additional: {
                  page: page + 1,
                },
            }
        }

        const results = response.items.map(x=>({
            value : x.id,
            label: x.name
        }));

        result = [...result, ...results];

        setMOTDTemplateOptions(result)
      
        return {
          options: result,
          hasMore: response.max_results>page*40,
          additional: {
            page: page + 1,
          },
        };
      }

    const findMOTDTemplateSelectedValue = async ()=>{
        let selectedOption : MOTDTemplateOptionType | undefined;
        if(motdTemplateOptions.length>0){
            selectedOption = motdTemplateOptions.find(x=>x.value === motd.template_id)
        }

        if(!!selectedOption){
            setMOTDTemplateSelectedOption(selectedOption);
            return;
        }

        const template = await motdService.getMotdTemplate(motd.template_id);

        if(!template){
            selectedOption = {
                label : 'No Template Found',
                value : -1
            }
        }
        else{
            selectedOption = {
                label : template.name,
                value : template.id
            }
        }

        setMOTDTemplateSelectedOption(selectedOption);  
    }

    if(motdTemplateSelectedOption?.value !== motd.template_id){
        findMOTDTemplateSelectedValue();
    }

    const displayOnce = [
        {value: 'yes', label:'Yes'},
        {value: 'no', label:'No'}
    ]

    return (
        <Container fluid py="xs" pos="relative">
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <Stack>
                <TextInput
                    onChange={(e) => updateMotdPartial({ title: e.target.value })}
                    value={motd.title}
                    label="Title"
                />

                <AsyncPaginate 
                    value={motdTemplateSelectedOption}
                    // @ts-ignore
                    loadOptions={loadOptions}
                    onChange={(value)=>{
                        // @ts-ignore
                        value && updateTemplate(value.value)
                    }}
                    placeholder="Select Template"
                    additional={{
                        page: 1
                    }}
                    />

                {/* <Select
                    label="Template"
                    placeholder="Select a template"
                    data={templateList?.items.map((item) => ({ label: item.name, value: item.id.toString() })) || []}
                    value={motd.template_id.toString()}
                    onChange={(value) => value && updateTemplate(parseInt(value))}
                    onScroll={e=>{
                        console.log(e);
                    }}
                    onScrollCapture={e=>{
                        console.log(e);
                    }}
                /> */}

                {!isDefaultTemplate && (
                    <SimpleGrid cols={2} spacing="lg">
                        {motd.variables &&
                            motd.variables?.map((variable, i) => (
                                <React.Fragment key={i}>
                                    <MOTDVariableInput
                                        variable={variable}
                                        dateTimeService={datetimeService}
                                        motdService={motdService}
                                        onVariableValueChanged={onVariableValueChanged}
                                    />
                                </React.Fragment>
                            ))}
                    </SimpleGrid>
                )}

                <Textarea
                    onChange={(e) => updateMotdPartial({ message: e.target.value })}
                    value={motd.message}
                    disabled={!isDefaultTemplate}
                    label="Message"
                    size="md"
                    minRows={3}
                    maxRows={8}
                />

                <SimpleGrid cols={2} spacing="lg">
                    <Select
                        miw={280}
                        label="Display Once"
                        onChange={(value) => updateMotdPartial({ one_time: value==='yes' })}
                        value={motd.one_time?'yes':'no'}
                        data={displayOnce}
                    ></Select>
                    <MultiSelect
                        miw={280}
                        variant="filled"
                        size="md"
                        label="Pipeline"
                        onChange={(value) => updateMotdPartial({ active_status: { pipelines: value } })}
                        value={motd.active_status.pipelines}
                        styles={{
                            item: { padding: '0.5rem 0.5rem' },
                            value: { fontSize: 10 },
                        }}
                        data={motdService.getPipelines().map((x) => ({ label: x[1], value: x[0] }))}
                    ></MultiSelect>
                </SimpleGrid>

                <SimpleGrid cols={2} spacing="lg">
                    <DateTimePicker
                        valueFormat="DD MMM YYYY hh:mm A"
                        miw={250}
                        label="From"
                        value={motdService.stringToDate(motd.from_date)}
                        onChange={(date: Date) => {
                            console.log('date changed');
                            updateMotdPartial({ from_date: motdService.dateToString(date) });
                        }}
                        variant="filled"
                        rightSection={datetimeService.getLocalTimezone()}
                    />
                    <DateTimePicker
                        valueFormat="DD MMM YYYY hh:mm A"
                        miw={250}
                        label="To"
                        value={motdService.stringToDate(motd.to_date)}
                        onChange={(date: Date) => {
                            console.log('date updated');
                            updateMotdPartial({ to_date: motdService.dateToString(date) });
                        }}
                        variant="filled"
                        rightSection={datetimeService.getLocalTimezone()}
                    />
                </SimpleGrid>

                <Flex w="max-content" gap="sm" ml="auto" mt="xl">
                    {motd.id !== -1 && (
                        <Button size="md" radius="md" disabled={!updateEnabled} onClick={saveMotd}>
                            Update Message
                        </Button>
                    )}

                    {motd.id !== -1 && (
                        <Button size="md" radius="md" color="red" variant="outline" onClick={deleteMotd}>
                            Remove Message
                        </Button>
                    )}
                </Flex>
            </Stack>
        </Container>
    );
};

interface MOTDVariableInputProps {
    variable: MotdVariable;
    motdService: IMotdService;
    dateTimeService: IDateTimeService;
    onVariableValueChanged: (variableId: number, value: string) => void;
}

const MOTDVariableInput = ({
    variable,
    motdService,
    dateTimeService,
    onVariableValueChanged,
}: MOTDVariableInputProps) => {
    let motd_value = variable.motd_value;
    const { variable_type, variable_name, motd_template_var_id } = variable;
    const timeRef = useRef<HTMLInputElement>(null);

    switch (variable_type) {
        case 'datetime':
            return (
                <DateTimePicker
                    valueFormat="DD MMM YYYY hh:mm A"
                    variant="filled"
                    label={variable_name}
                    value={motdService.stringToDate(motd_value)}
                    rightSection={dateTimeService.getLocalTimezone()}
                    onChange={(date: Date) =>
                        onVariableValueChanged(motd_template_var_id, motdService.dateToString(date))
                    }
                />
            );
        case 'date':
            if (motd_value === '') {
                motd_value = motdService.dateToString(new Date(Date.now()));
            }
            return (
                <DatePickerInput
                    valueFormat="DD MMM YYYY"
                    variant="filled"
                    label={variable_name}
                    value={motdService.stringToDate(motd_value)}
                    onChange={(date: Date) =>
                        onVariableValueChanged(motd_template_var_id, motdService.dateToString(date))
                    }
                />
            );

        case 'time':
            if (motd_value === '') {
                motd_value = '00:00';
                variable.motd_value = '00:00';
                onVariableValueChanged(motd_template_var_id, '00:00');
            }
            return (
                <TimeInput
                    ref={timeRef}
                    sx={{
                        'mantine-TimeInput-rightSection': {
                            width: '5.625rem',
                        },
                    }}
                    label={variable_name}
                    value={motd_value}
                    rightSection={
                        <Flex
                            dir="row"
                            sx={{
                                marginLeft: '-35px',
                                fontSize: '12px',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {dateTimeService.getLocalTimezone()}
                            <ActionIcon
                                onClick={() => {
                                    if (!!timeRef.current) return timeRef.current.showPicker();
                                }}
                            >
                                <IconClock size="1rem" stroke={1.5} />
                            </ActionIcon>
                        </Flex>
                    }
                    //rightSection={dateTimeService.getLocalTimezone()}
                    onChange={(e) => {
                        e.preventDefault();
                        onVariableValueChanged(motd_template_var_id, e.target.value);
                    }}
                />
            );
        case 'number':
            return (
                <TextInput
                    type="number"
                    label={variable_name}
                    value={motd_value}
                    onChange={(e) => onVariableValueChanged(motd_template_var_id, e.target.value)}
                />
            );
        case 'text':
            return (
                <TextInput
                    type="text"
                    label={variable_name}
                    value={motd_value}
                    onChange={(e) => onVariableValueChanged(motd_template_var_id, e.target.value)}
                />
            );
        default:
            return <></>;
    }

    return <></>;
};

import { Grid } from '@mantine/core';
import AssetGroupListView from './asset-groups-list.component';
import AssetGroupsForm from './asset-groups-form/asset-groups-form.component';
import AssetGroupList from '@/models/trans/asset-group/AssetGroupList';
import { useEffect, useState } from 'react';
import Nullable from '@/dataTypes/Nullable';
import { useInjection } from 'inversify-react';
import IUIService from '@/services/IUIService';
import IAssetGroupService from '@/services/IAssetGroupService';
import AssetGroup from '@/models/trans/asset-group/AssetGroup';
import AssetList from '@/models/trans/AssetList';
import ITransService from '@/services/ITransService';

export default function AssetGroupDirectory() {
    const [list, setList] = useState<Nullable<AssetGroupList>>(null);
    const [page, setPage] = useState(1);
    const [currentSelectedGroupId, setCurrentSelectedGroupId] = useState<Nullable<number>>(null);
    const [currentSelectedGroup, setCurrentSelectedGroup] = useState<Nullable<AssetGroup>>(null);
    const uiService = useInjection<IUIService>('IUIService');
    const assetGroupService = useInjection<IAssetGroupService>('IAssetGroupService');
    const transService = useInjection<ITransService>('ITransService');
    const [assetList, setAssetList] = useState<AssetList>();
    const [saveEnabled, setSaveEnabled] = useState<boolean>(false);
    const [assetGroupList, setAssetGroupList] = useState<Nullable<AssetGroupList>>(null)

    useEffect(() => {
        getData(true);
    }, [page]);

    useEffect(() => {
        getAssetGroup();
    }, [currentSelectedGroupId]);

    useEffect(()=>{}, [saveEnabled])

    const getAssetGroup = async () => {
        if (!!currentSelectedGroupId) {
            uiService.showLoading();
            const list = await assetGroupService.getAssetGroupList(1);
            const response = await assetGroupService.getAssetGroup(currentSelectedGroupId);
            uiService.hideLoading();
            if (!!response && !!list) {
                setAssetGroupList(list);
                setSaveEnabled(false);
                setCurrentSelectedGroup(response);
            } else {
                uiService.showErrorNotification('Failed to load asset group');
            }
        }
    };

    const getData = async (forceSetFirst = false) => {
        uiService.showLoading();
        const response = await assetGroupService.getAssetGroupList(page);
        uiService.hideLoading();
        if (response === null) {
            uiService.showErrorNotification('Loading asset group list failed!');
            return;
        }
        uiService.showLoading();
        const assetListResponse = await transService.getAllAssets();
        uiService.hideLoading();
        if (assetListResponse === null) {
            uiService.showErrorNotification('Loading assets failed!');
            return;
        }
        setAssetList(assetListResponse);

        const setToFirst = (currentSelectedGroupId === null && response.items.length > 0) || forceSetFirst;
        const firstId = response.items.length > 0 ? response.items[0].id : null;

        setList(response);
        setCurrentSelectedGroupId(setToFirst ? firstId : currentSelectedGroupId);
    };

    const createAssetGroup = async (name: string) => {
        uiService.showLoading();
        const assetGroup = await assetGroupService.createAssetGroup({
            name: name,
            id: 0,
            assets: [],
            asset_groups: [],
        });
        uiService.hideLoading();
        
        if(!assetGroup){
            uiService.showErrorNotification('Failed to create an asset group');
            return;
        }

        uiService.showSuccessNotification('Created an asset group successfuly');

        getData(true);
    }

    const renderList = () => {
        return (
            <AssetGroupListView
                onPageChange={(page) => setPage(page)}
                page={page}
                selectedId={!!currentSelectedGroupId ? currentSelectedGroupId : -1}
                selectionChange={(e) => {
                    setCurrentSelectedGroupId(e);
                }}
                assetGroupList={list}
                canCreate={true}
                create={async (name) => {
                  await createAssetGroup(name);
                }}
            />
        );
    };

    const renderForm = () => {
        return (
            <AssetGroupsForm
                assetGroupList={assetGroupList!}
                assetGroup={currentSelectedGroup}
                assets={assetList!}
                deleteAssetGroup={async (id) => {
                    uiService.showLoading();
                    const response = await assetGroupService.deleteAssetGroup(id);
                    uiService.hideLoading();
                    if (!!response) {
                        getData(true);
                    } else {
                        uiService.showErrorNotification('Failed to load asset group');
                    }
                }}
                updateAssetGroup={(assetGroup: AssetGroup) => {
                    setCurrentSelectedGroup(assetGroup);
                    setSaveEnabled(true);
                }}
                saveEnabled={saveEnabled}
                saveAssetGroup={async (assetGroup: AssetGroup) => {
                    uiService.showLoading();
                    const assetGroupResponse = await assetGroupService.updateAssetGroup(assetGroup);
                    uiService.hideLoading();
                    if(!assetGroupResponse){
                        uiService.showErrorNotification('Failed to update the asset group');
                        return;
                    }
            
                    uiService.showSuccessNotification('Updated the asset group successfuly');
                    if (!!assetGroupResponse) {
                        setSaveEnabled(false);
                        setCurrentSelectedGroup(assetGroupResponse);
                    }
                }}
            />
        );
    };

    return (
        <Grid w="calc(100vw - 250px)">
            <Grid.Col span="content">{renderList()}</Grid.Col>
            <Grid.Col span="auto">{renderForm()}</Grid.Col>
        </Grid>
    );
}

import IList from "../IList";
import TopicEntry from "./TopicEntry";

export default class TopicList implements IList<TopicEntry>{
    public max_results!: number;
    public items! : TopicEntry[]
    getItems(): TopicEntry[] {
        return this.items;
    }
    
}
import React from 'react';
import { DevOpsCategoryEnum } from '../../../models/devops/DevopsCategoryEnum';
import DevOpsTopDropdown from '../devops-top-dropdown/devops-top-dropdown.component';
import styles from '../devops-styles.module.scss';
import DevopsDefaultView from '../devops-default-view/devops-default-view.component';
import DevopRepositoryView from '../devops-repository-view/devops-repository-view.component';
import type ICreateProductWizardService from '../../../services/ICreateProductWizardService';
import { resolve } from 'inversify-react';
enum DevOpsViewEnum {
    Default,
    Repository,
}

interface DevOpsCPDirectoryState {
    category: string;
    view: DevOpsViewEnum;
}

export default class DevopsCPDirectory extends React.Component<{}, DevOpsCPDirectoryState> {
    @resolve('ICreateProductWizardService')
    private wizardCreationService!: ICreateProductWizardService;

    constructor(props: {}) {
        super(props);
        this.state = {
            category: 'kd-sim',
            view: DevOpsViewEnum.Default,
        };
    }

    defaultContent() {
        return <DevopsDefaultView category={this.state.category} />;
    }

    repositoryContent() {
        return <DevopRepositoryView />;
    }

    content() {
        const { view } = this.state;
        switch (view) {
            case DevOpsViewEnum.Default:
                return this.defaultContent();
            case DevOpsViewEnum.Repository:
                return this.repositoryContent();
            default:
                return this.defaultContent();
        }
    }

    render() {
        return (
            <div className={styles.column}>
                {this.renderHeader()}
                {this.content()}
            </div>
        );
    }

    renderHeader(): React.ReactNode {
        return (
            <DevOpsTopDropdown
                product={this.state.category}
                productChanged={(e) => {
                    console.log(e);
                    this.setState({
                        ...this.state,
                        category: e,
                    });
                }}
                onAddClick={() => {
                    console.log(this.wizardCreationService);
                    this.wizardCreationService.showWizard();
                }}
            />
        );
    }
}

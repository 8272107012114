import StoreProductEntry from '@/models/trans/storeProducts/StoreProductEntry';
import StoreProductList from '@/models/trans/storeProducts/StoreProductList';
import { Avatar, Group, Badge, Container, Box, ActionIcon } from '@mantine/core';
import DefaultListOptionView, {
    DefaultListOptionWithImageView,
} from '../../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../../general/list-view/list-view.component';
import { SearchBox } from '../../../general/search-box/search-box';
import TopicList from '@/models/mail/TopicList';
import MailTopicCreate from '../mail-topic-create-button/mail-topic-create-button.component';
import { MdOutlineDelete } from 'react-icons/md';

interface MailTopicListProps {
    create?: (name: string) => void;
    delete?: (name: string)=>void;
    selectionChange: (id: string) => void;
    selectedId: string;
    topicList: TopicList | null;
    page: number;
    onPageChange: (page: number) => void;
    searchChange?: (query: string) => void;
    canCreate?: boolean;
}
export default function MailTopicList({
    searchChange,
    selectedId,
    selectionChange,
    topicList,
    page,
    onPageChange,
    create,
    canCreate,
    ...rest
}: MailTopicListProps) {
    const onSelectionChange = (id: string) => {
        selectionChange(id);
    };


    return (
        <Container px={0} pt="md">
            {/* <Box px="xs">
                <SearchBox
                    setSearchQuery={(q) => {
                        if (!!searchChange) {
                            searchChange(q);
                        }
                    }}
                />
            </Box> */}
            <ListViewComponent
                elements={topicList}
                page={page}
                selectedValue={selectedId}
                pageChanged={onPageChange}
                selectionChangedString={onSelectionChange}
                listElement={(x, selectionChange, inSelect) => {
                    return (
                        <DefaultListOptionWithImageView
                            key={x.id}
                            id={x.name}
                            value={x.name.length>30?x.name.substring(0,27)+'...':x.name}
                            selectionChange={selectionChange}
                            selected={selectedId === x.name}
                            inSelectContainer={inSelect}
                            tooltip={x.name.length>30?x.name:undefined}
                            rightIcon={ (<ActionIcon variant="filled" color="red" onClick={e=>{
                                e.preventDefault();
                                if(!!rest.delete)
                                    rest.delete(x.name)
                            }}>
                                <MdOutlineDelete color='white'/>
                            </ActionIcon>)}
                        />
                    );
                }}
            >
                <ListViewComponent.Header>
                    {!!canCreate && (
                        <MailTopicCreate
                            create={(name) => {
                                if (!!create) create(name);
                            }}
                        />
                    )}
                </ListViewComponent.Header>
            </ListViewComponent>
        </Container>
    );
}

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MantineReactTable, MRT_ColumnDef, MRT_ToggleFullScreenButton } from 'mantine-react-table';
import { ActionIcon, Box, Button, Divider, Flex, Text, ThemeIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { IconBugOff, IconDownload } from '@tabler/icons-react';
import { CgAsterisk } from 'react-icons/cg';
import { BsExclamationLg, BsInfoLg } from 'react-icons/bs';
import { useInjection } from 'inversify-react';
import { IServerLogService } from '@/services/IServerLogService';
import { ServerLog } from '@/models/monitoring/server-logs/ServerLog';
import { SearchBox } from '../../general/search-box/search-box';
import IDateTimeService from '@/services/IDateTimeService';
import { LogHistogram } from './logs-histogram.component';
import styles from './styles.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import classes from '../../../utils/classes';
import { ServerLogList } from '@/models/monitoring/server-logs/ServerLogList';
import { setLoading } from '@/redux/slices/loadingSlice';
import Nullable from '@/dataTypes/Nullable';

interface LogsTableProps {
    shard: string;
    refreshOn: boolean;
}

interface LogFilterInterface {
    search: string;
    startDate: Date;
    endDate: Date;
}

interface PreContextState extends LogFilterInterface {}

interface LogFilterData extends LogFilterInterface {
    selection: string;
    fromScroll : boolean;
    lastFetchDate: number
}

export const LogsTable = ({ refreshOn, shard }: LogsTableProps) => {
    const [preContext, setPreContext] = useState<PreContextState>({
        startDate: dayjs().subtract(1, 'day').toDate(),
        endDate: dayjs().toDate(),
        search: '',
    });

    const [filter, setFilter] = useState<LogFilterData>({
        search: '',
        startDate: dayjs().subtract(1, 'day').toDate(),
        endDate: dayjs().toDate(),
        selection: '',
        fromScroll :false,
        lastFetchDate : 0
    });

    const theme = useMantineTheme();
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const serverLogService = useInjection<IServerLogService>('IServerLogService');
    const dtService = useInjection<IDateTimeService>('IDateTimeService');

    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState<Nullable<ServerLogList>>(null);

    // const { data, isError, isFetching, isLoading } = useQuery({
    //     queryKey: ['table-data', shard, filter],
    //     queryFn: () =>
    //     refetchOnWindowFocus: false,
    //     enabled: refreshOn,
    // });

    useEffect(() => {
        setIsLoading(true);
        setIsFetching(true);
        setIsError(false);
        serverLogService
            .getGoogleCloudLogs(shard, filter.startDate, filter.endDate, filter.search)
            .then((x) => {
                setIsLoading(false);
                setIsFetching(false);
                setData(x);
            })
            .catch(() => {
                setIsError(true);
            });
    }, [shard, filter]);

    const columns  : MRT_ColumnDef<ServerLog>[] = [
            {
                accessorKey: 'severity',
                header: 'SEVERITY',
                size: 100,
                enableSorting: false,
                Cell: ({ cell }) => {
                    const value = cell.getValue<string>().toLowerCase();

                    const getIconProperties = (value: string) => {
                        const iconProperties = {
                            info: {
                                icon: <BsInfoLg size={11} />,
                                variant: 'filled',
                                color: '#2196F3',
                            },
                            notice: {
                                icon: <BsInfoLg size={11} />,
                                variant: 'filled',
                                color: '#3F51B5',
                            },
                            warning: {
                                icon: <BsExclamationLg color="#fff" size={10} />,
                                variant: 'filled',
                                color: '#FF9800',
                            },
                            error: {
                                icon: <BsExclamationLg color="#fff" size={10} />,
                                variant: 'filled',
                                color: '#F44336',
                            },
                            critical: {
                                icon: <BsExclamationLg color="#fff" size={10} />,
                                variant: 'filled',
                                color: '#9C27B0',
                            },
                            alert: {
                                icon: <BsExclamationLg color="#fff" size={10} />,
                                variant: 'filled',
                                color: '#E91E63',
                            },
                            emergency: {
                                icon: <BsExclamationLg color="#fff" size={10} />,
                                variant: 'filled',
                                color: '#795548',
                            },
                            debug: {
                                icon: <IconBugOff size={14} />,
                                variant: 'filled',
                                color: '#9E9E9E',
                            },
                        };

                        return (
                            iconProperties[value as keyof typeof iconProperties] || {
                                icon: <CgAsterisk size={15} />,
                                variant: 'light',
                                color: 'blue',
                            }
                        );
                    };

                    const { icon, variant, color } = getIconProperties(value);
                    return (
                        <Tooltip position="right" label={value.toUpperCase()}>
                            <ThemeIcon size="sm" radius="xl" variant={variant} color={color}>
                                {icon}
                            </ThemeIcon>
                        </Tooltip>
                    );
                },
            },
            {
                accessorKey: 'timestamp',
                header: 'TIMESTAMP',
                size: 200,
                enableSorting: true,
                Header: () => (
                    <Flex align="center" justify="space-between">
                        <Text>TIMESTAMP</Text>
                        <Text span size="xs" ml="xs" weight={400}>
                            {dtService.getLocalTimezone()}
                        </Text>
                    </Flex>
                ),
                Cell: ({ cell }) => dtService.formatDate(cell.getValue<Date>()),
            },
            {
                header: 'SUMMARY',
                enableSorting: false,
                mantineTableBodyCellProps: {
                    sx: {
                        maxWidth: '800px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    },
                },
                Cell: ({ cell, row }) => {
                    return (
                        <div
                            className={classes(
                                'payloadColumn',
                                row.original.timestamp === filter.selection ? 'active' : undefined
                            )}
                            data-timestamp={row.original.timestamp}
                            data-selection={filter.selection}
                            data-selected={row.original.timestamp === filter.selection}
                        >
                            {row.original.payload}
                            {filter.selection === '' && (
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const timeStamp = row.original.timestamp;
                                        const date = Date.parse(timeStamp);

                                        const dateJS = dayjs(date);

                                        const startTime = dateJS.subtract(1, 'h');
                                        const endTime = dateJS.add(1, 'h');
                                        setPreContext({
                                            ...filter,
                                        });

                                        setFilter({
                                            selection: row.original.timestamp,
                                            search: '',
                                            startDate: startTime.toDate(),
                                            endDate: endTime.toDate(),
                                            fromScroll : false,
                                            lastFetchDate : Date.now()
                                        });
                                    }}
                                    className={classes(styles.button, styles.outlineButton, 'buttonJump')}
                                >
                                    Jump To Context
                                </button>
                            )}
                        </div>
                    );
                },
            },
        ];

    const flatData = useMemo(() => data?.items, [data]) ?? [];

    const fetchNext = ()=>{
        const endDate = dayjs(filter.endDate).add(1, 'h').toDate();
        setFilter({
            ...filter,
            endDate : endDate,
            fromScroll : true,
            lastFetchDate : Date.now()
        })
        console.log('loading');
    };

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
          if (containerRefElement) {
            const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
            //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
            if (
              scrollHeight - scrollTop - clientHeight < 50 &&
              !isFetching &&
              filter.endDate < dayjs().add(1,'h').toDate()
              && Date.now()-filter.lastFetchDate > 5000
            ) {
              fetchNext();
            }
          }
        },
        [fetchNext, isFetching],
      );

    // useEffect(()=>{
    //     fetchMoreOnBottomReached(tableContainerRef.current);
    // },
    // [fetchMoreOnBottomReached])


    const onDownload = () => {
        if (!data) return;
        serverLogService.exportAsCSV(data);
    };

    return (
        <Box sx={{ zIndex: 102 }}>
            <LogHistogram
                inContextMode={filter.selection !== ''}
                clearContext={() => {
                    setFilter({
                        ...filter,
                        search: preContext.search,
                        startDate: preContext.startDate,
                        endDate: preContext.endDate,
                        selection: '',
                    });
                }}
                data={data!}
                setStartEndDates={(e) => {
                    setFilter({
                        ...filter,
                        ...e,
                    });
                    setPreContext({
                        ...preContext,
                        ...e,
                    });
                }}
                startEndDates={{
                    startDate: filter.startDate,
                    endDate: filter.endDate,
                }}
            />
            <MantineReactTable
                columns={columns}
                data={flatData}
                enablePagination={false}
                enableTableFooter={false}
                enableStickyFooter={false}
                enableColumnActions={false}
                enableStickyHeader
                enableTopToolbar
                globalFilterFn="contains"
                layoutMode="grid"
                //@ts-ignore
                mantineTableBodyRowProps={({row})=>{
                    return ({
                        //@ts-ignore
                        ref : (node)=>{
                            row.original.timestamp === filter.selection && !filter.fromScroll && node?.scrollIntoView({ behavior: "smooth", block: "center" });
                        }
                    });
                }}
                initialState={{
                    density: 'xs',
                    showGlobalFilter: true,
                }}
                state={{
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                }}
                mantineTableHeadCellProps={{
                    sx: {
                        flex: '0 0 auto',
                    },
                }}
                mantineTableBodyCellProps={{
                    sx: {
                        flex: '0 0 auto',
                    },
                }}
                displayColumnDefOptions={{
                    'mrt-row-expand': {
                        size: 60,
                    },
                }}
                mantineTableBodyProps={{
                    sx: {
                        '.buttonJump': {
                            display: 'none',
                        },
                        'tr:has(.active)': {
                            backgroundColor: 'lightblue',
                        },
                        '& td:last-child': {
                            flex: '1 1',
                            maxWidth: 'unset',

                            '.payloadColumn': {
                                position: 'relative',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '100%',
                            },

                            '.active': {},

                            '&:hover': {
                                '.buttonJump': {
                                    display: 'block',
                                },
                            },
                        },
                    },
                }}
                mantineTableContainerProps={{
                    ref: tableContainerRef,
                    sx: { maxHeight: '600px' },
                    onScroll: (
                        event //add an event listener to the table container element
                      ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
                }}
                mantineTableHeadRowProps={{
                    bg: theme.colors.gray[2],
                }}
                renderTopToolbar={({ table }) => (
                    <Flex w="100%" justify="space-between" align="center" px="sm" h={45}>
                        <Flex gap={20} align="center">
                            <Text weight={500}>Query Results</Text>
                            <Text size="sm">{flatData.length} log entries</Text>
                        </Flex>
                        <Flex gap="sm" align="center">
                            <Box mt="sm">
                                <SearchBox
                                    placeholder="Search logs"
                                    setSearchQuery={(e) => {
                                        setFilter({
                                            ...filter,
                                            search: e,
                                        });
                                        setPreContext({
                                            ...preContext,
                                            search: e,
                                        });
                                    }}
                                />
                            </Box>
                            <ActionIcon onClick={onDownload}>
                                <IconDownload size={25} />
                            </ActionIcon>
                            <Flex align="center" gap="xs">
                                <Divider my="auto" orientation="vertical" h={25} />
                                <MRT_ToggleFullScreenButton table={table} />
                            </Flex>
                        </Flex>
                    </Flex>
                )}
                positionToolbarAlertBanner="bottom"
                mantineToolbarAlertBannerProps={
                    isError
                        ? {
                              color: 'red',
                              children: 'Error loading data',
                          }
                        : undefined
                }
                mantineDetailPanelProps={{
                    bg: theme.colors.gray[2],
                    px: 'sm',
                }}
                renderDetailPanel={({ row }) => <span style={{paddingLeft: '160px'}}>{row.original.payload}</span> }
            />
        </Box>
    );
};

import BundleInfoModel from "@/models/devops/build/BundleInfoModel";
import RepositoryModel from "@/models/devops/build/RepositoryModel";
import IBuildRepositoryService from "@/services/IBuildRepositoryService";
import IBundleService from "@/services/IBundleService";
import IUIService from "@/services/IUIService";
import { Button, Flex, Grid, Group, Stack, TextInput } from "@mantine/core";
import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import RepositorySelection from "../../devops-repository-view/repository-selection-views/repository-selection.component";
import UnityProjectSelectionComponent from "../../unity-project-selection/unity-project-selection.component";
import ProductBundleList from "../product-bundle-list/product-bundle-list.component";
import Nullable from "@/dataTypes/Nullable";
import BuildProductVersionInfoList from "@/models/devops/build/product/BuildProductVersionInfoList";
import { IDevOpsRESTClient } from "@/rest-clients/IDevOpsRESTClient";

interface IProductFormProps {
    product: string;
}
export default function ProductForm({ product }: IProductFormProps) {

    const bundleService = useInjection<IBundleService>('IBundleService')
    const buildRepositoryService = useInjection<IBuildRepositoryService>('IBuildRepositoryService')
    const devopsRESTClient = useInjection<IDevOpsRESTClient>('IDevOpsRESTClient')
    const uiService = useInjection<IUIService>('IUIService')

    const [bundles, setBundles] = useState<BundleInfoModel[]>([]);
    const [repository, setRepository] = useState<RepositoryModel>();
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [dirtyBundles, setDirtyBundles] = useState<number[]>([])
    const [newBundles, setNewBundles] = useState<number[]>([])
    const [deletedBundles, setDeletedBundles] = useState<number[]>([])
    const [productVersionInfoList, setProductVerionInfoList] = useState<Nullable<BuildProductVersionInfoList>>(null)

    const getData = async function (reset?: boolean) {
        uiService.showLoading();
        const response = await bundleService.getBundlesByProduct(product);
        const repositoryData = await buildRepositoryService.getRepository(product);
        const versionList = await devopsRESTClient.getBuildProductVersionInfoList(product);
        uiService.hideLoading();
        if (!!response && !!repositoryData && !!versionList) {
            setRepository(repositoryData);
            setBundles([...response]);
            setProductVerionInfoList(versionList);
        } else {
            uiService.showErrorNotification('Failed to retrieve bundles');
        }

        if (!!reset) {
            setSaveEnabled(false);
            setDirtyBundles([]);
            setNewBundles([]);
        }
    }

    useEffect(() => {
        const getData = async function () {
            uiService.showLoading();
            const response = await bundleService.getBundlesByProduct(product);
            const repositoryData = await buildRepositoryService.getRepository(product);
            const versionList = await devopsRESTClient.getBuildProductVersionInfoList(product);
            uiService.hideLoading();
            if (!!response && !!repositoryData && !!versionList) {
                setRepository(repositoryData);
                setBundles([...response]);
                setProductVerionInfoList(versionList);
            } else {
                uiService.showErrorNotification('Failed to retrieve bundles');
            }
        }
        getData();
    }, [product]);

    const content = !!repository &&
        (<>
            <Grid>
                <Grid.Col span={6}>
                    <UnityProjectSelectionComponent
                        label
                        repository={repository}
                        updateModel={x => { setRepository({ ...x }); setSaveEnabled(true); }} />
                    <RepositorySelection
                        label
                        repository={repository}
                        updateModel={x => { setRepository({ ...x }); setSaveEnabled(true); }}
                        minimize />
                </Grid.Col>
            </Grid>

            <ProductBundleList
                productList={productVersionInfoList!}
                bundles={bundles}
                dirtyBundles={dirtyBundles}
                newBundles={newBundles}
                deletedBundles={deletedBundles}
                deleteBundle={(i) => {
                    deletedBundles.push(i);
                    const index = newBundles.indexOf(i);
                    console.log(index);
                    if (index !== -1) {
                        delete newBundles[index];
                        setNewBundles([...newBundles]);
                    }
                    setDeletedBundles([...deletedBundles]);
                    setSaveEnabled(true);
                }}
                update={(i, b) => {
                    setBundles([...b])
                    if (newBundles.indexOf(i) === -1) {
                        dirtyBundles.push(i);
                        setDirtyBundles([...dirtyBundles]);
                    }

                    setSaveEnabled(true);
                }}
                createBundle={(ready) => {
                    bundles.push({
                        access: 0,
                        build_target_osx: '',
                        build_target_pc: '',
                        git_path: '',
                        id: 0,
                        install_path: '',
                        name: '',
                        product: product,
                        storage_path_mac: '',
                        storage_path_pc: '',
                        cutoff_version: null
                    });
                    newBundles.push(bundles.length - 1);
                    console.log(newBundles);
                    setNewBundles([...newBundles]);
                    setBundles([...bundles]);
                    setSaveEnabled(true);
                    if (!!ready) ready();
                }} />
            <Flex 
            justify='flex-end'
            align='center'
            gap='sm'>
                <Button disabled={!saveEnabled} onClick={async x => {
                    x.preventDefault();
                    uiService.showLoading();
                    const promises = [];
                    const buildUpdate = buildRepositoryService.updateRepository(repository);
                    promises.push(buildUpdate);

                    for (const dirtyBundle of dirtyBundles) {
                        if (deletedBundles.includes(dirtyBundle))
                            continue;
                        const bundleUpdate = bundleService.updateBundle(bundles[dirtyBundle]);
                        promises.push(bundleUpdate);
                    }
                    
                    for (const newBundle of newBundles) {
                        if (!newBundle) continue;
                        const bundleCreate = bundleService.createBundle(bundles[newBundle]);
                        promises.push(bundleCreate);
                    }

                    for (const deletedBundle of deletedBundles) {
                        if (!deletedBundle) continue;
                        const bundleDelete = bundleService.deleteBundleById(bundles[deletedBundle].id);
                        promises.push(bundleDelete);
                    }

                    try {
                        Promise.all(promises).then(x => {
                            uiService.hideLoading();
                            getData(true);
                        }).catch(x => {
                            uiService.hideLoading();
                        })

                    }
                    catch {
                        uiService.hideLoading();
                        getData(true);
                    }


                }}>Save</Button>
                <Button color="red" variant="outline" onClick={async x => {
                    x.preventDefault();
                    uiService.showLoading();
                    const response = await buildRepositoryService.deleteRepository(product);

                    if (response) {
                        uiService.showSuccessNotification('Deleting repository successful!');
                    } else {
                        uiService.showErrorNotification('Deleting repository failed!');
                    }

                    uiService.hideLoading();

                }}>Delete</Button>
            </Flex>
        </>)

    return (

        <Stack sx={(theme) => ({ padding: '24px' })}>
            {content}
        </Stack>
    )
}
import axios from 'axios';
import { inject, injectable } from 'inversify';
import AssetList from '../../models/trans/AssetList';
import FailedTransaction from '../../models/trans/failedTransactions/FailedTransaction';
import FailedTransactionList from '../../models/trans/failedTransactions/FailedTransactionList';
import type { IAuthService } from '../../services/IAuthService';
import ITransRESTClient from '../ITransRESTClient';
import { RESTClient } from './RESTClient';
import ShopifyCollectionList from '../../models/trans/storeItems/collections/ShopifyCollectionList';
import { ProductKeyList } from '../../models/trans/productKeys/ProductKeyCollection';
import StoreProductList from '../../models/trans/storeProducts/StoreProductList';
import StoreProduct from '@/models/trans/storeProducts/StoreProduct';
import Nullable from '@/dataTypes/Nullable';
import InventoryLocationData from '@/models/trans/storeProducts/Shopify/InventoryLocationData';
import CustomerList from '../../models/trans/customer/CustomerList';
import SucceededTransactionTitle from '@/models/trans/succeeded-transactions/SucceededTransactionsWithTitle';
import ProductKeyNameModel from '@/models/trans/productKeys/ProductKeyNameModel';
import AssetGroup from '../../models/trans/asset-group/AssetGroup';
import AssetGroupList from '../../models/trans/asset-group/AssetGroupList';
import StoreProductVariantList from '@/models/trans/storeProducts/StoreProductVariantList';

@injectable()
export default class TransRESTClient extends RESTClient implements ITransRESTClient {
    @inject('IAuthService')
    private authService!: IAuthService;

    constructor() {
        super();

        this.setBaseURL('https://wod-trans-uux56memxa-uc.a.run.app/api/v1/');
        //this.setBaseURL('https://localhost:44384/api/v1/');
    }

    async createAssetGroup(assetGroup: AssetGroup): Promise<Nullable<AssetGroup>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`asset-groups`);
        try {
            const response = await axios.post(
                path,
                JSON.stringify(assetGroup),
                this.getHeaderJson(this.getHeader(transToken))
            );

            return response.data as AssetGroup;
        } catch {
            return null;
        }
    }
    async updateAssetGroup(assetGroup: AssetGroup): Promise<Nullable<AssetGroup>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`asset-groups`);
        try {
            const response = await axios.put(
                path,
                JSON.stringify(assetGroup),
                this.getHeaderJson(this.getHeader(transToken))
            );

            return response.data as AssetGroup;
        } catch {
            return null;
        }
    }
    async getAssetGroup(id: number): Promise<Nullable<AssetGroup>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`asset-groups/${id}`);
        try {
            const response = await axios.get(
                path,
                this.getHeaderJson(this.getHeader(transToken))
            );

            return response.data as AssetGroup;
        } catch {
            return null;
        }
    }
    async getAssetGroupList(page: number): Promise<Nullable<AssetGroupList>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`asset-groups?page=${page}&perPage=40`);
        try {
            const response = await axios.get(
                path,
                this.getHeaderJson(this.getHeader(transToken))
            );

            const list = response.data as AssetGroupList;
            const returnList = new AssetGroupList();
            returnList.items = list.items;
            returnList.max_results = list.max_results;

            return returnList;
        } catch {
            return null;
        }
    }

    async deleteAssetGroup(id: number): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return false;

        const path = this.getFullURL(`asset-groups/${id}`);
        try {
            await axios.delete(
                path,
                this.getHeaderJson(this.getHeader(transToken))
            );

            return true;
        } catch {
            return false;
        }
    }

    async getCollections(): Promise<Nullable<ShopifyCollectionList>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`store-collections`);
        try {
            const response = await axios.get(path, this.getHeader(transToken));

            const list = response.data as ShopifyCollectionList;

            return list;
        } catch {
            return null;
        }
    }

    async getAllAssets(): Promise<Nullable<AssetList>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`assets`);
        try {
            const response = await axios.get(path, this.getHeader(transToken));

            const list = response.data as AssetList;

            return list;
        } catch {
            return null;
        }
    }

    async getAllVariants(): Promise<Nullable<StoreProductVariantList>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`store-product-variants`);
        try {
            const response = await axios.get(path, this.getHeader(transToken));

            const list = response.data as StoreProductVariantList;

            return list;
        } catch {
            return null;
        }
    }

    async getList(query:string): Promise<Nullable<StoreProductList>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`store-products?query=${query}`);
        try {
            const response = await axios.get(path, this.getHeader(transToken));

            const list = response.data as StoreProductList;

            const listParsed = new StoreProductList();
            listParsed.items = list.items;
            listParsed.max_results = list.max_results;

            return listParsed;
        } catch {
            return null;
        }
    }

    async getItem(id: number): Promise<Nullable<StoreProduct>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`store-product/${id}`);
        try {
            const response = await axios.get(path, this.getHeader(transToken));

            const group = response.data as StoreProduct;

            return group;
        } catch {
            return null;
        }
    }

    async updateItem(storeProduct: StoreProduct): Promise<Nullable<StoreProduct>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`store-product`);
        try {
            const response = await axios.put(
                path,
                JSON.stringify(storeProduct),
                this.getHeaderJson(this.getHeader(transToken))
            );

            return response.data as StoreProduct;
        } catch {
            return null;
        }
    }

    async deleteItem(id: number): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return false;

        const path = this.getFullURL(`store-product/${id}`);
        try {
            await axios.delete(path, this.getHeader(transToken));

            return true;
        } catch {
            return false;
        }
    }

    async createProduct(product: StoreProduct): Promise<Nullable<StoreProduct>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`store-product`);
        try {
            const response = await axios.post(
                path,
                JSON.stringify(product),
                this.getHeaderJson(this.getHeader(transToken))
            );

            return response.data as StoreProduct;
        } catch {
            return null;
        }
    }

    async getAllFailedTransactions(page: number, query: string): Promise<Nullable<FailedTransactionList>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`failed-transactions?page=${page}&query=${query}`);
        try {
            const response = await axios.get(path, this.getHeader(transToken));

            const list = response.data as FailedTransactionList;
            const copyList = new FailedTransactionList();
            copyList.items = list.items;
            copyList.max_results = list.max_results;
            return copyList;
        } catch {
            return null;
        }
    }
    async getFailedTransaction(orderId: number): Promise<Nullable<FailedTransaction>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        const path = this.getFullURL(`failed-transaction/${orderId}`);
        try {
            const response = await axios.get(path, this.getHeader(transToken));

            const data = response.data as FailedTransaction;
            return data;
        } catch {
            return null;
        }
    }

    async updateFailedTransaction(failedTransaction: FailedTransaction): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return false;

        const path = this.getFullURL(`failed-transaction`);
        try {
            await axios.put(path, JSON.stringify(failedTransaction), this.getHeaderJson(this.getHeader(transToken)));

            return true;
        } catch {
            return false;
        }
    }

    async getInventoryLocation(): Promise<InventoryLocationData[]> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return [];

        const transToken = await this.getToken(authToken);

        if (transToken === null) return [];

        const path = this.getFullURL(`shopify/inventory/locations`);
        try {
            const response = await axios.get(path, this.getHeader(transToken));

            return response.data as InventoryLocationData[];
        } catch {
            return [];
        }
    }

    async getProductKey(productId: string, count: number, quantity?: number): Promise<ProductKeyList | null> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        if(!quantity)
            quantity = 1;

        const data = {
            productId,
            count,
            quantity
        }

        const path = this.getFullURL(`product-key`);
        try {
            const response = await axios.post(path, data, this.getHeaderJson(this.getHeader(transToken)));

            return response.data as ProductKeyList;
        } catch {
            return null;
        }
    }

    async getCustomerList(page: number, query? : string): Promise<Nullable<CustomerList>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return null;

        let path = this.getFullURL(`customers?page=${page}`);

        if(!!query){
            path = path+`&query=${query}`
        }

        try {
            const response = await axios.get(path, this.getHeader(transToken));

            const list = response.data as CustomerList;
            const copyList = new CustomerList();
            copyList.items = list.items;
            copyList.max_results = list.max_results;
            return copyList;
        } catch {
            return null;
        }
    }

    async upgradeToShopify(productId: string): Promise<string | undefined> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return undefined;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return undefined;

        const path = this.getFullURL(`store-item/${productId}/shopify`);
        try {
            const response = await axios.put(path, null, this.getHeader(transToken));

            return response.data.id as string;
        } catch {
            return undefined;
        }
    }

    async getUserPurchases(iss:string) :Promise<Nullable<SucceededTransactionTitle[]>>{
        const authToken = await this.authService.getToken();
        if (authToken === null) return [];

        const transToken = await this.getToken(authToken);

        if (transToken === null) return [];

        const path = this.getFullURL(`customer/${iss}/transactions`);
        try {
            const response = await axios.get(path, this.getHeader(transToken));

            return response.data as SucceededTransactionTitle[];
        } catch {
            return [];
        }
    }


    async getUserGiftCodes(iss:string) :Promise<Nullable<ProductKeyNameModel[]>>{
        const authToken = await this.authService.getToken();
        if (authToken === null) return [];

        const transToken = await this.getToken(authToken);

        if (transToken === null) return [];

        const path = this.getFullURL(`customer/${iss}/product-key`);
        try {
            const response = await axios.get(path,  this.getHeader(transToken));

            return response.data as ProductKeyNameModel[];
        } catch {
            return [];
        }
    }

    
    async removePurchase(id: number): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return false;

        const path = this.getFullURL(`succeeded-transactions/${id}`);
        try {
            await axios.delete(path,  this.getHeader(transToken));

            return true;
        } catch {
            return false;
        }
    }
    
    async removeCustomerProductKey(id: number): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return false;

        const path = this.getFullURL(`product-key/${id}`);
        try {
            await axios.delete(path,  this.getHeader(transToken));
            
            return true;
        } catch {
            return false;
        }
    }

    async addCustomerProductKey(iss:string, variantId: number, quantity: number) : Promise<boolean>{
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return false;

        const path = this.getFullURL(`customer/${iss}/product-key/variant`);

        const formData = new FormData()
        formData.set('variantId', variantId.toString())
        formData.set('quantity', quantity.toString())

        try {
            await axios.post(path, formData,  this.getHeader(transToken));
            return true
        } catch {
            return false;
        }
    }

    
    async activateCustomerProductKey(iss: string, productKey: string): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return false;

        const path = this.getFullURL(`customer/${iss}/product-key`);

        const formData = new FormData()
        formData.set('productKey', productKey)

        try {
            await axios.put(path, formData,  this.getHeader(transToken));
            return true
        } catch {
            return false;
        }
    }

    async processEmailBatch(emails:string[], variantId:string, autoConsume : boolean, allEmails : boolean) : Promise<boolean>{
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return false;

        const path = this.getFullURL(`product-key/batches`);

        const data = {
            variantId,
            emails,
            autoConsume,
            allEmails
        }

        const jsonData = JSON.stringify(data)

        try {
            await axios.post(path, jsonData,  this.getHeaderJson(this.getHeader(transToken)));
            return true
        } catch {
            return false;
        }
    }

    async copyUnboxedItems(saveId: number, iss:string, newSaveId: number) : Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(
            `unboxed/saves/${saveId}/to/customer/${iss}/save/${newSaveId}`
        );

        try {
            await axios.put(path, null, this.getHeader(devopsToken));
            return true
        } catch {
            return false;
        }
    }

    async sendInvitesToEmails(emails:string[], variantId:string) : Promise<boolean>{
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const transToken = await this.getToken(authToken);

        if (transToken === null) return false;

        const path = this.getFullURL(`product-key/invites`);

        const data = {
            variantId,
            emails
        }

        const jsonData = JSON.stringify(data)

        try {
            await axios.post(path, jsonData,  this.getHeaderJson(this.getHeader(transToken)));
            return true
        } catch {
            return false;
        }
    }
}

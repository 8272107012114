import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface NewVersionDialogState {
    open : boolean,
    header:string,
    tooltip:string,
    response : string | null,
    createVersionButton? : string,
}

const initialState: NewVersionDialogState = {
    open:false,
    header : 'New Version',
    tooltip : 'Version Name',
    response : null,
    createVersionButton : "Create"
}

export interface NewDialogPayload{
    header:string,
    tooltip : string,
    createVersionButton?: string
}

export const newVersionSlice = createSlice({
    name: 'newVersionSlice',
    initialState,
    reducers: {
        showNewVersion: (state, action: PayloadAction<NewDialogPayload>) => {
            const {header, tooltip, createVersionButton} = action.payload;
            state.open = true;
            state.header = header;
            state.tooltip = tooltip;
            state.response = null;
            if(!!createVersionButton)
            state.createVersionButton = createVersionButton
            else
            state.createVersionButton = 'Create';
        },
        setResponse : (state, action : PayloadAction<string|null>)=>{
            state.response = action.payload;
        },
        hideNewVersion : (state, action : PayloadAction)=>{
            state.open = false;
        }
    },
})

export const { showNewVersion, setResponse, hideNewVersion } = newVersionSlice.actions

export const selectLoading = (state: RootState) => state.loading.loading

export default newVersionSlice.reducer

import { Chart } from 'react-google-charts';
import { useEffect, useState } from 'react';
import { ActionIcon, Box, Button, Flex, Paper, RangeSlider, Skeleton, Text } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconClockHour4 } from '@tabler/icons-react';
import { TimePicker } from '../../general/time-picker/time-picker.component';
import { ZoomControls } from '../../general/zoom-controls/zoom-controls.component';
import { IStartEndDate } from './types';
import { useInjection } from 'inversify-react';
import { IServerLogService } from '@/services/IServerLogService';
import { ServerLogList } from '@/models/monitoring/server-logs/ServerLogList';
import IDateTimeService from '@/services/IDateTimeService';

interface DateRangeSliderProps {
    minDate: Date;
    maxDate: Date;
    onSliderChange: (values: [number, number]) => void;
}

const DateRangeSlider: React.FC<DateRangeSliderProps> = ({ minDate, maxDate, onSliderChange }) => {
    const dtService = useInjection<IDateTimeService>('IDateTimeService');
    const [showRunQuery, setShowRunQuery] = useState(false);
    const [values, setValues] = useState<[number, number]>([0, 100]);

    const minTimestamp = minDate.getTime();
    const maxTimestamp = maxDate.getTime();

    useEffect(() => {
        setValues([minTimestamp, maxTimestamp]);
    }, [minTimestamp, maxTimestamp]);

    const dateDifference = dtService.getDateDifference(maxDate, minDate);

    const numSteps = 100;

    const stepValue = dateDifference / numSteps;

    const handleSliderChange = (values: [number, number]) => {
        setValues(values);
        if (values[0] === minTimestamp && values[1] === maxTimestamp) {
            setShowRunQuery(false);
        } else {
            setShowRunQuery(true);
        }
    };

    const handleRunQuery = () => {
        onSliderChange(values);
        setShowRunQuery(false);
    };

    const handleResetQuery = () => {
        setValues([minTimestamp, maxTimestamp]);
        setShowRunQuery(false);
    };

    const buttonPosition =
        ((values[0] - minTimestamp) / (maxTimestamp - minTimestamp)) * 100 +
        ((values[1] - values[0]) / (maxTimestamp - minTimestamp)) * 50;

    return (
        <Box pos="relative" bottom={33}>
            <RangeSlider
                min={minTimestamp}
                max={maxTimestamp}
                step={stepValue}
                value={values}
                onChange={handleSliderChange}
                size={3}
                thumbSize={15}
                label={(value) => {
                    return dtService.formatDate(new Date(value), 'DD/MM/YYYY HH:mm:ss');
                }}
            />
            {showRunQuery && (
                <Box pos="absolute" bottom="-40px" left={`calc(${buttonPosition}% - 50px)`}>
                    <Paper shadow="md" p={5} radius="md">
                        <Flex gap="xs">
                            <Button variant="subtle" size="xs" onClick={handleResetQuery}>
                                Reset
                            </Button>
                            <Button size="xs" onClick={handleRunQuery}>
                                Run Query
                            </Button>
                        </Flex>
                    </Paper>
                </Box>
            )}
        </Box>
    );
};

interface LogHistogramProps {
    data: ServerLogList | undefined;
    startEndDates: IStartEndDate;
    setStartEndDates: (startEndDates: IStartEndDate) => void;
    inContextMode: boolean;
    clearContext: ()=>void;
}

export const LogHistogram = ({ data, startEndDates, setStartEndDates, inContextMode, clearContext }: LogHistogramProps) => {
    const [zoomLevel, setZoomLevel] = useState(1);
    const serverlogsService = useInjection<IServerLogService>('IServerLogService');
    const chartData = data ? serverlogsService.prepareHistogramData(data.items, startEndDates) : undefined;

    const STEP_SIZE = 24 * 60 * 60 * 1000; // 1 day

    const handleLeftExpand = () => {
        const { startDate } = startEndDates;
        const newStartDate = new Date(startDate.getTime() - STEP_SIZE);
        setStartEndDates({ ...startEndDates, startDate: newStartDate });
    };

    const handleRightExpand = () => {
        const { endDate } = startEndDates;
        const now = new Date();
        const newEndDate = new Date(endDate.getTime() + STEP_SIZE);

        if (newEndDate > now) {
            setStartEndDates({ ...startEndDates, endDate: now });
        } else {
            setStartEndDates({ ...startEndDates, endDate: newEndDate });
        }
    };

    const handleSliderChange = (values: [number, number]) => {
        setStartEndDates({ startDate: new Date(values[0]), endDate: new Date(values[1]) });
    };

    return (
        <Box mt="xl" mb={30}>
            <Flex align="center" w="90%" mx="auto" gap="sm">
                <ActionIcon onClick={handleLeftExpand}>
                    <IconChevronLeft size={25} />
                </ActionIcon>
                <Box w="100%">
                    <Flex justify="space-between">
                        <Flex>
                            <TimePicker startEndDates={startEndDates} setStartEndDates={setStartEndDates}>
                                {({ timePickerBtnLabel, togglePopover }) => (
                                    <Button variant="light" size="xs" px="xs" radius="md" onClick={togglePopover}>
                                        <Box component="span" mr={2}>
                                            <IconClockHour4 size={15} />
                                        </Box>
                                        <Text tt="capitalize">{timePickerBtnLabel}</Text>
                                    </Button>
                                )}
                            </TimePicker>
                            {
                                !!inContextMode && (
                                    <Button 
                                    onClick={e=>{
                                        e.preventDefault();
                                        clearContext();
                                    }}
                                    style={{
                                        marginLeft: '5px'
                                    }} variant='light' size='xs' radius='md'>Clear Context</Button>
                                )
                            }
                         
                        </Flex>

                        <ZoomControls
                            zoomLevel={zoomLevel}
                            setZoomLevel={setZoomLevel}
                            startEndDates={startEndDates}
                            setStartEndDates={setStartEndDates}
                        />
                    </Flex>

                    {chartData ? (
                        <Chart
                            chartType="ColumnChart"
                            loader={<Skeleton m="auto" h={130} bottom={30} />}
                            data={chartData}
                            options={{
                                isStacked: true,
                                legend: 'none',
                                chartArea: { width: '100%', top: '5%', bottom: '20%' },
                                vAxis: {
                                    textStyle: {
                                        fontSize: 12,
                                    },
                                },
                                hAxis: {
                                    viewWindow: {
                                        min: startEndDates.startDate,
                                        max: startEndDates.endDate,
                                    },
                                    textPosition: 'out',
                                    textStyle: {
                                        fontSize: 10,
                                    },
                                },
                                colors: [
                                    '#4CAF50', // Default (Green)
                                    '#2196F3', // Info (Blue)
                                    '#3F51B5', // Notice (Indigo)
                                    '#FF9800', // Warning (Orange)
                                    '#F44336', // Error (Red)
                                    '#9C27B0', // Critical (Purple)
                                    '#E91E63', // Alert (Pink)
                                    '#795548', // Emergency (Brown)
                                    '#9E9E9E', // Debug (Grey)
                                ],
                            }}
                            width="100%"
                            height="150px"
                        />
                    ) : (
                        <Skeleton m="auto" h={130} bottom={30} />
                    )}

                    <DateRangeSlider
                        minDate={startEndDates.startDate}
                        maxDate={startEndDates.endDate}
                        onSliderChange={handleSliderChange}
                    />
                </Box>
                <ActionIcon onClick={handleRightExpand}>
                    <IconChevronRight size={25} />
                </ActionIcon>
            </Flex>
        </Box>
    );
};

import ISecurityProvider from "../../ISecurityProvider";

export default class SecurityProviderAnd implements ISecurityProvider {

    private securityProviders: ISecurityProvider[];

    constructor(...securityProviders: ISecurityProvider[]) {
        this.securityProviders = securityProviders;
    }

    valid(): boolean {
        for (let i = 0; i < this.securityProviders.length; i++) {
            if (!this.securityProviders[i].valid())
                return false;
        }
        return true;
    }

}
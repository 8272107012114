import { injectable } from 'inversify';
import { showNotification } from '@mantine/notifications';

import IUIService from '../IUIService';
import store from '../../redux/store';
import { setLoading, setLoadingWithProgress, setProgress, setProgressMessage } from '../../redux/slices/loadingSlice';
import { hideConfirm, showConfirm } from '../../redux/slices/confirmDialogSlice';
import { hideNewVersion, showNewVersion } from '../../redux/slices/newVersionDialogSlice';

@injectable()
export default class UIService implements IUIService {
    showProgressLoading = (progress: number, message: string | null) => {
        if (Number.isNaN(progress)) progress = 0;
        store.dispatch(setLoadingWithProgress(true));
        store.dispatch(setProgress(progress));
        if (message === null) message = 'Loading!';
        setProgressMessage(message);
    };
    showLoading = () => {
        store.dispatch(setLoading(true));
    };
    hideLoading = () => {
        store.dispatch(setLoading(false));
    };

    showConfirmationDialog = (prompt: string, negativeButton?: string, positiveButton?: string) => {
        store.dispatch(
            showConfirm({
                prompt: prompt,
                positiveButton: positiveButton,
                negativeButton: negativeButton,
            })
        );

        return new Promise<boolean>((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                resolve(store.getState().confirmDialog.response!);
                unsubscribe();
                store.dispatch(hideConfirm());
            });
        });
    };

    showNewVersionDialog = (header: string, tooltip: string, createButtonText?: string) => {
        store.dispatch(
            showNewVersion({
                header: header,
                tooltip: tooltip,
                createVersionButton: createButtonText,
            })
        );

        return new Promise<string | null>((resolve, reject) => {
            const unsubscribe = store.subscribe(() => {
                resolve(store.getState().newVersionDialog.response);
                unsubscribe();
                store.dispatch(hideNewVersion());
            });
        });
    };

    showSuccessNotification = (content: string) => {
        showNotification({
            autoClose: 3500,
            color: 'green',
            title: 'Success!',
            message: content,
        });
    };
    showErrorNotification = (content: string) => {
        showNotification({
            autoClose: 3500,
            color: 'red',
            title: 'Error!',
            message: content,
        });
    };
    showWarningNotification = (content: string) => {
        showNotification({
            autoClose: 3500,
            color: 'yellow',
            title: 'Warning!',
            message: content,
        });
    };
}

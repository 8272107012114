import BundleConnection from '@/models/devops/build/ui/BundleConnection';
import { ActionIcon, Flex, Input } from '@mantine/core';
import { MdDeleteOutline } from 'react-icons/md';

export default function BundleConnectionUI({
    connection,
    remove,
}: {
    connection: BundleConnection;
    remove: (id: string) => void;
}) {
    return (
        <Flex m={'md'}
         justify={'center'}
         align={'center'}>
            <Input type="text" readOnly value={connection.to.name} />
            <ActionIcon h='100%' onClick={()=>{
                remove(connection.id)
            }}>
                <MdDeleteOutline size={24} color="red"/>
            </ActionIcon>
        </Flex>
    );
}

import { inject, injectable } from 'inversify';

import { ServiceManagementList } from '@/models/serviceManagement/ServiceManagementList';
import { ServiceManagementModel } from '@/models/serviceManagement/ServiceManagementModel';
import type { IServiceManagementRESTClient } from '@/rest-clients/IServiceManagementRESTClient';
import type IDateTimeService from '../IDateTimeService';

import { IServiceManagementService } from '../IServiceManagementService';

@injectable()
export default class ServiceManagementService implements IServiceManagementService {
    @inject('IDateTimeService')
    private dtService!: IDateTimeService;
    @inject('IServiceManagementRESTClient')
    private readonly serviceManagementRESTClient!: IServiceManagementRESTClient;

    private processBEService(service: ServiceManagementModel): ServiceManagementModel {
        service.created_date = this.dtService.utcToLocalDateString(service.created_date, 'MM/DD/YYYY HH:mm:ss');
        return service;
    }

    async getServices(page: number): Promise<ServiceManagementList | null> {
        return await this.serviceManagementRESTClient.getServices(page);
    }

    async getService(email: string): Promise<ServiceManagementModel | null> {
        const service = await this.serviceManagementRESTClient.getService(email);
        return service ? this.processBEService(service) : null;
    }

    async deleteService(service: ServiceManagementModel): Promise<boolean> {
        return await this.serviceManagementRESTClient.deleteService(service);
    }

    async searchService(search: string, page: number): Promise<ServiceManagementList | null> {
        return await this.serviceManagementRESTClient.searchService(search, page);
    }

    async updateServiceScope(service: ServiceManagementModel): Promise<boolean> {
        return await this.serviceManagementRESTClient.updateServiceScope(service);
    }
    async createService(name: string, password: string): Promise<boolean> {
        return await this.serviceManagementRESTClient.createService(name, password);
    }
}

import SecopsReportList from "../../models/secops/SecopsReportList";
import ISecOpsRESTClient from "../ISecOpsRESTClient";
import { RESTClient } from "./RESTClient";
import type { IAuthService } from "@/services/IAuthService";
import { inject } from "inversify";
import SecopsReport from "../../models/secops/SecopsReport";
import axios from "axios";
import Nullable from "@/dataTypes/Nullable";

export default class SecOpsRESTClient extends RESTClient implements ISecOpsRESTClient{
   
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    constructor() {
        super();
        this.setBaseURL('https://wod-secops-uux56memxa-uc.a.run.app/api/v1/');
    }

    async generateReport(): Promise<Nullable<number>> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const secopsToken = await this.getToken(token);

        const path = this.getFullURL(`analysis`);

        try {
            const response = await axios.post(path, null, this.getHeader(secopsToken));

            return response.data.createdId;

        } catch {
            return null;
        }
    }

    async getReportsList(page: number, perPage: number): Promise<Nullable<SecopsReportList>> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const secopsToken = await this.getToken(token);

        const path = this.getFullURL(`reports?page=${page}&perPage=${perPage}`);

        try {
            const response = await axios.get(path, this.getHeader(secopsToken));

            const responseData = response.data as SecopsReportList;

            const responseParsed = new SecopsReportList();

            responseParsed.items = responseData.items;
            if(!responseParsed.items){
                responseParsed.items = [];
            }
            responseParsed.max_results = responseData.max_results;

            console.log(responseParsed);

            return responseParsed;
        } catch {
            return null;
        }
    }

    async getReport(id: number): Promise<Nullable<SecopsReport>> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const secopsToken = await this.getToken(token);


        const path = this.getFullURL(`reports/${id}`);

        try {
            const response = await axios.get(path, this.getHeader(secopsToken));

            const responseData = response.data as SecopsReport;

            return responseData;
        } catch {
            return  null;
        }
    }


    delay(ms : number) : Promise<object> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

}
import Pledge from '@/models/treasury/pledges/Pledge';
import ITreasuryService from '@/services/ITreasuryService';
import { ActionIcon, Box, Button, Flex, LoadingOverlay, Stack, Table, Tooltip } from '@mantine/core';
import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { IoTrash } from 'react-icons/io5';
import { MdOutlineDelete, MdOutlineEdit } from 'react-icons/md';

export default function PledgeSection({
    selectedEmail,
    pledges,
    deletePledge,
    editPledge,
}: {
    selectedEmail: string;
    pledges: Pledge[];
    deletePledge: (id: number) => void;
    editPledge: (pledge: Pledge) => void;
}) {
    useEffect(() => {}, [pledges]);

    return (
        <Box>
            <Stack>
                <h3>Pledges</h3>
                <Table highlightOnHover sx={{ width: '96%' }}>
                    <thead>
                        <tr>
                            <th>Reward</th>
                            <th>Status</th>
                            <th>Available</th>
                            <th>Claimed</th>
                            <th>BackerKit Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pledges.map((pledge) => (
                            <PledgeItem key={pledge.pledge_id} editPledge={editPledge} pledge={pledge} deletePledge={deletePledge} />
                        ))}
                    </tbody>
                </Table>
            </Stack>
        </Box>
    );
}

export function PledgeItem({
    pledge,
    deletePledge,
    editPledge,
}: {
    editPledge: (pledge: Pledge) => void;
    pledge: Pledge;
    deletePledge: (id: number) => void;
}) {
    const getStatusText = (status: number) => {
        switch (status) {
            case 1:
                return 'Development';
            case 2:
                return 'Available';
            case 3:
                return 'Fulfilled';
        }
    };

    return (
        <tr style={{ maxHeight: '40px', height: '40px' }}>
            <td width={'30%'} height={'40px'}>
                <TextWithMaxLength maxLength={30}>{pledge.reward?.reward_name ?? ''}</TextWithMaxLength>
            </td>
            <td width={'80px'} height={'40px'}>
                {getStatusText(pledge.reward?.reward_status ?? 1)}
            </td>
            <td height={'40px'}>{pledge.available_quantity}</td>
            <td height={'40px'}>{pledge.claimed_quantity}</td>
            <td height={'40px'}>
                <TextWithMaxLength>{pledge.backerkit_email}</TextWithMaxLength>
            </td>
            <td height={'40px'}>
                <Flex dir="row" columnGap={'xs'}>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            if (!!editPledge) editPledge(pledge);
                        }}
                        color="orange"
                    >
                        <MdOutlineEdit />
                    </Button>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            if (!!deletePledge) deletePledge(pledge.pledge_id);
                        }}
                        color="red"
                    >
                        <MdOutlineDelete />
                    </Button>
                </Flex>
            </td>
        </tr>
    );
}

export function TextWithMaxLength({ children, maxLength }: { children: string; maxLength?: number }) {
    const unwrappedMaxLength = !!maxLength ? maxLength : 30;

    if (children.length > unwrappedMaxLength) {
        return (
            <Tooltip label={children}>
                <p>{children.substring(0, unwrappedMaxLength)}...</p>
            </Tooltip>
        );
    }

    return <p>{children}</p>;
}

import { Overlay } from '../overlay/overlay.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import './loading-container.styles.scss';

export default function LoadingOverlay(props: {}) {
    const loading = useSelector((state: RootState) => state.loading.loading);
    const progressBar = useSelector((state:RootState)=>state.loading.isProgress);
    const progress = useSelector((state:RootState)=>state.loading.progress);
    const progressMessage = useSelector((state:RootState)=>state.loading.progressBarMessage);
    

   
    const progressIntPart = Math.floor(progress);
    const progressDecimalPart = progress - progressIntPart;

    let progressDecimalPartString = progressDecimalPart.toString();
    progressDecimalPartString = progressDecimalPartString.substring(0,1);

    const defaultLoading = () => {
        return (
            <div id="inner">
                <div id="loading-container">
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>

                <h1>Loading</h1>
            </div>
        );
    };

    const getProgress = () => {
        return 440 - (440 * (progress)) / 100;
    };

    const loadingProgress = () => {
        return (
            <div className="box">
                <div className="percent">
                    <svg>
                        <circle cx="70" cy="70" r="70"></circle>
                        <circle
                            cx="70"
                            cy="70"
                            r="70"
                            style={{
                                strokeDashoffset: getProgress(),
                                transition: 'all 0.3s ease-in-out'
                            }}
                        ></circle>
                    </svg>
                    <div className="num">
                        <p>
                            {progressIntPart}<span>.{progressDecimalPartString}%</span>
                        </p>
                    </div>
                </div>
                <h2 className="text">{progressMessage}</h2>
            </div>
        );
    };

    return (
        <Overlay open={loading}>
            <div id="wrapper">
                {progressBar ? loadingProgress() : defaultLoading()}
            </div>
        </Overlay>
    );
}

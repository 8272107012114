import { Pagination } from '@mantine/core';

interface ListViewPaginationProps {
    onChangePage(page: number): void;
    maxCount: number | undefined;
    currentPage: number;
    pageSize?: number;
}

export default function ListViewPagination(props: ListViewPaginationProps) {
    const { maxCount, currentPage, pageSize, onChangePage } = props;
    if (!maxCount) return null;
    const totalPages = Math.ceil(maxCount / (pageSize || 40));

    if (totalPages <= 1) {
        return null;
    }

    return (
        <>
            <Pagination
                mx="auto"
                mt="lg"
                w="max-content"
                size="xs"
                total={totalPages}
                value={currentPage}
                onChange={onChangePage}
            />
        </>
    );
}

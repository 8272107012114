import DefaultListOptionView from '../../../components/general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../../components/general/list-view/list-view.component';
import Nullable from '@/dataTypes/Nullable';
import { ActionIcon, Affix, Box, Button, Container, Flex, Tooltip } from '@mantine/core';
import SecopsReportList from '@/models/secops/SecopsReportList';
import { MdOutlineCreate } from 'react-icons/md';

interface SecopsReportListProps {
    generateClick: () => void;
    selectedId: Nullable<number>;
    reportList: SecopsReportList | null;
    page: number;
    onPageChange: (page: number) => void;
    selectionChange: (id: number) => void;
}

export default function SecopsList({
    generateClick,
    selectedId,
    reportList,
    page,
    onPageChange,
    selectionChange,
}: SecopsReportListProps) {
    return (
        <Container px={0} pt="md">
            <ListViewComponent
                elements={reportList}
                page={page}
                selectedValue={selectedId}
                pageChanged={onPageChange}
                selectionChanged={selectionChange}
                listElement={(x, selectionChange, inSelect) => {
                    const date = new Date(x.report_date);
                    return (
                        <DefaultListOptionView
                            key={x.id}
                            id={x.id}
                            value={date.toLocaleString()}
                            selectionChange={selectionChange}
                            selected={selectedId === x.id}
                            inSelectContainer={inSelect}
                        />
                    );
                }}
            >
                {/* <ListViewComponent.Header>
                    <Flex style={{width:'100%', justifyContent: 'stretch'}}>
                        <Button
                            style={{width:'100%'}}
                            onClick={(e) => {
                                e.preventDefault();
                                generateClick();
                            }}
                        >
                            Generate Report
                        </Button>
                    </Flex>
                </ListViewComponent.Header> */}
            </ListViewComponent>
            <GenerateReport onGenerateReport={()=>generateClick()}/>
        </Container>
    );
}


export const GenerateReport = ({ onGenerateReport }: {onGenerateReport :  () => void }) => {


    return (
        <>
            {/* <Modal
                opened={opened}
                centered
                closeOnClickOutside={false}
                onClose={() => setOpened(false)}
                title="Create new Service"
                styles={{
                    title: {
                        fontSize: '1.2rem',
                        fontWeight: 600,
                    },
                }}
            >
                <CreateServiceForm onCreateService={onCreateService} closeModel={closeModel} />
            </Modal> */}

            <Affix zIndex={1} position={{ bottom: 20, right: 20 }}>
                <Tooltip label="Generate Report" withArrow>
                    <ActionIcon size={50} radius="xl" variant="filled" color="blue" onClick={() => onGenerateReport()}>
                        <MdOutlineCreate size={25} />
                    </ActionIcon>
                </Tooltip>
            </Affix>
        </>
    );
};
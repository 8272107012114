import OrderHistoryWithName from '@/models/treasury/orderHistory/OrderHistoryWithName';
import { Stack, Table } from '@mantine/core';

export interface OrderHistoryFormProps {
    orders: OrderHistoryWithName[];
}

export default function OrderHistoryForm({ orders }: OrderHistoryFormProps) {
    return (
        <Stack>
            <h3>Order History</h3>
            <Table highlightOnHover striped>
                <thead>
                    <tr>
                        <th>Order Id</th>
                        <th>Pledge Id</th>
                        <th>Item Name</th>
                        <th>Quantity</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => (
                        <OrderHistoryItem key={order.order_history.order_history_id} order={order} />
                    ))}
                </tbody>
            </Table>
        </Stack>
    );
}

function OrderHistoryItem({ order }: { order: OrderHistoryWithName }) {
    return (
        <tr>
            <td><a href={`https://admin.shopify.com/store/kingdomdeath/orders/${order.order_history.order_id}`}>#{order.order_history.order_id}</a></td>
            <td>{order.order_history.pledge_id}</td>
            <td>{order.variant_name}</td>
            <td>{order.order_history.quantity}</td>
            <td>{new Date(order.order_history.date).toLocaleString()}</td>
        </tr>
    );
}

import dayjs from 'dayjs';
import { ActionIcon, Box, Button, Flex, Menu, Skeleton, Title } from '@mantine/core';
import { IconClockHour4, IconDotsVertical, IconDownload } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useInjection } from 'inversify-react';
import { IServerActivityService } from '@/services/IServerActivityService';
import { TimePicker } from '../../../../general/time-picker/time-picker.component';
import { MonitoringChart } from './chart.component';
import { GoogleChartWrapper } from 'react-google-charts';
import { DashboardTile } from '@/models/monitoring/server-activity/MonitoringDashboardList';
import { ZoomControls } from '../../../../general/zoom-controls/zoom-controls.component';

interface IActionMenu {
    onSave: () => void;
    onPNGExport: () => void;
}

const ActionMenu = ({ onSave, onPNGExport }: IActionMenu) => {
    return (
        <Menu shadow="md" width={200}>
            <Menu.Target>
                <ActionIcon>
                    <IconDotsVertical />
                </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Label>Options</Menu.Label>
                <Menu.Item icon={<IconDownload size={15} />} onClick={onSave}>
                    Download CSV
                </Menu.Item>
                <Menu.Item icon={<IconDownload size={15} />} onClick={onPNGExport}>
                    Download PNG
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};

interface MonitoringChartContainerProps {
    data: DashboardTile;
    refreshOn: boolean;
}

export const MonitoringChartContainer = ({ data, refreshOn }: MonitoringChartContainerProps) => {
    const [zoomLevel, setZoomLevel] = useState(1);
    const [startEndDates, setStartEndDates] = useState({
        startDate: dayjs().subtract(1, 'day').toDate(),
        endDate: dayjs().toDate(),
    });

    const [filter, setFilter] = useState('');
    const [chartWrapper, setChartWrapper] = useState<GoogleChartWrapper | null>(null);

    const serverActivityService = useInjection<IServerActivityService>('IServerActivityService');

    const { isLoading, data: chartData } = useQuery({
        queryKey: ['line-chart', filter],
        queryFn: () => serverActivityService.getGoogleCloudDashboardTimeSeries(filter),
        select: (data) => serverActivityService.transformTimeSeries(data),
        enabled: refreshOn && filter !== '',
        keepPreviousData: true,
    });

    useEffect(() => {
        const { filter, alignment } = data;
        const newFilter = serverActivityService.getSearchFilter(
            startEndDates.startDate,
            startEndDates.endDate,
            alignment,
            filter
        );
        setFilter(newFilter);
    }, [startEndDates]);

    const onSaveAsCSV = () => {
        if (!chartData) return;
        serverActivityService.exportAsCSV(chartData, `${data.title}`);
    };

    const onExportAsPNG = async () => {
        if (chartWrapper) {
            const imageURL = chartWrapper.getChart().getImageURI() as any;
            const chartImage = new Image();
            chartImage.src = imageURL;
            chartImage.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d')!;

                const titlePadding = 20;
                const titleText = data.title;
                const titleFontSize = 15;
                const titleMarginLeft = 10;
                canvas.width = chartImage.width;
                canvas.height = chartImage.height + titleFontSize + titlePadding;

                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                ctx.font = `${titleFontSize}px Arial`;
                ctx.fillStyle = 'black';
                ctx.textAlign = 'left';
                ctx.fillText(titleText, titleMarginLeft, titleFontSize + 10);

                ctx.drawImage(chartImage, 0, titleFontSize + titlePadding);

                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download = `${titleText}.png`;
                link.click();
            };
        }
    };

    return (
        <>
            <Box
                sx={(theme) => ({
                    border: '1px solid',
                    borderColor: theme.colors.gray[3],
                    borderRadius: theme.radius.sm,
                })}
            >
                <Flex py="sm" px="md" align="center" justify="space-between" w="100%">
                    <Flex align="center" gap="sm">
                        <Title order={5}>{data.title}</Title>
                    </Flex>
                    <Flex align="center" gap="xs">
                        <Flex>
                            <ZoomControls
                                zoomLevel={zoomLevel}
                                setZoomLevel={setZoomLevel}
                                startEndDates={startEndDates}
                                setStartEndDates={setStartEndDates}
                            />
                            <TimePicker startEndDates={startEndDates} setStartEndDates={setStartEndDates}>
                                {({ timePickerBtnLabel: _, togglePopover }) => (
                                    <ActionIcon variant="subtle" color="gray" onClick={togglePopover}>
                                        <IconClockHour4 size={20} />
                                    </ActionIcon>
                                )}
                            </TimePicker>
                        </Flex>
                        <ActionMenu onPNGExport={onExportAsPNG} onSave={onSaveAsCSV} />
                    </Flex>
                </Flex>
                <Box mih={320} w="100%">
                    {isLoading ? (
                        <Skeleton m="auto" h="95%" w="95%" />
                    ) : (
                        <MonitoringChart
                            dateRange={[startEndDates.startDate, startEndDates.endDate]}
                            setChartWrapper={setChartWrapper}
                            metaData={data}
                            chartData={chartData}
                        />
                    )}
                </Box>
            </Box>
        </>
    );
};

import Nullable from '@/dataTypes/Nullable';
import RewardExtraInfo from '@/models/treasury/rewards/RewardExtraInfo';
import ITreasuryService from '@/services/ITreasuryService';
import IUIService from '@/services/IUIService';
import { Button, FileInput, Flex, Modal, Image, Stack, ActionIcon } from '@mantine/core';
import { useInjection } from 'inversify-react';
import { Img } from 'react-image';
import { useState } from 'react';
import { IoTrash } from 'react-icons/io5';

export interface RewardImageUploadProps {
    rewardId: number;
    rewardExtraInfo: Nullable<RewardExtraInfo>;
    onRefresh: (imageSrc: string) => void;
}

export default function RewardImageUpload({ rewardId, rewardExtraInfo, onRefresh }: RewardImageUploadProps) {
    const [imageUploadActive, setImageUploadActive] = useState(false);
    const uiService = useInjection<IUIService>('IUIService');
    const treasuryService = useInjection<ITreasuryService>('ITreasuryService');
    return (
        <>
            <Flex direction="column">
                <Flex dir="row" columnGap={'xs'} justify={'flex-start'} align="end">
                    <Img
                        src={[rewardExtraInfo?.imageURL ?? '', '/images/placeholder KD.png']}
                        style={{ width: '80px', height: '80px' }}
                    />
                    <Flex direction="column" style={{ height: '80px' }} columnGap={'sm'} justify={'space-around'}>
                        <ActionIcon
                            color="red"
                            variant="filled"
                            onClick={async () => {
                                uiService.showLoading();
                                const response = await treasuryService.deleteRewardImage(rewardId);
                                uiService.hideLoading();
                                if (!!response) {
                                    uiService.showSuccessNotification('Successfuly deleted the image!');
                                    onRefresh('');
                                } else {
                                    uiService.showErrorNotification('Failed to delete the image!');
                                }
                            }}
                            w={'30px'}
                            h={'30px'}
                        >
                            <IoTrash />
                        </ActionIcon>
                        <Button
                            onClick={() => {
                                setImageUploadActive(true);
                            }}
                        >
                            Upload image...
                        </Button>
                    </Flex>
                </Flex>
            </Flex>

            <ImageUploadPopup
                visible={imageUploadActive}
                onClose={() => {
                    setImageUploadActive(false);
                }}
                onRefresh={onRefresh}
                id={rewardId}
            />
        </>
    );
}

interface ImageUploadPopupProps {
    visible: boolean;
    onClose: () => void;
    onRefresh: (imageSrc: string) => void;
    id: number;
}

function ImageUploadPopup({ id, visible, onClose, onRefresh }: ImageUploadPopupProps) {
    const uiService = useInjection<IUIService>('IUIService');
    const treasuryService = useInjection<ITreasuryService>('ITreasuryService');

    const [file, setFile] = useState<Nullable<File>>(null);

    return (
        <Modal title="Upload Image" onClose={onClose} opened={visible}>
            <Flex direction="column" rowGap={'sm'}>
                <FileInput
                    value={file}
                    onChange={(e) => {
                        setFile(e);
                    }}
                    label="Image"
                    accept="image/*"
                />
                <Button
                    disabled={file === null}
                    onClick={async () => {
                        if (file === null) {
                            return;
                        }
                        uiService.showLoading();
                        const response = await treasuryService.uploadRewardImage(id, file);
                        uiService.hideLoading();

                        if (!response) {
                            uiService.showErrorNotification('Failed upload an image');
                        } else {
                            const lastUrl = (response as any).url;
                            onRefresh(lastUrl);
                            onClose();
                        }
                    }}
                >
                    Upload
                </Button>
            </Flex>
        </Modal>
    );
}

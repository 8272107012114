import { Text, TextInput, Container, Stack, Flex, Button, SimpleGrid, Select, ActionIcon } from '@mantine/core';

import Nullable from '@/dataTypes/Nullable';
import { MotdTemplate } from '@/models/devops/MOTD/MotdTemplate';
import { MotdTemplateVariable } from '@/models/devops/MOTD/MotdTemplate';
import { IconTrash } from '@tabler/icons-react';

interface MOTDTemplateFormProps {
    selectedMotdId: number;
    template: Nullable<MotdTemplate>;
    updateMotdTemplate: (template: MotdTemplate) => void;
    updateEnabled: boolean;
    saveMotdTemplate: () => void;
    deleteMotdTemplate: () => void;
}

export const MOTDTemplateForm = ({
    template,
    deleteMotdTemplate,
    updateMotdTemplate,
    updateEnabled,
    saveMotdTemplate,
}: MOTDTemplateFormProps) => {
    if (template === null) return null;

    const updateMotdTemplatePartial = (updatedProperties: Partial<MotdTemplate>) => {
        updateMotdTemplate({ ...template, ...updatedProperties });
    };

    const createNewVariable = () => {
        const newVariable: MotdTemplateVariable = {
            id: -template.variables.length,
            motd_template_id: template.id,
            variable_type: 'string',
            variable_name: '',
        };

        updateMotdTemplate({ ...template, variables: [...template.variables, newVariable] });
    };

    const deleteVariable = (variableId: number) => {
        const newVariables = template.variables.filter((variable) => variable.id !== variableId);

        updateMotdTemplate({ ...template, variables: newVariables });
    };

    const updateVariable = (updatedVariable: MotdTemplateVariable) => {
        const newVariables = template.variables.map((variable) => {
            if (variable.id === updatedVariable.id) {
                return updatedVariable;
            }

            return variable;
        });

        updateMotdTemplate({ ...template, variables: newVariables });
    };

    return (
        <Container fluid py="xs" pos="relative">
            <Stack>
                <TextInput
                    onChange={(e) => updateMotdTemplatePartial({ name: e.target.value })}
                    value={template.name}
                    label="Template Name"
                />

                <TextInput
                    onChange={(e) => updateMotdTemplatePartial({ pattern: e.target.value })}
                    value={template.pattern}
                    label="Pattern"
                />

                <Flex direction="column" gap={2}>
                    <Text fw={500}>Variable List</Text>
                    <Flex direction="column" gap="sm" maw={450}>
                        {template.variables.map((variable) => (
                            <Flex align="center" gap="md">
                                <SimpleGrid cols={2} spacing="sm">
                                    <TextInput
                                        size="sm"
                                        value={variable.variable_name}
                                        onChange={(e) => updateVariable({ ...variable, variable_name: e.target.value })}
                                    />
                                    <Select
                                        size="sm"
                                        styles={{
                                            input: {
                                                height: 30,
                                            },
                                        }}
                                        value={variable.variable_type}
                                        onChange={(value) =>
                                            value && updateVariable({ ...variable, variable_type: value })
                                        }
                                        data={[
                                            { label: 'String', value: 'string' },
                                            { label: 'Number', value: 'number' },
                                            { label: 'DateTime', value: 'datetime' },
                                            { label: 'Date', value: 'date' },
                                            { label: 'Time', value: 'time' },
                                        ]}
                                    />
                                </SimpleGrid>
                                <ActionIcon
                                    w={30}
                                    h={30}
                                    size="xs"
                                    variant="light"
                                    color="red"
                                    onClick={() => deleteVariable(variable.id)}
                                >
                                    <IconTrash size={20} />
                                </ActionIcon>
                            </Flex>
                        ))}
                        <Button size="md" variant="outline" color="blue" onClick={createNewVariable}>
                            Create new Variable
                        </Button>
                    </Flex>
                </Flex>

                <Flex w="max-content" gap="sm" ml="auto" mt="xl">
                    {template.id !== -1 && (
                        <Button size="md" radius="md" disabled={!updateEnabled} onClick={saveMotdTemplate}>
                            Update Template
                        </Button>
                    )}

                    {template.id !== -1 && (
                        <Button size="md" radius="md" color="red" variant="outline" onClick={deleteMotdTemplate}>
                            Remove Template
                        </Button>
                    )}
                </Flex>
            </Stack>
        </Container>
    );
};

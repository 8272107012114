import { useState } from 'react';
import { Button, TextInput } from '@mantine/core';
import { useSelector } from 'react-redux';
import RepositoryModel from '../../../models/devops/build/RepositoryModel';
import { Overlay } from '../../overlay/overlay.component';
import RepositorySelection from '../devops-repository-view/repository-selection-views/repository-selection.component';
import styles from './repo-creation-wizard.module.scss';
import { RootState } from '../../../redux/store';
import ICreateProductWizardService from '../../../services/ICreateProductWizardService';
import { useInjection } from 'inversify-react';
import UnityProjectSelectionComponent from '../unity-project-selection/unity-project-selection.component';
import IUIService from '@/services/IUIService';
import IBuildRepositoryService from '@/services/IBuildRepositoryService';

export default function RepoCreationWizard() {
    const [slide, setSlide] = useState(0);
    const createProductWizardService = useInjection<ICreateProductWizardService>('ICreateProductWizardService');
    const buildService = useInjection<IBuildRepositoryService>('IBuildRepositoryService');
    const uiService = useInjection<IUIService>('IUIService');
    const [repository, setRepository] = useState<RepositoryModel>({
        build_url: '',
        product: '',
        branch: '',
        token: '',
        repository_type: 'github',
        base_url: 'https://github.com',
    });

    const showWizard = useSelector((state: RootState) => state.wizard.showWizard);

    const enterNameSlide = (function () {
        return (
            <TextInput
                label="Name"
                value={repository.product}
                onChange={(x) => {
                    x.preventDefault();
                    repository.product = x.target.value;
                    setRepository({ ...repository });
                }}
            />
        );
    })();

    const repoProviderSlide = (function () {
        return <RepositorySelection repository={repository} updateModel={(r) => setRepository({ ...r })} minimize/>;
    })();

    const unityBuildSelectionSlide = (function () {
        return <UnityProjectSelectionComponent repository={repository} updateModel={(r) => setRepository({ ...r })} />;
    })();

    const enterNameSlideRequirement = (function () {
        return repository.product !== '';
    })();

    const repoProviderSlideRequirement = (function () {
        return repository.base_url !== '' && repository.branch !== '' && repository.token !== '';
    })();

    const buildSelectionSlideRequirement = (function () {
        return repository.build_url !== '';
    })();

    const createRepository = async () => {
        await uiService.showLoading();
        const created = await buildService.createRepository(repository);
        await uiService.hideLoading();

        if (created) {
            uiService.showSuccessNotification('New repository created successfuly');
            createProductWizardService.hideWizard();
        } else {
            uiService.showErrorNotification('Failed to create a new repository');
        }
    };

    const slides = [enterNameSlide, repoProviderSlide, unityBuildSelectionSlide];
    const requirements = [enterNameSlideRequirement, repoProviderSlideRequirement, buildSelectionSlideRequirement];

    return (
        <Overlay open={showWizard}>
            <div className={styles.wrapper_creation}>
                <div className={styles.popup}>
                    {slides[slide]}

                    <div className={styles.button_container}>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                if (slide > 0) setSlide(slide - 1);
                                else {
                                    createProductWizardService.hideWizard();
                                }
                            }}
                        >
                            {slide > 0 ? 'Back' : 'Close'}
                        </Button>
                        <Button
                            onClick={async (e) => {
                                e.preventDefault();
                                if (slide < slides.length - 1) setSlide(slide + 1);
                                else {
                                    await createRepository();
                                }
                            }}
                            disabled={!requirements[slide]}
                        >
                            {slide === slides.length - 1 ? 'Create' : 'Next'}
                        </Button>
                    </div>
                </div>
            </div>
        </Overlay>
    );
}

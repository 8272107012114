import { useState } from 'react';
import { TextInput, ActionIcon } from '@mantine/core';
import { FiSearch } from 'react-icons/fi';
interface ISearchBoxProps {
    setSearchQuery: (query: string) => void;
    placeholder?: string;
    disabled? : boolean
}

export const SearchBox = ({ setSearchQuery, placeholder, disabled }: ISearchBoxProps) => {
    const [query, setQuery] = useState('');

    const handleSearch = () => {
        setSearchQuery(query);
    };

    return (
        <TextInput
            mb="sm"
            w="100%"
            disabled = {disabled}
            placeholder={placeholder || 'Search'}
            variant="filled"
            size="sm"
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    handleSearch();
                }
            }}
            rightSection={
                <ActionIcon onClick={handleSearch} color="blue" variant="subtle">
                    <FiSearch />
                </ActionIcon>
            }
        />
    );
};

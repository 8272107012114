import OwnerList from "@/models/gdm/OwnersList";
import DefaultListOptionView from "../../../components/general/list-view-default-option/list-view-default-option.component";
import ListViewComponent from "../../../components/general/list-view/list-view.component";
import { SearchBox } from "../../../components/general/search-box/search-box";
import Nullable from "@/dataTypes/Nullable";
import { Box, Container } from "@mantine/core";

interface CustomerTransactionListProps {
    selectionChange: (id: string) => void;
    selectedId: Nullable<string>;
    customerList: OwnerList | null;
    page: number;
    onPageChange: (page: number) => void;
    searchChange: (query:  string) =>void;
}

export default function DataStoreUserSavesList({ selectionChange, selectedId, customerList, page, onPageChange, searchChange }: CustomerTransactionListProps) {
    return (
        <Container px={0} pt="md">
            <Box px='xs'>
                <SearchBox setSearchQuery={searchChange} />
            </Box>
            <ListViewComponent
                elements={customerList}
                page={page}
                selectedValue={selectedId}
                pageChanged={onPageChange}
                selectionChangedString={selectionChange}
                listElement={(x, selectionChange, inSelect) => {
                    return (
                        <DefaultListOptionView
                            key={x}
                            id={x}
                            value={x.toLowerCase()}
                            selectionChange={selectionChange}
                            selected={selectedId === x}
                            inSelectContainer={inSelect}
                        />
                    )
                }}>
            </ListViewComponent>
        </Container>

    );
}
import { useInjection } from 'inversify-react';
import { useState } from 'react';
import BundleBuild from '../../../models/devops/BundleBuild';
import IDevOpsService from '../../../services/IDevOpsService';
import IUIService from '../../../services/IUIService';
import classes from '../../../utils/classes';
import styles from './bundle-build.module.scss';
import { BuildState } from '../../../models/devops/BuildState';
interface BundleBuildProps {
    bundle: BundleBuild;
    expanded?: boolean;
    refreshArtifacts: () => void;
}

export default function BundleBuildView(props: BundleBuildProps) {
    const devopsService = useInjection<IDevOpsService>('IDevOpsService');
    const uiService = useInjection<IUIService>('IUIService');

    const [expanded, setExpanded] = useState(true);

    const { bundle, refreshArtifacts } = props;

    const getStatus = (state: BuildState) => {
        switch (state) {
            case BuildState.Queued: 
                return [styles.queued,"Queued"];
            case BuildState.Started:
                return [styles.building,"Started"];
            case BuildState.Failure:
                return [styles.failed,"Failure"];
            case BuildState.Success:
                return [styles.building,"Success"];    
                case BuildState.Transferred:
                return [styles.success,"Transferred"];
            default :  
            return [styles.queued,"Queued"];
        }
    };

    const getSize = (size: number, decimal: number): string => {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        if (decimal < 0) throw new Error('Decimal should be bigger than 0');
        if (size < 0) {
            return '-' + getSize(-size, decimal);
        }
        if (size === 0) return '0.00 bytes';
        let magnitude = Math.floor(Math.log(size) / Math.log(1024));
        const rounding = Math.pow(10, decimal);
        size = size / Math.pow(1024, magnitude);
        let rounded = Math.round(size * rounding) / rounding;
        if (rounded >= 1000) {
            magnitude += 1;
            size /= 1024;
        }
        rounded = Math.round(size * rounding) / rounding;
        return `${rounded} ${units[magnitude]}`;
    };


    const [statusClass, status] = getStatus(bundle.state);

    return (
        <div className={styles.card}>
            <div
                className={classes(styles.cardStatus, statusClass)}
                onClick={(e) => {
                    e.preventDefault();
                    setExpanded(!expanded);
                }}
            ></div>
            <div className={classes(styles.cardContent, expanded ? undefined : styles.notExpanded)}>
                <div className={styles.row}>
                    <div className={styles.fieldWithLabel}>
                        <label>Path </label>
                        {bundle.storage_path}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.fieldWithLabel}>
                        <label>Size </label>
                        {bundle.state === BuildState.Transferred ? getSize(bundle.size, 2) : '-'}
                    </div>
                    <div className={classes(styles.fieldWithLabel, styles.last)}>
                        <label>Status </label>
                        {status}
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.fieldWithLabel}>
                        <label>Hash </label>
                        {!!bundle.hash && bundle.hash.length > 0 ? bundle.hash : '-'}
                    </div>
                    <div className={classes(styles.last, styles.actionButtonContainer)}>
                        <input
                            type="button"
                            value="Retransfer"
                            onClick={async (e) => {
                                e.preventDefault();
                                const result = await devopsService.issueRetransfer(bundle.id);
                                if (result) {
                                    await uiService.showSuccessNotification('Retransfer started');
                                    refreshArtifacts();
                                } else await uiService.showErrorNotification('Retransfer failed');
                            }}
                        />
                        <input
                            type="button"
                            value="Rebuild"
                            onClick={async (e) => {
                                e.preventDefault();
                                const result = await devopsService.issueRebuild(bundle.id);
                                if (result) {
                                    await uiService.showSuccessNotification('Rebuild started');
                                    refreshArtifacts();
                                } else await uiService.showErrorNotification('Rebuild failed');
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

import { resolve } from 'inversify-react';
import React from 'react';
import ProductVersionModel from '../../../models/devops/ProductVersionModel';
import type IDevOpsService from '../../../services/IDevOpsService';
import type IUIService from '../../../services/IUIService';
import ApplicationVersionForm from '../application-version-form/application-version-form.component';
import LauncherVersionForm from '../launcher-version-form/launcher-version-form.component';
import VersionList from '@/models/devops/VersionList';

interface DevOpsFormContainerProps {
    category: string;
    id: number;
    refresh: (forceSetFirst: boolean) => void;
    prevVersion?: string;
    allVersions: VersionList | null;
}

interface DevOpsFormContainerState {
    productVersion: ProductVersionModel | null;
    updateEnabled: boolean;
}

export default class DevOpsFormContainer extends React.Component<DevOpsFormContainerProps, DevOpsFormContainerState> {
    @resolve('IDevOpsService')
    private readonly devOpsService!: IDevOpsService;
    @resolve('IUIService')
    private readonly uiService!: IUIService;
    private timeOut?: NodeJS.Timeout;

    componentDidUpdate(prevProps: DevOpsFormContainerProps, prevState: DevOpsFormContainerState) {
        if (prevProps.id !== this.props.id || prevProps.category !== this.props.category) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
        this.timeOut = setInterval(() => this.refreshArtifacts(), 5000);
    }

    componentWillUnmount(): void {
        clearInterval(this.timeOut);
    }

    constructor(props: DevOpsFormContainerProps) {
        super(props);
        this.state = {
            productVersion: null,
            updateEnabled: false,
        };
    }

    getData = async () => {
        const { id } = this.props;
        if (id === -1) return;
        this.uiService.showLoading();
        const response = await this.devOpsService.getProductVersionInfo(id);
        this.setState({
            ...this.state,
            productVersion: response,
            updateEnabled: false,
        });
        this.uiService.hideLoading();
    };

    updateProductVersion = async () => {
        if (this.state.productVersion === null) {
            return;
        }
        this.uiService.showLoading();
        const success = await this.devOpsService.updateProductVersion(this.state.productVersion);
        this.uiService.hideLoading();
        if (success) {
            this.uiService.showSuccessNotification('Updated product info!');
            this.props.refresh(false);
        } else {
            this.uiService.showErrorNotification('Failed to update the product info!');
        }
    };

    deleteProductVersion = async () => {
        const prompt = await this.uiService.showConfirmationDialog('Delete this version?', 'No', 'Yes');
        if (!prompt) return;
        this.uiService.showLoading();
        const success = await this.devOpsService.deleteProductVersion(this.props.id);
        this.uiService.hideLoading();

        if (success) {
            this.uiService.showSuccessNotification('Successfuly deleted the product version');
            this.props.refresh(true);
        } else {
            this.uiService.showErrorNotification('Failed to delete the product version');
        }
    };

    async refreshArtifacts() {
        if(!this.props)
            return;
        const { id } = this.props;
        const { productVersion } = this.state;
        if (id === -1 || !productVersion) return;
        const response = await this.devOpsService.getProductVersionInfo(id);
        if (!response) return;
        productVersion.artifacts = response.artifacts;
        this.setState({
            ...this.state,
            productVersion,
        });
    }

    render() {
        const { category, id } = this.props;
        const { productVersion } = this.state;
        if (id === -1) return null;
        if (category === 'kd-sim') {
            if (productVersion === null) return null;
            return (
                <div className="content-fill">
                    <ApplicationVersionForm
                        previousVersion={this.props.prevVersion}
                        versionInfo={productVersion}
                        updateModel={(m) => {
                            this.setState({
                                ...this.state,
                                productVersion: m,
                                updateEnabled: true,
                            });
                        }}
                        update={this.updateProductVersion}
                        delete={this.deleteProductVersion}
                        updateEnabled={this.state.updateEnabled}
                        refreshArtifacts={this.refreshArtifacts}
                        allVersions={this.props.allVersions}
                    />
                </div>
            );
        }

        if (category === 'wod-launcher') {
            if (productVersion === null) return null;
            return (
                <div className="content-fill">
                    <LauncherVersionForm
                        versionInfo={productVersion}
                        updateModel={(m) => {
                            this.setState({
                                ...this.state,
                                productVersion: m,
                                updateEnabled: true,
                            });
                        }}
                        update={this.updateProductVersion}
                        delete={this.deleteProductVersion}
                        updateEnabled={this.state.updateEnabled}
                        refreshArtifacts={this.refreshArtifacts}
                    />
                </div>
            );
        }

        return null;
    }
}

import { ShardStatus } from '@/models/gso/shard/ShardStatus';
import { inject, injectable } from 'inversify';
import { IGSOService } from '../IGSOService';
import type { IGSORESTClient } from '@/rest-clients/IGSORESTClient';
import Nullable from '@/dataTypes/Nullable';
@injectable()
export default class GSOService implements IGSOService {
    @inject('IGSORESTClient')
    private gsoRESTClient!: IGSORESTClient;

    private statusMap = new Map<string,string>();

    constructor(){
        this.statusMap.set('offline', '0');
        this.statusMap.set('maintainance', '1');
        this.statusMap.set('online', '2');
    }

    getShardStatus(shardName: string): Promise<ShardStatus> {
        return this.gsoRESTClient.getShardStatus(shardName);
    }

    setShardStatus(pipeline: string, status: string) : Promise<boolean>{
        return this.gsoRESTClient.setShardStatus(pipeline, this.statusMap.get(status.toLowerCase())!);
    }

    forceStopTheGameRoom(accessToken : string): Promise<Nullable<string>> {
        return this.gsoRESTClient.forceStopTheGameRoom(accessToken);
    }
}

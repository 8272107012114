import { ActionIcon, Affix, Tooltip } from '@mantine/core';
import { MdOutlineAdd, MdOutlineCreate } from 'react-icons/md';

interface CreateButtonProps {
    title: string;
    icon?: React.ReactNode;
    click?: () => void;
    zIndex? : number;
}

export const CreateButton = (props: CreateButtonProps) => {
    const { title, icon } = props;
    return (
        <Affix zIndex={props.zIndex??1} position={{ bottom: 20, right: 20 }}>
            <Tooltip label={title} withArrow>
                <ActionIcon size={50} radius="xl" variant="filled" color="blue" onClick={props.click}>
                    {!!icon ? icon: (<MdOutlineCreate size={25} />)}
                </ActionIcon>
            </Tooltip>
        </Affix>
    );
};

import { MonitoringHeader } from '../monitoring-header/monitoring-header';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { MantineProvider } from '@mantine/core';
import { LogsTable } from './logs-table.component';

export const ServerLogsDirectory = () => {
    const [selectedShard, setSelectedShard] = useState<string | null>(null);
    const [refreshOn, setRefreshOn] = useState<boolean>(true);
    const navigate = useNavigate();
    const { shard } = useParams();

    useEffect(() => {
        if (!!shard && shard !== ':shard') {
            setSelectedShard(shard);
        } else {
            setSelectedShard('shard-001');
        }
    }, []);

    useEffect(() => {
        if (!!selectedShard) {
            navigate(`/monitoring/logs/${selectedShard}`);
        }
    }, [selectedShard]);

    if (!selectedShard) return null;

    return (
        <>
            <MonitoringHeader
                refreshOn={refreshOn}
                setRefreshOn={setRefreshOn}
                selectedShard={selectedShard}
                setSelectedShard={setSelectedShard}
            />
            <MantineProvider theme={{ spacing: { xs: '4px' } }}>
                <LogsTable shard={selectedShard} refreshOn={refreshOn} />
            </MantineProvider>
        </>
    );
};

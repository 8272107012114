import Motd, { MotdVariable } from '@/models/devops/MOTD/Motd';
import { MotdTemplateVariable } from '@/models/devops/MOTD/MotdTemplate';
import IMotdService from '@/services/IMotdService';
import IUIService from '@/services/IUIService';
import {
    ActionIcon,
    Affix,
    Box,
    Button,
    Flex,
    Modal,
    Select,
    Skeleton,
    Stack,
    TextInput,
    Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import { MdOutlineCreate } from 'react-icons/md';
import { useQuery } from '@tanstack/react-query';

interface CreateMotdProps {
    motdService: IMotdService;
    uiService: IUIService;
    getData: (forceSelectFirst?: boolean) => Promise<void>;
    product: string
}

export const CreateMotd = ({ motdService, uiService, getData, product }: CreateMotdProps) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [selected, setSelected] = useState<number | null>(null);
    const [isCreating, setIsCreating] = useState(false);
    const [title, setTitle] = useState('');

    const { isLoading, data } = useQuery({
        queryKey: ['motd-template-list'],
        queryFn: () => motdService.getMotdTemplatesList(1),
    });

    const { data: templateData } = useQuery({
        queryKey: ['motd-template', selected],
        queryFn: () => motdService.getMotdTemplate(selected!),
        enabled: !!selected,
    });

    const isDisabled = !selected || !title || !data || !templateData;

    const onCreateMotd = async () => {
        if (isDisabled) return;
        setIsCreating(true);

        const today = new Date(Date.now());
        const fromDate = new Date();
        fromDate.setDate(today.getDate() + 1);
        const toDate = new Date();
        toDate.setTime(fromDate.getTime() + 30 * 60000);

        console.log(templateData.variables);

        const variablesData: MotdVariable[] = templateData.variables.map((variable: MotdTemplateVariable) =>
            motdService.getMotdDefaultVariable(variable)
        );

        console.log(variablesData);

        const data: Motd = {
            id: 0,
            template_id: templateData.id,
            template_pattern: templateData.pattern,
            title: title,
            message: '',
            from_date: motdService.dateToString(fromDate),
            to_date: motdService.dateToString(toDate),
            product: product,
            active_status: {
                pipelines: ['development'],
            },
            variables: variablesData,
            one_time : false
        };

        const response = await motdService.createMotd(product, data);
        uiService.hideLoading();
        setIsCreating(false);

        if (response) {
            uiService.showSuccessNotification('Message created successfully!');
            getData(true);
            close();
            return;
        } else {
            uiService.showErrorNotification('Error when creating message!');
            close();
        }
    };

    const content = (
        <Stack spacing="xs">
            <TextInput label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
            <Select
                label="Template"
                placeholder="Select a template"
                withinPortal
                data={data?.items.map((item) => ({ label: item.name, value: item.id.toString() })) || []}
                value={selected?.toString()}
                onChange={(value) => value && setSelected(parseInt(value))}
            />

            <Flex gap="sm" justify="stretch" align="stretch" mt="sm">
                <Button fullWidth color="red" variant="outline" onClick={close} disabled={isCreating}>
                    Cancel
                </Button>
                <Button fullWidth disabled={isDisabled} onClick={onCreateMotd} loading={isCreating}>
                    Create
                </Button>
            </Flex>
        </Stack>
    );

    const loading = <Skeleton height={100} />;

    return (
        <Box>
            <Modal opened={opened} onClose={close} title="Create new Motd" centered>
                {isLoading ? loading : content}
            </Modal>
            <Affix zIndex={1} position={{ bottom: 20, right: 20 }}>
                <Tooltip label="Create new Motd" withArrow>
                    <ActionIcon size={50} radius="xl" variant="filled" color="blue" onClick={open}>
                        <MdOutlineCreate size={25} />
                    </ActionIcon>
                </Tooltip>
            </Affix>
        </Box>
    );
};

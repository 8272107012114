import SecopsEndpointReport from '@/models/secops/SecopsEndpointReport';
import SecopsReport from '@/models/secops/SecopsReport';
import { Badge, Flex } from '@mantine/core';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { MdError } from 'react-icons/md';
import { FaMinusCircle } from 'react-icons/fa';

import styles from './secops-styles.module.scss';
import { useMemo } from 'react';
import SecopsServiceReport from '@/models/secops/SecopsServiceReport';
import classes from '../../../utils/classes';
import KDTable, { KDTableColumn } from '../../../components/general/table/KDTable';

interface SecopsFormProps {
    secopsReport: SecopsReport;
}

const isSuccess = (status: string) => {
    return !status || status === '' || status === 'OK';
};

export default function SecopsFormComponent({ secopsReport }: SecopsFormProps) {
    const flatData =
        useMemo(
            () =>
                secopsReport.report.serviceReports.sort((a, b) => {
                    if (isSuccess(a.serviceStatus)) {
                        if (isSuccess(b.serviceStatus)) {
                            return 0;
                        }

                        return 1;
                    }
                    if (isSuccess(b.serviceStatus)) {
                        return -1;
                    }

                    return 0;
                }),
            [secopsReport]
        ) ?? [];


    const kdTableColumns: KDTableColumn<SecopsServiceReport>[] = [
        {
            header: '',
            width: '20px',
            field: 'serviceStatus',
            render: (data) => {
                return <StatusIcon status={data.serviceStatus} />;
            },
        },
        {
            header: 'Name',
            field: 'serviceURI',
        },
        {
            field: 'serviceStatus',
            header: 'Status',
            render: (data) => {
                var status = data.serviceStatus;
                return (
                    <span
                        style={{
                            color: isSuccess(status) ? 'green' : 'red',
                        }}
                    >
                        {status}
                    </span>
                );
            },
        },
    ];

    return (
        <Flex
            direction="column"
            sx={{
                maxWidth: 'calc(100% - 56px)',
            }}
        >
            <h3>Service Report</h3>
            <KDTable
                detailSkipCol={1}
                detailStyle={{
                    padding: '0px'
                }}
                columns={kdTableColumns}
                data={flatData}
                renderDetails={(data) => {
                    return !data.endpointReports ? (
                        <span style={{paddingLeft: '30px'}}>No Data Available</span>
                    ) : (
                        <EndpointReports endpointReports={data.endpointReports} />
                    );
                }}
            />
        </Flex>
    );
}

function StatusIcon({ status }: { status: string }) {
    if (status.toLowerCase() === 'skipped') return <FaMinusCircle color="orange" size={15} />;
    return isSuccess(status) ? <IoCheckmarkCircleSharp color="green" /> : <MdError color="red" />;
}

function EndpointReports({ endpointReports }: { endpointReports: SecopsEndpointReport[] }) {

    const kdTableColumns: KDTableColumn<SecopsEndpointReport>[] = [
        {
            field: 'endpointStatus',
            header: '',
            render: (data) => {
                return <StatusIcon status={data.endpointStatus} />;
            },
            width: '20px',
        },
        {
            field: 'routeAccess',
            header: 'Access',
            render: (data) => {
                return (
                    <div className={classes(styles.badgeContainer)}>
                        <Badge color="green">{data.routeAccess}</Badge>
                    </div>
                );
            },
        },
        {
            field: 'endpointMethod',
            header: 'Method',
            render: (data) => {
                let method = data.endpointMethod;
                if (!method) {
                    method = 'N/A';
                }
                const methodColor = () => {
                    return 'blue';
                    switch (method.toUpperCase()) {
                        case 'POST':
                            return 'green';
                        case 'PUT':
                            return 'orange';
                        case 'GET':
                            return 'blue';
                        case 'DELETE':
                            return 'red';
                        case 'N/A':
                            return 'violet';
                    }
                };

                const methodBadge = () => (
                    <div className={styles.badgeContainer}>
                        <Badge color={methodColor()}>{method.toUpperCase()}</Badge>
                    </div>
                );
                return methodBadge();
            },
        },
        {
            render: (data) => {
                return !!data.controllerName ? data.controllerName : data.endpointURI;
            },
            field: 'controllerName',
            header: 'Name',
        },
        {
            field: 'endpointStatus',
            header: 'Status',
            render: (data) => {
                var status = data.statusMessage;
                return <span>{status}</span>;
            },
        },
    ];

 
    return (
        <KDTable
            detailStyle={{
                padding: '10px',
            }}
            tableStyle = {{
                width: '100%'
            }}
            data={endpointReports.sort((a, b) => {
                if (isSuccess(a.endpointStatus)) {
                    if (isSuccess(b.endpointStatus)) {
                        return 0;
                    }

                    return 1;
                }
                if (isSuccess(b.endpointStatus)) {
                    return -1;
                }

                return 0;
            })}
            columns={kdTableColumns}
            hasDetails={(data)=>{
                return !!data.statusMessage;
            }}
            detailSkipCol={1}
            renderDetails={(data) => <EndpointReportView endpointReport={data} />}
        />
    );
}

function LabelValuePair({ label, value }: { label: string; value: string }) {
    return (
        <Flex direction="row">
            <div className={styles.label}>{label}</div>
            <div>{value}</div>
        </Flex>
    );
}

function EndpointReportView({ endpointReport }: { endpointReport: SecopsEndpointReport }) {
    const accessName = () => {
        switch (endpointReport.routeAccess) {
            case -1:
                return 'UNAUTHORIZED';
            case 0:
                return 'READ ONLY';
            case 1:
                return 'CREATE';
            case 2:
                return 'UPDATE';
            case 3:
                return 'DELETE';
            default:
                return 'MISSING';
        }
    };

    const errorMessage = () => (
        <p
            style={{
                marginLeft: '12px',
                margin: '0px',
                padding: '0px',
            }}
        >
            {endpointReport.statusMessage !== null ? endpointReport.statusMessage.toString() : ''}
        </p>
    );

    const successMessage = () => 'No Issues Found';

    return <Flex direction="column">{!!endpointReport.statusMessage ? errorMessage() : successMessage()}</Flex>;
}

import Nullable from '@/dataTypes/Nullable';
import SucceededTransactionTitle from '@/models/trans/succeeded-transactions/SucceededTransactionsWithTitle';
import { Flex, Alert, Table, Button, Switch } from '@mantine/core';
import { Container } from 'inversify';
import { useState } from 'react';
import { MdOutlineDelete } from 'react-icons/md';

export default function CustomerSucceededTransactions({
    email,
    deleteElement,
    succeededTransactions,
}: {
    email: Nullable<string>;
    succeededTransactions: SucceededTransactionTitle[];
    deleteElement?: (id: number) => void;
}) {
    const [hideDeleted, setHideDeleted] = useState(true);
    return (
        <Flex gap="md" justify="stretch" direction="column" wrap="wrap" w="calc(100%)">
            <h3>Succeeded Transactions</h3>
            <Flex direction="row" justify="flex-end">
                <Switch
                    labelPosition='left'
                    checked={hideDeleted}
                    onChange={(e) => setHideDeleted(e.currentTarget.checked)}
                    label="Hide Invalidated"
                />
            </Flex>
            {!!succeededTransactions && succeededTransactions.length > 0 && (
                <SucceededTransactionTable
                    elements={succeededTransactions}
                    email={email}
                    deleteElement={deleteElement}
                    hideDeleted={hideDeleted}
                />
            )}
            {(!succeededTransactions || succeededTransactions.length === 0) && (
                <Alert title="No Transactions!" color="blue">
                    Seems like the customer has no succeeded transactions
                </Alert>
            )}
        </Flex>
    );
}

interface SucceededTransactionTitleEmail extends SucceededTransactionTitle {
    email: Nullable<string>;
    deleteElement?: (id: number) => void;
}

export function SucceededTransactionTable({
    elements,
    deleteElement,
    email,
    hideDeleted,
}: {
    elements: SucceededTransactionTitle[];
    email: Nullable<string>;
    deleteElement?: (id: number) => void;
    hideDeleted?: boolean;
}) {
    console.log();
    return (
        <Table striped highlightOnHover>
            <thead>
                <tr>
                    <th>Title</th>
                    <th>
                        Purchase Date <br /> Delivery Date
                    </th>
                    <th>Referrer</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {elements
                    .sort((x, y) =>
                        !!x.succeeded_transaction.invalid ? 1 : 0 - (!!y.succeeded_transaction.invalid ? 1 : 0)
                    )
                    .filter((x) => {
                        if (!!hideDeleted) {
                            return !x.succeeded_transaction.invalid;
                        }
                        return true;
                    })
                    .map((x, index) => (
                        <SucceededTransactionRow email={email} deleteElement={deleteElement} {...x} key={index} />
                    ))}
            </tbody>
        </Table>
    );
}
export function SucceededTransactionRow({
    title,
    email,
    succeeded_transaction,
    deleteElement,
}: SucceededTransactionTitleEmail) {
    const referral = (function () {
        const { customer_id, referrer } = succeeded_transaction;
        if (!referrer) return <></>;

        if (referrer !== email) {
            return `By ${referrer}`;
        } else {
            return `To ${customer_id}`;
        }
    })();
    const purchaseDate = new Date(succeeded_transaction.purchase_date);
    const deliveryDate = new Date(succeeded_transaction.delivery_date);
    return (
        <tr style={{ backgroundColor: !!succeeded_transaction.invalid ? '#fa5252' : undefined }}>
            <td>{title}</td>
            <td>
                {purchaseDate.toLocaleString()} <br /> {deliveryDate.toLocaleString()}
            </td>
            <td>{referral}</td>
            <td>
                {!succeeded_transaction.invalid && (
                    <Flex gap="sm">
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                if (!!deleteElement) deleteElement(succeeded_transaction.id);
                            }}
                            color="red"
                        >
                            <MdOutlineDelete />
                        </Button>
                    </Flex>
                )}
            </td>
        </tr>
    );
}

import { ShardStatus } from '@/models/gso/shard/ShardStatus';
import { ActionIcon, Box, Flex, Indicator, Menu, Tooltip } from '@mantine/core';

const shardStatuses = ['Online', 'Maintainance','Offline'];

interface ShardStatusProps {
    shardStatus: ShardStatus;
    changeShardStatus: (status: string) => void;
}

export const ShardStatusIndicator = ({ shardStatus, changeShardStatus }: ShardStatusProps) => {
    const getIndicatorColor = (status: string) => {
        if (status === 'Online') {
            return 'green';
        } else if (status === 'Offline') {
            return 'red';
        } else if (status === 'Maintainance') {
            return 'orange';
        } else {
            return 'red';
        }
    };

    return (
        <Menu shadow="md" position="bottom-end" withArrow width={200}>
            <Menu.Target>
                <Tooltip label={shardStatus.status}>
                    <ActionIcon size="lg" variant="light">
                        <Indicator
                            color={getIndicatorColor(shardStatus.status)}
                            processing={shardStatus.status === 'Online'}
                            position="middle-center"
                            zIndex={1}
                        >
                            <Box w="100%" h="100%"></Box>
                        </Indicator>
                    </ActionIcon>
                </Tooltip>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Change Status</Menu.Label>
                {shardStatuses.map((status) => (
                    <Menu.Item
                        onClick={(e) => {
                            e.preventDefault();
                            changeShardStatus(status);
                        }}
                        value={status}
                        key={status}
                    >
                        <Flex direction="row" columnGap={10}>
                            <Indicator
                                color={getIndicatorColor(status)}
                                position="middle-center"
                                zIndex={1}
                            >
                                {' '}
                                <Box w="100%" h="100%"></Box>
                            </Indicator>
                            {status}
                        </Flex>
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};

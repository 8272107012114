import React, { useEffect, useState } from 'react';
import styles from './KDTable.module.scss';
import { FiChevronsRight } from 'react-icons/fi';
import classes from '../../../utils/classes';

export interface KDTableColumn<T extends object> {
    header: string;
    render?: (t: T) => React.ReactNode;
    field: keyof T;
    width?: string | number;
    cellStyle?: React.CSSProperties;
}

export interface KDTableProps<T extends object> {
    columns: KDTableColumn<T>[];
    data: T[];
    hasDetails?: (data: T) => boolean;
    renderDetails?: (data: T) => React.ReactNode;
    detailStyle? : React.CSSProperties;
    tableStyle? : React.CSSProperties
    detailSkipCol? : number;
}

export default function KDTable<T extends object>(props: KDTableProps<T>) {
    const { data, columns } = props;

    const enableDetailsRendering = !!props.renderDetails;

    const enableSkipCol = !!props.detailSkipCol;

    const skipColumns = !!props.detailSkipCol?props.detailSkipCol:0;

    const [expandedStatus, setExpandedStatus] = useState(data.map((x) => false));

    const hasDetails = (data: T) => {
        if (!!props.hasDetails) return props.hasDetails(data);
        return true;
    };

    const renderDetails = (data: T) => {
        if (!!props.renderDetails) return props.renderDetails(data);
        return <></>;
    };

    const header = (
        <tr>
            {enableDetailsRendering ? <th style={{ width: '30px' }}></th> : undefined}
            {columns.map((column) => (
                <th style={{ width: column.width }}>{column.header}</th>
            ))}
        </tr>
    );

    const body = data.map((datum, index) => {
        return (
            <>
                <tr>
                    {enableDetailsRendering && (
                        <td style={{ width: '30px' }}>
                            <button
                                type="button"
                                className={classes(
                                    hasDetails(datum)? styles.disabled : undefined,
                                    styles.expandButton,
                                    expandedStatus[index] ? styles.expanded : undefined,
                                )}
                                disabled={!hasDetails(datum)}
                                onClick={(e) => {
                                    e.preventDefault();
                                    expandedStatus[index] = !expandedStatus[index];
                                    setExpandedStatus([...expandedStatus]);
                                }}
                            >
                                <FiChevronsRight />
                            </button>
                        </td>
                    )}

                    {columns.map((column) => {
                        return (
                            <td style={{ ...column.cellStyle, width: column.width }}>
                                {(() => {
                                    if (!!column.render) {
                                        return column.render(datum);
                                    }
                                    return datum[column.field];
                                })()}
                            </td>
                        );
                    })}
                </tr>
                {enableDetailsRendering && expandedStatus[index] && (
                    <tr className={styles.detailRow}>
                        {
                            enableSkipCol && (
                                <td colSpan={skipColumns + 1}></td>
                            )
                        }
                        <td style={{...props.detailStyle}} colSpan={props.columns.length - skipColumns + 1}>{renderDetails(datum)}</td>
                    </tr>
                )}
            </>
        );
    });

    return (
        <table className={styles.table} style={{...props.tableStyle}}>
            <thead>{header}</thead>
            <tbody>{body}</tbody>
        </table>
    );
}

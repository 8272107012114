import { Box, Table, Stack, Title, Modal, Skeleton, Alert, Divider, Button, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useInjection } from 'inversify-react';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { GameRoomDetails } from './game-room-details';

import { SearchBox } from '../../../general/search-box/search-box';
import ListViewPagination from '../../../general/list-view-pagination/list-view-pagination.component';
import { GameRoomEntry } from '@/models/monitoring/server-activity/gameRoom/GameRoomEntry';
import { IServerActivityService } from '@/services/IServerActivityService';
import { GameRoomList } from '@/models/monitoring/server-activity/gameRoom/GameRoomList';
import { IoStop } from 'react-icons/io5';
import Nullable from '@/dataTypes/Nullable';
import ForceStopRoom from './force-stop-room.component';

interface GameRoomRowProps {
    room: GameRoomEntry;
    refreshOn: boolean;
    shard: string;
}
const GameRoomRow = ({ room, refreshOn, shard }: GameRoomRowProps) => {
    const [opened, { open, close }] = useDisclosure(false);

    const [targetRoom, setTargetRoom] = useState<Nullable<string>>(null);

    const getGUID = (id: string) => {
        if(id===null){
            return ''
        }
        var guid = id!.substring(0, id!.lastIndexOf('-'));
        return guid;
    };

    return (
        <>
            <Box
                component="tr"
                key={room.guid}
                sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                    console.log(e.currentTarget);
                    open();
                }}
            >
                <Box component="td" maw={200}>
                    {room.guid}
                </Box>
                <td>{room.creator}</td>
                <td>
                    <Tooltip label="Force Stop Room">
                        <Button
                            color="red"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                console.log('room guid');
                                setTargetRoom(room.access_token);
                            }}
                        >
                            <IoStop />
                        </Button>
                    </Tooltip>
                </td>
            </Box>
            <Modal size={700} withinPortal opened={opened} onClose={close}>
                <GameRoomDetails refreshOn={refreshOn} roomID={room.guid} />
            </Modal>
            <Modal
                title="Force Stop Room?"
                size={700}
                withinPortal
                opened={targetRoom !== null}
                onClose={() => {
                    setTargetRoom(null);
                }}
            >
                <ForceStopRoom
                    shard={shard}
                    guid={getGUID(targetRoom!)}
                    close={() => {
                        setTargetRoom(null);
                    }}
                    roomId={targetRoom!}
                />
            </Modal>
        </>
    );
};

interface GameRoomProps {
    selectedShard: string;
    refreshOn: boolean;
}

export const GameRooms = ({ selectedShard, refreshOn }: GameRoomProps) => {
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const serverActivityService = useInjection<IServerActivityService>('IServerActivityService');

    const { isLoading, data, isError } = useQuery<GameRoomList>({
        queryKey: ['gameRooms', search, page, selectedShard],
        queryFn: () => serverActivityService.getGameRoomList(selectedShard, search, page),
        keepPreviousData: true,
        refetchInterval: refreshOn ? 1000 * 10 : false,
        enabled: refreshOn,
    });

    const onRoomNameChange = (value: string) => {
        setSearch(value);
    };

    if (data?.max_results === 0) return null;

    const rows = data?.roomEntryList?.map((room) => (
        <GameRoomRow shard={selectedShard} refreshOn={refreshOn} room={room} key={room.guid} />
    ));

    const table = (
        <Table highlightOnHover horizontalSpacing={0}>
            <thead>
                <tr>
                    <th>Room ID</th>
                    <th>Creator email</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );

    const loading = <Skeleton height={280} />;

    const error = (
        <Alert w="100%" color="red" title="Error">
            Something went wrong, Please reload page.
        </Alert>
    );

    const content = isError ? error : isLoading ? loading : table;

    return (
        <Box>
            <Title order={3}>Game Rooms</Title>
            <Stack align="center" justify="center" spacing="xs">
                <SearchBox placeholder="Search Room by creator email" setSearchQuery={onRoomNameChange} />
                {content}
                <ListViewPagination
                    pageSize={10}
                    currentPage={page}
                    maxCount={data?.max_results}
                    onChangePage={setPage}
                />
            </Stack>
            <Divider my={20} />
        </Box>
    );
};

import { Overlay } from '../overlay/overlay.component';
///import './new-version-dialog.styles.scss';
import store, { RootState } from '../../redux/store';
import { setResponse } from '../../redux/slices/newVersionDialogSlice';
import { useSelector } from 'react-redux';
import { Button, Flex, Modal, Stack, TextInput } from '@mantine/core';
import { useState } from 'react';

export default function NewVersionDialog() {
    const confirm = useSelector((state: RootState) => state.newVersionDialog.open);
    const header = useSelector((state: RootState) => state.newVersionDialog.header);
    const tooltip = useSelector((state: RootState) => state.newVersionDialog.tooltip);
    const createButtonText = useSelector((state: RootState) => state.newVersionDialog.createVersionButton);

    const [inputValue, setInputValue] = useState('');

    const buttonClick = (value: string | null) => {
        setInputValue('');
        store.dispatch(setResponse(value));
    };

    return (
        <Modal
            centered
            onClose={() => buttonClick(null)}
            opened={confirm}
            withinPortal
            title={header}
            styles={{ title: { fontSize: '1.2rem' } }}
            closeOnClickOutside={false}
        >
            <Stack>
                <TextInput
                    label={tooltip}
                    value={inputValue}
                    onChange={(e) => {
                        e.preventDefault();
                        setInputValue(e.target.value);
                    }}
                />

                <Flex  gap="sm" justify='stretch' align='stretch'>
                    <Button
                        sx={()=>({
                            flexGrow:1,
                            flexShrink:1,
                            flexBasis:1
                        })}
                        color="red"
                        variant="outline"
                        onClick={() => {
                            buttonClick(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                     sx={()=>({
                        flexGrow:1,
                        flexShrink:1,
                        flexBasis:1
                    })}
                        onClick={(e) => {
                            e.preventDefault();
                            buttonClick(inputValue);
                        }}
                    >
                        {createButtonText}
                    </Button>
                </Flex>
            </Stack>
        </Modal>
    );
}

import { Reward } from '@/models/treasury/rewards/Reward';
import { Button, Flex, Select, Stack, TextInput, Textarea } from '@mantine/core';
import ShopifyProductPicker from './shopify-product-picker.component';
import { DatePicker, DatePickerInput, DateTimePicker } from '@mantine/dates';
import RewardImageUpload from './reward-image-upload.component';

interface RewardFormProps {
    reward: Reward;
    updateReward?: (reward: Reward) => void;
    deleteClicked?: (id: number) => void;
    saveRewardClicked?: (reward: Reward) => void;
}

export default function RewardForm({ reward, updateReward, saveRewardClicked, deleteClicked }: RewardFormProps) {
    const tryUpdate = (reward: Reward) => {
        if (!!updateReward) {
            updateReward(reward);
        }
    };

    return (
        <Stack mt="md">
            <TextInput
                label="Reward Name"
                value={reward.reward_name}
                onChange={(e) => {
                    e.preventDefault();
                    tryUpdate({
                        ...reward,
                        reward_name: e.target.value,
                    });
                }}
            />

            <Select
                label="Reward Status"
                data={[
                    {
                        value: '1',
                        label: 'Pending',
                    },
                    {
                        value: '2',
                        label: 'Available',
                    },
                    {
                        value: '3',
                        label: 'Shipped',
                    },
                ]}
                value={reward.reward_status.toString()}
                onChange={(e) => {
                    if (!e) {
                        return;
                    }
                    tryUpdate({
                        ...reward,
                        reward_status: parseInt(e!),
                    });
                }}
            />

            <ShopifyProductPicker
                label="Shopify Product"
                value={reward.shopify_product_id}
                onChange={(value) => {
                    tryUpdate({
                        ...reward,
                        shopify_product_id: value,
                    });
                }}
            />

            <Textarea
                label="Description"
                value={reward.extra_info.description}
                onChange={(e) => {
                    e.preventDefault();
                    tryUpdate({
                        ...reward,
                        extra_info: {
                            ...reward.extra_info,
                            description: e.target.value,
                        },
                    });
                }}
            />

            <RewardImageUpload onRefresh={(src)=>{
                 tryUpdate({
                    ...reward,
                    extra_info: {
                        ...reward.extra_info,
                        imageURL : src
                    }
                });
            }} rewardExtraInfo={reward.extra_info} rewardId={reward.reward_id}/>

            {reward.reward_status === 1 && (
                <DatePickerInput
                    label="Expected Delivery Date"
                    onChange={(e) => {
                        if (!!e) {
                            tryUpdate({
                                ...reward,
                                extra_info: {
                                    ...reward.extra_info,
                                    delivery_date: e.toDateString(),
                                },
                            });
                        }
                    }}
                    value={new Date(reward.extra_info.delivery_date)}
                />
            )}

            <Flex dir='row' align={'end'} columnGap={'6px'} justify={'end'}>
                <Button onClick={e=>{
                    e.preventDefault();
                    if(!!saveRewardClicked)
                        saveRewardClicked(reward);
                }}>Update</Button>
                <Button color='red' onClick={e=>{
                    e.preventDefault();
                    if(!!deleteClicked){
                        deleteClicked(reward.reward_id);
                    }
                }}>Remove</Button>
            </Flex>
        </Stack>
    );
}

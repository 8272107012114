import { Shard } from '../../../../models/monitoring/server-activity/Shard';
import { IServerActivityService } from '@/services/IServerActivityService';
import { Alert, Box, Skeleton, Table, Title, useMantineTheme } from '@mantine/core';
import { useInjection } from 'inversify-react';
import { useQuery } from '@tanstack/react-query';

const ServerStatsTable = ({ data }: { data: Shard }) => {
    const theme = useMantineTheme();
    return (
        <Table withBorder withColumnBorders>
            <Box component="thead" bg={theme.colors.gray[0]}>
                <tr>
                    <th>Local IP</th>
                    <th>Public IP</th>
                    <th>Pipeline</th>
                    <th>Zone</th>
                    <th>Active Game Rooms</th>
                    <th>Active Users</th>
                </tr>
            </Box>
            <tbody>
                <tr>
                    <td>{data.localIP}</td>
                    <td>{data.publicIP}</td>
                    <td>{data.pipeline}</td>
                    <td>{data.zone}</td>
                    <td>{data.room_count}</td>
                    <td>{data.user_count}</td>
                </tr>
            </tbody>
        </Table>
    );
};


interface ShardInfoProps {
  selectedShard: string;
  refreshOn: boolean;
}

export const ShardInfo = ({ selectedShard,refreshOn }: ShardInfoProps) => {
    const serverActivityService = useInjection<IServerActivityService>('IServerActivityService');
    const { data, isLoading, isError } = useQuery({
        queryKey: ['shard-stats', selectedShard],
        queryFn: () => serverActivityService.getShardStats(selectedShard),
        refetchInterval: refreshOn ? 1000 * 10 : false,
        enabled: refreshOn
    });

    const error = (
        <Alert color="red" title="Error">
            There was an error fetching the shard stats.
        </Alert>
    );

    const table = data ? <ServerStatsTable data={data} /> : null;
    const skeleton = <Skeleton height={75} />;

    const content = isError ? error : isLoading ? skeleton : table;

    return (
        <Box my="md">
            <Title order={3}>Shard Info</Title>
            {content}
        </Box>
    );
};

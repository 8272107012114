import { CreateButton } from '../general/create-button/create-button.component';
import IUIService from '@/services/IUIService';
import { Box } from '@mantine/core';
import { useInjection } from 'inversify-react';

interface AssetGroupButtonProps {
    create: (name: string) => void;
}

export default function AssetGroupsCreateButton(props: AssetGroupButtonProps) {
    const { create } = props;
    const uiService = useInjection<IUIService>('IUIService');

    return (
        <Box px="sm" mt="sm">
            <CreateButton
                title="New AssetGroup"
                click={async () => {
                    const name = await uiService.showNewVersionDialog('New Asset Group', 'Asset Group Name');
                    if (!!name) {
                        create(name);
                    }
                }}
            />
        </Box>
    );
}

import { Select } from '@mantine/core';
import RepositoryModel from '../../../../models/devops/build/RepositoryModel';
import AzureRepositorySelection from './azure-repository-selection.component';
import GithubRepositorySelection from './github-repository-selection.component';

interface RepositorySelectionProps {
    updateModel: (repository: RepositoryModel) => void;
    repository: RepositoryModel;
    label?: boolean,
    minimize?: boolean
}

export default function RepositorySelection({ repository, updateModel, label, minimize }: RepositorySelectionProps) {


    const repos = [
        { key: 'github', title: 'Github' },
        { key: 'azure', title: 'Azure' },
    ];
    const select = <Select
        label={label && 'Repository'}
        value={repository.repository_type}
        onChange={(value) => {
            if (!value) return;
            repository.repository_type = value;
            updateModel(repository);
        }}
        data={repos.map((repo) => ({ label: repo.title, value: repo.key }))}
    ></Select>;

    const repositoryRender = () => {
        if (repository.repository_type === 'github') {
            return <GithubRepositorySelection select={select
            } repository={repository} updateModel={updateModel} minimize={minimize} />;
        }

        if (repository.repository_type === 'azure') {
            return <AzureRepositorySelection select={select
            } repository={repository} updateModel={updateModel}
                minimize={minimize}
            />;
        }
    };

    return (
        <>
            {repositoryRender()}
        </>
    );
}

import ISecurityProvider from '@/models/security/ISecurityProvider';
import NoAccessPage from '../../pages/no-access/NoAccessPage';

function ProtectedPage({
    children,
    securityProvider,
    debug,
}: {
    children: any;
    securityProvider : ISecurityProvider
    debug?: boolean;
}): any {
    let isGodMode = false;
    if(!!process.env.REACT_APP_TOTAL_ACCESS)
        isGodMode = (process.env.REACT_APP_TOTAL_ACCESS === 'true');

    return securityProvider.valid() || debug || !!isGodMode ? (
        children
    ) : (
        <NoAccessPage />
    );
}

export default ProtectedPage;

import SecopsReport from "@/models/secops/SecopsReport";
import SecopsReportList from "@/models/secops/SecopsReportList";
import ISecopsService from "../ISecopsService";
import { inject, injectable } from "inversify";
import type ISecOpsRESTClient from "@/rest-clients/ISecOpsRESTClient";
import Nullable from "@/dataTypes/Nullable";

@injectable()
export default class SecopsService implements ISecopsService{
    

    @inject('ISecOpsRESTClient')
    private secopsRESTClient! : ISecOpsRESTClient;

    async getReportsList(page: number): Promise<Nullable<SecopsReportList>> {
        return await this.secopsRESTClient.getReportsList(page, 40);
    }
    async getReport(id: number): Promise<Nullable<SecopsReport>> {
        return await this.secopsRESTClient.getReport(id);
    }

    async generateReport(): Promise<Nullable<number>> {
        return await this.secopsRESTClient.generateReport();
    }

}
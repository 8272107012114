import { Button, Flex, Group, Tooltip } from '@mantine/core';

interface DefaultOptionProps {
    value: string;
    id: number | string;
    inSelectContainer?: boolean;
    selected?: boolean;
    selectionChange?: (id: number | string) => void;
    tooltip? : string;
}

interface DefaultOptionPropsImage extends DefaultOptionProps {
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
}

export default function DefaultListOptionView(props: DefaultOptionProps) {
    const { id, value, inSelectContainer, selected, selectionChange } = props;
    if (!inSelectContainer)
        return (
            <LiOptionView id={id} selected={selected} selectionChange={selectionChange}>
                {value}
            </LiOptionView>
        );
    return <option value={id}>{value}</option>;
}

export function DefaultListOptionWithImageView(props: DefaultOptionPropsImage) {
    const { id, value, inSelectContainer, selected, selectionChange, leftIcon, rightIcon, tooltip } = props;
    if (!inSelectContainer)
        return (
            <LiOptionView id={id} selected={selected} selectionChange={selectionChange} tooltipString={tooltip}>
                <Flex direction="row" sx={{ flex: 1 }} justify="space-between" align="center">
                    {leftIcon && leftIcon}
                    {value}
                    {rightIcon && rightIcon}
                </Flex>
            </LiOptionView>
        );
    return <option value={id}>{value}</option>;
}

function LiOptionView({
    id,
    children,
    selected,
    selectionChange,
    tooltipString,
}: {
    id: string | number;
    children?: React.ReactNode;
    selected?: boolean;
    selectionChange?: (id: number | string) => void;
    tooltipString?: string;
}) {
    const button = (
        <Button
            fullWidth
            radius={0}
            variant={selected ? 'light' : 'subtle'}
            c="dark"
            onClick={() => {
                if (selectionChange) selectionChange(id);
            }}
            styles={{
                root: { minHeight: 40, paddingRight: 5 },
                inner: {
                    justifyContent: 'space-between',

                    span: {
                        flex: 1,
                        width: '100%',
                    },
                },
            }}
        >
            {children}
        </Button>
    );

    if (!!tooltipString) {
        return <Tooltip label={tooltipString}>{button}</Tooltip>;
    }
    return button;
}

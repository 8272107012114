import { useInjection } from 'inversify-react'
import { useSearchParams } from 'react-router-dom';
import { IWindowMessage } from '../../services/IWindowMessage'
import styles from './github-auth.module.scss'

export default function GithubAuth() {
    const windowMessage = useInjection<IWindowMessage>('IWindowMessage');

    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")

    if(!!code){
        windowMessage.postMessageToSpawner(
            {
                key : 'githubToken',
                value : code
            }
        )
    }
    

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.githubContainer}>
                    <div className={styles.img}></div>
                    <p>Github Authorization {!!code?'Success':'Failed'}</p>
                </div>
            </div>
        </div>

    )
}
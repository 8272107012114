import IList from '../../IList';
import { MotdTemplateEntry } from './MotdTemplateEntry';

export class MotdTemplateList implements IList<MotdTemplateEntry> {
    public max_results!: number;

    public items!: MotdTemplateEntry[];

    getItems(): MotdTemplateEntry[] {
        return this.items;
    }
}

import IScopeRepositoryService from "@/services/repositories/IScopeRepositoryService";
import ISecurityProvider from "../../ISecurityProvider";

export default class ScopeSecurityProvider implements ISecurityProvider{

    private scopeService : IScopeRepositoryService;

    public scopes: string[];

    constructor(scope:string[], scopeService : IScopeRepositoryService){
        this.scopes = scope;
        this.scopeService = scopeService;
    }


    valid(): boolean {
        const scope = this.scopeService.getLoggedInUserScope()
        if(!scope)
        return false;
        return this.scopes.includes(scope);
    }
}
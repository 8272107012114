import StoreProductEntry from '@/models/trans/storeProducts/StoreProductEntry';
import StoreProductList from '@/models/trans/storeProducts/StoreProductList';
import { Avatar, Group, Badge, Container, Box } from '@mantine/core';
import DefaultListOptionView, {
    DefaultListOptionWithImageView,
} from '../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../general/list-view/list-view.component';
import RewardsCreateButton from '../rewards-create-button/rewards-create-button';
import { SearchBox } from '../../general/search-box/search-box';
import RewardList from '../../../models/treasury/rewards/RewardList';

interface RewardsListProps {
    create?: (name: string) => void;
    selectionChange: (id: number) => void;
    selectedId: number;
    versionList: RewardList | null;
    page: number;
    onPageChange: (page: number) => void;
    searchChange?: (query: string) => void;
    canCreate?: boolean;
}
export default function RewardsList({
    searchChange,
    selectedId,
    selectionChange,
    versionList,
    page,
    onPageChange,
    create,
    canCreate,
}: RewardsListProps) {
    const onSelectionChange = (id: number) => {
        selectionChange(id);
    };
    
    return (
        <Container px={0} pt="md">
            <Box px="xs">
                <SearchBox
                    placeholder='Search Reward'
                    setSearchQuery={(q) => {
                        if (!!searchChange) {
                            searchChange(q);
                        }
                    }}
                />
            </Box>
            <ListViewComponent
                elements={versionList}
                page={page}
                selectedValue={selectedId}
                pageChanged={onPageChange}
                selectionChanged={onSelectionChange}
                listElement={(x, selectionChange, inSelect) => {
                    return (
                        <DefaultListOptionView
                            key={x.reward_id}
                            id={x.reward_id}
                            value={x.reward_name.length>30?x.reward_name.substring(0,27)+'...':x.reward_name}
                            selectionChange={selectionChange}
                            selected={selectedId === x.reward_id}
                            inSelectContainer={inSelect}
                        />
                    );
                }}
            >
                <ListViewComponent.Header>
                    {!!canCreate && (
                        <RewardsCreateButton
                            create={(name) => {
                                if (!!create) create(name);
                            }}
                        />
                    )}
                </ListViewComponent.Header>
            </ListViewComponent>
        </Container>
    );
}

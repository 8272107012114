import { Grid } from '@mantine/core';
import PledgeListComponent from './pledge-list/pledge-customer-list.component';
import { useEffect, useState } from 'react';
import Nullable from '@/dataTypes/Nullable';
import RewardsList from '@/models/treasury/rewards/RewardList';
import { useMount } from 'ahooks';
import { useInjection } from 'inversify-react';
import IUIService from '@/services/IUIService';
import ITreasuryService from '@/services/ITreasuryService';
import { Reward } from '@/models/treasury/rewards/Reward';
//import RewardForm from './reward-form/reward-form.component';
import { useDebouncedState } from '@mantine/hooks';
import CustomerList from '@/models/treasury/pledges/CustomerList';
import PledgeCPForm from './pledge-cp-form/pledge-cp-form.component';
import PledgeAddForm from './pledge-add-form/pledge-add-form.component';
import Pledge from '@/models/treasury/pledges/Pledge';
import PledgeEditForm from './pledge-edit-form/pledge-edit-form.component';
import OrderHistory from '@/models/treasury/orderHistory/OrderHistory';
import OrderHistoryWithName from '@/models/treasury/orderHistory/OrderHistoryWithName';
import PledgeAddBacker from './pledge-add-backer/pledge-add-backer.component';

export default function PledgeCPDirectory() {
    const uiService = useInjection<IUIService>('IUIService');
    const treasuryService = useInjection<ITreasuryService>('ITreasuryService');
    const [customerList, setCustomerList] = useState<Nullable<CustomerList>>(null);
    const [page, setPage] = useState<number>(1);
    const [selectedEmail, setSelectedEmail] = useState<Nullable<string>>();
    const [search, setSearch] = useDebouncedState<string>('', 400);
    const [createVisible, setCreateVisible] = useState(false);
    const [addBackerVisible, setAddBackerVisible] = useState(false)
    const [pledges, setPledges] = useState<Pledge[]>([]);
    const [orderHistory, setOrderHistory] = useState<OrderHistoryWithName[]>([])
    const [editingPledge, setEditingPledge] = useState<Nullable<Pledge>>(null);

    useMount(() => {
        getItems();
    });

    useEffect(() => {
        getItems();
    }, [page, search]);

    const getCustomerPledges = (getOrderHistory?: boolean) => {
        uiService.showLoading();
        treasuryService.getCustomerPledges(selectedEmail!).then((pledges) => {
            uiService.hideLoading();
            if (!!pledges) {
                setPledges(pledges);

                if(!!getOrderHistory){
                    getCustomerOrderHistory();
                }
            } else {
                uiService.showErrorNotification('Failed to load customer pledges');
            }
        });
    };

    const getCustomerOrderHistory = ()=>{
        uiService.showLoading();
        treasuryService.getOrderHistory(selectedEmail!).then((orders) => {
            uiService.hideLoading();
            if (!!orders) {
                setOrderHistory(orders);
            } else {
                uiService.showErrorNotification('Failed to load customer order history');
            }
        });
    }

    useEffect(() => {
        getCustomerPledges(true);
    }, [selectedEmail]);

    const getItems = () => {
        uiService.showLoading();
        treasuryService.getCustomers(page, search).then((customers) => {
            uiService.hideLoading();
            if (!!customers) {
                setCustomerList(customers);
                if (customers.getItems().length > 0) {
                    setSelectedEmail(customers.getItems()[0]);
                }
            } else {
                uiService.showErrorNotification('Failed to load customer list');
            }
        });
    };

    const renderList = () => {
        return (
            <PledgeListComponent
                customerList={customerList}
                page={page}
                searchChange={(s) => setSearch(s)}
                onPageChange={(e) => setPage(e)}
                canCreate={true}
                selectedEmail={selectedEmail!}
                selectionChange={(newId) => {
                    setSelectedEmail(newId);
                }}
                create={() => {
                    setCreateVisible(true);
                }}
                onAddNewBacker={()=>{
                    setAddBackerVisible(true)
                }}
            />
        );
    };

    return (
        <>
            <Grid w="calc(100vw - 250px)">
                <Grid.Col span="content">{renderList()}</Grid.Col>
                <Grid.Col span="auto">
                    {selectedEmail === null ? (
                        'No Data to Display'
                    ) : (
                        <PledgeCPForm
                            orderHistories={orderHistory}
                            selectedEmail={selectedEmail!}
                            pledges={pledges}
                            editPledge = {(pledge)=>{
                                setEditingPledge(pledge);
                            }}
                            deletePledge={async (id) => {
                                uiService.showLoading();
                                const response = await treasuryService.deletePledge(id);
                                uiService.hideLoading();

                                if (!response) {
                                    uiService.showErrorNotification('Failed to remove the pledge');
                                } else {
                                    getCustomerPledges();
                                }
                            }}
                        />
                    )}
                </Grid.Col>
            </Grid>

            <PledgeAddBacker closed={()=>{
                setAddBackerVisible(false)
            }} visible={addBackerVisible} create={async (r, email, backerKitEmail, quantity)=>{
                    uiService.showLoading();
                    const response = await treasuryService.createPledge({
                        wod_email: email,
                        backerkit_email: backerKitEmail,
                        quantity: quantity,
                        reward_id: r,
                        available_quantity: quantity,
                        claimed_quantity: 0,
                        pledge_id: 0,
                    });
                    uiService.hideLoading();

                    if (!response) {
                        uiService.showErrorNotification('Failed to create a new pledge!');
                    }

                    setAddBackerVisible(false);
                    getItems();
            }}/>

            <PledgeAddForm
                create={async (r, backerKitEmail, quantity) => {
                    uiService.showLoading();
                    const response = await treasuryService.createPledge({
                        wod_email: selectedEmail!,
                        backerkit_email: backerKitEmail,
                        quantity: quantity,
                        reward_id: r,
                        available_quantity: quantity,
                        claimed_quantity: 0,
                        pledge_id: 0,
                    });
                    uiService.hideLoading();

                    if (!response) {
                        uiService.showErrorNotification('Failed to create a new pledge!');
                    }

                    setCreateVisible(false);
                    getCustomerPledges();
                }}
                visible={createVisible}
                closed={() => {
                    setCreateVisible(false);
                }}
                email={selectedEmail ?? ''}
            />

            <PledgeEditForm
                visible={!!editingPledge}
                pledge={editingPledge!}
                closed={() => {
                    
                    setEditingPledge(null);
                }}
                edit={async (pledge, backerKitEmail, claimedQuantity, quantity) => {
                    uiService.showLoading();
                    const newPledge = {
                        ...pledge,
                        backerKitEmail : backerKitEmail,
                        claimedQuantity : claimedQuantity,
                        quantity : quantity
                    };
                    const response = await treasuryService.savePledge(newPledge);
                    uiService.hideLoading();

                    if(!!response){
                        getCustomerPledges();
                    }else{
                        uiService.showErrorNotification('Failed to save the pledge');
                    }

                    setEditingPledge(null);
                }}
            />
        </>
    );
}

import React from 'react';
import { resolve } from 'inversify-react';
import { connect } from 'react-redux';
import type IViewFactoryService from '@/services/factories/IViewFactoryService';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RootState } from '../../redux/store';
import ProtectedPage from '../../components/protected-page/protected-page';
import MainLayout from '../../components/main-layout/main-layout.component';
import LoginPanel from '../../components/login-panel/login-panel.component';
import DevopsCPDirectory from '../../components/devops-cp/devops-cp-directory/devops-cp-directory.component';
import { MOTDDirectory } from '../../components/motds/motd-directory/motd-directory.component';
import MOTDTemplateDirectory from '../../components/motds/motd-directory/motd-template-directory.component';
import StoreItemDirectory from '../../components/store-items/store-product-directory/store-product-directory.component';
import FailedTransactionDirectory from '../../components/failed-transactions/failed-transaction-directory.component';
import { UserCPDirectory } from '../../components/iam/user-cp/user-cp-directory/user-cp-directory.component';
import GithubAuth from '../../components/github-auth/github-auth.component';
import { ServiceCPDirectory } from '../../components/iam/services/services-cp-directory/services.component';
import { ScopeCPDirectory } from '../../components/iam/scopes/scopes-cp-directory/scopes.component';
import { ServerActivityDirectory } from '../../components/monitoring/server-activity/server-activity-directory/server-activity.component';
import ProductCPDirectory from '../../components/devops-cp/products/product-cp-directory/product-cp-directory.component';
import ProductInit from '../../components/devops-cp/products/product-init/product-init.component';
import { ServerLogsDirectory } from '../../components/monitoring/server-logs/server-logs.component';
import CustomerTransactionsDirectory from '../../components/customer-transactions-cp/customer-transactions-directory.component';
import HomePage from '../homepage/HomePage';
import DatastoreDashboard from '../../components/datastore-cp/datastore-dashboard/datastore-dashboard.component';
import ProductKeyDirectory from '../../components/store-items/product-key-directory/product-key-directory.component';
import DataStoreUserSavesDirectory from '../../components/datastore-cp/datastore-user-saves/datastore-user-saves-directory.component';
import SecopsDirectory from '../../components/secops-cp/secops-directory/secops-directory.component';
import AssetGroupDirectory from '../../components/asset-groups-cp/asset-groups-directory.component';
import BundleDependencyDirectory from '../../components/devops-cp/bundle-dependency/bundle-dependency-directory/bundle-dependency-directory.component';
import RewardsCPDirectory from '../../components/rewards-cp/rewards-cp-directory.component';
import PledgeCPDirectory from '../../components/pledge-cp/pledge-cp-directory.component';
import MailIntegrationDirectory from '../../components/integrations/mail-integrations/MailIntegrationDirectory';
import RewardsDiagnosticsDirectory from '../../components/rewards-diagnostics/rewards-diagnostics-directory.component';

interface PageWithHeaderProps {
    isAuthorized: boolean;
}
class PageWithHeader extends React.Component<PageWithHeaderProps, {}> {
    @resolve('IViewFactoryService')
    public viewFactoryService!: IViewFactoryService;

    componentDidUpdate() {}
    layout = () => {
        const elements = this.viewFactoryService.getNavRoutesComponents().map((element, key) => {
            const component = (function () {
                switch (element.component) {
                    case 'FailedTransactionDirectory':
                        return <FailedTransactionDirectory />;
                    case 'StoreItemDirectory':
                        return <StoreItemDirectory />;
                    case 'UserCPDirectory':
                        return <UserCPDirectory />;
                    case 'ServiceCPDirectory':
                        return <ServiceCPDirectory />;
                    case 'ScopeCPDirectory':
                        return <ScopeCPDirectory />;
                    case 'DevopsCPDirectory':
                        return <DevopsCPDirectory />;
                    case 'MOTDDirectory':
                        return <MOTDDirectory />;
                    case 'MOTDTemplateDirectory':
                        return <MOTDTemplateDirectory />;
                    case 'ProductCPDirectory':
                        return <ProductCPDirectory />;
                    case 'ProductInitComponent':
                        return <ProductInit />;
                    case 'ServerActivityDirectory':
                        return <ServerActivityDirectory />;
                    case 'ServerLogsDirectory':
                        return <ServerLogsDirectory />;
                    case 'CustomerTransactionsDirectory':
                        return <CustomerTransactionsDirectory />;
                    case 'DataStoreDashboard':
                        return <DatastoreDashboard />;
                    case 'DataStoreUserSavesDirectory':
                        return <DataStoreUserSavesDirectory />;
                    case 'ProductKeyDirectory':
                        return <ProductKeyDirectory />;
                    case 'SecopsDirectoryComponent':
                        return <SecopsDirectory />;
                    case 'AssetGroupsDirectory':
                        return <AssetGroupDirectory/>;
                    case "BundleDependencyDirectory":
                        return <BundleDependencyDirectory/>;
                    case 'RewardsCPDirectory': 
                        return <RewardsCPDirectory/>
                    case 'PledgeCPDirectory': 
                        return <PledgeCPDirectory/>
                    case 'MailIntegrationDirectory':
                        return <MailIntegrationDirectory/>
                    case 'RewardsDiagnosticsDirectory': 
                        return <RewardsDiagnosticsDirectory/>
                }
            })();
            return (
                <Route
                    key={key}
                    path={element.route}
                    element={
                        !!element.securityProvider ? (
                            <ProtectedPage securityProvider={element.securityProvider} children={component} />
                        ) : (
                            component
                        )
                    }
                />
            );
        });

        return (
            <MainLayout>
                <Routes>
                    <Route path="/githubAuth" element={<GithubAuth />} />
                    <Route path="/" element={<HomePage />} />
                    {elements}
                </Routes>
            </MainLayout>
        );
    };

    login = () => {
        return (
            <Routes>
                <Route path="/githubAuth" element={<GithubAuth />} />
                <Route path="*" index element={<LoginPanel />} />
            </Routes>
        );
    };

    render() {
        return (
            <BrowserRouter basename={process.env.REACT_APP_ROUTE_BASE_NAME}>
                {this.props.isAuthorized ? this.layout() : this.login()}
            </BrowserRouter>
        );
    }
}
const mapStateToProps = (state: RootState) => ({
    isAuthorized: state.auth.jwtToken !== null,
});

export default connect(mapStateToProps)(PageWithHeader);

import Nullable from "@/dataTypes/Nullable";
import BundleConnection from "@/models/devops/build/ui/BundleConnection";
import { useEffect } from "react";
import style from './bundleConnectionSidebar.module.scss'
import classes from "../../../../utils/classes";
import BundleConnectionUI from "./bundle-connection";

export default function BundleConnectionSidebar({selection, remove} 
    : {selection : Nullable<BundleConnection[]>, 
    remove : (id:string)=>void}){

        useEffect(()=>{

        }, [selection])

        return (
        <div className={classes(style.sidebar, selection!==null?style.open:undefined)}>
            <div className={style.content}>
                <p className={style.header}>Connections</p>

                <div className={style.connections}>
                    {selection===null || selection.length===0?(<p>No dependencies for this bundle</p>):(selection.map(x=>{
                        return <BundleConnectionUI key={x.id}  connection={x} remove={remove}/>
                    }))}
                    
                </div>
            </div>
        </div>)
}
import IList from "@/models/IList";

export default class CustomerList implements IList<string> {
    
    public emails!: string[];
    public max_results!: number;

    getItems(): string[] {
        return this.emails;
    }
}
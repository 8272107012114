import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mantine/core';

import DefaultListOptionView from '../../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../../general/list-view/list-view.component';
import { UserForm } from '../user-form/user-form.component';
import { SearchBox } from '../../../general/search-box/search-box';

import UserList from '@/models/auth/users/UserList';
import UserModel from '@/models/auth/users/UserModel';
import type IUIService from '@/services/IUIService';
import type IUserManagementService from '@/services/IUserManagementService';

export const UserCPDirectory = () => {
    const [list, setList] = useState<UserList | null>(null);
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState(-1);
    const [currentSelectedUser, setCurrentSelectedUser] = useState<UserModel | null>(null);
    const [updateEnabled, setUpdateEnabled] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const userService = useInjection<IUserManagementService>('IUserManagementService');
    const uiService = useInjection<IUIService>('IUIService');

    useEffect(() => {
        getData(true);
    }, [page, searchQuery]);

    useEffect(() => {
        if (selected !== -1) {
            onListSelectionChanged(selected);
        }
    }, [selected]);

    const getData = async (forceSetFirst = false, reload = false) => {
        uiService.showLoading();
        const response = await (searchQuery ? userService.searchUser(searchQuery, page) : userService.getUsers(page));

        uiService.hideLoading();
        if (response === null) {
            uiService.showErrorNotification('Loading user list failed!');
            return;
        }

        const setToFirst = (selected === -1 && response.accounts.length > 0) || forceSetFirst;
        const firstId = response.accounts.length > 0 ? response.accounts[0].id : -1;

        setList(response);
        setSelected(setToFirst ? firstId : selected);
    };

    const onListSelectionChanged = async (selectedId: number) => {
        const userEmail = list?.accounts.find((x) => x.id === selectedId)?.email;
        uiService.showLoading();

        try {
            const response = await userService.getUser(userEmail!);
            if (response === null) {
                uiService.hideLoading();
                uiService.showErrorNotification('Failed to get user data');
                return;
            }
            setCurrentSelectedUser(response);

            setUpdateEnabled(false);
            uiService.hideLoading();
        } catch (error) {
            uiService.hideLoading();
        }

        setSelected(selectedId);
    };

    const onSelectionChanged = (selectedId: number) => {
        setSelected(selectedId);
    };

    const onListPageChanged = (page: number) => {
        setPage(page);
    };

    const onSave = async (user: UserModel) => {
        if (currentSelectedUser === null) return;
        setCurrentSelectedUser(user);

        uiService.showLoading();
        const success = await userService.updateUserScope(user);
        uiService.hideLoading();
        if (success) {
            uiService.showSuccessNotification('User successfuly update');
            setUpdateEnabled(false);
        } else {
            uiService.showErrorNotification('Failed to update the user');
        }
    };

    const onDelete = async () => {
        if (currentSelectedUser === null) return;
        const promptResponse = await uiService.showConfirmationDialog(
            `Delete user ${currentSelectedUser.email}?`,
            'Cancel',
            'Delete'
        );
        if (!promptResponse) return;
        uiService.showLoading();
        const success = await userService.deleteUser(currentSelectedUser);
        uiService.hideLoading();
        if (success) {
            uiService.showSuccessNotification('User successfuly deleted');
        } else {
            uiService.showErrorNotification('Failed to delete the user');
        }
        await getData(true);
    };

    const onUpdate = () => {
        setUpdateEnabled(true);
    };

    const onSearch = (email: string) => {
        setSearchQuery(email);
        setPage(1);
    };

    const renderUserList = () => {
        if (list === null) return null;

        return (
            <Container px={0} pt="md">
                <Box px="xs">
                    <SearchBox setSearchQuery={onSearch} />
                </Box>
                <ListViewComponent
                    elements={list}
                    page={page}
                    selectedValue={selected}
                    containerId="user-list"
                    listId="users"
                    listClass="user_list"
                    pageChanged={onListPageChanged}
                    selectionChanged={onSelectionChanged}
                    listElement={(element, selectionChanged, inSelect) => {
                        return (
                            <DefaultListOptionView
                                key={element.id}
                                value={element.email}
                                id={element.id}
                                selectionChange={selectionChanged}
                                inSelectContainer={inSelect}
                                selected={selected === element.id}
                            />
                        );
                    }}
                />
            </Container>
        );
    };

    const renderForm = () => {
        if (currentSelectedUser === null) return null;
        return (
            <UserForm
                user={currentSelectedUser}
                delete={onDelete}
                update={onUpdate}
                save={onSave}
                updateEnabled={updateEnabled}
            />
        );
    };

    return (
        <Grid w="calc(100vw - 250px)">
            <Grid.Col span="content">{renderUserList()}</Grid.Col>
            <Grid.Col span="auto">{renderForm()}</Grid.Col>
        </Grid>
    );
};

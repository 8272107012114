import AzureBranchModel from "@/models/devops/azure/AzureBranchModel";
import AzureRepositoryModel from "@/models/devops/azure/AzureRepositoryModel";
import axios, { AxiosRequestConfig } from "axios";
import { injectable } from "inversify";
import IAzureRESTClient from "../IAzureRESTClient";
import { RESTClient } from "./RESTClient";


@injectable()
export default class AzureRESTClient extends RESTClient implements  IAzureRESTClient{
    constructor(){
        super();
        this.setBaseURL('');
    }



    async getRepositories(personalToken: string) : Promise<AzureRepositoryModel[]>{
        const url = 'https://dev.azure.com/adampoots/Kingdom-Death-Simulator/_apis/git/repositories?api-version=4.1';

        try{
            const response = await axios.get(url, this.getBasicHeader(personalToken));
            return response.data.value as AzureRepositoryModel[];
        }
        catch{
            return []
        }
    }

    async getRepositoryBranch(personalToken: string, repoUrl: string) : Promise<AzureBranchModel[]>{
        const url = `${repoUrl}/refs`;
        try{
            const response = await axios.get(url, this.getBasicHeader(personalToken));
            return response.data.value as AzureBranchModel[];
        }
        catch{
            return []
        }
    }

    getBasicHeader(personalToken:string){
        const token = window.btoa(`:${personalToken}`);

        const config: AxiosRequestConfig = {
            headers: {
                Authorization: `Basic ${token}`,
            }
        }
        return config
    }
}
import React from "react";
import FailedTransaction from "../../models/trans/failedTransactions/FailedTransaction";
import { ActionIcon, Button, Container, Flex, Stack, TextInput } from "@mantine/core";
import { MdOutlineShop } from "react-icons/md";
import { FiShoppingBag } from "react-icons/fi";
import { useInjection } from "inversify-react";
import IDateTimeService from "@/services/IDateTimeService";

interface FailedTransactionFormProps {
    failedTransaction: FailedTransaction,
    changeStatus: (failedTransaction: FailedTransaction) => void;
}

export default function FailedTransactionForm(
    props: FailedTransactionFormProps
) {
    const dateTimeService = useInjection<IDateTimeService>('IDateTimeService')
    const { failedTransaction, changeStatus: update } = props;
    

    const orderUrl = `https://kingdomdeath.myshopify.com/admin/orders/${failedTransaction.order_id}`;

    const changeOrderStatus = () => {
        failedTransaction.status = !failedTransaction.status;
        update(failedTransaction);
    }

    return (

        <Container fluid mt="lg">
            <Stack>
                
                <TextInput value={failedTransaction.order_id}
                    rightSection={
                        <ActionIcon onClick={e => {
                            e.preventDefault();
                            window.open(orderUrl, '_blank')
                        }} color="green" variant="subtle">
                            <FiShoppingBag />
                        </ActionIcon>}
                    disabled label="Order ID" />

                <TextInput label="Token" disabled value={failedTransaction.guid_token} />
                <TextInput label="Reason" disabled value={failedTransaction.reason} />
                <TextInput label="Status" disabled value={failedTransaction.status ? "Resolved" : "Not Resolved"} />
                <TextInput label="Date" disabled value={!!failedTransaction.date?dateTimeService.formatDate(new Date(Date.parse(failedTransaction.date))) + " UTC0":"No Date Available"}/>

            </Stack>


            <Flex w="max-content" ml="auto" mt="lg" gap="md">
                <Button
                    size="md"
                    radius="md"
                    color={!failedTransaction.status ? "blue" : "red"}
                    onClick={e => {
                        e.preventDefault();
                        changeOrderStatus();
                    }}>
                    {!failedTransaction.status ? "Mark as Resolved" : "Mark as Not Resolved"}
                </Button>
            </Flex>
        </Container>
    );
}

import { inject, injectable } from "inversify";
import MenuAction from "../../models/MenuAction";
import MenuItem from "../../models/MenuItem";
import type { IAuthService } from "../IAuthService";
import ILayoutService from "../ILayoutService";

@injectable()
export default class LayoutService implements ILayoutService{
    @inject("IAuthService")
    private authService! : IAuthService;
    getMenuItems = (accessLevel: number) => {
        const items : MenuItem[] = [];
        items.push({
            id:1,
            name:"Record Sheets",
            target_path : "records",
            icon_id : "records",
            access_level : 0
        });
        items.push({
            id:2,
            name:"Decks",
            target_path : "decks",
            icon_id : "decks",
            access_level : 0
        });
        items.push({
            id:3,
            name:"Tokens",
            target_path : "tokens",
            icon_id : "tokens",
            access_level : 0
        });
        items.push({
            id:4,
            name:"Books",
            target_path : "books",
            icon_id : "books",
            access_level : 0
        });
        const returnItems : MenuItem[] = [];
        items.forEach(item=>{
            if(item.access_level<=accessLevel)
                returnItems.push(item)
        })

        return returnItems;

    }
    getActions = () => {
        const items : MenuAction[] = [];
        items.push({
            id:1,
            action_name:"DevOps CP",
            target_path : "launcher-cp",
            icon_id : "launcher",
            access_type : "devops",
            access_level : 3
        });

        items.push({
            id:2,
            action_name:"User CP",
            target_path : "user-cp",
            icon_id : "admin",
            access_type : "auth",
            access_level : 3
        });

        items.push({
            id:3,
            action_name:"Store Items",
            target_path : "store-items",
            icon_id : "store",
            access_type : "trans",
            access_level : 3
        });

        items.push({
            id:4,
            action_name:"Failed Transactions",
            target_path : "failed-transactions",
            icon_id : "bug",
            access_type : "trans",
            access_level : 3
        });

        items.push({
            id:5,
            action_name:"MOTD",
            target_path : "motd",
            icon_id : "motd",
            access_type : "devops",
            access_level : 3
        });

        items.push({
            id:6,
            action_name:"Reset Password",
            target_path : "registration/reset",
            icon_id : "reset",
            access_type : "auth",
            access_level : -1
        });


        const returnItems : MenuAction[] = [];

        items.forEach(item=>{
            if(item.access_level<=this.authService.getAccessLevel(item.access_type))
                returnItems.push(item)
        })

        return returnItems;
    }

}
import BundleInfoModel from '@/models/devops/build/BundleInfoModel';
import { Button, createStyles, Flex, Grid, Group, Select, Switch, TextInput } from '@mantine/core';
import styles from './product-bundle.module.scss';
import BuildProductVersionInfoList from '@/models/devops/build/product/BuildProductVersionInfoList';

interface IProductBundleProps {
    bundle: BundleInfoModel;
    updateModel: (bundle: BundleInfoModel) => void;
    delete: () => void;
    isDirty: boolean;
    isNew: boolean;
    productList: BuildProductVersionInfoList;
}
export default function ProductBundle({
    bundle,
    delete: deleteBundle,
    updateModel,
    isDirty,
    isNew,
    productList,
}: IProductBundleProps) {
    const update = () => {
        updateModel(bundle);
    };

    const backgroundColor = (function () {
        if (isNew) return 'green';
        if (isDirty) return 'orange';
        return 'white';
    })();

    var setOfValues = new Set<number>();

    let data = productList.versions.map((x) => {
        setOfValues.add(x.id);
        return {
            label: x.version,
            value: x.id.toString(),
        };
    });

    data.push({
        label: 'No Cutoff Version',
        value: '-1',
    });

    setOfValues.add(-1);

    let deletedVersion = false;

    if (!!bundle.cutoff_version && !setOfValues.has(bundle.cutoff_version)) {
        deletedVersion = true;
        data.push({
            label: `Deleted Version ${bundle.cutoff_version}`,
            value: bundle.cutoff_version.toString(),
        });
    }

    return (
        <div className={styles.bundleContainer} style={{ backgroundColor: backgroundColor }}>
            <Grid>
                <Grid.Col span={6}>
                    <TextInput
                        label="Name"
                        value={bundle.name}
                        onChange={(e) => {
                            e.preventDefault();
                            bundle.name = e.target.value;
                            update();
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={2}></Grid.Col>
                <Grid.Col span={4}>
                    <Flex mih="100%" justify="flex-end" align="center" direction="row" wrap="wrap" gap="sm">
                        <Button variant="outline" color="red" onClick={deleteBundle}>
                            Delete
                        </Button>
                        <Switch
                            labelPosition="left"
                            label="Restrict"
                            checked={bundle.access === 1}
                            onChange={(e) => {
                                bundle.access = e.currentTarget.checked ? 1 : 0;
                                update();
                            }}
                        />
                    </Flex>
                </Grid.Col>
            </Grid>
            <Grid>
                <Grid.Col span={6}>
                    <TextInput
                        label="Install Path"
                        value={bundle.install_path}
                        onChange={(e) => {
                            e.preventDefault();
                            bundle.install_path = e.target.value;
                            update();
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label="GIT Path"
                        value={bundle.git_path}
                        onChange={(e) => {
                            e.preventDefault();
                            bundle.git_path = e.target.value;
                            update();
                        }}
                    />
                </Grid.Col>
            </Grid>
            <Grid>
                <Grid.Col span={6}>
                    <TextInput
                        label="Build Target PC"
                        value={bundle.build_target_pc}
                        onChange={(e) => {
                            e.preventDefault();
                            bundle.build_target_pc = e.target.value;
                            update();
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label="Build Target OSX"
                        value={bundle.build_target_osx}
                        onChange={(e) => {
                            e.preventDefault();
                            bundle.build_target_osx = e.target.value;
                            update();
                        }}
                    />
                </Grid.Col>
            </Grid>
            <Grid>
                <Grid.Col span={6}>
                    <TextInput
                        label="Storage Path PC"
                        value={bundle.storage_path_pc}
                        onChange={(e) => {
                            e.preventDefault();
                            bundle.storage_path_pc = e.target.value;
                            update();
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label="Storage Path OSX"
                        value={bundle.storage_path_mac}
                        onChange={(e) => {
                            e.preventDefault();
                            bundle.storage_path_mac = e.target.value;
                            update();
                        }}
                    />
                </Grid.Col>
            </Grid>
            <Grid>
                <Grid.Col span={6}>
                    <Select
                        label={`Cutoff Version${deletedVersion?(' ⚠️'):''}`}
                        value={!!bundle.cutoff_version ? bundle.cutoff_version.toString() : '-1'}
                        onChange={(e) => {
                            if (!e) return;

                            const value = parseInt(e);

                            bundle.cutoff_version = value === -1 ? null : value;
                            update();
                        }}
                        data={data}
                    />
                </Grid.Col>
                <Grid.Col span={6}></Grid.Col>
            </Grid>
        </div>
    );
}

import React, { useRef } from 'react';
import { ShopifyStoreProductImage } from "../../../models/trans/storeItems/ShopifyStoreProductImage";

interface ShopifyStoreMediaComponentProps {
    images?: ShopifyStoreProductImage[];
    onChange?: (images: ShopifyStoreProductImage[]) => void;
}

export default function ShopifyStoreMediaComponent(
    props: ShopifyStoreMediaComponentProps
) {
    let { images, onChange } = props;
    const imageButton = useRef<HTMLInputElement | null>();

    const onImageDelete = (index: number) => {
        if (!images) return;
        images.splice(index, 1);
        onChangeInner(images);
    };

    const onChangeInner = (images: ShopifyStoreProductImage[]) => {
        images.forEach((v,index)=>{
            v.position = index+1;
        })
        if (onChange) {
            onChange(images);
        }
    };

    const imageDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const files = e.dataTransfer.files;
        const readyImages: File[] = [];
        for (let i = 0; i < files.length; i++) {
            if (isFileImage(files[i])) {
                readyImages.push(files[i]);
            }
        }

        addImage(readyImages);
    };

    const isFileImage = (file: File) => {
        return file && file.type.split('/')[0] === 'image';
    };

    const addImageList = (fileList: FileList | null) => {
        if (!fileList) return;
        const files: File[] = [];
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);
            if (file) files.push(file);
        }

        addImage(files);
    };

    const addImage = (files: File[]) => {
        let loaded = 0;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileReader = new FileReader();
            /* eslint-disable */
            fileReader.onload = (x) => {
                if (!images) {
                    images = [];
                }
                if (!x.target) return;
                const result = x.target.result;
                images.push({
                    attachment: result as string,
                });
                loaded++;
                if (loaded === files.length) {
                    onChangeInner(images);
                }
            };
            /* eslint-enable */
            fileReader.readAsDataURL(file);
        }
    };

    const setMain = (index: number) => {
        if (!images) return;

        const image = images.splice(index, 1);
        images.unshift(image[0]);
        onChangeInner(images);
    };

    return (
        <div
            className="image-wrapper"
            onDragOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
                e.dataTransfer.dropEffect = 'copy';
            }}
            onDrop={imageDrop}
        >
            <input
                ref={(x) => (imageButton.current = x)}
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={(e) => {
                    e.preventDefault();
                    addImageList(e.target.files);
                }}
            />
            <div className="image-grid">
                {images &&
                    images.map((image, index) => (
                        <div className="image-item-wrapper" key={index}>
                            <button>
                                <div className="img-wrapper">
                                    <img
                                        alt=""
                                        src={
                                            image.attachment
                                                ? image.attachment
                                                : image.src
                                        }
                                    />
                                    {/* <div className="image-item__btn-wrapper">
                                                        <button
                                                            onClick={() =>
                                                                onImageUpdate(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Update
                                                        </button> */}
                                </div>
                                <div className="overlay-btn">
                                    <input
                                        type="button"
                                        value="❌"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onImageDelete(index);
                                        }}
                                    />
                                </div>
                                {index === 0 ? (
                                    <div className="overlay-btn main is-main">
                                        <input
                                            type="button"
                                            disabled
                                            value="Main"
                                        />
                                    </div>
                                ) : (
                                    <div className="overlay-btn main">
                                        <input
                                            type="button"
                                            value="Set Main"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setMain(index);
                                            }}
                                        />
                                    </div>
                                )}
                            </button>
                        </div>
                    ))}
                <div className="upload-button-div">
                    <div className="btn-content">
                        <button
                            className="image-upload"
                            onClick={(e) => {
                                e.preventDefault();
                                imageButton.current?.click();
                            }}
                        >
                            Add Image
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

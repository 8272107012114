import type { IAuthService } from "@/services/IAuthService";
import ISecurityProvider from "../../ISecurityProvider";

export default class AccessLevelSecurityProvider implements ISecurityProvider{

    private authService: IAuthService;

    public access: string;
    public accessLevel : number;

    constructor(access:string, accessLevel:number, authService: IAuthService){
        this.access = access;
        this.accessLevel = accessLevel;
        this.authService = authService;
    }


    valid(): boolean {
        return this.authService.getAccessLevel(this.access) >= this.accessLevel;
    }
}
import { ActionIcon, Box, Flex, Select, Tooltip } from '@mantine/core';
import { IconRefresh, IconRefreshOff } from '@tabler/icons-react';
import { useInjection } from 'inversify-react';
import { IGSOService } from '@/services/IGSOService';
import { ShardStatusIndicator } from '../server-activity/shard-status/shard-status';
import { useQuery } from '@tanstack/react-query';
import IUIService from '@/services/IUIService';
import { useEffect } from 'react';

interface MonitoringHeaderProps {
    selectedShard: string;
    setSelectedShard: (shard: string) => void;
    refreshOn: boolean;
    setRefreshOn: (value: boolean) => void;
}

export const MonitoringHeader = (props: MonitoringHeaderProps) => {
    const { selectedShard, setSelectedShard, refreshOn, setRefreshOn } = props;

    const gsoService = useInjection<IGSOService>('IGSOService');

    const uiService = useInjection<IUIService>('IUIService')

    const { isLoading, data, isError, refetch } = useQuery({
        queryKey: ['shardStatus'],
        queryFn: () => gsoService.getShardStatus(selectedShard),
    });


    useEffect(()=>{
        refetch();
    },
    [selectedShard]);

    return (
        <Box px={{ sm: 'xs', xl: 'xl' }} py="xs" sx={{ borderBottom: '1px solid rgb(0 0 0 /15%)' }}>
            <Flex align="center" justify="space-between">
                <Select
                    value={selectedShard}
                    onChange={setSelectedShard}
                    data={[
                        {
                            label: 'Shard-000',
                            value: 'shard-000',
                        },
                        {
                            label: 'Shard-001',
                            value: 'shard-001',
                        },
                    ]}
                />
                <Flex gap="xs" align="center">
                    {isLoading || isError ? null : <ShardStatusIndicator changeShardStatus={async status=>{
                        uiService.showLoading();
                        await gsoService.setShardStatus(data.pipeline, status);
                        uiService.hideLoading();
                        refetch();
                    }} shardStatus={data} />}
                    <ActionIcon size="lg" color="blue" onClick={() => setRefreshOn(!refreshOn)}>
                        {refreshOn ? (
                            <Tooltip label="Disable Refresh">
                                <IconRefreshOff />
                            </Tooltip>
                        ) : (
                            <Tooltip label="Enable Refresh">
                                <IconRefresh />
                            </Tooltip>
                        )}
                    </ActionIcon>
                </Flex>
            </Flex>
        </Box>
    );
};

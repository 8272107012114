import { JWTAccessToken } from "@/models/JWTAccessToken";
import axios, { AxiosRequestConfig } from "axios";
import { injectable } from "inversify";
import GithubBranch from "../../models/devops/github/GithubBranch";
import RepositoryModelGithub from "../../models/devops/github/RepositoryModel";
import IGithubRESTClient from "../IGithubRESTClient";
import { RESTClient } from "./RESTClient";

@injectable()
export default class GithubRESTClient extends RESTClient implements IGithubRESTClient {

    private clientId: string;
    private clientSecret: string;

    private token?: string;

    constructor() {
        super();
        this.clientId = process.env.REACT_APP_GITHUB_CLIENT!;
        this.clientSecret = process.env.REACT_APP_GITHUB_SECRET!;
        this.setBaseURL('');
    }

    authenticateApp(): Promise<string | null> {
        const openUrl = `https://github.com/login/oauth/authorize?client_id=${this.clientId}&scope=repo`
        return new Promise<string | null>((resolve, reject) => {
            const newWindow = window.open(openUrl);
            if (!!newWindow) {
                window.onmessage = (e) => {
                    const { data } = e;
                    if (data.key !== 'githubToken')
                        return;

                    const token = data.value;
                    resolve(token);
                    newWindow.close();
                }
            } else {
                resolve(null);
            }
        }
        );
    }
    async getBearerToken(code: string): Promise<JWTAccessToken | null> {
        const url = `https://github.com/login/oauth/access_token?client_id=${this.clientId}&client_secret=${this.clientSecret}&code=${code}&redirect_uri=https://admin.kingdomdeath.com/githubAuth`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json'
                }
            });
            if (!!response.data.access_token) {
                this.token = response.data.access_token;
                return {
                    jwt: response.data.access_token,
                    expires: 1800,
                    refreshToken: response.data.refresh_token
                };
            }
            return null;
        } catch {
            return null;
        }
    }

    getHeader(token:string): AxiosRequestConfig {
        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                // 'X-GitHub-Api-Version': '2022-11-28'
            }
        }
    }

    async getRepos(token? : string): Promise<RepositoryModelGithub[]> {

        let finalToken = this.resolveToken(token);

        if (!finalToken)
            return [];

        const url = `https://api.github.com/user/repos?affiliation=owner,collaborator,organization_member`;

        try {
            const response = await axios.get(url, this.getHeader(finalToken));

            return response.data as RepositoryModelGithub[];
        } catch {
            return []
        }
    }

    private resolveToken(token: string | undefined) {
        let finalToken = this.token;

        if (!!token)
            finalToken = token;
        return finalToken;
    }

    async getBranches(name: string, token? : string): Promise<GithubBranch[]> {
        let finalToken = this.resolveToken(token);

        if (!finalToken)
            return [];

        
        const url = `${name}/branches`
        try {
            const response = await axios.get(url, this.getHeader(finalToken));

            return response.data as GithubBranch[];
        } catch {
            return []
        }
    }

}
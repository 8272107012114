import IGithubService from '@/services/IGithubService';
import { useInjection } from 'inversify-react';
import { useState } from 'react';
import { Button, Flex, Grid, Group, Select } from '@mantine/core';
import RepositoryModel from '../../../../models/devops/build/RepositoryModel';
import GithubBranch from '../../../../models/devops/github/GithubBranch';
import RepositoryModelGithub from '../../../../models/devops/github/RepositoryModel';
import styles from './repository-selection.module.scss';
import classes from '../../../../utils/classes';
import IUIService from '@/services/IUIService';

interface GithubRepositorySelectionProps {
    repository: RepositoryModel;
    updateModel: (repository: RepositoryModel) => void;
    select?: React.ReactNode,
    minimize?: boolean
}

export default function GithubRepositorySelection({ repository, updateModel, select, minimize }: GithubRepositorySelectionProps) {
    const githubService = useInjection<IGithubService>('IGithubService');
    const uiService = useInjection<IUIService>('IUIService')
    const [repos, setRepos] = useState<RepositoryModelGithub[]>();
    const [branches, setBranches] = useState<GithubBranch[]>([]);

    const githubAuth = async () => {
        uiService.showLoading();
        const token = await githubService.getToken();
        if (!token){
            uiService.hideLoading();
            uiService.showErrorNotification('Failed to authorize');
            return;
        }
        console.log(token);
        repository.token = token.jwt;
        const repos = await githubService.getRepos();
        setRepos(repos);

        let index = repos.findIndex(x => githubService.getFullUrl(x.full_name) === repository.base_url);

        if (index === -1) {
            repository.base_url = githubService.getFullUrl(repos[0].full_name);
            index = 0;
        }


        const branchResponse = await githubService.getBranches(repository.base_url);

        setBranches(branchResponse);

        if (branchResponse.length > 0) {
            let branchIndex = branchResponse.findIndex(x => x.name === repository.branch);

            if (branchIndex === -1) {
                branchIndex = 0;
            }

            repository.branch = branchResponse[branchIndex].name;
        }

        updateModel(repository)

        uiService.hideLoading();
    };

    return (
        <>
            <div className="row">
                <Grid>
                    <Grid.Col span={8}>
                        {select}
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Flex
                            justify="end"
                            align="flex-end"
                            direction="row"
                            wrap="nowrap"
                            mih="100%"
                            
                        >
                            <Button variant='subtle'
                                className={classes(styles.btn, styles.connectWithGithubContainer)}
                                onClick={githubAuth}
                                style={{ padding: !!minimize ? '0px' : undefined }}>
                                <div className={classes(styles.buttonContent, !!minimize ? styles.minimize : undefined)}>
                                    <div className={styles.img} />
                                    {!minimize && 'Connect'}
                                </div>
                            </Button>
                            <Button variant='subtle'
                                className={classes(styles.btn, styles.connectWithGithubContainer)}
                                onClick={e=>{e.preventDefault();window.open('https://github.com/logout')}}
                                style={{ padding: !!minimize ? '0px' : undefined }}>
                                <div className={classes(styles.buttonContent, !!minimize ? styles.minimize : undefined)}>
                                    Logout
                                </div>
                            </Button>
                        </Flex>

                    </Grid.Col>
                </Grid>

            </div>
            <div className='row'>
                <Select
                    label="Github Repository Project"
                    value={repository.base_url}
                    disabled={!repos || repos.length === 0}
                    onChange={(value) => {
                        if (!value) return;
                        repository.base_url = value;
                        githubService.getBranches(repository.base_url).then((b) => {
                            if (b.length > 0) {
                                let branchIndex = b.findIndex(x => x.name === repository.branch);

                                if (branchIndex === -1) {
                                    branchIndex = 0;
                                }

                                repository.branch = b[branchIndex].name;
                            }
                            setBranches(b);
                            updateModel(repository);

                        });
                        updateModel(repository);
                    }}
                    data={
                        !!repos && repos.length > 0
                            ? repos.map((r) => ({
                                label: r.full_name,
                                value: githubService.getFullUrl(r.full_name),
                            }))
                            : [
                                {
                                    label: repository.base_url,
                                    value: repository.base_url
                                }
                            ]
                    }
                ></Select>
            </div>
            <div className="row">
                <Select
                    label="Branch"
                    value={repository.branch}
                    disabled={!branches || branches.length === 0}
                    onChange={(value) => {
                        if (!value) return;
                        repository.branch = value;
                        updateModel(repository);
                    }}
                    data={
                        !!branches && branches.length > 0 ?
                            branches.map((b) => ({ label: b.name, value: b.name }))
                            : [
                                {
                                    label: repository.branch,
                                    value: repository.branch
                                }
                            ]
                    }
                ></Select>
            </div>
        </>
    );
}

import { CreateButton } from '../../general/create-button/create-button.component';
import IUIService from '@/services/IUIService';
import { Box } from '@mantine/core';
import { useInjection } from 'inversify-react';

interface CreateButtonProps {
    create: (name: string) => void;
}

export default function RewardsProductCreateButton(props: CreateButtonProps) {
    const { create } = props;
    const uiService = useInjection<IUIService>('IUIService');

    return (
        <Box px="sm" mt="sm">
            <CreateButton
                title="New Rewards"
                click={async () => {
                    const name = await uiService.showNewVersionDialog('New Reward', 'Reward Title');
                    if (name) {
                        create(name);
                    }
                }}
            />
        </Box>
    );
}

import Nullable from "@/dataTypes/Nullable";
import { Reward } from "../../models/treasury/rewards/Reward";
import RewardsList from "../../models/treasury/rewards/RewardList";
import ITreasuryRESTClient from "../ITreasuryRESTClient";
import { RESTClient } from "./RESTClient";
import type { IAuthService } from "../../services/IAuthService";
import { inject, injectable } from "inversify";
import axios from 'axios';
import CustomerList from "../../models/treasury/pledges/CustomerList";
import Pledge from "@/models/treasury/pledges/Pledge";
import OrderHistoryWithName from "@/models/treasury/orderHistory/OrderHistoryWithName";
import ShopifyDiagnosticsItem from "@/models/treasury/ShopifyDiagnosticsItem";

@injectable()
export default class TreasuryRESTClient extends RESTClient implements ITreasuryRESTClient{
    @inject('IAuthService')
    private authService!: IAuthService;

    constructor() {
        super();

        this.setBaseURL('https://wod-treasury-uux56memxa-uc.a.run.app/api/v1/');
    }
   


    async getRewards(page: number, search: string): Promise<Nullable<RewardsList>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return null;

        const url = this.getFullURL(`rewards?page=${page}&query=${search}`);

        try{
            const response = await axios.get(url, this.getHeader(treasuryToken));
            const list = response.data as RewardsList;

            let result = new RewardsList();
            result.items = list.items;
            result.max_results = list.max_results;

            return result;
        }
        catch{
            return null;
        }
    }

    async getAllRewards(): Promise<Nullable<RewardsList>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return null;

        const url = this.getFullURL(`rewards?page=${1}&perPage=${10000}`);

        try{
            const response = await axios.get(url, this.getHeader(treasuryToken));
            const list = response.data as RewardsList;

            let result = new RewardsList();
            result.items = list.items;
            result.max_results = list.max_results;

            return result;
        }
        catch{
            return null;
        }
    }

    async getReward(id: number): Promise<Nullable<Reward>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return null;

        const url = this.getFullURL(`reward/${id}`);

        
        try{
            const response = await axios.get(url, this.getHeader(treasuryToken));
            return response.data as Reward;
        }
        catch{
            return null;
        }
    }

    
    async createReward(reward: Reward): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return false;

        const url = this.getFullURL(`reward`);

        try{
            await axios.post(url, JSON.stringify(reward), this.getHeaderJson(this.getHeader(treasuryToken)));
            return true;
        }
        catch {
            return false;
        }
    }

    async updateReward(reward: Reward): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return false;

        const url = this.getFullURL(`reward`);

        try{
            await axios.put(url, JSON.stringify(reward), this.getHeaderJson(this.getHeader(treasuryToken)));
            return true;
        }
        catch {
            return false;
        }
    }

    async deleteReward(id: number): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return false;

        const url = this.getFullURL(`reward/${id}`);

        
        try{
            await axios.delete(url, this.getHeader(treasuryToken));
            return true;
        }
        catch{
            return false;
        }       
    }
    
    async uploadRewardImage(id: number, image: File) : Promise<Nullable<string>>{
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return null;

        const url = this.getFullURL(`reward/${id}/image`);

        const formData = new FormData();
        formData.set("file", image);
        
        try{
            const response = await axios.putForm(url, formData, this.getHeader(treasuryToken));
            return response.data;
        }
        catch{
            return null;
        }       
    }

    async deleteRewardImage(id: number): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return false;

        const url = this.getFullURL(`reward/${id}/image`);

        
        try{
            await axios.delete(url, this.getHeader(treasuryToken));
            return true;
        }
        catch{
            return false;
        }       
    }


    async getCustomers(page: number, search: string): Promise<Nullable<CustomerList>> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return null;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return null;

        const url = this.getFullURL(`customers?page=${page}&query=${search}`);

        try{
            const response = await axios.get(url, this.getHeader(treasuryToken));
            const list = response.data as CustomerList;

            let result = new CustomerList();
            result.emails = list.emails;
            result.max_results = list.max_results;

            return result;
        }
        catch{
            return null;
        }
    }

    async getCustomerPledges(email : string) : Promise<Pledge[]> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return [];

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return [];

        const url = this.getFullURL(`customer/${email}/pledges`);

        try{
            const response = await axios.get(url, this.getHeader(treasuryToken));
            const list = response.data as Pledge[];
            return list;
        }
        catch{
            return [];
        }
    }

    async createPledge(pledge: Pledge) : Promise<boolean>{
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return false;

        const url = this.getFullURL(`pledge`);

        try{
            const response = await axios.post(url, JSON.stringify(pledge), this.getHeaderJson(this.getHeader(treasuryToken)));
            return true;
        }
        catch{
            return false;
        }
    }

    async savePledge(pledge: Pledge) : Promise<boolean>{
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return false;

        const url = this.getFullURL(`pledge`);

        try{
            const response = await axios.put(url, JSON.stringify(pledge), this.getHeaderJson(this.getHeader(treasuryToken)));
            return true;
        }
        catch{
            return false;
        }
    }

    async deletePledge(id: number): Promise<boolean> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return false;

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return false;

        const url = this.getFullURL(`pledge/${id}`);

        try{
            const response = await axios.delete(url, this.getHeader(treasuryToken));
            return true;
        }
        catch{
            return false;
        }
    }

    async getOrderHistory(iss: string): Promise<OrderHistoryWithName[]> {
        const authToken = await this.authService.getToken();
        if (authToken === null) return [];

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return [];

        const url = this.getFullURL(`customer/${iss}/orders`);

        try{
            const response = await axios.get(url, this.getHeader(treasuryToken));
            return response.data as OrderHistoryWithName[];
        }
        catch{
            return [];
        }
    }

    async diagnose(): Promise<ShopifyDiagnosticsItem[]>{
        const authToken = await this.authService.getToken();
        if (authToken === null) return [];

        const treasuryToken = await this.getToken(authToken);

        if (treasuryToken === null) return [];

        const url = this.getFullURL(`diagnostics/shopify-items`);

        try{
            const response = await axios.get(url, this.getHeader(treasuryToken));
            return response.data as ShopifyDiagnosticsItem[];
        }
        catch{
            return [];
        }
    }
}
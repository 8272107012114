import { inject, injectable } from "inversify";
import { ProductKeyList } from "../../models/trans/productKeys/ProductKeyCollection";
import type ITransRESTClient from "../../rest-clients/ITransRESTClient";
import IProductKeyService from "../IProductKeyService";
import type { IUserValidator } from "../IUserValidator";

@injectable()
export default class ProductKeyService implements IProductKeyService{
    @inject('ITransRESTClient')
    private transRESTClient! : ITransRESTClient;

    @inject('IUserValidator')
    private userValidatorService! : IUserValidator;
    
    async generateProductKey(shopifyProductId: string, count: number, quantity?: number): Promise<ProductKeyList | null> {
        return await this.transRESTClient.getProductKey(shopifyProductId, count, quantity);
    }

    async addCustomerProductKey(iss:string, variantId: number, quantity: number) : Promise<boolean>{
        return await this.transRESTClient.addCustomerProductKey(iss,variantId, quantity)
    }

    async activateCustomerProductKey(iss: string, productKey: string): Promise<boolean>{
        return await this.transRESTClient.activateCustomerProductKey(iss, productKey)
    }

    async processEmailList(emailList: string, variantId : string, autoConsume: boolean, allEmails : boolean):Promise<boolean>{
        const emails = emailList.split('\n');
        const emailsFiltered : string[] = []
        
        emails.forEach(email=>{
            if(this.userValidatorService.validateEmail(email))
                emailsFiltered.push(email)
        });
        
        return await this.transRESTClient.processEmailBatch(emails, variantId, autoConsume, allEmails);
    }

    async sendInvitesToEmails(emailList: string, variantId : string):Promise<boolean>{
        const emails = emailList.split('\n');
        const emailsFiltered = []
        
        emails.forEach(email=>{
            if(this.userValidatorService.validateEmail(email))
                emailsFiltered.push(email)
        });


        return await this.transRESTClient.sendInvitesToEmails(emails, variantId);
    }
}
import IBuildRepositoryService from '@/services/IBuildRepositoryService';
import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { Select, ActionIcon, Flex, Menu, Box } from '@mantine/core';
import { FiSettings } from 'react-icons/fi';
import styles from './devops-top-dropdown.module.scss';
import { useNavigate } from 'react-router';
import { setCategory } from '../../../redux/slices/devopsCategorySlice';
import store from '../../../redux/store';

interface DevOpsTopDropdownProps {
    productChanged: (product: string) => void;
    product: string;
    onAddClick?: () => void;
    hideSettings?:boolean
}

export default function DevOpsTopDropdown({ product, productChanged, onAddClick, hideSettings }: DevOpsTopDropdownProps) {

    const buildRepositoryService = useInjection<IBuildRepositoryService>('IBuildRepositoryService');

    const [options, setOptions] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(
        function () {
            const getData = async function () {
                const repositories = await buildRepositoryService.getRepositories();
                if (!!repositories) {
                    setOptions(repositories);
                }
            };
            getData();
        },
        [product]
    );

    return (
        <Box pos="sticky" top={0} bg="#fff" className={styles.topBar} sx={{ zIndex: 10 }}>
            <Flex ml="sm" h="100%" align="center" gap="5px">
                <Select
                    value={product}
                    onChange={(value) => {
                        if (!value) return;
                        store.dispatch(setCategory(value));
                        productChanged(value);
                    }}
                    data={options.map((o) => ({ label: buildRepositoryService.getRepositoryName(o), value: o }))}
                />

                {!hideSettings && <Menu width={100} withArrow withinPortal position="bottom-end">
                    <Menu.Target>
                        <ActionIcon>
                            <FiSettings />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item
                            onClick={() => {
                                navigate(`/devops/product/${product}`);
                            }}
                        >
                            Edit
                        </Menu.Item>
                        <Menu.Item onClick={onAddClick}>Add</Menu.Item>
                    </Menu.Dropdown>
                </Menu>}
            </Flex>
        </Box>
    );
}

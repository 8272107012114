export default class AsyncImage{

    private image!: HTMLImageElement;

    constructor(width: number, height: number){
        this.image = new Image(width, height);
    }

    public setSize(width: number, height: number){
        this.image = new Image(width, height);
    }

    public setSrc(src: string) : Promise<HTMLImageElement | null>{
        const promise = new Promise<HTMLImageElement | null>((resolve,reject)=>{
            this.image.onload = ()=>{
                resolve(this.image);
            }
            this.image.onerror = ()=>{
                reject();
            }
            this.image.src = src;
        })
        return promise;
    }
}
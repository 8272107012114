import { Container, Grid } from '@mantine/core';
import { useInjection } from 'inversify-react';
import { useState, useEffect } from 'react';
import type IMotdService from '../../../services/IMotdService';
import type IUIService from '../../../services/IUIService';
import DefaultListOptionView from '../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../general/list-view/list-view.component';
import { MOTDTemplateForm } from '../motd-template-form/motd-template-form.component';
import Nullable from '@/dataTypes/Nullable';
import { MotdTemplateList } from '../../../models/devops/MOTD/MotdTemplateList';
import { MotdTemplate } from '../../../models/devops/MOTD/MotdTemplate';
import { CreateMotdTemplate } from '../create-motd-template/create-mord-template.component';

interface MOTDTemplateDirectoryListState {
    itemList: MotdTemplateList | null;
    page: number;
    selectedId: number;
}

const MOTDTemplateDirectory = () => {
    const [motdTemplateList, setMotdTemplateList] = useState<MOTDTemplateDirectoryListState>({
        itemList: null,
        page: 1,
        selectedId: -1,
    });

    const motdService = useInjection<IMotdService>('IMotdService');
    const uiService = useInjection<IUIService>('IUIService');

    const [template, setTemplate] = useState<Nullable<MotdTemplate>>(null);

    const [updateEnabled, setUpdateEnabled] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async (forceSelectFirst: boolean = false) => {
        uiService.showLoading();
        let data = await motdService.getMotdTemplatesList(motdTemplateList.page);

        uiService.hideLoading();
        if (!data) {
            const data = new MotdTemplateList();
            data.items = [];
            setMotdTemplateList((state) => ({
                ...state,
                itemList: data,
                selectedId: -1,
            }));
            uiService.showErrorNotification('No message data');
            return;
        }

        if (data && data.items.length === 0) {
            setMotdTemplateList((state) => ({
                ...state,
                itemList: data,
                selectedId: -1,
            }));
            return;
        }

        if (!data || data.items.length < 1) return;

        const dataWithoutDefaultTemplate = new MotdTemplateList();
        dataWithoutDefaultTemplate.items = data.items.filter((i) => i.id !== 1);

        const setToFirst = (motdTemplateList.selectedId === -1 && data.items.length > 0) || forceSelectFirst;
        const firstId = dataWithoutDefaultTemplate.items[0].id;

        setMotdTemplateList((state) => ({
            ...state,
            itemList: dataWithoutDefaultTemplate,
            selectedId: setToFirst ? firstId : state.selectedId,
        }));
        if (setToFirst) {
            onListSelectionChanged(firstId);
        } else {
            onListSelectionChanged(motdTemplateList.selectedId);
        }
    };

    const onListPageChanged = async (page: number) => {
        setMotdTemplateList((state) => ({
            ...state,
            page: page,
        }));
        await getData();
    };

    const onListSelectionChanged = async (selectedId: number) => {
        if (selectedId === -1) {
            return;
        }

        setMotdTemplateList((state) => ({
            ...state,
            selectedId: selectedId,
        }));

        uiService.showLoading();
        const template = await motdService.getMotdTemplate(selectedId);
        uiService.hideLoading();

        if (!template) {
            uiService.showErrorNotification('Failed to load the message!');
            return;
        }
        const sortedTemplate: MotdTemplate = {
            ...template,
            variables: !!template.variables ? template.variables.sort((a, b) => a.id - b.id) : [],
        };
        setTemplate(sortedTemplate);
        setUpdateEnabled(false);
    };

    const onSaveMotdTemplate = async () => {
        if (!template) return;
        uiService.showLoading();
        const newTemplate: MotdTemplate = {
            ...template,
            variables: template.variables.map((v) => ({ ...v, id: v.id < 0 ? 0 : v.id })),
        };
        const response = await motdService.updateMotdTemplate(newTemplate);

        uiService.hideLoading();

        if (response) {
            uiService.showSuccessNotification('Message updated successfully!');
            getData(false);
            return;
        } else {
            uiService.showErrorNotification('Error when updating message!');
        }
    };

    const onDeleteMotdTemplate = async () => {
        if (!template) return;

        const prompt = await uiService.showConfirmationDialog(
            'Are you sure you want to delete the template?',
            'No',
            'Yes'
        );

        if (!prompt) return;

        uiService.showLoading();
        const response = await motdService.deleteMotdTemplate(template.id);
        uiService.hideLoading();

        if (response) {
            uiService.showSuccessNotification('Message deleted successfully!');
            getData(true);
            return;
        } else {
            uiService.showErrorNotification('Error when deleting the message!');
        }
    };

    const renderList = () => {
        const { itemList, page, selectedId } = motdTemplateList;

        return (
            <Container px={0} py="sm">
                <ListViewComponent
                    elements={itemList}
                    page={page}
                    pageChanged={onListPageChanged}
                    selectionChanged={onListSelectionChanged}
                    selectedValue={selectedId}
                    listElement={(entry, selectionChanged, inSelect) => {
                        const { name } = entry;
                        return (
                            <DefaultListOptionView
                                key={entry.id}
                                value={name}
                                id={entry.id}
                                inSelectContainer={inSelect}
                                selectionChange={selectionChanged}
                                selected={entry.id === selectedId}
                            />
                        );
                    }}
                ></ListViewComponent>
            </Container>
        );
    };

    const renderMotdTemplateForm = () => {
        if (motdTemplateList.selectedId === -1) return null;
        return (
            <MOTDTemplateForm
                saveMotdTemplate={onSaveMotdTemplate}
                updateEnabled={updateEnabled}
                updateMotdTemplate={(template) => {
                    setTemplate(template);
                    setUpdateEnabled(true);
                }}
                template={template}
                selectedMotdId={motdTemplateList.selectedId}
                deleteMotdTemplate={onDeleteMotdTemplate}
            />
        );
    };

    return (
        <>
            <Grid gutter={0} w="calc(100vw - 250px)">
                <Grid.Col span="content">{renderList()}</Grid.Col>
                <Grid.Col span="auto">{renderMotdTemplateForm()}</Grid.Col>
            </Grid>
            <CreateMotdTemplate getData={getData} uiService={uiService} motdService={motdService} />
        </>
    );
};

export default MOTDTemplateDirectory;

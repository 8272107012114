import IList from '../IList';
import { ScopeEntry } from './ScopeEntry';

export class ScopeList implements IList<ScopeEntry> {
    public max_results!: number;
    public scopes!: ScopeEntry[];

    getItems(): ScopeEntry[] {
        return this.scopes;
    }
}

import { injectable } from 'inversify'
import ValidationMessage from '../../models/ValidationMessage'
import { IUserValidator } from '../IUserValidator'

@injectable()
export class UserValidator implements IUserValidator {
    validatePasswordConfirm(
        password: string,
        passwordConfirm: string
    ): ValidationMessage {
        if (password !== passwordConfirm) {
            return {
                isSuccess: false,
                message: 'Passwords do not match',
            }
        }

        return {
            isSuccess: true,
            message: '',
        }
    }

    validateEmail(email: string): ValidationMessage {
        const regex = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )

        if (regex.test(email)) {
            return {
                isSuccess: true,
                message: '',
            }
        }

        return {
            isSuccess: false,
            message: 'Enter a valid email!',
        }
    }

    validatePassword(
        password: string,
        passwordConfirm: string
    ): ValidationMessage {
        if (password !== passwordConfirm) {
            return {
                isSuccess: false,
                message: 'Passwords do not match!',
            }
        }

        const passwordValidator = new RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[_@$!%*?&#])[A-Za-z\\d_@$!%*?&#]{8,}$'
        )

        if (passwordValidator.test(password)) {
            return {
                isSuccess: true,
                message: '',
            }
        }

        return {
            isSuccess: false,
            message:
                'Password should be at least 8 characters in length and should include at least one upper case letter, one number, and one special character.',
        }
    }
}

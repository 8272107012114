import { useNavigate, useParams } from "react-router";
import DevOpsTopDropdown from "../../devops-top-dropdown/devops-top-dropdown.component";
import ProductForm from "../product-form/product-form.component";

export default function ProductCPDirectory(){
    const {product} = useParams();
    const navigate = useNavigate();

    return (
        <>
            <DevOpsTopDropdown hideSettings product={product!} productChanged={p=>{
                navigate(`/devops/product/${p}`)
            }}/>
            {product && <ProductForm product={product}/>}
        </>
    )
}

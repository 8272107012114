import { inject, injectable } from "inversify";
import IViewFactoryService from "../IViewFactoryService";
import navigation from '../../../data/navigation.json'
import NavigationHeader from "@/models/navigation/NavigationHeader";
import type IScopeRepositoryService from "@/services/repositories/IScopeRepositoryService";
import NavigationElement from "@/models/navigation/NavigationElement";
import type { IAuthService } from "@/services/IAuthService";
import NavigationComponentSecurityProviderPayload from "@/models/navigation/NavigationComponentSecurityProviderPayload";
import ISecurityProvider from "@/models/security/ISecurityProvider";
import type ISecurityProviderFactoryService from "../ISecurityProviderFactoryService";
import SecurityProviderAnd from "../../../models/security/Implementations/Logical/SecurityProviderAnd";

@injectable()
export default class ViewFactoryService implements IViewFactoryService{
    @inject('IScopeRepositoryService')
    private scopeRepositoryService! : IScopeRepositoryService;

    @inject('IAuthService')
    private authService! : IAuthService;

    @inject('ISecurityProviderFactoryService')
    private securityProviderFactoryService! : ISecurityProviderFactoryService;

    getSideNavRoutes() : NavigationHeader[]{
        const navigationHeader : NavigationHeader[] = []

        navigation.data.forEach(header => {
            const elements : NavigationElement[] = []
            header.elements.forEach(element=>{
                let visible = true;
                if(!!element.hideInMenu)
                    return;
                element.access.forEach(access=>{
                    if(access.key==='_accessLevel' && !!access.access && !!access.accessLevel){
                        const result = this.authService.getAccessLevel(access.access)>=access.accessLevel;
                        visible = visible && result;
                    }
                    if(access.key==='_scope' && !!(access as any).scopes){
                        const result = (access as any).scopes.includes(this.scopeRepositoryService.getLoggedInUserScope()!);
                        console.log('scope result ' + result);
                        visible = visible && result;
                    }
                })
                
                if(visible || 
                    (!!process.env.REACT_APP_TOTAL_ACCESS && process.env.REACT_APP_TOTAL_ACCESS==='true')){
                    elements.push(
                        {
                            route : element.route,
                            title : element.title,
                            regex : element.regex
                        }
                    )
                }
            });

            if(elements.length>0){
                navigationHeader.push({
                    elements : elements,
                    icon : header.icon,
                    title : header.title
                })
            }
        });

        console.log(navigationHeader);
        return navigationHeader;
    }

    getNavRoutesComponents() : NavigationComponentSecurityProviderPayload[]{
        const navigationComponents : NavigationComponentSecurityProviderPayload[] = []

        navigation.data.forEach(header => {
            header.elements.forEach(element=>{
                const securityProviders : ISecurityProvider[] = [];
                
                element.access.forEach(access=>{
                    if(access.key==='_accessLevel' && !!access.access && !!access.accessLevel){
                        securityProviders.push(this.securityProviderFactoryService.createAccessLevel(access.access, access.accessLevel));
                    }
                    if(access.key==='_scope' && !!(access as any).scopes){
                        securityProviders.push(this.securityProviderFactoryService.createScope((access as any).scopes));
                    }
                })

                let securityProvider : ISecurityProvider|null = null;
                
                if(securityProviders.length>0){
                    securityProvider = new SecurityProviderAnd(...securityProviders);
                }

                navigationComponents.push({
                    component : element.component,
                    route : element.route,
                    securityProvider : securityProvider
                })
            });

        });
        return navigationComponents;
    }

    getFirstAvailable() : string {
        const sideNavRoutes = this.getSideNavRoutes();
        console.log(sideNavRoutes);
        if(sideNavRoutes.length>0 && sideNavRoutes[0].elements.length>0)
            return sideNavRoutes[0].elements[0].route;
        
        return '';
    }
} 

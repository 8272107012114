import Nullable from '@/dataTypes/Nullable';
import TopicList from '@/models/mail/TopicList';
import { IMailRESTClient } from '@/rest-clients/IMailRESTClient';
import IUIService from '@/services/IUIService';
import { Flex, Grid } from '@mantine/core';
import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import MailTopicList from './mail-topic-list/mail-topic-list.component';
import MailTopicForm from './mail-topic-form/mail-topic-form.component';

export default function MailIntegrationDirectory() {
    const [topics, setTopics] = useState<Nullable<TopicList>>(null);
    const mailRestClient = useInjection<IMailRESTClient>('IMailRESTClient');
    const uiService = useInjection<IUIService>('IUIService');
    const [page, setPage] = useState(1);
    const [selectedTopic, setSelectedTopic] = useState<Nullable<string>>(null);

    const [subscribers, setSubscribers] = useState<string[]>([]);

    const getItems = async (forceSelectFirst?: boolean) => {
        uiService.showLoading();
        const response = await mailRestClient.getTopicsList(page);
        uiService.hideLoading();
        if (!response) {
            uiService.showErrorNotification('Failed to get topic list');
        } else {
            setTopics(response);
            if (!!forceSelectFirst) {
                setSelectedTopic(response!.getItems()[0].name);
            }
        }
    };

    const getSubscribers = async () => {
        if (!selectedTopic) {
            return;
        }
        uiService.showLoading();
        const response = await mailRestClient.getTopicSubscribers(selectedTopic);
        uiService.hideLoading();
        

        setSubscribers(response);
    };

    useEffect(() => {
        getItems(true);
    }, []);

    useEffect(() => {
        getItems(true);
    }, [page]);

    useEffect(() => {
        getSubscribers();
    }, [selectedTopic]);

    const createTopic = async (topicName: string)=>{
        uiService.showLoading();
        const response = await mailRestClient.createTopic(topicName)
        uiService.hideLoading();

        if(!response){
            uiService.showErrorNotification("Failed to create topic")
            return;
        }

        getItems(true);
    }

    const deleteTopic = async (name:string)=>{
        if(!selectedTopic){
            return;
        }
        uiService.showLoading();
        const response = await mailRestClient.deleteTopic(name)
        uiService.hideLoading();

        if(!response){
            uiService.showErrorNotification("Failed to delete topic")
            return;
        }

        getItems(true);
    }

    const deleteSubscriber = async (email: string)=>{
        if(!selectedTopic){
            return;
        }
        uiService.showLoading();
        const response = await mailRestClient.deleteSubscription(email,selectedTopic)
        uiService.hideLoading();

        if(!response){
            uiService.showErrorNotification("Failed to delete subscriber")
            return;
        }

        getSubscribers();
    }

    const addSubscriber = async (email: string)=>{
        if(!selectedTopic){
            return;
        }
        uiService.showLoading();
        const response = await mailRestClient.subscribeToTopic(email,selectedTopic)
        uiService.hideLoading();

        if(!response){
            uiService.showErrorNotification("Failed to add subscriber")
            return;
        }

        getSubscribers();
    }

    const renderList = () => {
        return (
            <MailTopicList
                page={page}
                onPageChange={(p) => {
                    setPage(p);
                }}
                canCreate={true}
                create={createTopic}
                delete={deleteTopic}
                topicList={topics}
                selectedId={selectedTopic ?? ''}
                selectionChange={(e) => {
                    setSelectedTopic(e);
                }}
            />
        );
    };

    return (
        <Grid w="calc(100vw - 250px)">
            <Grid.Col span="content">{renderList()}</Grid.Col>
            <Grid.Col span="auto">
                <MailTopicForm 
                subscribers={subscribers} 
                deleteSubscriber={deleteSubscriber} 
                addSubscriber={addSubscriber}/>
            </Grid.Col>
        </Grid>
    );
}

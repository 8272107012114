import UnityProjectPayload from "@/models/devops/unity/UnityProjectPayload";
import type { IDevOpsRESTClient } from "@/rest-clients/IDevOpsRESTClient";
import { inject, injectable } from "inversify";
import IUnityService from "../IUnityService";

@injectable()
export default class UnityService implements IUnityService{
   
    @inject('IDevOpsRESTClient')
    private devopsRESTClient! : IDevOpsRESTClient;

    private baseUnity = 'https://build-api.cloud.unity3d.com';
    
    async getUnityBuild() : Promise<UnityProjectPayload[]>{
        return await this.devopsRESTClient.getUnityBuild();
    }

    getFullBuildUrl(projectPayload: UnityProjectPayload): string {
        return this.baseUnity + projectPayload.links.self.href;
    }
}
import { resolve } from 'inversify-react';
import React from 'react';
import type IStoreItemService from '../../../services/IStoreItemService';
import type IUIService from '../../../services/IUIService';
import StoreProductForm from '../store-product-form/store-product-form.component';
import './store-product-directory.styles.scss';
import AssetList from '../../../models/trans/AssetList';
import type ITransService from '../../../services/ITransService';
import StoreProductCreateButton from '../store-product-create/store-product-create.component';
import StoreItemList from '../store-product-list/store-product-list.component';
import StoreProduct from '@/models/trans/storeProducts/StoreProduct';
import StoreProductList from '@/models/trans/storeProducts/StoreProductList';
import { Grid } from '@mantine/core';
import Nullable from '@/dataTypes/Nullable';
import type IAssetGroupService from '@/services/IAssetGroupService';
import AssetGroupList from '@/models/trans/asset-group/AssetGroupList';
import StoreProductVariantList from '@/models/trans/storeProducts/StoreProductVariantList';

interface StoreItemDirectoryListState {
    itemList: StoreProductList | null;
    page: number;
    selectedId: number | null;
    searchQuery : string
}

interface StoreItemDirectoryState {
    list: StoreItemDirectoryListState;
    selectedValue: StoreProduct | null;
    saveEnabled: boolean;
    assets: AssetList | null;
    assetGroups : Nullable<AssetGroupList>,
    variants : Nullable<StoreProductVariantList>
}

export default class StoreItemDirectory extends React.Component<
    {},
    StoreItemDirectoryState
> {
    @resolve('IStoreItemService')
    private readonly storeItemService!: IStoreItemService;

    @resolve('ITransService')
    private readonly transService!: ITransService;

    @resolve('IAssetGroupService')
    private readonly assetGroupService!: IAssetGroupService;

    @resolve('IUIService')
    private readonly uiService!: IUIService;

    constructor(props: {}) {
        super(props);

        this.state = {
            list: {
                itemList: null,
                selectedId: null,
                page: 1,
                searchQuery: ''
            },
            selectedValue: null,
            saveEnabled: false,
            assets: null,
            assetGroups: null,
            variants : null
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (
        forceSelectFirst: boolean = false,
        selectedId?: number
    ) => {
        this.uiService.showLoading();
        const data = await this.storeItemService.getList(1, this.state.list.searchQuery);
        const assets = await this.transService.getAllAssets();
        const assetGroups = await this.assetGroupService.getAssetGroupList(1);
        const variants = await this.storeItemService.getAllVariants();
        this.uiService.hideLoading();

        if (!data) {
            this.uiService.showErrorNotification(
                'Failed to fetch store item data'
            );
            return;
        }

        let setToFirst =
            (this.state.list.selectedId === null && data.items.length > 0) ||
            forceSelectFirst;

        if (selectedId) {
            setToFirst = false;
        }

        if (!data.items || !data.items[0]) {
            if (assets) {
                this.setState({
                    ...this.state,
                    assets: assets,
                    assetGroups: assetGroups,
                    list: {
                        ...this.state.list,
                        itemList: data,
                    },
                    variants : variants
                });
            }
            return;
        }

        const firstId = data.items[0].id;

        const finalSelection = setToFirst
            ? firstId
            : selectedId
                ? selectedId
                : this.state.list.selectedId;

        this.setState(
            {
                ...this.state,
                list: {
                    ...this.state.list,
                    itemList: data,
                    selectedId: finalSelection,
                },
                assets: assets,
                assetGroups: assetGroups,
                variants: variants
            },
            () => {
                if (setToFirst) {
                    this.onListSelectionChanged(firstId);
                }
                if (selectedId) {
                    this.onListSelectionChanged(selectedId);
                }
            }
        );
    };

    onListPageChanged = (page: number) => {
        this.setState(
            {
                ...this.state,
                list: {
                    ...this.state.list,
                    page: page,
                },
            },
            () => {
                this.getData();
            }
        );
    };

    onListSelectionChanged = async (selectedId: number) => {
        this.setState(
            {
                ...this.state,
                list: {
                    ...this.state.list,
                    selectedId: selectedId,
                },
            },
            () => {
                this.uiService.showLoading();
                this.storeItemService
                    .getItem(selectedId)
                    .then((response) => {
                        if (response === null) {
                            this.uiService.showErrorNotification(
                                'Failed to get store item data'
                            );
                            this.uiService.hideLoading();
                            return;
                        }

                        this.setState({
                            ...this.state,
                            selectedValue: response,
                            saveEnabled: false,
                            list: {
                                ...this.state.list,
                                selectedId: selectedId,
                            }
                        });
                        this.uiService.hideLoading();
                    })
                    .catch(() => {
                        this.uiService.hideLoading();
                    });
            }
        );
    };

    storeItemCreate = async (name: string) => {
        this.uiService.showLoading();
        const product: StoreProduct = {
            id: 0,
            title: name,
            shopify_product_id: 0,
            variants: [],
            is_shopify_linked : false,
            product_tag: null
        };
        const response = await this.storeItemService.createItem(product);
        if(!!response){
            this.uiService.showSuccessNotification('Store product created successfuly');
            this.setState({
                ...this.state,
                selectedValue: response,
                saveEnabled: true,
                list:{
                    ...this.state.list,
                    selectedId : response.id
                }
            }, ()=>this.getData(false));
        }else{
            this.uiService.showErrorNotification('Failed to create a store product!')
        }
        this.uiService.hideLoading();
     
    };

    storeItemUpdate = (sig: StoreProduct) => {
        this.setState({
            ...this.state,
            selectedValue: sig,
            saveEnabled: true,
        });
    };

    onRefresh = () => {
        this.onListSelectionChanged(this.state.list.selectedId!);
    };

    refreshListClicked = () => {
        this.getData(false);
    };

    storeItemSave = async () => {
        const { selectedValue } = this.state;
        if (!selectedValue) return;
        this.uiService.showLoading();
        
        let response : Nullable<StoreProduct> = null
        if (selectedValue.id !== 0)
            response = await this.storeItemService.updateItem(selectedValue);
        else response = await this.storeItemService.createItem(selectedValue);
        this.uiService.hideLoading();
        if (!!response) {
            this.uiService.showSuccessNotification('Item save successfuly!');
            this.setState({
                ...this.state,
                selectedValue : response,
                saveEnabled : false
            }, ()=>{
                this.getData(false);
            });
            
            return;
        } else {
            this.uiService.showErrorNotification('Saving item failed!');
            return;
        }
    };

    storeItemDelete = async () => {
        const { selectedValue } = this.state;
        if (!selectedValue) return;
        this.uiService.showLoading();
        const response = await this.storeItemService.deleteItem(
            selectedValue.id
        );
        this.uiService.hideLoading();
        if (response) {
            this.uiService.showSuccessNotification('Item deleted successfuly!');
            this.getData(true);
            return;
        } else {
            this.uiService.showErrorNotification('Deleting item failed!');
            return;
        }
    };

    renderList() {
        const { list } = this.state;
        return (
            <StoreItemList
                canCreate
                create={this.storeItemCreate}
                onPageChange={this.onListPageChanged}
                page={list.page}
                selectedId={list.selectedId!}
                selectionChange={this.onListSelectionChanged}
                versionList={list.itemList}
                searchChange={q=>{
                    this.setState({
                        ...this.state,
                        list : {
                            ...this.state.list,
                            searchQuery : q
                        }
                    }, ()=>this.getData(true))
                }}
            />
        );
    }

    render(): React.ReactNode {
        return (
            <Grid w="calc(100vw - 250px)">
                <Grid.Col span="content">{this.renderList()}</Grid.Col>
                <Grid.Col span="auto">{this.renderProductForm()}</Grid.Col>
            </Grid>
        );
    }

    renderProductForm() {
        if (!this.state.selectedValue || !this.state.assets || !this.state.assetGroups || !this.state.variants) return;
        return (
            <StoreProductForm
                product={this.state.selectedValue}
                assets={this.state.assets}
                assetGroupList={this.state.assetGroups}
                updateProduct={this.storeItemUpdate}
                saveEnabled={this.state.saveEnabled}
                variants={this.state.variants}
                saveProduct={this.storeItemSave}
                deleteProduct={this.storeItemDelete}
            />
        );


    }

    renderTopButton() {
        return (
            <div className="refresh-button">
                <input
                    className="refresh"
                    type="button"
                    value="Refresh"
                    onClick={this.refreshListClicked}
                ></input>
                <StoreProductCreateButton create={this.storeItemCreate} />
            </div>
        );
    }
}

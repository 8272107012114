import { ShardStatus } from '../../models/gso/shard/ShardStatus';
import axios, { AxiosError } from 'axios';
import { inject } from 'inversify';

import type { IAuthService } from '../../services/IAuthService';
import { IGSORESTClient } from '../IGSORESTClient';
import { RESTClient } from './RESTClient';
import Nullable from '@/dataTypes/Nullable';

export default class GSORESTClient extends RESTClient implements IGSORESTClient {
    
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    constructor() {
        super();
        this.setBaseURL('https://wod-gso-uux56memxa-uc.a.run.app/api/v1/');
    }

    async getShardStatus(shardName: string): Promise<ShardStatus> {
        const token = await this.authService.getToken();
        if (token === null) {
            throw new Error('UnAuthorized');
        }

        const authToken = await this.getToken(token);

        const path = this.getFullURL(`shard/${shardName}/status`);
        const response = await axios.get(path, this.getHeader(authToken));
        return response.data as ShardStatus;
    }

    async setShardStatus(pipeline: string, status: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) {
            return false;
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            return false;
        }

        const path = this.getFullURL(`pipeline/${pipeline}/status/${status}`);
        try{
            await axios.put(path, null, this.getHeader(authToken));
            return true;
        }
        catch{
            return false;
        }
    }

    async forceStopTheGameRoom(accessToken : string): Promise<Nullable<string>> {
        const token = await this.authService.getToken();
        if (token === null) {
            return 'Unexpected Error Message!';
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            return 'Unexpected Error Message!';
        }

        const path = this.getFullURL(`room/${accessToken}/force`);
        try{
            await axios.delete(path, this.getHeader(authToken));
            return null;
        }
        catch (e){
           const error = e as AxiosError;
           if(!!error.response){
                const data = error.response.data as any;
                if(!!data.errorMessage){
                    return data.errorMessage;
                }
           }
           return 'Unexpected Error Message!'
        }
    }
}

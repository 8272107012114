import { inject, injectable } from 'inversify';
import Motd, { MotdVariable } from '../../models/devops/MOTD/Motd';
import MotdList from '../../models/devops/MOTD/MotdList';
import type { IDevOpsRESTClient } from '../../rest-clients/IDevOpsRESTClient';
import IMotdService from '../IMotdService';
import { parse, format } from 'date-format-parse';
import { MotdTemplateList } from '../../models/devops/MOTD/MotdTemplateList';
import { MotdTemplate, MotdTemplateVariable } from '../../models/devops/MOTD/MotdTemplate';
import type IDateTimeService from '../IDateTimeService';
import Time from '../../models/time/time';
import { MotdEntry } from '@/models/devops/MOTD/MotdEntry';
import { MotdTemplateEntry } from '@/models/devops/MOTD/MotdTemplateEntry';

@injectable()
export default class MotdService implements IMotdService {
    @inject('IDateTimeService')
    private dtService!: IDateTimeService;

    private readonly dateFormat = 'YYYY-MM-DD HH:mm:ss';

    stringToDate(dateString: string): Date {
        return parse(dateString, this.dateFormat);
    }

    dateToString(date: Date): string {
        return format(date, this.dateFormat);
    }

    dateFromLocalToUTC(date: Date): Date {
        return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    }

    dateFromUTCToLocal(date: Date): Date {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    }

    private products: string[][] = [['kd-sim', 'KD Sim']];

    private pipelines: string[][] = [
        ['development', 'Development'],
        ['production', 'Production'],
    ];

    @inject('IDevOpsRESTClient')
    private readonly devOpsRestClient!: IDevOpsRESTClient;

    async getMotdsList(page: number): Promise<MotdList | null> {
        const motdList = await this.devOpsRestClient.getMotdsList(page);

        if (!motdList) return motdList;

        return motdList;
    }

    async getMotdTemplatesList(page: number): Promise<MotdTemplateList | null> {

       const motdList = await this.devOpsRestClient.getMotdTemplatesList(page);

        if (!motdList) return motdList;

        return motdList;
    }

    private processBEMotd(motd: Motd): Motd {
        const newMotd = { ...motd };
        if (!!newMotd.from_date) {
            const dateFrom = this.dtService.utcToLocalDateString(newMotd.from_date, this.dateFormat);
            newMotd.from_date = dateFrom;
        }
        if (!!newMotd.to_date) {
            const dateTo = this.dtService.utcToLocalDateString(newMotd.to_date, this.dateFormat);
            newMotd.to_date = dateTo;
        }

        if (!!newMotd.variables && newMotd.variables.length > 0) {
            newMotd.variables.forEach((variable) => {
                if (variable.variable_type === 'datetime') {
                    const newDate = this.dtService.utcToLocalDateString(variable.motd_value, this.dateFormat);
                    variable.motd_value = newDate;
                }
                if (variable.variable_type === 'time') {
                    const time = new Time().parse(variable.motd_value).toLocal();
                    variable.motd_value = time.toString('HH:mm');
                }
            });
        }
        return newMotd;
    }

    getMotdDefaultVariable(templateVariable: MotdTemplateVariable): MotdVariable {
        return {
            id: 0,
            motd_id: 0,
            motd_template_var_id: templateVariable.id,
            motd_value: this.getMotdVariableDefaultValue(templateVariable.variable_type),
            variable_type: templateVariable.variable_type,
            variable_name: templateVariable.variable_name,
        };
    }

    async getMotd(id: number): Promise<Motd | null> {
        const motd = await this.devOpsRestClient.getMotd(id);
        return motd ? this.processBEMotd(motd) : motd;
    }

    async getMotdTemplate(id: number): Promise<MotdTemplate | null> {
        const motd = await this.devOpsRestClient.getMotdTemplate(id);
        return motd;
    }

    async createMotd(product: string, motd: Motd): Promise<boolean> {
        const newMotd = this.processMOTDForBE(motd);
        return await this.devOpsRestClient.createMotd(product, newMotd);
    }

    async createMotdTemplate(template: MotdTemplate): Promise<boolean> {
        return await this.devOpsRestClient.createMotdTemplate(template);
    }

    async getMotdsListByProduct(product: string, page: number): Promise<MotdList | null>{
        return await this.devOpsRestClient.getMotdsListByProduct(product, page);
    }

    private processMOTDForBE(motd: Motd): Motd {
        const resultMotd = { ...motd };

        resultMotd.variables = resultMotd.variables.map((v) => ({ ...v }));

        if (!!resultMotd.from_date) {
            const dateFrom = this.dtService.localToUtcDateString(motd.from_date, this.dateFormat);
            resultMotd.from_date = dateFrom;
        }

        if (!!resultMotd.to_date) {
            const dateTo = this.dtService.localToUtcDateString(motd.to_date, this.dateFormat);
            resultMotd.to_date = dateTo;
        }
        if (resultMotd.variables.length > 0) {
            resultMotd.variables.forEach((variable) => {
                if (variable.variable_type === 'datetime') {
                    const newDate = this.dtService.localToUtcDateString(variable.motd_value, this.dateFormat);
                    variable.motd_value = newDate;
                }
                if (variable.variable_type === 'time') {
                    const time = new Time().parse(variable.motd_value).toUTC();
                    variable.motd_value = time.toString('HH:mm');
                }
            });
        }

        return resultMotd;
    }

    async updateMotd(motd: Motd): Promise<boolean> {
        const updatedMotd = this.processMOTDForBE(motd);
        return await this.devOpsRestClient.updateMotd(updatedMotd);
    }

    async deleteMotd(id: number): Promise<boolean> {
        return await this.devOpsRestClient.deleteMotd(id);
    }

    async deleteMotdTemplate(id: number): Promise<boolean> {
        return await this.devOpsRestClient.deleteMotdTemplate(id);
    }

    async updateMotdTemplate(template: MotdTemplate): Promise<boolean> {
        return await this.devOpsRestClient.updateMotdTemplate(template);
    }

    getProducts(): string[][] {
        return this.products;
    }
    getPipelines(): string[][] {
        return this.pipelines;
    }

    getMotdVariableDefaultValue(type: string): string {
        switch (type) {
            case 'datetime':
                return this.dateToString(new Date());
            case 'text':
                return '';
            case 'number':
                return '0';
            case 'time': 
                return '00:00';
            default:
                return '';
        }
    }
}

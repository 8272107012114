import IList from "../IList";
import VersionEntry from "./VersionEntry";

export default class VersionList implements IList<VersionEntry>{
    public max_results!: number;
    public versions!:VersionEntry[];

    getItems(): VersionEntry[] {
        return this.versions;
    }
}

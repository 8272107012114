import { useInjection } from 'inversify-react';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Alert, Box, Container, Skeleton, Grid } from '@mantine/core';

import { IServerActivityService } from '@/services/IServerActivityService';
import { MonitoringChartContainer } from './chart/chart-layout';
import { GameRooms } from '../game-rooms/game-rooms.component';
import { ShardInfo } from '../shard-info/shard-info.component';
import { useQuery } from '@tanstack/react-query';
import { MonitoringHeader } from '../../monitoring-header/monitoring-header';

interface ServerActivityProps {
    selectedShard: string;
    refreshOn: boolean;
}

const ServerActivity = ({ selectedShard, refreshOn }: ServerActivityProps) => {
    const serverActivityService = useInjection<IServerActivityService>('IServerActivityService');

    const { isLoading, isError, data } = useQuery({
        queryKey: ['server-activity', selectedShard],
        queryFn: () => serverActivityService.getGoogleCloudDashboards(),
        select: (data) => serverActivityService.filterDashboardsByShard(data, selectedShard),
        refetchInterval: refreshOn ? 10000 : false,
        enabled: refreshOn,
    });

    const dashboards = data
        ? data.map((tile, i) => (
              <Grid.Col span={1} key={i}>
                  <MonitoringChartContainer refreshOn={refreshOn} key={i} data={tile} />
              </Grid.Col>
          ))
        : null;

    const skeletons = Array(2)
        .fill(0)
        .map((_, i) => (
            <Grid.Col span={1} key={i}>
                <Skeleton key={i} height={350} />
            </Grid.Col>
        ));

    const error = (
        <Grid.Col span={2}>
            <Alert color="red" title="Error">
                Something went wrong, Please reload page.
            </Alert>
        </Grid.Col>
    );

    const content = isError ? error : isLoading ? skeletons : dashboards;
    return (
        <Grid columns={2} gutter={20}>
            {content}
        </Grid>
    );
};

interface IShardInfoProps {
    shard: string;
    refreshOn: boolean;
}

const ServerActivityGameRooms = ({ shard, refreshOn }: IShardInfoProps) => {
    return (
        <>
            <Box px={{ sm: 'xs', xl: 'xl' }}>
                <ShardInfo selectedShard={shard} refreshOn={refreshOn} />
                <GameRooms selectedShard={shard} refreshOn={refreshOn} />
            </Box>
        </>
    );
};

export const ServerActivityDirectory = () => {
    const [selectedShard, setSelectedShard] = useState<string | null>(null);
    const [refreshOn, setRefreshOn] = useState<boolean>(true);
    const navigate = useNavigate();
    const { shard } = useParams();

    useEffect(() => {
        if (!!shard && shard !== ':shard') {
            setSelectedShard(shard);
        } else {
            setSelectedShard('shard-001');
        }
    }, []);

    useEffect(() => {
        if (!!selectedShard) {
            navigate(`/monitoring/server-activity/${selectedShard}`);
        }
    }, [selectedShard]);

    if (!selectedShard) return null;

    return (
        <Box>
            <MonitoringHeader
                refreshOn={refreshOn}
                setRefreshOn={setRefreshOn}
                setSelectedShard={setSelectedShard}
                selectedShard={selectedShard}
            />
            <ServerActivityGameRooms refreshOn={refreshOn} shard={selectedShard} />
            <Container px={{ sm: 'xs', xl: 'xl' }} fluid>
                <ServerActivity refreshOn={refreshOn} selectedShard={selectedShard} />
            </Container>
        </Box>
    );
};

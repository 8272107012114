import Nullable from "@/dataTypes/Nullable";
import AssetGroup from "@/models/trans/asset-group/AssetGroup";
import AssetGroupList from "@/models/trans/asset-group/AssetGroupList";
import IAssetGroupService from "../IAssetGroupService";
import type ITransRESTClient from "@/rest-clients/ITransRESTClient";
import { inject, injectable } from "inversify";

@injectable()
export default class AssetGroupService implements IAssetGroupService{

    @inject("ITransRESTClient")
    private transRESTClient! : ITransRESTClient;

    async createAssetGroup(assetGroup: AssetGroup): Promise<Nullable<AssetGroup>> {
        return await this.transRESTClient.createAssetGroup(assetGroup); 
    }
    async updateAssetGroup(assetGroup: AssetGroup): Promise<Nullable<AssetGroup>> {
        return await this.transRESTClient.updateAssetGroup(assetGroup);
    }
    async getAssetGroup(id: number): Promise<Nullable<AssetGroup>> {
        return await this.transRESTClient.getAssetGroup(id);
    }
    async getAssetGroupList(page: number): Promise<Nullable<AssetGroupList>> {
        return await this.transRESTClient.getAssetGroupList(page);
    }
    async deleteAssetGroup(id: number): Promise<boolean> {
        return await this.transRESTClient.deleteAssetGroup(id);
    }

}
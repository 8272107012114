import { Button, Flex, Text, Modal } from '@mantine/core';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux/store';
import { setResponse } from '../../redux/slices/confirmDialogSlice';

export function ConfirmDialog() {
    const confirm = useSelector((state: RootState) => state.confirmDialog.open);
    const prompt = useSelector((state: RootState) => state.confirmDialog.prompt);
    const negativeButton = useSelector((state: RootState) => state.confirmDialog.negativeButton);
    const positiveButton = useSelector((state: RootState) => state.confirmDialog.positiveButton);

    const buttonClick = (value: boolean) => {
        store.dispatch(setResponse(value));
    };

    return (
        <Modal
            centered
            onClose={() => buttonClick(false)}
            opened={confirm}
            withinPortal
            title="Confirm"
            styles={{ title: { fontSize: '1.2rem' } }}
            closeOnClickOutside={false}
        >
            <div>
                <Text component="p">{prompt}</Text>
                <Flex ml="auto" w="max-content" gap="sm">
                    <Button
                        w="150px"
                        color="red"
                        variant="outline"
                        onClick={(e) => {
                            e.preventDefault();
                            buttonClick(false);
                        }}
                    >
                        {negativeButton}
                    </Button>
                    <Button
                        w="150px"
                        onClick={(e) => {
                            e.preventDefault();
                            buttonClick(true);
                        }}
                    >
                        {positiveButton}
                    </Button>
                </Flex>
            </div>
        </Modal>
    );
}

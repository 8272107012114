import IList from '../../IList';
import { MotdEntry } from './MotdEntry';

export default class MotdList implements IList<MotdEntry> {
    public max_results!: number;

    public messages!: MotdEntry[];

    getItems(): MotdEntry[] {
        return this.messages;
    }
}

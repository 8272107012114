import { useInjection } from 'inversify-react';
import { Button, Select, TextInput, Container, Stack, Flex, Text } from '@mantine/core';

import { IScopeService } from '@/services/IScopeService';
import { useEffect, useState } from 'react';
import { ServiceManagementModel } from '@/models/serviceManagement/ServiceManagementModel';
import { ScopeList } from '@/models/scope/ScopeList';
import type IDateTimeService from '@/services/IDateTimeService';

export interface IServiceFormProps {
    service: ServiceManagementModel;
    update: (service: ServiceManagementModel) => void;
    updateEnabled: boolean;
    save: () => void;
    delete: () => void;
}

export function ServiceForm(props: IServiceFormProps) {
    const { service, update, updateEnabled, save } = props;
    const dtService = useInjection<IDateTimeService>('IDateTimeService');

    return (
        <Container fluid mt="sm">
            <Stack>
                <TextInput value={service.email} disabled label="Email" />
                <TextInput
                    value={service.created_date}
                    disabled
                    label="Created"
                    rightSectionWidth={60}
                    rightSection={
                        <Text size="xs" color="gray">
                            {dtService.getLocalTimezone()}
                        </Text>
                    }
                />
                <ServiceDatabaseAccess service={service} update={update} />
            </Stack>
            <Flex w="max-content" ml="auto" mt="lg" gap="md">
                <Button
                    size="md"
                    radius="md"
                    onClick={(e) => {
                        e.preventDefault();
                        save();
                    }}
                    disabled={!updateEnabled}
                >
                    Update
                </Button>
                <Button
                    size="md"
                    radius="md"
                    color="red"
                    variant="outline"
                    onClick={(e) => {
                        e.preventDefault();
                        props.delete();
                    }}
                >
                    Delete
                </Button>
            </Flex>
        </Container>
    );
}

interface IServiceRole {
    service: ServiceManagementModel;
    update: (service: ServiceManagementModel) => void;
}

const ServiceDatabaseAccess = (props: IServiceRole) => {
    const { update, service } = props;
    const [scopes, setScopes] = useState<ScopeList | null>(null);

    const scopeService = useInjection<IScopeService>('IScopeService');

    const roleChanged = (value: string) => {
        update({ ...service, scope: value });
    };

    useEffect(() => {
        const getScopes = async () => {
            const allScopes = await scopeService.getScopes('service');
            if (allScopes) {
                setScopes(allScopes);
            }
        };
        getScopes();
    }, [service.scope]);

    return (
        <Select
            label="Database Access"
            defaultValue={service.scope}
            value={service.scope}
            data={
                scopes
                    ? scopes.getItems().map((scope) => ({
                          label: scope.display,
                          value: scope.id,
                      }))
                    : []
            }
            onChange={roleChanged}
        />
    );
};

import { inject, injectable } from 'inversify';
import IGDMService from '../IGDMService';
import ShardModel from '@/models/gdm/ShardModel';
import type IGDMRESTClient from '@/rest-clients/IGDMRESTClient';
import HouseSave from '@/models/gdm/HouseSave';
import type { IAuthService } from '../IAuthService';
import OwnerList from '../../models/gdm/OwnersList';
import Nullable from '@/dataTypes/Nullable';
import IncompatibilityType from '@/models/gdm/IncompatibilityType';
import type ITransRESTClient from '@/rest-clients/ITransRESTClient';

@injectable()
export default class GDMService implements IGDMService {
    @inject('IGDMRESTClient')
    private gdmRESTClient!: IGDMRESTClient;

    @inject('IAuthService')
    private authService!: IAuthService;

    @inject('ITransRESTClient')
    private transRESTClient!: ITransRESTClient;

    async getUserSaveList(user: string): Promise<HouseSave[]> {
        return await this.gdmRESTClient.getUserSaveList(user);
    }

    async breakCompatibility(pipeline: string): Promise<boolean> {
        return await this.gdmRESTClient.breakCompatibility(pipeline);
    }
    async getPipelines(): Promise<ShardModel[]> {
        return [
            { id: 'development', name: 'Development' },
            { id: 'production', name: 'Production' },
        ];
    }

    async deleteSave(id: number): Promise<boolean> {
        return await this.gdmRESTClient.deleteSave(id);
    }

    async deleteSaveBinary(id: number): Promise<boolean> {
        return await this.gdmRESTClient.deleteSaveBinary(id);
    }

    async copySaveFileToUserProfile(
        id: number,
        originalOwner: string,
        targetPipeline: string,
        copyPolaroids: boolean,
        copyUnboxed: boolean,
        iss?: string
    ): Promise<boolean> {
        if (!iss) {
            iss = await this.authService.getIss();
        }

        const saveId = await this.gdmRESTClient.copySaveFileToUserProfile(id, targetPipeline, iss);

        if (saveId === -1) return false;

        if (!!copyPolaroids) await this.gdmRESTClient.copyPolaroidsToUserProfile(originalOwner, iss);

        if (!!copyUnboxed) await this.transRESTClient.copyUnboxedItems(id, iss, saveId);

        return true;
    }

    async getOwnerList(page: number, perPage: number, query: string): Promise<OwnerList> {
        return await this.gdmRESTClient.getOwnerList(page, perPage, query);
    }

    getCompatibility(oldVersion: string, newVersion: string): Promise<IncompatibilityType[]> {
        return this.gdmRESTClient.getCompatibility(oldVersion, newVersion);
    }
}

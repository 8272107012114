import { useInjection } from 'inversify-react';
import { useEffect, useRef, useState } from 'react';
import IProductKeyService from '../../../services/IProductKeyService';
import IUIService from '../../../services/IUIService';
//import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import Nullable from '@/dataTypes/Nullable';
import { IGiveGiftModel } from './product-key-directory.component';
import { Button, Checkbox, Group, NumberInput, Radio, Select, Stack, Textarea } from '@mantine/core';
import { QRCodeCanvas } from '../../../third-party/qr-code/src';
import QRCode from 'qrcode';
import AsyncImage from '../../../models/images/AsyncImage';
import QRLayoutCanvas from './QRLayoutCanvas.component';
import lanternExcavated from '../../../assets/images/lanternExcavated.png';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { ProductKeyGenerationTypeEnum } from '../../../models/trans/ProductKeyGenerationTypeEnum';

interface ProductKeyGenerationFormProps {
    product: Nullable<IGiveGiftModel>;
    isLoading: boolean;
}

export default function ProductKeyGenerationForm({ product, isLoading }: ProductKeyGenerationFormProps) {
    const [qrValue, setQRValue] = useState('');
    const [generationType, setGenerationType] = useState(ProductKeyGenerationTypeEnum.Txt);
    const [keyQuantity, setKeyQuantity] = useState(1);
    const [emailList, setEmailList] = useState('');
    const [allEmails, setAllEmails] = useState(false);
    const uiService = useInjection<IUIService>('IUIService');
    const productKeyService = useInjection<IProductKeyService>('IProductKeyService');

    const qrLayout = useRef<QRLayoutCanvas | null>(null);

    useEffect(() => {
        if (!qrValue || qrValue === '') {
            return;
        }
        setTimeout(() => {
            const canvasElement = document.getElementById('qr-gen');
            if (!canvasElement) return;

            const canvas = canvasElement as HTMLCanvasElement;

            const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');

            let downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = `KEY_${new Date().toString().replaceAll(' ', '_')}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setQRValue('');
        }, 100);
    }, [qrValue]);

    const generateKeys = async (postProcess: (list: string[]) => void) => {
        if (!product) return;
        uiService.showLoading();
        let quantity = keyQuantity;
        if (generationType === 'qrlayout') {
            quantity *= 6;
        }
        const productKeys = await productKeyService.generateProductKey(product.variantId, quantity, product.quantity);
        uiService.hideLoading();
        if (productKeys) {
            uiService.showSuccessNotification('Generated successfuly!');
            postProcess(productKeys.productKeys);
        } else {
            uiService.showErrorNotification('Failed to generate!');
        }
    };

    const buttonDisabled = () => {
        return !product || product.variantId === '' || product.variantId === '0';
    };

    const downloadTxt = (list: string[]) => {
        let txtContent = 'data:text/plain;charset=utf-8,' + list.join('\n');

        var encodedUri = encodeURI(txtContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `product_keys_${new Date().toString().replaceAll(' ', '_')}.txt`);
        document.body.appendChild(link); // Required for FF

        link.click();
    };

    const downloadCSV = (list: string[]) => {
        let csvContent = 'data:text/csv;charset=utf-8,' + list.join('\n');

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `product_keys_${new Date().toString().replaceAll(' ', '_')}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();
    };

    const downloadQR = (list: string[]) => {
        setQRValue('https://client.kingdomdeath.com/account?invite=' + list[0]);
    };

    const downloadQRLayout = async (list: string[]) => {
        uiService.showLoading();

        const options: QRCode.QRCodeToDataURLOptions = {
            errorCorrectionLevel: 'H',
            width: 512,
        };

        const logoImage = new AsyncImage(128, 128);
        const logoResult = await logoImage.setSrc(lanternExcavated);

        const zipArchive = new JSZip();

        const positions = [
            {
                x: 295,
                y: 145,
            },
            {
                x: 1402,
                y: 145,
            },
            {
                x: 2485,
                y: 145,
            },
            {
                x: 295,
                y: 1405,
            },
            {
                x: 1402,
                y: 1405,
            },
            {
                x: 2485,
                y: 1405,
            },
        ];

        const canvasToBlob = (canvas: HTMLCanvasElement) => {
            const promise = new Promise<Blob | null>((resolve, reject) => {
                canvas.toBlob((b) => {
                    resolve(b);
                }, 'image/png');
            });

            return promise;
        };

        for (let index = 0; index < list.length; index++) {
            const invitation = 'https://client.kingdomdeath.com/account?invite=' + list[index];
            const imageDataURL = await QRCode.toDataURL(invitation, options);
            const img = new AsyncImage(512, 512);
            const resultImage = await img.setSrc(imageDataURL);
            if (!resultImage) continue;
            if (!qrLayout.current) continue;
            const context = qrLayout.current.getContext();
            if (!context) continue;

            const point = positions[index % 6];

            context.drawImage(resultImage, point.x, point.y);
            if (!!logoResult) context.drawImage(logoResult, point.x + 512 / 2 - 128 / 2, point.y + 512 / 2 - 128 / 2);

            if ((index + 1) % 6 === 0 || ((index + 1) % 6 !== 0 && index === list.length - 1)) {
                //add to zip or download
                const canvas = qrLayout.current.getCanvas();
                if (!canvas) continue;

                const imageBlob = await canvasToBlob(canvas);

                if (!imageBlob) continue;
                const name = 'code_sheet_' + Math.floor(index / 6) + '.png';

                zipArchive.file(name, imageBlob);

                qrLayout.current.resetCanvas();
            }
        }
        saveAs(await zipArchive.generateAsync({ type: 'blob' }), 'qrcodes.zip');

        uiService.hideLoading();
    };

    const processEmails = async () => {
        if (!product) return;
        uiService.showLoading();
        let response = false;
        if (generationType === ProductKeyGenerationTypeEnum.Emails)
            response = await productKeyService.processEmailList(emailList, product.variantId, false, false);
        else if (generationType === ProductKeyGenerationTypeEnum.Invites) {
            response = await productKeyService.sendInvitesToEmails(emailList, product.variantId);
        } else if (generationType === ProductKeyGenerationTypeEnum.AutoConsumeEmailList) {
            response = await productKeyService.processEmailList(emailList, product.variantId, true, allEmails);
        }
        uiService.hideLoading();

        if (!response) {
            uiService.showErrorNotification('Failed to process emails!');
        } else {
            uiService.showSuccessNotification('Successfuly processed emails!');
            //setEmailList('');
        }
    };

    const processClick = async () => {
        uiService.showLoading();
        if (
            generationType !== ProductKeyGenerationTypeEnum.Emails &&
            generationType !== ProductKeyGenerationTypeEnum.Invites &&
            generationType !== ProductKeyGenerationTypeEnum.AutoConsumeEmailList
        ) {
            await generateKeys((list) => {
                if (generationType === ProductKeyGenerationTypeEnum.CSV) {
                    downloadCSV(list);
                }
                if (generationType === ProductKeyGenerationTypeEnum.Txt) {
                    downloadTxt(list);
                }
                if (generationType === ProductKeyGenerationTypeEnum.QRCode) {
                    downloadQR(list);
                }
                if (generationType === ProductKeyGenerationTypeEnum.QRLayout) {
                    downloadQRLayout(list);
                }
            });
        } else {
            await processEmails();
        }

        uiService.hideLoading();
    };

    const generationData = [
        { value: ProductKeyGenerationTypeEnum.Txt, label: 'Text File' },
        { value: ProductKeyGenerationTypeEnum.CSV, label: 'CSV File' },
        { value: ProductKeyGenerationTypeEnum.QRCode, label: 'QR Code' },
        { value: ProductKeyGenerationTypeEnum.Emails, label: 'Email List' },
        { value: ProductKeyGenerationTypeEnum.Invites, label: 'Send Invites' },
        { value: ProductKeyGenerationTypeEnum.QRLayout, label: 'QR Layout' },
        { value: ProductKeyGenerationTypeEnum.AutoConsumeEmailList, label: 'Auto Consume Email List' },
    ];

    const enumFromValue = <T extends Record<string, string>>(val: string, _enum: T) => {
        const enumName = (Object.keys(_enum) as Array<keyof T>).find(k => _enum[k] === val)
        if (!enumName) throw Error() // here fail fast as an example
        return _enum[enumName]
    }

    return (
        <Stack spacing="md" mt={25}>
            <NumberInput
                disabled={
                    generationType === ProductKeyGenerationTypeEnum.QRCode ||
                    generationType === ProductKeyGenerationTypeEnum.Emails ||
                    generationType === ProductKeyGenerationTypeEnum.Invites ||
                    generationType === ProductKeyGenerationTypeEnum.AutoConsumeEmailList
                }
                label={
                    generationType === ProductKeyGenerationTypeEnum.QRLayout
                        ? 'Number of pages to generate'
                        : 'Number of keys to generate'
                }
                value={keyQuantity}
                onChange={(e) => {
                    let value = parseInt(e.toString());
                    if (value < 1) {
                        value = 1;
                    }
                    setKeyQuantity(value);
                }}
            />

            <Select
                data={generationData}
                name="downloadType"
                label="Select the product key generation type"
                description="QR Code is only available for a single key"
                value={generationType}
                onChange={(e) => {
                    let target = ProductKeyGenerationTypeEnum.Txt;
                    if (!e) target = ProductKeyGenerationTypeEnum.Txt;

                    target = enumFromValue(e!, ProductKeyGenerationTypeEnum);// ProductKeyGenerationTypeEnum[e! as keyof typeof ProductKeyGenerationTypeEnum];

                    console.log(target);

                    setGenerationType(target);
                    setAllEmails(false);

                    if (
                        target === ProductKeyGenerationTypeEnum.QRCode ||
                        target === ProductKeyGenerationTypeEnum.Emails ||
                        target === ProductKeyGenerationTypeEnum.Invites ||
                        target === ProductKeyGenerationTypeEnum.AutoConsumeEmailList
                    ) {
                        setKeyQuantity(1);
                    }

                    if (
                        target === ProductKeyGenerationTypeEnum.Emails ||
                        target === ProductKeyGenerationTypeEnum.Invites
                    ) {
                        setEmailList('');
                    }
                }}
                withAsterisk
            ></Select>

            {(generationType === ProductKeyGenerationTypeEnum.AutoConsumeEmailList) && (
                <Checkbox
                    checked={allEmails}
                    onChange={(e) => {
                        setAllEmails(e.target.checked);
                    }}
                    label="All Emails"
                />
            )}

            {(generationType === ProductKeyGenerationTypeEnum.Emails ||
                generationType === ProductKeyGenerationTypeEnum.Invites ||
                generationType === ProductKeyGenerationTypeEnum.AutoConsumeEmailList) && (
                <Textarea
                    value={emailList}
                    onChange={(e) => {
                        e.preventDefault();
                        setEmailList(e.target.value);
                    }}
                    description="One email per line"
                    label={"Email List"+(generationType===ProductKeyGenerationTypeEnum.AutoConsumeEmailList && !!allEmails?'(to be excluded)':'')}
                />
            )}

            <Button
                type="submit"
                disabled={buttonDisabled()}
                onClick={async (e) => {
                    e.preventDefault();
                    await processClick();
                }}
                size="md"
                radius="md"
                loading={isLoading}
                loaderPosition="center"
            >
                {!isLoading && 'Generate'}
            </Button>

            <QRCodeCanvas
                id="qr-gen"
                value={qrValue}
                size={512}
                level={'H'}
                includeMargin={true}
                style={{ display: 'none' }}
                imageSettings={{
                    src: 'https://admin.kingdomdeath.com/lantern.png',
                    x: undefined,
                    y: undefined,
                    height: 128,
                    width: 128,
                    excavate: true,
                }}
            />

            <QRLayoutCanvas ref={qrLayout} />
        </Stack>
    );
}

import IList from "../../IList";
import FailedTransactionEntry from "./FailedTransactionEntry";

export default class FailedTransactionList implements IList<FailedTransactionEntry>{
    public max_results!: number;
    public items! : FailedTransactionEntry[];
    
    getItems(): FailedTransactionEntry[] {
        return this.items;
    }

}
import RepositoryModel from '@/models/devops/build/RepositoryModel';
import UnityProjectPayload from '@/models/devops/unity/UnityProjectPayload';
import IUnityService from '@/services/IUnityService';
import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { Select } from '@mantine/core';

interface UnityProjectSelectionProps {
    updateModel: (repository: RepositoryModel) => void;
    repository: RepositoryModel;
    label?: boolean;
}

export default function UnityProjectSelectionComponent({ repository, updateModel, label }: UnityProjectSelectionProps) {
    const unityService = useInjection<IUnityService>('IUnityService');

    const [builds, setBuilds] = useState<UnityProjectPayload[]>([]);

    const getData = async () => {
        const response = await unityService.getUnityBuild();
        setBuilds(response);
    };

    useEffect(() => {
        getData();
    }, []);

    let options =
        builds &&
        builds.length > 0 &&
        builds.map((build) => ({ label: build.name, value: unityService.getFullBuildUrl(build) }));

    return (
        <div>
            <Select
                label={!!label&&'Unity Build Project'}
                onChange={(value) => {
                    const build = builds.find((x) => unityService.getFullBuildUrl(x) === value);
                    if (!build) return;
                    repository.build_url = unityService.getFullBuildUrl(build);
                    updateModel(repository);
                }}
                value={repository.build_url}
                data={options ? options : [{ label: 'Nothing available', value: 'n/a' }]}
            ></Select>
        </div>
    );
}

import { injectable } from 'inversify';
import IDateTimeService from '../IDateTimeService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import Time from '../../models/time/time';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(duration);

@injectable()
export default class DateTimeService implements IDateTimeService {
    getLocalTimezone(): string {
        const gmtOffset = dayjs().utcOffset() / 60;
        const gmtOffsetFormatted = `${gmtOffset >= 0 ? '+' : '-'}${Math.abs(gmtOffset)}`;
        return `GMT${gmtOffsetFormatted}`;
    }

    formatDate(date: Date, format?: string, timezone?: string): string {
        const formattedDate = timezone ? dayjs(date).tz(timezone) : dayjs(date);
        return format ? formattedDate.format(format) : formattedDate.format('MM/DD/YYYY HH:mm:ss');
    }

    formatTime(time: string): string {
        const timeObject = new Time().parse(time).toUTC();
        return timeObject.toString('hh:mm A', 'America/Chicago', true);
    }

    private localToUtc(date: string | Date, format?: string): Date {
        const parsedDate = typeof date === 'string' && format ? dayjs(date, format) : dayjs(date);
        return parsedDate.subtract(parsedDate.utcOffset(), 'minute').toDate();
    }

    private utcToLocal(date: string | Date, format?: string): Date {
        const parsedDate = typeof date === 'string' && format ? dayjs.utc(date, format) : dayjs.utc(date);
        return parsedDate.local().toDate();
    }

    utcToLocalDate: (date: string | Date, format?: string) => Date = this.utcToLocal;
    localToUtcDate: (date: string | Date, format?: string) => Date = this.localToUtc;

    utcToLocalDateString(date: string | Date, format?: string): string {
        return this.formatDate(this.utcToLocal(date, format), format);
    }

    localToUtcDateString(date: string | Date, format?: string): string {
        return format ? this.formatDate(this.localToUtc(date, format), format) : this.formatDate(this.localToUtc(date));
    }

    stringToDate(date: string, format?: string): Date {
        const parsedDate = format ? dayjs(date, format, true) : dayjs(date);

        if (parsedDate.isValid()) {
            return parsedDate.toDate();
        } else {
            throw new Error(`Invalid date string: ${date} with format: ${format}`);
        }
    }

    getDateDifference(date1: Date, date2: Date): number {
        return dayjs(date1).diff(date2);
    }
}

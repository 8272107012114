import IList from "@/models/IList";
import CustomerEntry from "./CustomerEntry";

export default class CustomerList implements IList<CustomerEntry>{
    public items! : CustomerEntry[]
    public max_results!: number;
    getItems(): CustomerEntry[] {
        return this.items;
    }

}
import { ICreateScopeModel, ScopeAccessLevelModel, ScopeModel } from '@/models/scope/ScopeModel';
import axios, { AxiosRequestConfig } from 'axios';
import { inject } from 'inversify';
import UserList from '../../models/auth/users/UserList';
import UserModel from '../../models/auth/users/UserModel';
import { ScopeList } from '../../models/scope/ScopeList';

import type { IAuthService } from '../../services/IAuthService';
import IUserManagementRESTClient from '../IUserManagementRESTClient';
import { RESTClient } from './RESTClient';

export default class UserManagementRESTClient extends RESTClient implements IUserManagementRESTClient {
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    constructor() {
        super();
        this.setBaseURL('https://wod-auth-uux56memxa-uc.a.run.app/api/v1/');
    }

    async getUser(email: string): Promise<UserModel | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`user/${email}`);

        try {
            const response = await axios.get(path, this.getHeader(dodToken));

            return response.data as UserModel;
        } catch {
            return null;
        }
    }

    async getUsers(page: number): Promise<UserList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`user?page=${page}`);

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            const parsedList = response.data as UserList;
            const returnList = new UserList();
            returnList.max_results = parsedList.max_results;
            returnList.accounts = parsedList.accounts;
            return returnList;
        } catch {
            return null;
        }
    }

    async updateUserScope(user: UserModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);

        const path = this.getFullURL(`user/${user.email}/scope/${user.scope}`);

        try {
            await axios.put(path, null, this.getHeader(authToken));
            return true;
        } catch {
            return false;
        }
    }

    async deleteUser(user: UserModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);

        const path = this.getFullURL(`user/${user.email}`);

        try {
            await axios.delete(path, this.getHeader(authToken));
            return true;
        } catch {
            return false;
        }
    }

    async searchUser(search: string, page: number): Promise<UserList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const authToken = await this.getToken(token);

        const path = this.getFullURL(`user?page=${page}&search=${search}`);

        try {
            const response = await axios.get(path, this.getHeader(authToken));
            const parsedList = response.data as UserList;
            const returnList = new UserList();
            returnList.max_results = parsedList.max_results;
            returnList.accounts = parsedList.accounts;
            return returnList;
        } catch {
            return null;
        }
    }

    async forceActivateUser(email: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);
        const path = this.getFullURL(`user/${email}/activation`);

        try {
            await axios.put(path, null, this.getHeader(authToken));
            return true;
        } catch {
            return false;
        }
    }

    async getScopes(scopeType: string): Promise<ScopeList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const authToken = await this.getToken(token);
        const path = this.getFullURL(`scopes/${scopeType}`);

        try {
            const response = await axios.get(path, this.getNoCacheHeader(this.getHeader(authToken)));
            const parsedList = response.data as ScopeModel[];
            const returnList = new ScopeList();
            returnList.max_results = parsedList.length;
            returnList.scopes = parsedList;
            return returnList;
        } catch {
            return null;
        }
    }

    async getScope(scopeId: string): Promise<ScopeAccessLevelModel | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const authToken = await this.getToken(token);
        const path = this.getFullURL(`scope/${scopeId}`);

        try {
            const response = await axios.get(path, this.getNoCacheHeader(this.getHeader(authToken)));
            const scopes = response.data;
            return scopes;
        } catch {
            return null;
        }
    }

    async createScope(scope: ICreateScopeModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);
        const path = this.getFullURL('scope');

        const form = new FormData();
        form.append('display', scope.display);
        form.append('id', scope.id);
        form.append('scopeType', scope.scopeType);

        try {
            await axios.post(path, form, this.getHeader(authToken));
            return true;
        } catch {
            return false;
        }
    }

    async updateScopeAccessLevels(scopeId: string, accessLevels: ScopeAccessLevelModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);
        const path = this.getFullURL(`scope/${scopeId}`);

        try {
            await axios.put(path, accessLevels, this.getHeader(authToken));
            return true;
        } catch {
            return false;
        }
    }

    async deleteScope(scope: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);

        const path = this.getFullURL(`scope/${scope}`);

        try {
            await axios.delete(path, this.getHeader(authToken));
            return true;
        } catch {
            return false;
        }
    }

    getNoCacheHeader(config : AxiosRequestConfig):AxiosRequestConfig{
        return {
            ...config,
            headers:{
                ...config.headers,
                'Cache-Control' : 'no-cache',
                'Expires': '0',
            }
        }
    }
}

import { Container, Box, Flex, ActionIcon, Tooltip } from '@mantine/core';
import DefaultListOptionView from '../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../general/list-view/list-view.component';
import { SearchBox } from '../../general/search-box/search-box';
import CustomerList from '@/models/treasury/pledges/CustomerList';
import { CreateButton } from '../../../components/general/create-button/create-button.component';
import { IoAdd } from 'react-icons/io5';
import { MdOutlineAdd } from 'react-icons/md';

interface PledgeCustomerProps {
    create?: (name: string) => void;
    selectionChange: (email: string) => void;
    selectedEmail: string;
    customerList: CustomerList | null;
    page: number;
    onPageChange: (page: number) => void;
    searchChange?: (query: string) => void;
    canCreate?: boolean;
    onAddNewBacker? : ()=>void;
}
export default function PledgeCustomerList({
    searchChange,
    selectedEmail,
    selectionChange,
    customerList,
    page,
    onPageChange,
    create,
    canCreate,
    onAddNewBacker
}: PledgeCustomerProps) {
    const onSelectionChange = (email: string) => {
        selectionChange(email);
    };

    return (
        <Container px={0} pt="md">
            <Box px="xs">
                <Flex direction="row" justify="center" align="center">
                    <SearchBox
                        placeholder="Search Customer"
                        setSearchQuery={(q) => {
                            if (!!searchChange) {
                                searchChange(q);
                            }
                        }}
                    />
                    <Tooltip label="Add New Backer">
                        <ActionIcon onClick={e=>{
                            e.preventDefault();
                            if(!!onAddNewBacker)
                                onAddNewBacker();
                        }} size={34} color="blue" variant="filled" sx={{marginBottom:'0.75rem', marginLeft: '5px'}}>
                            <IoAdd size={22}/>
                        </ActionIcon>
                    </Tooltip>
                </Flex>
            </Box>
            <ListViewComponent
                elements={customerList}
                page={page}
                selectedValue={selectedEmail}
                pageChanged={onPageChange}
                selectionChangedString={onSelectionChange}
                listElement={(x, selectionChange, inSelect) => {
                    return (
                        <DefaultListOptionView
                            key={x}
                            id={x}
                            value={x.length > 30 ? x.substring(0, 27) + '...' : x}
                            selectionChange={selectionChange}
                            selected={selectedEmail === x}
                            inSelectContainer={inSelect}
                        />
                    );
                }}
            >
                <ListViewComponent.Header>
                    {!!canCreate && (
                        <div>
                            <Box px="sm" mt="sm">
                                <CreateButton
                                    title="Add New Pledges"
                                    icon={<MdOutlineAdd size={28}/>}
                                    click={() => {
                                        if (!!create) create('');
                                    }}
                                />
                            </Box>
                        </div>
                    )}
                </ListViewComponent.Header>
            </ListViewComponent>
        </Container>
    );
}

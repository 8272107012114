import IList from "@/models/IList";
import RewardItem from "./RewardItem";

export default class RewardsList implements IList<RewardItem> {
    
    public items!: RewardItem[];
    public max_results!: number;

    getItems(): RewardItem[] {
        return this.items;
    }
}
import { Grid } from "@mantine/core";
import DataStoreUserSavesList from "./datastore-user-saves-list.component";
import { useEffect, useState } from "react";
import Nullable from "@/dataTypes/Nullable";
import { useInjection } from "inversify-react";
import IUIService from "@/services/IUIService";
import DataStoreUserSavesForm from "./datastore-user-saves-form.component";
import IGDMService from "@/services/IGDMService";
import OwnerList from "@/models/gdm/OwnersList";

export default function DataStoreUserSavesDirectory(){
    const [list, setList] = useState<Nullable<OwnerList>>(null);
    const [page, setPage] = useState(1);
    const [currentSelectedUser, setCurrentSelectedUser] = useState<Nullable<string>>(null);
    const uiService = useInjection<IUIService>('IUIService');
    const gdmService = useInjection<IGDMService>('IGDMService');
    const [query, setQuery] = useState('')

    useEffect(() => {
        getData(true);
    }, [page, query]);

    useEffect(() => {
       
    }, [currentSelectedUser]);

    const getData = async (forceSetFirst = false, reload = false) => {
        uiService.showLoading();
        const response = query==='' || !query ? await gdmService.getOwnerList(page,40, '') : await gdmService.getOwnerList(page,40, query);

        uiService.hideLoading();
        if (response === null) {
            uiService.showErrorNotification('Loading user list failed!');
            return;
        }

        const setToFirst = (currentSelectedUser === null && response.owners.length > 0) || forceSetFirst;
        const firstId = response.owners.length > 0 ? response.owners[0] : null;

        setList(response);
        setCurrentSelectedUser(setToFirst ? firstId : currentSelectedUser);
    };


 
    return (
        <Grid w="calc(100vw - 250px)">
            <Grid.Col span="content">
                <DataStoreUserSavesList
                  searchChange={(q)=>{setPage(1); setQuery(q)}}
                  customerList={list}
                  onPageChange={(p)=>{
                      setPage(p);
                  }}
                  page = {page}
                  selectedId = {currentSelectedUser}
                  selectionChange={change=>{
                      setCurrentSelectedUser(change)
                  }}
                />
            </Grid.Col>
            <Grid.Col span="auto">
                {!!currentSelectedUser && <DataStoreUserSavesForm email={currentSelectedUser}/>}
            </Grid.Col>
        </Grid>
    );
}
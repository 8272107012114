import Nullable from '@/dataTypes/Nullable';
import BundleInfoModel from '@/models/devops/build/BundleInfoModel';
import { MotdTemplate } from '../../models/devops/MOTD/MotdTemplate';
import UnityProjectPayload from '@/models/devops/unity/UnityProjectPayload';
import { JWTAccessToken } from '@/models/JWTAccessToken';
import axios, { AxiosError } from 'axios';
import { inject, injectable } from 'inversify';
import RepositoryModel from '../../models/devops/build/RepositoryModel';
import Motd from '../../models/devops/MOTD/Motd';
import MotdList from '../../models/devops/MOTD/MotdList';
import { MotdTemplateList } from '../../models/devops/MOTD/MotdTemplateList';
import ProductVersionModel from '../../models/devops/ProductVersionModel';
import VersionList from '../../models/devops/VersionList';
import type { IAuthService } from '../../services/IAuthService';
import { IDevOpsRESTClient } from '../IDevOpsRESTClient';
import { RESTClient } from './RESTClient';
import BuildProductVersionInfoList from '@/models/devops/build/product/BuildProductVersionInfoList';
import DependencyBundles from '@/models/devops/build/dependencyBundles/DependencyBundles';

@injectable()
export default class DevOpsRESTClient extends RESTClient implements IDevOpsRESTClient {
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    private readonly baseURLv2: string;

    constructor() {
        super();
        this.setBaseURL('https://wod-devops-uux56memxa-uc.a.run.app/api/v1/');
        this.baseURLv2 = 'https://wod-devops-uux56memxa-uc.a.run.app/api/v2/';
    }

    async getMotdsListByProduct(product: string, page: number): Promise<MotdList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`product/${product}/motds?page=${page}`);

        try {
            const response = await axios.get(path, this.getHeader(devopsToken));
            const motdListResponse = response.data as MotdList;
            const motdList = new MotdList();
            motdList.messages = motdListResponse.messages;
            motdList.max_results = motdListResponse.max_results;
            return motdList;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;

                if (axiosError.response?.status === 404) {
                    const motdList = new MotdList();
                    motdList.messages = [];
                    return motdList;
                }
            }
            return null;
        }
    }

    async getMotdsList(page: number): Promise<MotdList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`motds?page=${page}`);

        try {
            const response = await axios.get(path, this.getHeader(devopsToken));
            const motdListResponse = response.data as MotdList;
            const motdList = new MotdList();
            motdList.messages = motdListResponse.messages;
            motdList.max_results = motdListResponse.max_results;
            return motdList;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;

                if (axiosError.response?.status === 404) {
                    const motdList = new MotdList();
                    motdList.messages = [];
                    return motdList;
                }
            }
            return null;
        }
    }

    async getMotdTemplatesList(page: number): Promise<MotdTemplateList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;
        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`motd/templates?page=${page}`);

        try {
            const response = await axios.get(path, this.getHeader(devopsToken));
            const motdListResponse = response.data;
            const motdList = new MotdTemplateList();
            motdList.items = motdListResponse.items;
            motdList.max_results = motdListResponse.max_count;
            return motdList;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;

                if (axiosError.response?.status === 404) {
                    const motdList = new MotdTemplateList();
                    motdList.items = [];
                    return motdList;
                }
            }
            return null;
        }
    }

    async getMotd(id: number): Promise<Motd | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`motd/${id}`);

        try {
            const response = await axios.get(path, this.getHeader(devopsToken));
            return response.data as Motd;
        } catch {
            return null;
        }
    }

    async getMotdTemplate(id: number): Promise<MotdTemplate | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`motd/template/${id}`);

        try {
            const response = await axios.get(path, this.getHeader(devopsToken));
            return response.data as MotdTemplate;
        } catch {
            return null;
        }
    }

    async createMotd(product: string, motd: Motd): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`product/${product}/motd`);

        try {
            await axios.post(path, motd, this.getHeader(devopsToken));
            return true;
        } catch {
            return false;
        }
    }

    async createMotdTemplate(template: MotdTemplate): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;


        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`motd/template`);

        try {
            await axios.post(path, template, this.getHeader(devopsToken));
            return true;
        } catch {
            return false;
        }
    }

    async updateMotd(motd: Motd): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`motd`);

        try {
            await axios.put(path, JSON.stringify(motd), this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }

    async updateMotdTemplate(template: MotdTemplate): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`motd/template`);

        try {
            await axios.put(path, JSON.stringify(template), this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }

    async deleteMotd(id: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`motd/${id}`);

        try {
            await axios.delete(path, this.getHeader(devopsToken));
            return true;
        } catch {
            return false;
        }
    }
    async deleteMotdTemplate(id: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`motd/template/${id}`);

        try {
            await axios.delete(path, this.getHeader(devopsToken));
            return true;
        } catch {
            return false;
        }
    }

    async getProductVersionList(product: string, page: number): Promise<VersionList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`product-version-info?page=${page}&product=${product}`);

        try {
            const response = await axios.get(path, this.getHeader(devopsToken));
            const versionListResponse = response.data as VersionList;
            const versionList = new VersionList();
            versionList.versions = versionListResponse.versions;
            versionList.max_results = versionListResponse.max_results;
            return versionList;
        } catch {
            return null;
        }
    }

    async getProductVersionInfo(id: number): Promise<ProductVersionModel | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`product-version-info/${id}`);

        try {
            const response = await axios.get(path, this.getHeader(devopsToken));
            return response.data as ProductVersionModel;
        } catch {
            return null;
        }
    }

    async breakCompatibility(version: string, pipeline: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURLV2(`saves/bulk/${version}/pipeline/${pipeline}`);

        try {
            await axios.put(path, null, this.getHeader(devopsToken));
            return true;
        } catch {
            return false;
        }
    }

    async createProductVersion(version: string, product: string, buildAll : boolean): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`product-version-info?buildAll=${buildAll}`);

        try {
            await axios.post(
                path,
                JSON.stringify({
                    version: version,
                    product: product,
                }),
                this.getHeaderJson(this.getHeader(devopsToken))
            );
            return true;
        } catch {
            return false;
        }
    }

    async deleteProductVersion(id: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`product-version-info/${id}`);

        try {
            await axios.delete(path, this.getHeader(devopsToken));
            return true;
        } catch {
            return false;
        }
    }

    async issueRetransfer(bundleBuildId: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`build/${bundleBuildId}`);

        try {
            axios.put(path, null, this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }

    async issueRebuild(bundleBuildId: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`build/${bundleBuildId}`);

        try {
            await axios.post(path, null, this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }

    async updateProductVersion(version: ProductVersionModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`product-version-info`);

        try {
            await axios.put(path, JSON.stringify(version), this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }

    async createRepository(repository: RepositoryModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`repository`);

        try {
            await axios.post(path, JSON.stringify(repository), this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }
    async updateRepository(repository: RepositoryModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`repository`);

        try {
            await axios.put(path, JSON.stringify(repository), this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }

    async getRepositories(): Promise<string[] | null> {
        const token = await this.authService.getToken();
        if (token === null) return [];

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`repositories`);

        try {
            const response = await axios.get(path, this.getHeader(devopsToken));
            return response.data as string[];
        } catch {
            return [];
        }
    }

    async getRepository(product: string): Promise<RepositoryModel | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`repository/${product}`);

        try {
            const response = await axios.get(path, this.getHeaderJson(this.getHeader(devopsToken)));
            return response.data as RepositoryModel;
        } catch {
            return null;
        }
    }

    async getGithubBearerToken(code: string): Promise<Nullable<JWTAccessToken>> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL('github/token');
        const form = new FormData();
        form.set('code', code);
        try {
            const response = await axios.post(path, form, this.getHeaderJson(this.getHeader(devopsToken)));
            if (!!response.data.access_token) {
                return {
                    jwt: response.data.access_token,
                    expires: 1800,
                    refreshToken: response.data.refresh_token,
                };
            }
            return null;
        } catch {
            return null;
        }
    }

    async deleteRepository(product: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`repository/${product}`);

        try {
            await axios.delete(path, this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }

    async getUnityBuild(): Promise<UnityProjectPayload[]> {
        const token = await this.authService.getToken();
        if (token === null) return [];

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`unity/projects`);

        try {
            const response = await axios.get(path, this.getHeaderJson(this.getHeader(devopsToken)));
            return response.data as UnityProjectPayload[];
        } catch {
            return [];
        }
    }

    async createBundle(bundle: BundleInfoModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`bundle`);

        try {
            await axios.post(path, JSON.stringify(bundle), this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }

    async deleteBundleById(id: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`bundle/${id}`);

        try {
            await axios.delete(path, this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }

    async getBundlesByProduct(product: string): Promise<BundleInfoModel[]> {
        const token = await this.authService.getToken();
        if (token === null) return [];

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`product/${product}/bundles`);

        try {
            const response = await axios.get(path, this.getHeaderJson(this.getHeader(devopsToken)));
            return response.data as BundleInfoModel[];
        } catch {
            return [];
        }
    }

    async updateBundle(bundle: BundleInfoModel): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`bundle`);

        try {
            await axios.put(path, JSON.stringify(bundle), this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }
    async getBundleById(id: number): Promise<BundleInfoModel | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`bundle/${id}`);

        try {
            const response = await axios.get(path, this.getHeaderJson(this.getHeader(devopsToken)));
            return response.data as BundleInfoModel;
        } catch {
            return null;
        }
    }

    async getBuildProductVersionInfoList(product: string): Promise<BuildProductVersionInfoList | null> {
        console.log('get build product version info list');
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`build/product/${product}`);

        try {
            const response = await axios.get(path, this.getHeaderJson(this.getHeader(devopsToken)));
            console.log(response);
            return response.data as BuildProductVersionInfoList;
        } catch {
            return null;
        }
    }

    async getBundleDependencies(product: string) : Promise<Nullable<DependencyBundles>>{
        const token = await this.authService.getToken();
        if (token === null) return null;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`product/${product}/bundle-dependencies`);

        try {
            const response = await axios.get(path, this.getHeaderJson(this.getHeader(devopsToken)));
            return response.data as DependencyBundles;
        } catch {
            return null;
        }
    }

    async updateBundleDependencies(product: string, bundles:  DependencyBundles): Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) return false;

        const devopsToken = await this.getToken(token);

        const path = this.getFullURL(`product/${product}/bundle-dependencies`);

        try {
            await axios.put(path, JSON.stringify(bundles), this.getHeaderJson(this.getHeader(devopsToken)));
            return true;
        } catch {
            return false;
        }
    }

    protected getFullURLV2(url: string, params: Map<string, string> = new Map<string, string>()): string {
        let targetUrl = this.baseURLv2 + url;
        let currentParam = 0;
        params.forEach((value: string, key: string) => {
            if (currentParam === 0) {
                targetUrl += '?';
            } else targetUrl += '&';

            targetUrl += key;
            targetUrl += '=';
            targetUrl += value;

            currentParam++;
        });
        return targetUrl;
    }
}

import { Avatar, Group, Badge, Container, Box } from '@mantine/core';
import DefaultListOptionView from '../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../general/list-view/list-view.component';
import AssetGroupsCreateButton from './asset-groups-create.component';
import AssetGroupList from '@/models/trans/asset-group/AssetGroupList';

interface AssetGroupListProps {
    create?: (name: string) => void;
    selectionChange: (id: number) => void;
    selectedId: number;
    assetGroupList: AssetGroupList | null;
    page: number;
    onPageChange: (page: number) => void;
    canCreate?: boolean;
}
export default function AssetGroupListView({
    selectedId,
    selectionChange,
    assetGroupList,
    page,
    onPageChange,
    create,
    canCreate,
}: AssetGroupListProps) {
    const onSelectionChange = (id: number) => {
        selectionChange(id);
    };

    

    return (
        <Container px={0} pt="md">
            <ListViewComponent
                elements={assetGroupList}
                page={page}
                selectedValue={selectedId}
                pageChanged={onPageChange}
                selectionChanged={onSelectionChange}
                listElement={(x, selectionChange, inSelect) => {
                    return (
                        <DefaultListOptionView
                            key={x.id}
                            id={x.id}
                            value={x.name}
                            selectionChange={selectionChange}
                            selected={selectedId === x.id}
                            inSelectContainer={inSelect}
                        />
                    );
                }}
            >
                <ListViewComponent.Header>
                    {!!canCreate && (
                        <AssetGroupsCreateButton
                            create={(name) => {
                                if (!!create) create(name);
                            }}
                        />
                    )}
                </ListViewComponent.Header>
            </ListViewComponent>
        </Container>
    );
}

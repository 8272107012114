import { Container, Grid, Select } from '@mantine/core';
import { useInjection } from 'inversify-react';
import { useState, useEffect } from 'react';
import MotdList from '../../../models/devops/MOTD/MotdList';
import type IMotdService from '../../../services/IMotdService';
import type IUIService from '../../../services/IUIService';
import DefaultListOptionView from '../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../general/list-view/list-view.component';
import { CreateMotd } from '../create-motd/create-motd.component';
import { MOTDForm } from '../motd-form/motd-form.component';
import Nullable from '@/dataTypes/Nullable';
import Motd, { MotdVariable } from '@/models/devops/MOTD/Motd';
import IDateTimeService from '@/services/IDateTimeService';

interface MOTDDirectoryListState {
    itemList: MotdList | null;
    page: number;
    selectedId: number;
}

export const MOTDDirectory = () => {
    const [motdList, setMotdList] = useState<MOTDDirectoryListState>({
        itemList: null,
        page: 1,
        selectedId: -1,
    });
    const [motdProduct, setMotdProduct] = useState<string>('wod-launcher');
    const motdService = useInjection<IMotdService>('IMotdService');
    const uiService = useInjection<IUIService>('IUIService');
    const dateTimeService = useInjection<IDateTimeService>('IDateTimeService');

    const [isUpdatingTemplate, setIsUpdatingTemplate] = useState(false);

    const [motd, setMotd] = useState<Nullable<Motd>>(null);

    const [updateEnabled, setUpdateEnabled] = useState(false);

    useEffect(() => {
        getData(true);
    }, [motdProduct]);

    const getData = async (forceSelectFirst: boolean = false) => {
        console.log('get data');
        uiService.showLoading();
        let data = await motdService.getMotdsListByProduct(motdProduct, motdList.page);
        uiService.hideLoading();
        if (!data) {
            data = new MotdList();
            data.messages = [];
            setMotdList((state) => ({
                ...state,
                itemList: data,
                selectedId: -1,
            }));
            uiService.showErrorNotification('No message data');
            return;
        }

        if (data && data.messages.length === 0) {
            setMotdList((state) => ({
                ...state,
                itemList: data,
                selectedId: -1,
            }));
            return;
        }

        if (!data || data.messages.length < 1) return;

        const setToFirst = (motdList.selectedId === -1 && data.messages.length > 0) || forceSelectFirst;
        const firstId = data.messages[0].id;

        setMotdList((state) => ({
            ...state,
            itemList: data,
            selectedId: setToFirst ? firstId : state.selectedId,
        }));
        if (setToFirst) {
            onListSelectionChanged(firstId);
        } else {
            onListSelectionChanged(motdList.selectedId);
        }
    };

    const onListPageChanged = async (page: number) => {
        setMotdList((state) => ({
            ...state,
            page: page,
        }));
        await getData();
    };

    const onListSelectionChanged = async (selectedId: number) => {
        if (selectedId === -1) {
            return;
        }

        setMotdList((state) => ({
            ...state,
            selectedId: selectedId,
        }));

        uiService.showLoading();
        const motd = await motdService.getMotd(selectedId);
        uiService.hideLoading();

        if (!motd) {
            uiService.showErrorNotification('Failed to load the message!');
            return;
        }
        const sortedMotd = {
            ...motd,
            variables: !!motd.variables
                ? motd.variables.sort((a, b) => a.motd_template_var_id - b.motd_template_var_id)
                : [],
        };
        setMotd(sortedMotd);
        setUpdateEnabled(false);
    };

    const generateMessage = (updatedVariables: MotdVariable[], motdTemplate?: Motd) => {
        if (!motdTemplate && !!motd) {
            motdTemplate = motd;
        }
        if (!motdTemplate) return;

        let message = motdTemplate.template_pattern;

        updatedVariables.forEach((variable, i) => {
            const { motd_value, variable_type } = variable;
            let value = motd_value;

            if (variable_type === 'datetime') {
                value = dateTimeService.formatDate(
                    new Date(motd_value),
                    'dddd, MMMM DD YYYY hh:mm:ss',
                    'America/Chicago'
                );
            }

            if (variable_type === 'date') {
                value = dateTimeService.formatDate(new Date(motd_value), 'dddd, MMMM DD');
            }

            if (variable_type === 'time') {
                value = dateTimeService.formatTime(motd_value);
            }

            const pattern = `{${i}}`;
            message = message.replace(pattern, value);
        });

        return message;
    };

    const onSaveMotd = async () => {
        if (!motd) return;
        uiService.showLoading();
        const response = await motdService.updateMotd(motd);

        uiService.hideLoading();

        if (response) {
            uiService.showSuccessNotification('Message updated successfully!');
            getData(false);
            return;
        } else {
            uiService.showErrorNotification('Error when updating message!');
        }
    };

    const onUpdateTemplate = async (templateId: number) => {
        if (!motd) return;
        if (templateId !== motd.template_id) {
            setIsUpdatingTemplate(true);
            const newTemplate = await motdService.getMotdTemplate(templateId);

            if (newTemplate) {
                const newVariables = newTemplate.variables.map((v) => {
                    const existingVariable = motd.variables.find((mv) => mv.motd_template_var_id === v.id);
                    if (existingVariable) {
                        return existingVariable;
                    } else {
                        return motdService.getMotdDefaultVariable(v);
                    }
                });

                const newMotd: Motd = {
                    ...motd,
                    template_id: newTemplate.id,
                    template_pattern: newTemplate.pattern,
                    variables: newVariables,
                };

                newMotd.message = generateMessage(newVariables, newMotd)!;

                setMotd(newMotd);
            }
            setIsUpdatingTemplate(false);
            setUpdateEnabled(true);
        }
    };

    const onDeleteMotd = async () => {
        if (!motd) return;

        const prompt = await uiService.showConfirmationDialog(
            'Are you sure you want to delete the message?',
            'No',
            'Yes'
        );

        if (!prompt) return;

        uiService.showLoading();
        const response = await motdService.deleteMotd(motd.id);
        uiService.hideLoading();

        if (response) {
            uiService.showSuccessNotification('Message deleted successfully!');
            getData(true);
            return;
        } else {
            uiService.showErrorNotification('Error when deleting the message!');
        }
    };

    const onForceDeleteMotd = async () => {
        const prompt = await uiService.showConfirmationDialog(
            'Are you sure you want to delete the message?',
            'No',
            'Yes'
        );

        if (!prompt) return;

        uiService.showLoading();
        const response = await motdService.deleteMotd(motdList.selectedId);
        uiService.hideLoading();

        if (response) {
            uiService.showSuccessNotification('Message deleted successfully!');
            getData(true);
            return;
        } else {
            uiService.showErrorNotification('Error when deleting the message!');
        }
    };

    const productListData = [
        {
            value: 'kd-sim',
            label: 'Simulator',
        },
        {
            value: 'wod-launcher',
            label: 'Launcher',
        },
    ];

    const renderList = () => {
        const { itemList, page, selectedId } = motdList;

        return (
            <Container px={0} py="sm">
                <ListViewComponent
                    elements={itemList}
                    page={page}
                    pageChanged={onListPageChanged}
                    selectionChanged={onListSelectionChanged}
                    selectedValue={selectedId}
                    listElement={(entry, selectionChanged, inSelect) => {
                        const { title } = entry;
                        return (
                            <DefaultListOptionView
                                key={entry.id}
                                value={title}
                                id={entry.id}
                                inSelectContainer={inSelect}
                                selectionChange={selectionChanged}
                                selected={entry.id === selectedId}
                            />
                        );
                    }}
                >
                    <ListViewComponent.Header>
                        <Select
                            style={{
                                marginBottom: '10px'
                            }}
                            data={productListData}
                            value={motdProduct}
                            onChange={(e) => {
                                if (!!e) setMotdProduct(e);
                            }}
                        ></Select>
                    </ListViewComponent.Header>
                </ListViewComponent>
            </Container>
        );
    };

    const renderMotdForm = () => {
        if (motdList.selectedId === -1) return null;

        return (
            <MOTDForm
                saveMotd={onSaveMotd}
                isLoading={isUpdatingTemplate}
                updateEnabled={updateEnabled}
                updateMotd={(motd) => {
                    setMotd(motd);
                    setUpdateEnabled(true);
                }}
                motd={motd}
                selectedMotdId={motdList.selectedId}
                updateTemplate={onUpdateTemplate}
                generateMessage={generateMessage}
                deleteMotd={onDeleteMotd}
                forceDeleteMotd={onForceDeleteMotd}
            />
        );
    };

    return (
        <>
            <Grid gutter={0} w="calc(100vw - 250px)">
                <Grid.Col span="content">{renderList()}</Grid.Col>
                <Grid.Col span="auto">{renderMotdForm()}</Grid.Col>
            </Grid>
            <CreateMotd 
                product={motdProduct}
                getData={getData} 
                uiService={uiService} 
                motdService={motdService} />
        </>
    );
};

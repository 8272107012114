import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { Container, Grid, Select } from '@mantine/core';

import DefaultListOptionView from '../../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../../general/list-view/list-view.component';

import type IUIService from '@/services/IUIService';
import { ScopeForm } from '../scope-form/scope-form.component';
import { CreateScope } from '../create-scope/create-scope.component';
import { ScopeList } from '@/models/scope/ScopeList';
import { IScopeService } from '@/services/IScopeService';
import { ICreateScopeModel, ScopeAccessLevelModel } from '@/models/scope/ScopeModel';

export const ScopeCPDirectory = () => {
    const [list, setList] = useState<ScopeList | null>(null);
    const [page, setPage] = useState(1);
    const [selectedScopeId, setSelectedScopeId] = useState<string | null>(null);
    const [currentSelectedScope, setCurrentSelectedScope] = useState<ScopeAccessLevelModel | null>(null);
    const [updateEnabled, setUpdateEnabled] = useState(false);
    const [scopeType, setScopeType] = useState<string>('user');

    const scopeService = useInjection<IScopeService>('IScopeService');
    const uiService = useInjection<IUIService>('IUIService');

    useEffect(() => {
        if (scopeType) {
            getData(true);
        }
    }, [page, scopeType]);

    useEffect(() => {
        if (selectedScopeId !== null) {
            onListSelectionChanged(selectedScopeId);
        }
    }, [selectedScopeId]);

    const getData = async (forceSetFirst = false) => {
        uiService.showLoading();
        const response = await scopeService.getScopes(scopeType);

        uiService.hideLoading();
        if (response === null) {
            uiService.showErrorNotification('Loading Scope list failed!');
            return;
        }

        const setToFirst = (selectedScopeId === null && response.scopes.length > 0) || forceSetFirst;
        const firstId = response.scopes.length > 0 ? response.scopes[0].id : null;

        setList(response);
        setSelectedScopeId(setToFirst ? firstId : selectedScopeId);
    };

    const onListSelectionChanged = async (selectedScopeId: string) => {
        const scopeId = list?.scopes.find((x) => x.id === selectedScopeId)?.id;
        uiService.showLoading();

        try {
            const response = await scopeService.getScope(scopeId!);
            if (response === null) {
                uiService.hideLoading();
                uiService.showErrorNotification('Failed to get scope data');
                return;
            }
            setCurrentSelectedScope(response);

            setUpdateEnabled(false);
            uiService.hideLoading();
        } catch (error) {
            uiService.hideLoading();
        }
    };

    const onSelectionChanged = (selectedId: string) => {
        setSelectedScopeId(selectedId);
    };

    const onListPageChanged = (page: number) => {
        setPage(page);
    };

    const onSave = async (newScopeAccessLevels: ScopeAccessLevelModel) => {
        if (selectedScopeId === null || newScopeAccessLevels === null) return;
        uiService.showLoading();
        const success = await scopeService.updateScopeAccessLevels(selectedScopeId, newScopeAccessLevels);
        uiService.hideLoading();
        if (success) {
            uiService.showSuccessNotification('Scope successfuly update');
        } else {
            uiService.showErrorNotification('Failed to update the serivce');
        }
    };

    const onDelete = async () => {
        if (selectedScopeId === null) return;
        const promptResponse = await uiService.showConfirmationDialog(
            `Delete scope ${selectedScopeId}?`,
            'Cancel',
            'Delete'
        );
        if (!promptResponse) return;
        uiService.showLoading();
        const success = await scopeService.deleteScope(selectedScopeId);
        await getData(true);

        uiService.hideLoading();

        if (success) {
            uiService.showSuccessNotification('Scope successfuly deleted');
        } else {
            uiService.showErrorNotification('Failed to delete the scope');
        }
    };

    const onUpdate = () => {
        setUpdateEnabled(true);
    };

    const onScopeTypeChange = (value: string | null) => {
        if (value) {
            setScopeType(value);
        }
    };

    const onCreateScope = async (scope: ICreateScopeModel) => {
        const response = await scopeService.createScope(scope);
        await getData(true);
        if (response) {
            uiService.showSuccessNotification('Scope successfuly created');
        } else {
            uiService.showErrorNotification('Failed to create the scope');
        }
    };

    const renderScopeList = () => {
        if (list === null) return null;

        return (
            <Container px={0} pt="md">
                <Select
                    size="sm"
                    px="sm"
                    mb="sm"
                    styles={{
                        input: {
                            height: 30,
                        },
                    }}
                    data={[
                        { label: 'Service', value: 'service' },
                        { label: 'User', value: 'user' },
                    ]}
                    value={scopeType}
                    onChange={onScopeTypeChange}
                />
                <ListViewComponent
                    elements={list}
                    page={page}
                    selectedValue={selectedScopeId}
                    containerId="scopes-list"
                    listId="scopes"
                    listClass="scope_list"
                    pageChanged={onListPageChanged}
                    selectionChangedString={onSelectionChanged}
                    listElement={(element, selectionChanged, inSelect) => {
                        return (
                            <DefaultListOptionView
                                key={element.id}
                                value={element.display}
                                id={element.id}
                                selectionChange={selectionChanged}
                                inSelectContainer={inSelect}
                                selected={selectedScopeId === element.id}
                            />
                        );
                    }}
                />
            </Container>
        );
    };

    const renderForm = () => {
        if (currentSelectedScope === null) return null;
        return (
            <ScopeForm
                scopeAccessLevels={currentSelectedScope}
                delete={onDelete}
                update={onUpdate}
                save={onSave}
                updateEnabled={updateEnabled}
            />
        );
    };

    return (
        <>
            <Grid w="calc(100vw - 250px)">
                <Grid.Col span="content">{renderScopeList()}</Grid.Col>
                <Grid.Col span="auto">{renderForm()}</Grid.Col>
            </Grid>
            <CreateScope onCreateScope={onCreateScope} />
        </>
    );
};

import IList from "../../IList";
import UserEntry from "./UserEntry";

export default class UserList implements IList<UserEntry>{
    public max_results!: number;
    public accounts! : UserEntry[];
    
    getItems(): UserEntry[] {
       return this.accounts;
    }
}
import Nullable from '@/dataTypes/Nullable';
import { IMonitoringRESTClient } from '@/rest-clients/IMonitoringRESTClient';
import { IGSOService } from '@/services/IGSOService';
import IUIService from '@/services/IUIService';
import { Button, Card, Flex } from '@mantine/core';
import { useInjection } from 'inversify-react';
import { useState } from 'react';

export default function ForceStopRoom({
    shard,
    guid,
    roomId,
    close,
}: {
    shard: string;
    guid: string;
    roomId: string;
    close: () => void;
}) {
    const gsoService = useInjection<IGSOService>('IGSOService');
    const uiService = useInjection<IUIService>('IUIService');
    const monitoringService = useInjection<IMonitoringRESTClient>('IMonitoringRESTClient');

    const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);

    return (
        <>
            {errorMessage === null && (
                <DefaultStopRoom
                    roomId={roomId}
                    close={close}
                    confirm={async () => {
                        uiService.showLoading();
                        const response = await gsoService.forceStopTheGameRoom(roomId);
                        uiService.hideLoading();
                        if (response === null) {
                            uiService.showSuccessNotification('Successfuly stopped the game room');
                            close();
                        } else {
                            setErrorMessage(response);
                        }
                    }}
                />
            )}
            {!!errorMessage && (
                <ErrorStopRoom
                    errorMessage={errorMessage}
                    close={close}
                    deleteOnlyFromMonitoring={async () => {
                        uiService.showLoading();
                        const response = await monitoringService.deleteRoom(shard, guid);
                        uiService.hideLoading();

                        if(!!response){
                            uiService.showSuccessNotification('Deleted the room from monitoring!');
                        }
                        else{
                            uiService.showErrorNotification('Failed to delete the room!')
                        }

                        close();
                    }}
                />
            )}
        </>
    );
}

function DefaultStopRoom({ roomId, close, confirm }: { roomId: string; close: () => void; confirm: () => void }) {
    return (
        <Flex direction={'column'} rowGap={'sm'}>
            Do you want to force stop room: {roomId}?
            <Flex direction={'row'} columnGap={'sm'} align={'stretch'}>
                <Button color="red" onClick={close} sx={{ flex: 1 }}>
                    Cancel
                </Button>
                <Button color="blue" sx={{ flex: 1 }} onClick={confirm}>
                    Confirm
                </Button>
            </Flex>
        </Flex>
    );
}

function ErrorStopRoom({
    errorMessage,
    close,
    deleteOnlyFromMonitoring,
}: {
    errorMessage: string;
    close: () => void;
    deleteOnlyFromMonitoring: () => void;
}) {
    return (
        <Flex direction={'column'} rowGap={'sm'}>
            {errorMessage}
            <Flex direction={'row'} columnGap={'sm'} align={'stretch'}>
                <Button color="red" onClick={deleteOnlyFromMonitoring} sx={{ flex: 1 }}>
                    Delete Only From Monitoring
                </Button>
                <Button color="blue" onClick={close} sx={{ flex: 1 }}>
                    OK
                </Button>
            </Flex>
        </Flex>
    );
}

import type { ISessionStorageService } from '@/services/ISessionStorageService';
import { inject, injectable } from 'inversify';
import IScopeRepositoryService from '../IScopeRepositoryService';

@injectable()
export default class ScopeRepositoryService implements IScopeRepositoryService {
    @inject('ISessionStorageService')
    private readonly storageService!: ISessionStorageService;

    private cacheScopeTotal(total: number): void {
        this.storageService.set(`total:scope`, total.toString());
    }

    getScopeTotal() {
        return this.storageService.get(`total:scope`);
    }

    setScopeTotal(accessLevels: number[]) {
        const total = accessLevels.reduce((a, b) => a + b, 0);
        this.cacheScopeTotal(total);
    }

    setLoggedInUserScope(scope: string) {
        const scopeType = scope.split('scope/').pop();
        this.storageService.set('user:scope', scopeType!);
    }

    getLoggedInUserScope() {
        return this.storageService.get('user:scope');
    }
}

import { TextInput, Text, Badge, Modal, Table, Group, Loader } from '@mantine/core';
import ProductVersionModel from '../../../models/devops/ProductVersionModel';
import BundleBuildList from '../bundle-build-list/bundle-build-list.component';
import styles from './common-product-version.module.scss';
import { useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import IGDMService from '@/services/IGDMService';
import IncompatibilityType from '@/models/gdm/IncompatibilityType';
import Nullable from '@/dataTypes/Nullable';
import VersionList from '@/models/devops/VersionList';
import classes from '../../../utils/classes';
interface CommonProductVersionProps {
    versionInfo: ProductVersionModel;
    updateModel: (versionInfo: ProductVersionModel) => void;
    refreshArtifacts: () => void;
    previousVersion?: string;
    allVersions: VersionList | null;
}

export default function CommonProductVersion(props: CommonProductVersionProps) {
    const { versionInfo, updateModel, previousVersion, allVersions } = props;
    const gdmService = useInjection<IGDMService>('IGDMService');

    const [prevCompatibility, setPrevCompatibility] = useState<Nullable<IncompatibilityType[]>>(null);
    const [mainCompatibility, setMainCompatibility] = useState<Nullable<IncompatibilityType[]>>(null);

    const [prevModalOpen, setPrevModalOpen] = useState(false);
    const [mainModalOpen, setMainModalOpen] = useState(false);

    const isLatest = () => {
        if (!!allVersions) {
            const currentVersionIndex = allVersions.versions.findIndex((x) => x.id === versionInfo.id);

            return currentVersionIndex === 0;
        }

        return false;
    };

    useEffect(() => {
        if (!!allVersions) {
            setPrevCompatibility(null);
            setMainCompatibility(null);

            const prevVersion = () => {
                if (!!allVersions) {
                    const currentVersionIndex = allVersions.versions.findIndex((x) => x.id === versionInfo.id);
                    if (currentVersionIndex !== -1) {
                        return allVersions.versions[currentVersionIndex + 1].version;
                    }
                }

                return '-1';
            };

            const fetchData = () => {
                const prev = prevVersion();
                console.log(previousVersion + ' c' + versionInfo.version);

                const promises: Promise<unknown>[] = [];

                const cancelPromises: (() => void)[] = [];

                const makeCancelable = (promise: Promise<void>) => {
                    let hasCanceled_ = false;

                    const wrappedPromise = new Promise((resolve, reject) => {
                        promise.then((val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)));
                        promise.catch((error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)));
                    });

                    return {
                        promise: wrappedPromise,
                        cancel() {
                            hasCanceled_ = true;
                        },
                    };
                };

                if (!!prev && versionInfo.version !== prev && prev !== '-1') {
                    const prevCompatibility = makeCancelable(
                        gdmService.getCompatibility(prev, versionInfo.version).then((x) => {
                            setPrevCompatibility(x);

                            if (!!versionInfo.version && isLatest()) {
                                const latestCompatibility = makeCancelable(
                                    gdmService.getCompatibility(versionInfo.version, 'last-commit').then((x) => {
                                        setMainCompatibility(x);
                                    })
                                );
            
                                promises.push(latestCompatibility.promise);
                                cancelPromises.push(latestCompatibility.cancel);
                            }
                        })
                    );
                    promises.push(prevCompatibility.promise);
                    cancelPromises.push(prevCompatibility.cancel);
                }

               

                return () => {
                    cancelPromises.forEach((x) => {
                        x();
                    });
                };
            };

            const promiseCancel = fetchData();

            return () => {
                promiseCancel();
            };
        }
    }, [versionInfo.version]);

    useEffect(() => {}, [prevCompatibility]);

    const getInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        return e.target.value;
    };

    const incompatibilityContent = (compatibility: Nullable<IncompatibilityType[]>) => {
        return (
            <Table highlightOnHover>
                <thead>
                    <tr>
                        <th>NAME</th>
                        <th>TYPE</th>
                    </tr>
                </thead>
                <tbody>
                    {!!compatibility && compatibility.map((x, index) => (
                        <tr key={index}>
                            <td>{x.name}</td>
                            <td>{x.compatibilityType}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };

    return (
        <>
            <div className="row">
                <TextInput
                    value={versionInfo.version}
                    disabled
                    onChange={(e) => {
                        versionInfo.version = getInputValue(e);
                        updateModel(versionInfo);
                    }}
                    label="Version Name"
                />
                {!!allVersions && !!allVersions.versions && allVersions.versions.length > 0 && (
                    <Group style={{ marginTop: '15px' }}>
                        <CompatibilityViewBadge
                            compatibility={prevCompatibility}
                            prevVersion={previousVersion}
                            onClick={() => {
                                setPrevModalOpen(true);
                            }}
                        />
                        {isLatest() && (
                            <CompatibilityViewBadge
                                compatibility={mainCompatibility}
                                prevVersion="Main"
                                onClick={() => {
                                    setMainModalOpen(true);
                                }}
                            />
                        )}
                    </Group>
                )}
            </div>
            <div className="row">
                <TextInput value={versionInfo.sha} disabled label="Hash" />
            </div>
            <div className="row">
                <TextInput
                    rightSection={
                        <Text
                            sx={{
                                marginRight: '15px',
                            }}
                            size="xs"
                            color="gray"
                        >
                            GMT+0
                        </Text>
                    }
                    value={versionInfo.release_date}
                    disabled
                    label="Release Date"
                />
            </div>
            <div className="row">
                <div className={styles.flexRowContainer}>
                    <label>Artifacts</label>

                    <div className={styles.last}>
                        <label>
                            {versionInfo.artifacts &&
                                versionInfo.artifacts.reduce((p, c) => p + (c.state === 5 ? 1 : 0), 0)}
                            /{versionInfo.artifacts && versionInfo.artifacts.length}
                        </label>
                    </div>
                </div>
                <BundleBuildList bundles={versionInfo.artifacts} refreshArtifacts={props.refreshArtifacts} />
            </div>
            <Modal
                opened={prevModalOpen}
                onClose={() => {
                    setPrevModalOpen(false);
                }}
                title={`Incompatibility with ${previousVersion}`}
            >
                {incompatibilityContent(prevCompatibility)}
            </Modal>

            <Modal
                opened={mainModalOpen}
                onClose={() => {
                    setMainModalOpen(false);
                }}
                title="Incompatibility with Main"
            >
                {incompatibilityContent(mainCompatibility)}
            </Modal>
        </>
    );
}

export function CompatibilityViewBadge({
    compatibility,
    prevVersion,
    onClick,
}: {
    compatibility: Nullable<IncompatibilityType[]>;
    prevVersion?: string;
    onClick?: () => void;
}) {
    if (compatibility === null) {
        return (
            <Badge color="gray">
                <Loader size="xs" style={{ scale: '0.8', translate: '0% 15%' }} /> Pending
            </Badge>
        );
    }
    if (compatibility.length === 0) {
        return <Badge color="green"  className={classes(styles.compatibilityBadge)}>Compatible with {prevVersion}</Badge>;
    }
    return (
        <Badge
            color="red"
            className={classes(styles.compatibilityBadge,styles.error)}
            onClick={(e) => {
                if (!!onClick) onClick();
            }}
        >
            Incompatible with {prevVersion}
        </Badge>
    );
}

import TopicList from "../../models/mail/TopicList";
import { IMailRESTClient } from "../IMailRESTClient";
import { RESTClient } from "./RESTClient";
import { inject, injectable } from "inversify";
import type { IAuthService } from "@/services/IAuthService";
import Nullable from "@/dataTypes/Nullable";
import axios from "axios";

@injectable()
export default class MailRESTClient extends RESTClient implements IMailRESTClient{
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    constructor() {
        super();
        this.setBaseURL('https://wod-mail-uux56memxa-uc.a.run.app/api/v1/');
    }
    
    async getTopicsList(page: number): Promise<Nullable<TopicList>> {
        const token = await this.authService.getToken();
        if (token === null) {
            return null;
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            return null;
        }

        const path = this.getFullURL(`topics?page=${page}`);
        try{
            const response = await axios.get(path, this.getHeader(authToken));
            const list = response.data as TopicList;
            const newList = new TopicList();
            newList.items = list.items;
            newList.max_results = list.max_results
            return newList;
        }
        catch{
            return null;
        }
    }


    async getTopicSubscribers(name: string): Promise<string[]> {
        const token = await this.authService.getToken();
        if (token === null) {
            return [];
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            return [];
        }

        const path = this.getFullURL(`topic/${name}/subscribers`);
        try{
            const response = await axios.get(path, this.getHeader(authToken));
            const list = response.data as string[];
            return list;
        }
        catch{
            return [];
        }
    }

    async subscribeToTopic(email : string, topic : string) : Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) {
            return false;
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            return false;
        }

        const path = this.getFullURL(`subscription/${email}/topic/${topic}`);
        try{
            const response = await axios.post(path, null, this.getHeader(authToken));
            return true;
        }
        catch{
            return false;
        }
    }

    async deleteSubscription(email : string, topic : string) : Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) {
            return false;
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            return false;
        }

        const path = this.getFullURL(`subscription/${email}/topic/${topic}`);
        try{
            const response = await axios.delete(path, this.getHeader(authToken));
            return true;
        }
        catch{
            return false;
        }
    }

    async createTopic(topic : string) : Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) {
            return false;
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            return false;
        }

        const path = this.getFullURL(`topic/${topic}`);
        try{
            const response = await axios.post(path, null,  this.getHeader(authToken));
            return true;
        }
        catch{
            return false;
        }
    }

    async deleteTopic(topic : string) : Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) {
            return false;
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            return false;
        }

        const path = this.getFullURL(`topic/${topic}`);
        try{
            const response = await axios.delete(path, this.getHeader(authToken));
            return true;
        }
        catch{
            return false;
        }
    }


    async updateTopic(id: number, topic : string) : Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) {
            return false;
        }

        const authToken = await this.getToken(token);

        if (authToken === null) {
            return false;
        }

        const path = this.getFullURL(`topic/${id}/name/${topic}`);
        try{
            const response = await axios.put(path, null,  this.getHeader(authToken));
            return true;
        }
        catch{
            return false;
        }
    }
}
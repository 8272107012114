import { useState } from 'react';
import { useForm } from '@mantine/form';

import { Modal, Stack, TextInput, Button, Select } from '@mantine/core';
import { ICreateScopeModel } from '@/models/scope/ScopeModel';
import { CreateButton } from '../../../general/create-button/create-button.component';

interface ICreateScopeProps {
    onCreateScope: (values: ICreateScopeModel) => Promise<void>;
}

export const CreateScope = ({ onCreateScope }: ICreateScopeProps) => {
    const [opened, setOpened] = useState(false);

    const closeModel = () => {
        setOpened(false);
    };

    return (
        <>
            <Modal
                opened={opened}
                centered
                closeOnClickOutside={false}
                onClose={() => setOpened(false)}
                title="Create new Scope"
                styles={{
                    title: {
                        fontSize: '1.2rem',
                        fontWeight: 600,
                    },
                }}
            >
                <CreateScopeForm onCreateScope={onCreateScope} closeModel={closeModel} />
            </Modal>

            <CreateButton title="Create new Scope" click={() => setOpened(true)} />
        </>
    );
};

interface ICreateScopeFormProps extends ICreateScopeProps {
    closeModel: () => void;
}

const CreateScopeForm = ({ closeModel, onCreateScope }: ICreateScopeFormProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const form = useForm<ICreateScopeModel>({
        initialValues: {
            display: '',
            id: '',
            scopeType: '',
        },

        validate: {
            display: (value) => (value === '' ? 'Name is required' : null),
            id: (value) => (value === '' ? 'Scope id is required' : null),
            scopeType: (value) => (value === '' ? 'Scope type is required' : null),
        },
    });

    const onSubmit = async (values: ICreateScopeModel) => {
        setIsLoading(true);
        await onCreateScope(values);
        setIsLoading(false);
        closeModel();
    };

    const transformScopeID = (value: string) => {
        const transformedValue = value.toLowerCase().replace(/ /g, '-');
        form.setFieldValue('id', transformedValue);
    };

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack spacing="md" mt={30}>
                <TextInput
                    variant="filled"
                    label="Scope name"
                    radius="md"
                    size="md"
                    {...form.getInputProps('display')}
                />
                <TextInput
                    variant="filled"
                    label="Scope ID"
                    radius="md"
                    size="md"
                    value={form.values.id}
                    error={form.errors.id}
                    onChange={(event) => transformScopeID(event.currentTarget.value)}
                />
                <Select
                    variant="filled"
                    label="Scope type"
                    radius="md"
                    data={[
                        { label: 'User', value: 'user' },
                        { label: 'Service', value: 'service' },
                    ]}
                    {...form.getInputProps('scopeType')}
                />
                <Button type="submit" size="md" radius="md" loading={isLoading} loaderPosition="center">
                    {!isLoading && 'Save'}
                </Button>
            </Stack>
        </form>
    );
};

import qrBGLayoutImage from '../../../assets/images/qrCodeLayout.png';
import React from "react";

class QRLayoutCanvas extends React.Component<{},{}>{

    private canvasRef = React.createRef<HTMLCanvasElement>();
    private bgImage? : HTMLImageElement;

    public getContext(){
        const canvas = this.canvasRef.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        return context;
    };

    public getCanvas(){
        const canvas = this.canvasRef.current;
        return canvas;
    }

    public resetCanvas(){
        const context = this.getContext();
        if (!context) return;
        if(!this.bgImage) return;
        context.drawImage(this.bgImage, 0, 0);
    }


    componentDidMount(): void {
        const context = this.getContext();
        if (!context) return;
        //Our first draw
        context.fillStyle = '#ffffff';
        context.fillRect(0, 0, context.canvas.width, context.canvas.height);

        this.bgImage = new Image();
        this.bgImage.onload = () => {
            if(!this.bgImage)
            return;
            context.drawImage(this.bgImage, 0, 0);
        };
        this.bgImage.src = qrBGLayoutImage;
    }

    render(): React.ReactNode {
        return (
            <div
                style={{
                    width: 330,
                    height: 255,
                    display:'none'
                }}
            >
                <canvas
                    ref={this.canvasRef}
                    width={3300}
                    height={2550}
                    style={{
                        scale: 0.1,
                    }}
                />
            </div>
        );
    }

    
};



export type QRLayoutCanvasType = InstanceType<typeof QRLayoutCanvas>;

export default QRLayoutCanvas;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface DevopsCategoryState {
    category: string;
}

const initialState: DevopsCategoryState = {
    category : 'kd-sim'
}

export const devopsCategorySlice = createSlice({
    name: 'devopsCategory',
    initialState,
    reducers: {
        setCategory: (state, action: PayloadAction<string>) => {
            state.category = action.payload
        },
    },
})

export const { setCategory } = devopsCategorySlice.actions

export const selectCategory = (state: RootState) => state.devopsCategory.category

export default devopsCategorySlice.reducer

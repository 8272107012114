import { Grid } from '@mantine/core';
import SecopsList from './secops-list.component';
import { useEffect, useState } from 'react';
import SecopsReport from '../../../models/secops/SecopsReport';
import SecopsReportList from '../../../models/secops/SecopsReportList';
import Nullable from '@/dataTypes/Nullable';
import SecopsFormComponent from '../secops-form/secops-form.component';
import { useInjection } from 'inversify-react';
import ISecopsService from '@/services/ISecopsService';
import IUIService from '@/services/IUIService';

export default function SecopsDirectory() {
    const [page, setPage] = useState(1);
    const [list, setList] = useState<Nullable<SecopsReportList>>(null);
    const [currentSelectedReportId, setCurrentSelectedReportId] = useState<Nullable<number>>(null);
    const [currentSelectedReport, setCurrentSelectedReport] = useState<Nullable<SecopsReport>>(null);

    const secopsService = useInjection<ISecopsService>('ISecopsService');
    const uiService = useInjection<IUIService>('IUIService');

    useEffect(() => {
        getData(true);
    }, []);

    useEffect(() => {
        if (!!currentSelectedReportId) 
            onSelectedChange(currentSelectedReportId!);
    }, [currentSelectedReportId]);

    useEffect(()=>{

    }, [currentSelectedReport])

    const getData = async (forceSelectFirst?: boolean) => {
        uiService.showLoading();
        const response = await secopsService.getReportsList(page);
        uiService.hideLoading();

        if (!!response) {
            setList(response);

            if (!!forceSelectFirst && !!response.items && response.items.length > 0) {
                setCurrentSelectedReportId(response.items[0].id);
            }
        } else {
            uiService.showErrorNotification('Failed to get reports');
        }
    };

    const onSelectedChange = async (id: number) => {
        uiService.showLoading();
        const response = await secopsService.getReport(id);
        uiService.hideLoading();

        if(!response){
            uiService.showErrorNotification('Failed to load report'); 
            return;
        }

        setCurrentSelectedReport(response);
    };

    return (
        <Grid w="calc(100vw - 260px)">
            <Grid.Col span="content">
                <SecopsList
                    reportList={list}
                    onPageChange={(p) => {
                        setPage(p);
                    }}
                    page={page}
                    selectedId={currentSelectedReportId}
                    selectionChange={(change) => {
                        setCurrentSelectedReportId(change);
                    }}
                    generateClick={async () => {
                        uiService.showLoading();
                        const response = await secopsService.generateReport();
                        uiService.hideLoading();

                        if(!response){
                            uiService.showErrorNotification('Failed to generate report');
                        }

                        await getData(true);
                    }}
                />
            </Grid.Col>
            <Grid.Col span="auto" sx={{marginRight: '6px'}}>
                {!!currentSelectedReport ? <SecopsFormComponent secopsReport={currentSelectedReport} /> : 'No Data Selected'}
            </Grid.Col>
        </Grid>
    );
}


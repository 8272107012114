import { Container } from 'inversify';
import DoDRESTClient from './rest-clients/Implementations/DoDRESTClient';
import UIService from './services/Implementations/UIService';
import LayoutService from './services/Implementations/LayoutService';
import TextureRegistry from './services/Implementations/TextureRegistry';
import GoogleStorageRESTClient from './rest-clients/Implementations/GoogleStorageRESTClient';
import FileNameValidatorService from './services/Implementations/FileNameValidatorService';
import WorkerRequestService from './services/Implementations/WorkerRequestService';
import ZoomService from './services/Implementations/ZoomService';
import UserManagementService from './services/Implementations/UserManagementService';
import UserManagementRESTClient from './rest-clients/Implementations/UserManagementRESTClient';
import DevOpsService from './services/Implementations/DevOpsService';
import DevOpsRESTClient from './rest-clients/Implementations/DevOpsRESTClient';
import ImageThumbRESTClient from './rest-clients/Implementations/ImageThumbRESTClient';
import TransRESTClient from './rest-clients/Implementations/TransRESTClient';
import StoreItemService from './services/Implementations/StoreItemService';
import TransService from './services/Implementations/TransService';
import FailedTransactionService from './services/Implementations/FailedTransactionService';
import MotdService from './services/Implementations/MotdService';
import GDMRESTClient from './rest-clients/Implementations/GDMRESTClient';
import ProductKeyService from './services/Implementations/ProductKeyService';
import { WindowMessage } from './services/Implementations/WindowMessage';
import GithubRESTClient from './rest-clients/Implementations/GithubRESTClient';
import CreateProductWizardService from './services/Implementations/CreateProductWizardService';
import ScopeService from './services/Implementations/ScopeService';
import UnityService from './services/Implementations/UnityService';
import BuildRepositoryService from './services/Implementations/BuildRepositoryService';
import ScopeRepositoryService from './services/repositories/Implementations/ScopeRepositoryService';
import GithubService from './services/Implementations/GithubService';
import ServiceManagementService from './services/Implementations/ServiceManagementService';
import ServiceManagementRESTClient from './rest-clients/Implementations/ServiceManagementRESTClient';
import SecurityProviderFactoryService from './services/factories/Implementations/SecurityProviderFactoryService';
import ViewFactoryService from './services/factories/Implementations/ViewFactoryService';
import BundleService from './services/Implementations/BundleService';
import { AuthRESTClient } from './rest-clients/Implementations/AuthRESTClient';
import { SessionStorageService } from './services/Implementations/SessionStorageService';
import { AuthService } from './services/Implementations/AuthService';
import { UserValidator } from './services/Implementations/UserValidator';
import { AuthGateRESTClient } from './rest-clients/Implementations/AuthGateRESTClient';
import AzureService from './services/Implementations/AzureService';
import AzureRESTClient from './rest-clients/Implementations/AzureRESTClient';
import MonitoringRESTClient from './rest-clients/Implementations/MonitoringRESTClient';
import ServerActivityService from './services/Implementations/ServerActivityService';
import DateTimeService from './services/Implementations/DateTimeService';
import GSORESTClient from './rest-clients/Implementations/GSORESTClient';
import GSOService from './services/Implementations/GSOService';
import GDMService from './services/Implementations/GDMService';
import ServerLogService from './services/Implementations/ServerLogService';
import AssetGroupService from './services/Implementations/AssetGroupService';
import SecOpsRESTClient from './rest-clients/Implementations/SecOpsRESTClient';
import SecopsService from './services/Implementations/SecopsService';
import TreasuryService from './services/Implementations/TreasuryService';
import TreasuryRESTClient from './rest-clients/Implementations/TreasuryRESTClient';
import MailRESTClient from './rest-clients/Implementations/MailRESTClient';

export default function BootstrapApp(container: Container) {
    initializeRESTClients(container);
    initializeServices(container);
}

function initializeServices(container: Container) {
    container.bind('IUIService').to(UIService).inSingletonScope();
    container.bind('ILayoutService').to(LayoutService).inSingletonScope();
    container.bind('IUserManagementService').to(UserManagementService).inSingletonScope();
    container.bind('IZoomService').to(ZoomService).inSingletonScope();
    container.bind('IStoreItemService').to(StoreItemService).inSingletonScope();
    container.bind('ITransService').to(TransService).inSingletonScope();
    container.bind('IDevOpsService').to(DevOpsService).inSingletonScope();
    container.bind('IFailedTransactionService').to(FailedTransactionService).inSingletonScope();
    container.bind('IMotdService').to(MotdService).inSingletonScope();
    container.bind('IWorkerRequestService').to(WorkerRequestService);
    container.bind('IFileNameValidatorService').to(FileNameValidatorService).inSingletonScope();
    container.bind('ITextureRegistry').to(TextureRegistry).inSingletonScope();
    container.bind('IProductKeyService').to(ProductKeyService).inSingletonScope();
    container.bind('IWindowMessage').to(WindowMessage).inSingletonScope();
    container.bind('ICreateProductWizardService').to(CreateProductWizardService).inSingletonScope();
    container.bind('IScopeService').to(ScopeService).inSingletonScope();
    container.bind('IUnityService').to(UnityService).inSingletonScope();
    container.bind('IBuildRepositoryService').to(BuildRepositoryService).inSingletonScope();
    container.bind('IScopeRepositoryService').to(ScopeRepositoryService).inSingletonScope();
    container.bind('IGithubService').to(GithubService).inSingletonScope();
    container.bind('IServiceManagementService').to(ServiceManagementService).inSingletonScope();
    container.bind('ISecurityProviderFactoryService').to(SecurityProviderFactoryService).inSingletonScope();
    container.bind('IViewFactoryService').to(ViewFactoryService).inSingletonScope();
    container.bind('IBundleService').to(BundleService).inSingletonScope();
    container.bind('IAzureService').to(AzureService).inSingletonScope();
    container.bind('IServerActivityService').to(ServerActivityService).inSingletonScope();
    container.bind('IDateTimeService').to(DateTimeService).inSingletonScope();
    container.bind('IGSOService').to(GSOService).inSingletonScope();
    container.bind('IGDMService').to(GDMService).inSingletonScope();
    container.bind('IServerLogService').to(ServerLogService).inSingletonScope();
    container.bind('IAssetGroupService').to(AssetGroupService).inSingletonScope();
    container.bind('ISecopsService').to(SecopsService).inSingletonScope()
    container.bind('ITreasuryService').to(TreasuryService).inSingletonScope()
}

function initializeRESTClients(container: Container) {
    container.bind('IAuthRESTClient').to(AuthRESTClient).inSingletonScope();
    container.bind('ISessionStorageService').to(SessionStorageService).inSingletonScope();
    container.bind('IAuthService').to(AuthService).inSingletonScope();
    container.bind('IUserValidator').to(UserValidator).inSingletonScope();
    container.bind('IAuthGateRESTClient').to(AuthGateRESTClient).inSingletonScope();
    container.bind('IDoDRESTClient').to(DoDRESTClient).inSingletonScope();
    container.bind('IUserManagementRESTClient').to(UserManagementRESTClient).inSingletonScope();
    container.bind('IGDMRESTClient').to(GDMRESTClient).inSingletonScope();
    container.bind('IGoogleStorageRESTClient').to(GoogleStorageRESTClient).inSingletonScope();
    container.bind('IDevOpsRESTClient').to(DevOpsRESTClient).inSingletonScope();
    container.bind('ITransRESTClient').to(TransRESTClient).inSingletonScope();
    container.bind('IImageThumbRESTClient').to(ImageThumbRESTClient).inSingletonScope();
    container.bind('IAzureRESTClient').to(AzureRESTClient).inSingletonScope();
    container.bind('IGithubRESTClient').to(GithubRESTClient).inSingletonScope();
    container.bind('IServiceManagementRESTClient').to(ServiceManagementRESTClient).inSingletonScope();
    container.bind('IMonitoringRESTClient').to(MonitoringRESTClient).inSingletonScope();
    container.bind('IGSORESTClient').to(GSORESTClient).inSingletonScope();
    container.bind('ISecOpsRESTClient').to(SecOpsRESTClient).inSingletonScope()
    container.bind('ITreasuryRESTClient').to(TreasuryRESTClient).inSingletonScope()
    container.bind('IMailRESTClient').to(MailRESTClient).inSingletonScope()
}

import axios, { AxiosError } from 'axios';
import CaptchaValidationModel from '../../models/auth/CaptchaValidationModel';
import RegistrationModel from '../../models/auth/RegistrationModel';
import { ErrorMessage } from '../../models/ErrorMessage';
import { IAuthGateRESTClient } from '../IAuthGateRESTClient';
import { RESTClient } from './RESTClient';

export class AuthGateRESTClient
    extends RESTClient
    implements IAuthGateRESTClient
{
    constructor() {
        super();
        this.setBaseURL(
            'https://wod-auth-gate-uux56memxa-uc.a.run.app/api/v1/'
        );
    }

     async verifyCaptcha(captcha: string): Promise<boolean> {
        const form = new FormData();
        form.append('response', captcha);

        const path = this.getFullURL('captcha');

        try {
            await axios.post(path, form);
            return true;
        } catch (error) {
            return false;
        }
    }

    async register(email: string, password: string, captcha: string, siteKey : string): Promise<ErrorMessage> {
        const captchaBody : CaptchaValidationModel = {
            response : captcha,
            siteKey : siteKey
        };

        const registrationBody : RegistrationModel = {
            email:email,
            password:password,
            captcha:captchaBody,
            confirmEmailPath : process.env.REACT_APP_ACTIVATE_EMAIL!
        };

        const path = this.getFullURL('user');

        try {
            const jsonString = JSON.stringify(registrationBody);
            const response = await axios.post(path, jsonString, this.getHeaderJson());
            if (this.isSuccess(response)) return null;
        } catch (error) {
            if(axios.isAxiosError(error)){
                const axiosError = error as AxiosError;
                if (axiosError.code === '401')
                    return { value: 'Captcha validation failed' };
                
                return { value: 'Email is already taken.' };
            }
          
        }

        return { value: 'Unexpected error' };
    }

    async reset(email: string): Promise<ErrorMessage> {
        const path = this.getFullURL(`user/${email}/password`);
        const formData = new FormData();
        formData.set('resetURL',process.env.REACT_APP_RESET_EMAIL!);
        try {
            const response = await axios.put(path, formData);
            if (this.isSuccess(response)) return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.code === '404')
                    return { value: 'Email not found!' };
            }
        }

        return { value: 'Unexpected error!' };
    }

    

    async activateUser(key: string): Promise<string> {
        const url = this.getFullURL('user/activation');
        try {
            const formData = new FormData();
            formData.set('hash', key);
            await axios.put(url, formData);
            return 'Account activated successfully!';
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;

                if (axiosError.response?.status === 404) {
                    return 'User not found.';
                }
                if (axiosError.response?.status === 400) {
                    return 'Invalid activation link!';
                }
                if (axiosError.response?.status === 500) {
                    return 'A problem was encountered with the activation process.';
                }
            }
            return 'A problem was encountered with the activation process.';
        }
    }

    async resetPassword(password: string, hash: string): Promise<ErrorMessage> {
        const form = new FormData();
        form.append('password', password);
        form.append('token', hash);

        const path = this.getFullURL('user/password');

        try {
            const response = await axios.put(path, form);
            if (this.isSuccess(response)) return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.code === '404')
                    return { value: 'Could not found user!' };

                if (axiosError.code === '406')
                    return {
                        value: 'Reset Token is not acceptable. Please request a new one.',
                    };

                if (axiosError.code === '400')
                    return {
                        value: 'Supplied password is invalid. Please choose a different one.',
                    };

                if (axiosError.code === '500')
                    return {
                        value: 'A problem was encountered while submitting the request.',
                    };
            }
        }

        return { value: 'Unexpected error!' };
    }

    

    async uploadBuildManually(jwt:string, appName:string,platform: string): Promise<boolean> {

        const path = this.getFullURL(`build`);
        const formData = new FormData();
        formData.set('assertion', jwt);
        formData.set('appName', appName);
        formData.set('platform',platform);
        try {
            await axios.post(path,formData);
            return true;
        } catch (error) {
            return false;
        }
    }
}

import BundleInfoModel from "@/models/devops/build/BundleInfoModel";
import type { IDevOpsRESTClient } from "@/rest-clients/IDevOpsRESTClient";
import { inject, injectable } from "inversify";
import IBundleService from "../IBundleService";
import Nullable from "@/dataTypes/Nullable";
import DependencyBundles from "@/models/devops/build/dependencyBundles/DependencyBundles";

@injectable()
export default class BundleService implements IBundleService{
 

    @inject('IDevOpsRESTClient')
    private devopsRESTClient! : IDevOpsRESTClient;

    getBundleDependencies(product: string): Promise<Nullable<DependencyBundles>> {
        return this.devopsRESTClient.getBundleDependencies(product);
    }

    updateBundleDependencies(product: string, bundles:  DependencyBundles): Promise<boolean> {
        return this.devopsRESTClient.updateBundleDependencies(product, bundles);
    }

    createBundle(bundle: BundleInfoModel): Promise<boolean> {
        bundle.git_path = encodeURIComponent(bundle.git_path)
        return this.devopsRESTClient.createBundle(bundle);
    }
    deleteBundleById(id: number): Promise<boolean> {
        return this.devopsRESTClient.deleteBundleById(id);
    }
    async getBundlesByProduct(product: string): Promise<BundleInfoModel[]> {
        const response = await this.devopsRESTClient.getBundlesByProduct(product)
        response.forEach(b=>{
            b.git_path = decodeURIComponent(b.git_path)
        })
        return response;
    }
    updateBundle(bundle: BundleInfoModel): Promise<boolean> {
        bundle.git_path = encodeURIComponent(bundle.git_path)
        return this.devopsRESTClient.updateBundle(bundle)
    }
    async getBundleById(id: number): Promise<BundleInfoModel | null> {
        const response = await this.devopsRESTClient.getBundleById(id)
        if(!!response)
            response.git_path = decodeURIComponent(response.git_path)
        return response;
    }

}
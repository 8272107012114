import { inject, injectable } from 'inversify';
import type ITransRESTClient from '../../rest-clients/ITransRESTClient';
import IStoreItemService from '../IStoreItemService';
import { ShopifyProductCollection } from '../../models/trans/storeItems/ShopifyProductCollection';
import StoreProductList from '@/models/trans/storeProducts/StoreProductList';
import StoreProduct from '@/models/trans/storeProducts/StoreProduct';
import Nullable from '@/dataTypes/Nullable';
import InventoryLocationData from '@/models/trans/storeProducts/Shopify/InventoryLocationData';
import StoreProductVariantList from '@/models/trans/storeProducts/StoreProductVariantList';

@injectable()
export default class StoreItemService implements IStoreItemService {
    @inject('ITransRESTClient')
    private transRESTClient!: ITransRESTClient;

    async getDefaultCollections(): Promise<ShopifyProductCollection[]> {
        const collections = await this.transRESTClient.getCollections();
        if (!collections) return [];
        const items: ShopifyProductCollection[] = [];
        for (let i = 0; i < collections.custom_collections.length; i++) {
            items.push({
                id: collections.custom_collections[i].id,
                title: collections.custom_collections[i].title,
                isSelected: false,
            });
        }

        return items;
    }

    async getList(page: number, query: string): Promise<Nullable<StoreProductList>> {
        return await this.getListInner(query);
    }

    async getAllList(): Promise<Nullable<StoreProductList>> {
        return await this.getListInner('');
    }

    async getListInner(query: string): Promise<StoreProductList | null> {
        return await this.transRESTClient.getList(query);
    }

    async getItem(id: number): Promise<Nullable<StoreProduct>> {
        const product = await this.transRESTClient.getItem(id);

        this.prepareProductForFE(product);

        return product;
    }

    prepareProductForSaving(storeProduct: StoreProduct) {
        if (!storeProduct.variants) return;
        for (const variant of storeProduct.variants) {
            if (!variant.linked_items) continue;

            variant.asset_groups = [];
            variant.assets = [];
            variant.linked_variants = [];

            for (const linkedItem of variant.linked_items) {
                if (!!linkedItem.assetData) {
                    if (linkedItem.assetData.game_id === 'invalid') continue;
                    variant.assets.push(linkedItem.assetData);
                }
                if (!!linkedItem.assetGroupData) {
                    variant.asset_groups.push(linkedItem.assetGroupData);
                }
                if (!!linkedItem.variantData) {
                    variant.linked_variants.push(linkedItem.variantData);
                }
            }

            variant.linked_items = undefined;
        }
    }

    prepareProductForFE(storeProduct: Nullable<StoreProduct>): Nullable<StoreProduct> {
        if (!storeProduct) return storeProduct;

        for (let variant of storeProduct.variants) {
            if (!variant) continue;

            variant.linked_items = [];

            for (let asset of variant.assets) {
                variant.linked_items.push({
                    assetData: asset,
                });
            }

            for (let assetGroup of variant.asset_groups) {
                variant.linked_items.push({
                    assetGroupData: assetGroup,
                });
            }

            for (let linkedVariant of variant.linked_variants) {
                variant.linked_items.push({
                    variantData: linkedVariant,
                });
            }
        }

        return storeProduct;
    }

    async updateItem(storeProduct: StoreProduct): Promise<Nullable<StoreProduct>> {
        this.prepareProductForSaving(storeProduct);
        return this.prepareProductForFE(await this.transRESTClient.updateItem(storeProduct));
    }

    async deleteItem(id: number): Promise<boolean> {
        return await this.transRESTClient.deleteItem(id);
    }

    async createItem(product: StoreProduct): Promise<Nullable<StoreProduct>> {
        this.prepareProductForSaving(product);
        return this.prepareProductForFE(await this.transRESTClient.createProduct(product));
    }

    async upgradeToShopify(productId: string): Promise<string | undefined> {
        return await this.transRESTClient.upgradeToShopify(productId);
    }

    async getInventoryLocation(): Promise<InventoryLocationData[]> {
        return await this.transRESTClient.getInventoryLocation();
    }

    async getAllVariants(): Promise<Nullable<StoreProductVariantList>> {
        return await this.transRESTClient.getAllVariants();
    }
}

import { useInjection } from 'inversify-react';
import { useState } from 'react';
import { TextInput, Button, Container, Title, Text, Card, Stack, Center, PasswordInput } from '@mantine/core';
import { useNavigate } from 'react-router';
import { IAuthService } from '../../services/IAuthService';
import IUIService from '../../services/IUIService';
import { useForm } from '@mantine/form';

export default function LoginPanel() {
    return (
        <Container fluid bg="#f5f5f5" mih="100vh">
            <Center h="100vh" w="100%">
                <Card m="auto" maw={400} w="100%" p="xl">
                    <LoginForm />
                </Card>
            </Center>
        </Container>
    );
}

interface ILoginFormData {
    email: string;
    password: string;
}

const LoginForm = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const authService = useInjection<IAuthService>('IAuthService');
    const uiService = useInjection<IUIService>('IUIService');

    const form = useForm<ILoginFormData>({
        initialValues: {
            email: '',
            password: '',
        },

        validate: {
            email: (value) => {
                if (value === '') {
                    return 'Email is required';
                }

                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (!emailRegex.test(value)) {
                    return 'Invalid email format';
                }

                return null;
            },
            password: (value) => (value === '' ? 'Password is required' : null),
        },
    });

    const onSubmit = async (values: ILoginFormData) => {
        const { email, password } = values;
        setIsLoading(true);
        const response = await authService.login(email, password);
        if (response) {
            navigate('/');
        } else {
            uiService.showErrorNotification('Failed to login. Wrong credentials!');
        }
        setIsLoading(false);
    };

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack>
                <Title fs="1.8rem" align="center">
                    Login
                </Title>
                <TextInput variant="filled" size="md" type="email" label="Email" {...form.getInputProps('email')} />
                <PasswordInput variant="filled" size="md" label="Password" {...form.getInputProps('password')} />

                <Button mt="xs" radius="md" size="md" fullWidth type="submit" loading={isLoading}>
                    Sign In
                </Button>
                <Text align="center" size="sm">
                    © Adam Poots {new Date().getFullYear()}
                </Text>
            </Stack>
        </form>
    );
};

import Nullable from "@/dataTypes/Nullable";
import UserList from "@/models/auth/users/UserList";
import CustomerList from "@/models/trans/customer/CustomerList";
import ITransService from "@/services/ITransService";
import IUIService from "@/services/IUIService";
import IUserManagementService from "@/services/IUserManagementService";
import { Grid } from "@mantine/core";
import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import CustomerTransactionForm from "./customer-transactions-form/customer-transactions-form.component";
import CustomerTransactionList from "./customer-transactions-list.component";

export default function CustomerTransactionsDirectory(){
    const [list, setList] = useState<Nullable<CustomerList>>(null);
    const [page, setPage] = useState(1);
    const [currentSelectedUser, setCurrentSelectedUser] = useState<Nullable<string>>(null);
    const uiService = useInjection<IUIService>('IUIService');
    const transService = useInjection<ITransService>('ITransService');
    const userService = useInjection<IUserManagementService>('IUserManagementService');
    const [query, setQuery] = useState('')

    useEffect(() => {
        getData(true);
    }, [page, query]);

    useEffect(() => {
       
    }, [currentSelectedUser]);

    const getData = async (forceSetFirst = false, reload = false) => {
        uiService.showLoading();
        const response = query==='' || !query ? await transService.getCustomerList(page) : await transService.searchCustomerList(query,page);

        uiService.hideLoading();
        if (response === null) {
            uiService.showErrorNotification('Loading user list failed!');
            return;
        }

        const setToFirst = (currentSelectedUser === null && response.items.length > 0) || forceSetFirst;
        const firstId = response.items.length > 0 ? response.items[0].email : null;

        setList(response);
        setCurrentSelectedUser(setToFirst ? firstId : currentSelectedUser);
    };



    return (
        <Grid w="calc(100vw - 250px)">
            <Grid.Col span="content">{
                <CustomerTransactionList
                    searchChange={(q)=>{setPage(1); setQuery(q)}}
                    customerList={list}
                    onPageChange={(p)=>{
                        setPage(p);
                    }}
                    page = {page}
                    selectedId = {currentSelectedUser}
                    selectionChange={change=>{
                        setCurrentSelectedUser(change)
                    }}

                />
            }</Grid.Col>
            <Grid.Col span="auto">
                <CustomerTransactionForm email={currentSelectedUser}/>
            </Grid.Col>
        </Grid>
    );
}
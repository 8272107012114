import { MantineThemeOverride } from '@mantine/core';

export const mantineTheme: MantineThemeOverride = {
    spacing: {
        xxs: '4px',
    },
    components: {
        TextInput: {
            defaultProps: {
                radius: 'sm',
                variant: 'filled',
                size: 'md',
            },
        },
        Select: {
            defaultProps: {
                variant: 'filled',
            },
            styles: {
                input: {
                    height: 44,
                },
            },
        },
        DateTimePicker: {
            styles: {
                input: {
                    height: 44,
                },
            },
            defaultProps: {
                rightSectionWidth: 70,
                rightSectionProps: { style: { fontSize: 12 } },
            },
        },
    },
};

import { Box, Button, Checkbox, Flex, Modal, TextInput } from '@mantine/core';
import { useInjection } from 'inversify-react';
import VersionEntry from '../../../models/devops/VersionEntry';
import VersionList from '../../../models/devops/VersionList';
import { CreateButton } from '../../general/create-button/create-button.component';
import DefaultListOptionView from '../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../general/list-view/list-view.component';
import './devops-version-list.styles.scss';
import { useState } from 'react';

interface DevOpsVersionListProps {
    create: (name: string, buildAll: boolean) => void;
    selectionChange: (id: number) => void;
    selectedId: number;
    versionList: VersionList | null;
    page: number;
    onPageChange: (page: number) => void;
}
export default function DevOpsVersionList(props: DevOpsVersionListProps) {
    const { selectedId, selectionChange, versionList, page, onPageChange } = props;

    const { create } = props;

    const [createNew, setCreateNew] = useState(false);

    const onSelectionChange = (id: number) => {
        selectionChange(id);
    };

    const getOptionValue = (version: VersionEntry) => {
        let versionTxt = '';
        let addSpace = false;
        if (version.active === 1) {
            versionTxt += '★';
            addSpace = true;
        }
        if (version.active_production === 1) {
            versionTxt += '☆';
            addSpace = true;
        }
        if (version.active_maintainance === 1) {
            versionTxt += '⚙';
            addSpace = true;
        }
        if (addSpace) {
            versionTxt += ' ';
        }
        versionTxt += version.version;
        return versionTxt;
    };

    return (
        <>
            <ListViewComponent
                elements={versionList}
                selectedValue={selectedId}
                containerId="version-list"
                listClass="version_list selection-list"
                listId="versions"
                page={page}
                pageChanged={onPageChange}
                selectionChanged={onSelectionChange}
                listElement={(e, changeCallback, inSelect) => {
                    return (
                        <DefaultListOptionView
                            key={e.id}
                            id={e.id}
                            value={getOptionValue(e)}
                            selected={selectedId === e.id}
                            selectionChange={changeCallback}
                            inSelectContainer={inSelect}
                        />
                    );
                }}
            >
                <ListViewComponent.Header>
                    <Box px="sm" mt="sm">
                        <CreateButton
                            title="New Version"
                            click={() => {
                                setCreateNew(true);
                            }}
                        />
                    </Box>
                </ListViewComponent.Header>
            </ListViewComponent>

            <CreateVersionDialog
                open={createNew}
                create={create}
                close={() => {
                    setCreateNew(false);
                }}
            />
        </>
    );
}

function CreateVersionDialog({
    open,
    create,
    close,
}: {
    open: boolean;
    create: (name: string, buildAll: boolean) => void;
    close: () => void;
}) {
    const [versionName, setVersionName] = useState('');
    const [buildAll, setBuildAll] = useState(false);

    const closeInner = () => {
        setVersionName('');
        setBuildAll(false);
        close();
    };

    return (
        <Modal opened={open} onClose={close} title="New Version">
            <Flex direction={'column'} rowGap={'sm'}>
                <TextInput
                    label="Version Name"
                    value={versionName}
                    onChange={(e) => {
                        e.preventDefault();
                        setVersionName(e.target.value);
                    }}
                />
                <Checkbox
                    checked={buildAll}
                    onChange={(e) => {
                        setBuildAll(e.target.checked);
                    }}
                    label="Build All Bundles"
                />
                <Flex
                    direction="row"
                    justify="space-between"
                    columnGap={'sm'}
                    align={'stretch'}
                    sx={{ marginTop: '10px' }}
                >
                    <Button
                        variant="outline"
                        color="red"
                        style={{ flex: 1 }}
                        onClick={(e) => {
                            e.preventDefault();
                            closeInner();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ flex: 1 }}
                        onClick={(e) => {
                            e.preventDefault();
                            const nameCache = versionName;
                            const buildAllCache = buildAll;
                            closeInner();
                            create(nameCache, buildAllCache);
                        }}
                    >
                        Create
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    );
}

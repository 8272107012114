import React from 'react';
import { Select, TextInput } from '@mantine/core';
import RepositoryModel from '../../../models/devops/build/RepositoryModel';
import styles from './devops-repository-view.module.scss';
import AzureRepositorySelection from './repository-selection-views/azure-repository-selection.component';
import GithubRepositorySelection from './repository-selection-views/github-repository-selection.component';

interface DevopsRepositoryState {
    repository: RepositoryModel;
}

export default class DevopRepositoryView extends React.Component<{}, DevopsRepositoryState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            repository: {
                product: 'kd-sim',
                build_url: '',
                token: '',
                branch: 'github.com',
                repository_type: 'github',
                base_url: 'github.com',
            },
        };
    }

    updateModel(repository: RepositoryModel) {
        this.setState({
            ...this.state,
            repository: repository,
        });
    }

    repositoryRender() {
        const { repository } = this.state;
        if (repository.repository_type === 'github') {
            return <GithubRepositorySelection repository={this.state.repository} updateModel={this.updateModel} />;
        }

        if (repository.repository_type === 'azure') {
            return <AzureRepositorySelection repository={this.state.repository} updateModel={this.updateModel} />;
        }
    }

    render() {
        const { repository } = this.state;
        const options = [
            { key: 'github', label: 'Github' },
            { key: 'azure', label: 'Azure' },
        ];

        return (
            <div className={styles.container}>
                <div className="row">
                    <TextInput label="Product" />
                </div>
                <div className="row">
                    <Select
                        label="Repository"
                        value={repository.repository_type}
                        onChange={(value) => {
                            if (value) {
                                this.setState({
                                    ...this.state,
                                    repository: {
                                        ...repository,
                                        repository_type: value,
                                    },
                                });
                            }
                        }}
                        data={options.map((option) => ({ label: option.label, value: option.key }))}
                    />
                </div>
                {this.repositoryRender()}
            </div>
        );
    }
}

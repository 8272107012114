import { ScopeAccessLevelModel } from '@/models/scope/ScopeModel';
import { IScopeService } from '@/services/IScopeService';
import { Button, Text, Container, Stack, Flex, Select, Grid } from '@mantine/core';
import { useInjection } from 'inversify-react';

import { useEffect, useState } from 'react';

export interface IScopeFormProps {
    scopeAccessLevels: ScopeAccessLevelModel;
    update: () => void;
    updateEnabled: boolean;
    save: (newScopeAccessLevels: ScopeAccessLevelModel) => void;
    delete: () => void;
}

export function ScopeForm(props: IScopeFormProps) {
    const { updateEnabled, save, update } = props;
    const [scopeAccessLevels, setScopeAccessLevels] = useState<ScopeAccessLevelModel | null>(null);

    useEffect(() => {
        setScopeAccessLevels(props.scopeAccessLevels);
    }, [props.scopeAccessLevels]);

    const handleChange = (key: string, value: number) => {
        const newScopeAccessLevels = { ...scopeAccessLevels, [key]: value };
        setScopeAccessLevels(newScopeAccessLevels);
    };

    const handleSave = () => {
        if (scopeAccessLevels) {
            save(scopeAccessLevels);
        }
    };

    const handleDelete = () => {
        props.delete();
    };

    return (
        <Container fluid mt="sm">
            <Stack mt="lg">
                {scopeAccessLevels &&
                    Object.keys(scopeAccessLevels).map((key) => (
                        <Grid align="center" key={key}>
                            <ScopeAccessLevel
                                onChange={handleChange}
                                key={key}
                                update={update}
                                id={key}
                                value={scopeAccessLevels[key]}
                            />
                        </Grid>
                    ))}
            </Stack>
            <Flex w="max-content" ml="auto" mt="lg" gap="md">
                <Button size="md" radius="md" onClick={handleSave} disabled={!updateEnabled}>
                    Update
                </Button>
                <Button size="md" radius="md" color="red" variant="outline" onClick={handleDelete}>
                    Delete
                </Button>
            </Flex>
        </Container>
    );
}

type ScopeAccessLevelProps = {
    id: string;
    value: number;
    update: () => void;
    onChange: (key: string, value: number) => void;
};

const ScopeAccessLevel = (props: ScopeAccessLevelProps) => {
    const [value, setValue] = useState<number | null>(null);
    const scopeService = useInjection<IScopeService>('IScopeService');

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleChange = (value: string) => {
        setValue(parseInt(value));
        props.onChange?.(props.id, parseInt(value));
        props.update();
    };

    return (
        <>
            <Grid.Col span={3}>
                <Text>{props.id}</Text>
            </Grid.Col>
            <Grid.Col span={4}>
                <Select value={value?.toString()} onChange={handleChange} data={scopeService.getAccessLevelOptions()} />
            </Grid.Col>
        </>
    );
};

import { Grid } from '@mantine/core';
import RewardsListComponent from './rewards-list/rewards-list.component';
import { useEffect, useState } from 'react';
import Nullable from '@/dataTypes/Nullable';
import RewardsList from '@/models/treasury/rewards/RewardList';
import { useMount } from 'ahooks';
import { useInjection } from 'inversify-react';
import IUIService from '@/services/IUIService';
import ITreasuryService from '@/services/ITreasuryService';
import { Reward } from '@/models/treasury/rewards/Reward';
import RewardForm from './reward-form/reward-form.component';
import { useDebouncedState } from '@mantine/hooks';

export default function RewardsCPDirectory() {
    const uiService = useInjection<IUIService>('IUIService');
    const treasuryService = useInjection<ITreasuryService>('ITreasuryService');
    const [rewardList, setRewardList] = useState<Nullable<RewardsList>>(null);
    const [page, setPage] = useState<number>(1);
    const [selectedId, setSelectedId] = useState<number>(-1);
    const [reward, setReward] = useState<Nullable<Reward>>(null);
    const [search, setSearch] = useDebouncedState<string>('', 400)

    useMount(() => {
        getItems();
    });

    useEffect(() => {
        getItems();
    }, [page, search]);

    useEffect(() => {
        getReward();
    }, [selectedId]);

    const getReward = () => {
        if (selectedId === -1) {
            return;
        }
        uiService.showLoading();
        treasuryService.getReward(selectedId).then((reward) => {
            uiService.hideLoading();
            if (!!reward) {
                setReward(reward);
            } else {
                uiService.showErrorNotification('Failed to load the reward');
            }
        });
    };

    const getItems = () => {
        uiService.showLoading();
        treasuryService.getRewards(page, search).then((item) => {
            uiService.hideLoading();
            if (!!item) {
                setRewardList(item);
                if (item.getItems().length > 0) {
                    setSelectedId(item.getItems()[0].reward_id);
                }
            } else {
                uiService.showErrorNotification('Failed to load rewards list');
            }
        });
    };

    const renderList = () => {
        return (
            <RewardsListComponent
                versionList={rewardList}
                page={page}
                searchChange={s=>setSearch(s)}
                onPageChange={(e) => setPage(e)}
                canCreate={false}
                selectedId={selectedId}
                selectionChange={(newId) => {
                    setSelectedId(newId);
                }}
            />
        );
    };

    return (
        <Grid w="calc(100vw - 250px)">
            <Grid.Col span="content">{renderList()}</Grid.Col>
            <Grid.Col span="auto">
                {reward === null ? (
                    'No Data to Display'
                ) : (
                    <RewardForm
                        saveRewardClicked={async (r) => {
                            uiService.showLoading();
                            const success = await treasuryService.updateReward(r);
                            if(!success){
                                uiService.hideLoading();
                                uiService.showErrorNotification('Failed to save the reward!');
                            }
                            getReward();
                        }}
                        deleteClicked={async (r) => {
                            uiService.showLoading();
                            const success = await treasuryService.deleteReward(r);
                            if(!success){
                                uiService.hideLoading();
                                uiService.showErrorNotification('Failed to delete the reward!');
                            }
                            getItems();
                        }}
                        reward={reward}
                        updateReward={(r) => setReward(r)}
                    />
                )}
            </Grid.Col>
        </Grid>
    );
}

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import loadingReducer from './slices/loadingSlice';
import copiedSurvivorReducer from './slices/copiedSurvivorSlice';
import confirmReducer from './slices/confirmDialogSlice';
import newVersionReducer from './slices/newVersionDialogSlice';
import zoomReducer from './slices/zoomSlice';
import wizardReducer from './slices/wizardSlice';
import devopsCategoryReducer from './slices/devopsCategorySlice';
//import logger from 'redux-logger'

const store = configureStore({
    reducer: {
        auth: authReducer,
        loading: loadingReducer,
        copiedSurvivor: copiedSurvivorReducer,
        confirmDialog: confirmReducer,
        zoom: zoomReducer,
        newVersionDialog: newVersionReducer,
        wizard: wizardReducer,
        devopsCategory: devopsCategoryReducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;

import { Button, Select } from '@mantine/core';
import styles from './bundle-dependency-top-bar.module.scss';
import IBuildRepositoryService from '@/services/IBuildRepositoryService';
import { useInjection } from 'inversify-react';

export interface BundleDependencyTopBarProps {
    saveClick(): void;

    addClick(): void;

    products: string[];
    product: string;
    updateProduct: (product: string) => void;
}

export default function BundleDependencyTopBar(props: BundleDependencyTopBarProps) {
    const buildRepositoryService = useInjection<IBuildRepositoryService>('IBuildRepositoryService');
    return (
        <div className={styles.topBar}>
            <div className={styles.row}>
                <div className={styles.left}>
                    <Select
                        value={props.product}
                        onChange={(value) => {
                            if (!value) return;
                            props.updateProduct(value);
                        }}
                        data={props.products.map((o) => ({
                            label: buildRepositoryService.getRepositoryName(o),
                            value: o,
                        }))}
                    />
                </div>
                <div className={styles.right}>
                <Button onClick={() => props.saveClick()}>Save</Button>
                </div>

              
            </div>
        </div>
    );
}

import RepositoryModel from "@/models/devops/build/RepositoryModel";
import type { IDevOpsRESTClient } from "@/rest-clients/IDevOpsRESTClient";
import { inject, injectable } from "inversify";
import IBuildRepositoryService from "../IBuildRepositoryService";

@injectable()
export default class BuildRepositoryService implements IBuildRepositoryService{
    @inject('IDevOpsRESTClient')
    private devopsRESTClient! : IDevOpsRESTClient;

    private nameLookUp = new Map<string, string>();

    constructor(){
        this.nameLookUp.set('wod-launcher', 'Launcher');
        this.nameLookUp.set('kd-sim', 'Simulator')
    }


    async createRepository(repository: RepositoryModel): Promise<boolean> {
        return await this.devopsRESTClient.createRepository(repository)
    }
    async updateRepository(repository: RepositoryModel): Promise<boolean> {
        return await this.devopsRESTClient.updateRepository(repository)
    }
    async getRepositories(): Promise<string[] | null> {
        return await this.devopsRESTClient.getRepositories()
    }
    async getRepository(product: string): Promise<RepositoryModel | null> {
        return await this.devopsRESTClient.getRepository(product)
    }

    async deleteRepository(product:string) : Promise<boolean>{
        return this.devopsRESTClient.deleteRepository(product);
    }

    getRepositoryName(product: string): string {
        if(this.nameLookUp.has(product))
            return this.nameLookUp.get(product)!;
        return product
    }
    
}
import { useState } from 'react';
import { useForm } from '@mantine/form';

import { Modal, Affix, ActionIcon, Tooltip, Stack, PasswordInput, TextInput, Button } from '@mantine/core';
import { MdOutlineCreate } from 'react-icons/md';

type ICreateServiceFormData = {
    name: string;
    password: string;
    confirmPassword: string;
};

interface ICreateServiceProps {
    onCreateService: (data: Omit<ICreateServiceFormData, 'confirmPassword'>) => Promise<void>;
}

export const CreateService = ({ onCreateService }: ICreateServiceProps) => {
    const [opened, setOpened] = useState(false);

    const closeModel = () => {
        setOpened(false);
    };

    return (
        <>
            <Modal
                opened={opened}
                centered
                closeOnClickOutside={false}
                onClose={() => setOpened(false)}
                title="Create new Service"
                styles={{
                    title: {
                        fontSize: '1.2rem',
                        fontWeight: 600,
                    },
                }}
            >
                <CreateServiceForm onCreateService={onCreateService} closeModel={closeModel} />
            </Modal>

            <Affix zIndex={1} position={{ bottom: 20, right: 20 }}>
                <Tooltip label="Create new Service" withArrow>
                    <ActionIcon size={50} radius="xl" variant="filled" color="blue" onClick={() => setOpened(true)}>
                        <MdOutlineCreate size={25} />
                    </ActionIcon>
                </Tooltip>
            </Affix>
        </>
    );
};

type ICreateServiceFormProps = {
    closeModel: () => void;
    onCreateService: (data: Omit<ICreateServiceFormData, 'confirmPassword'>) => Promise<void>;
};

const CreateServiceForm = ({ closeModel, onCreateService }: ICreateServiceFormProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const form = useForm({
        initialValues: {
            name: '',
            password: '',
            confirmPassword: '',
        },

        validate: {
            name: (value) => (value === '' ? 'Name is required' : null),
            password: (value) => (value === '' ? 'Password is required' : null),
            confirmPassword: (value, { password }) => (value !== password ? 'Passwords do not match' : null),
        },

        transformValues: (values) => ({
            name: values.name,
            password: values.password,
            confirmPassword: values.confirmPassword,
        }),
    });

    const onSubmit = async (values: ICreateServiceFormData) => {
        setIsLoading(true);
        await onCreateService(values);
        setIsLoading(false);
        closeModel();
    };

    const transformServiceName = (value: string) => {
        const transformedValue = value.toLowerCase().replace(/ /g, '-');
        form.setFieldValue('name', transformedValue);
    };

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack spacing="md" mt={30}>
                <TextInput
                    variant="filled"
                    label="Name"
                    radius="md"
                    size="md"
                    rightSection="@wod-service-account.com"
                    rightSectionWidth={200}
                    rightSectionProps={{ style: { fontSize: '0.8rem' } }}
                    value={form.values.name}
                    error={form.errors.name}
                    onChange={(event) => transformServiceName(event.currentTarget.value)}
                />
                <PasswordInput
                    variant="filled"
                    label="Password"
                    radius="md"
                    size="md"
                    {...form.getInputProps('password')}
                />
                <PasswordInput
                    variant="filled"
                    label="Confirm Password"
                    radius="md"
                    size="md"
                    {...form.getInputProps('confirmPassword')}
                />
                <Button type="submit" size="md" radius="md" loading={isLoading} loaderPosition="center">
                    {!isLoading && 'Save'}
                </Button>
            </Stack>
        </form>
    );
};

import { inject, injectable } from 'inversify';
import { ExportToCsv, Options } from 'export-to-csv';

import type { IMonitoringRESTClient } from '@/rest-clients/IMonitoringRESTClient';
import { IServerActivityService } from '../IServerActivityService';
import { TimeSeriesList } from '@/models/monitoring/server-activity/TimeSeriesList';
import 'dayjs/locale/en';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { GameRoom } from '@/models/monitoring/server-activity/gameRoom/GameRoom';
import { GameRoomList } from '@/models/monitoring/server-activity/gameRoom/GameRoomList';
import { Shard } from '@/models/monitoring/server-activity/Shard';
import { DashboardChart } from '@/models/monitoring/server-activity/Dashboard';
import MonitoringDashboardList, {
    DashboardTile,
    DashboardTileAlignment,
    IDictionary,
} from '@/models/monitoring/server-activity/MonitoringDashboardList';

dayjs.extend(utc);
dayjs.extend(timezone);

@injectable()
export default class ServerActivityService implements IServerActivityService {
    @inject('IMonitoringRESTClient')
    private monitoringRESTClient!: IMonitoringRESTClient;

    getSearchFilter(startDate: Date, endDate: Date, aggregation: DashboardTileAlignment, filter: IDictionary) {
        var hours = Math.abs(endDate.getTime() - startDate.getTime()) / 3.6e6;

        aggregation.alignmentPeriod = this.alignmentPeriod(hours);

        const filterData: IDictionary = {
            'interval.startTime': startDate.toISOString(),
            'interval.endTime': endDate.toISOString(),
            'aggregation.alignmentPeriod': aggregation.alignmentPeriod,
            'aggregation.perSeriesAligner': aggregation.aligner,
            filter: this.filterMapToFilterString(filter),
            'secondaryAggregation.alignmentPeriod': aggregation.secondAlignmentPeriod,
        };

        let filterString = '';
        Object.keys(filterData).forEach((key, index, arr) => {
            if (!!filterData[key]) {
                filterString += `${key}=${filterData[key]}`;
                filterString += '&';
            }
        });

        return filterString.substring(0, filterString.length - 1);
    }

    private filterMapToFilterString(filter?: IDictionary) {
        let filterString = '';
        if (!filter) return filterString;

        Object.keys(filter).forEach((key) => {
            filterString += `${key}="${filter[key]}" `;
        });

        return filterString.trim();
    }

    private alignmentPeriod(hours: number) {
        let alignmentPeriod = '';
        if (hours <= 1) {
            alignmentPeriod = '60s';
        } else if (hours <= 4) {
            alignmentPeriod = '120s';
        } else if (hours <= 12) {
            alignmentPeriod = '240s';
        } else if (hours <= 24) {
            alignmentPeriod = '480s';
        } else if (hours <= 48) {
            alignmentPeriod = '960s';
        } else if (hours <= 96) {
            alignmentPeriod = '1920s';
        } else if (hours <= 168) {
            alignmentPeriod = '7200s';
        } else if (hours <= 336) {
            alignmentPeriod = '14400s';
        } else if (hours <= 720) {
            alignmentPeriod = '43200s';
        } else {
            alignmentPeriod = '86400s';
        }
        return alignmentPeriod;
    }

    calculateStartAndEndTime(time: string): { startTime: Date; endTime: Date } {
        const timeUnit = time.slice(-1);
        const timeValue = Number(time.slice(0, -1));
        const endTime = dayjs().toDate();
        let startTime;

        switch (timeUnit) {
            case 'h':
                startTime = dayjs().subtract(timeValue, 'hour');
                break;
            case 'd':
                startTime = dayjs().subtract(timeValue, 'day');
                break;
            case 'm':
                startTime = dayjs().subtract(timeValue, 'month');
                break;
            default:
                startTime = dayjs().subtract(timeValue, 'hour');
                break;
        }

        return { startTime: startTime.toDate(), endTime };
    }

    exportAsCSV(data: DashboardChart, title: string) {
        const options: Options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename: `${title}.csv`,
        };

        const transformedData = data.map((point) => {
            const time = point[0];
            const value = point[1];
            return { time, value };
        });

        const csvToExport = new ExportToCsv(options);

        csvToExport.generateCsv(transformedData);
    }

    transformTimeSeries(timeSeriesList: TimeSeriesList): DashboardChart {
        const valueType = timeSeriesList.timeSeries[0].valueType;
        const isCPUTimeSeries =
            timeSeriesList.timeSeries[0].metric.type === 'compute.googleapis.com/instance/cpu/utilization';
        const points = timeSeriesList.timeSeries[0].points;

        const transformedData = points.map((point: any) => {
            const time = dayjs(point.interval.endTime).toDate();
            const value = valueType === 'DOUBLE' ? Number(point.value.doubleValue) : Number(point.value.int64Value);
            const transformedValue = isCPUTimeSeries ? value * Math.pow(10, 2) : value;
            return [time, transformedValue];
        });

        return transformedData;
    }

    filterDashboardsByShard(dashboardList: MonitoringDashboardList, shard: string): DashboardTile[] {
        return dashboardList.dashboards[0].tiles.filter((tile) => tile.title.includes(shard));
    }

    async getGoogleCloudDashboards(): Promise<MonitoringDashboardList> {
        return await this.monitoringRESTClient.getGoogleCloudDashboards();
    }

    async getGoogleCloudDashboardTimeSeries(filter: string): Promise<TimeSeriesList> {
        return await this.monitoringRESTClient.getGoogleCloudDashboardTimeSeries(filter);
    }

    async getGameRoomInfo(roomID: string): Promise<GameRoom> {
        return await this.monitoringRESTClient.getGameRoomInfo(roomID);
    }
    async getGameRoomList(shard: string, search: string, page: number): Promise<GameRoomList> {
        return await this.monitoringRESTClient.getGameRoomList(shard, search, page);
    }
    async getShardStats(shard: string): Promise<Shard> {
        return await this.monitoringRESTClient.getShardStats(shard);
    }
}

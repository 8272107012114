import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mantine/core';

import DefaultListOptionView from '../../../general/list-view-default-option/list-view-default-option.component';
import ListViewComponent from '../../../general/list-view/list-view.component';
import { SearchBox } from '../../../general/search-box/search-box';

import type IUIService from '@/services/IUIService';
import type { IServiceManagementService } from '@/services/IServiceManagementService';
import { ServiceManagementList } from '@/models/serviceManagement/ServiceManagementList';
import { ServiceManagementModel } from '@/models/serviceManagement/ServiceManagementModel';
import { ServiceForm } from '../services-form/services-form.component';
import { CreateService } from '../create-service/create-service';

export const ServiceCPDirectory = () => {
    const [list, setList] = useState<ServiceManagementList | null>(null);
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState(-1);
    const [currentSelectedService, setCurrentSelectedService] = useState<ServiceManagementModel | null>(null);
    const [updateEnabled, setUpdateEnabled] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const serviceManagementService = useInjection<IServiceManagementService>('IServiceManagementService');
    const uiService = useInjection<IUIService>('IUIService');

    useEffect(() => {
        getData(true);
    }, [page, searchQuery]);

    useEffect(() => {
        if (selected !== -1) {
            onListSelectionChanged(selected);
        }
    }, [selected]);

    const getData = async (forceSetFirst = false, reload = false) => {
        uiService.showLoading();
        const response = await (searchQuery
            ? serviceManagementService.searchService(searchQuery, page)
            : serviceManagementService.getServices(page));

        uiService.hideLoading();
        if (response === null) {
            uiService.showErrorNotification('Loading service list failed!');
            return;
        }

        const setToFirst = (selected === -1 && response.accounts.length > 0) || forceSetFirst;
        const firstId = response.accounts.length > 0 ? response.accounts[0].id : -1;

        setList(response);
        setSelected(setToFirst ? firstId : selected);
    };

    const onListSelectionChanged = async (selectedId: number) => {
        const serviceEmail = list?.accounts.find((x) => x.id === selectedId)?.email;
        uiService.showLoading();

        try {
            const response = await serviceManagementService.getService(serviceEmail!);
            if (response === null) {
                uiService.hideLoading();
                uiService.showErrorNotification('Failed to get service data');
                return;
            }
            setCurrentSelectedService(response);

            setUpdateEnabled(false);
            uiService.hideLoading();
        } catch (error) {
            uiService.hideLoading();
        }

        setSelected(selectedId);
    };

    const onSelectionChanged = (selectedId: number) => {
        setSelected(selectedId);
    };
    const onListPageChanged = (page: number) => {
        setPage(page);
    };

    const onSave = async () => {
        if (currentSelectedService === null) return;
        uiService.showLoading();
        const success = await serviceManagementService.updateServiceScope(currentSelectedService);
        uiService.hideLoading();
        if (success) {
            uiService.showSuccessNotification('Service successfuly update');
        } else {
            uiService.showErrorNotification('Failed to update the serivce');
        }
    };

    const onDelete = async () => {
        if (currentSelectedService === null) return;
        const promptResponse = await uiService.showConfirmationDialog(
            `Delete service ${currentSelectedService.email}?`,
            'Cancel',
            'Delete'
        );
        if (!promptResponse) return;
        uiService.showLoading();
        const success = await serviceManagementService.deleteService(currentSelectedService);
        await getData(true);
        uiService.hideLoading();
        if (success) {
            uiService.showSuccessNotification('Service successfuly deleted');
        } else {
            uiService.showErrorNotification('Failed to delete the service');
        }
    };

    const onUpdate = (service: ServiceManagementModel) => {
        setCurrentSelectedService(service);
        setUpdateEnabled(true);
    };

    const onSearch = (email: string) => {
        setSearchQuery(email);
        setPage(1);
    };

    const onCreateService = async ({ name, password }: { name: string; password: string }) => {
        const response = await serviceManagementService.createService(name, password);
        getData(true);

        if (response) {
            uiService.showSuccessNotification('Service successfuly created');
        } else {
            uiService.showErrorNotification('Failed to create the service');
        }
    };

    const renderServiceList = () => {
        if (list === null) return null;

        return (
            <Container px={0} pt="md">
                <Box px="xs">
                    <SearchBox setSearchQuery={onSearch} />
                </Box>
                <ListViewComponent
                    elements={list}
                    page={page}
                    selectedValue={selected}
                    containerId="service-list"
                    listId="services"
                    listClass="service_list"
                    pageChanged={onListPageChanged}
                    selectionChanged={onSelectionChanged}
                    listElement={(element, selectionChanged, inSelect) => {
                        return (
                            <DefaultListOptionView
                                key={element.id}
                                value={element.email}
                                id={element.id}
                                selectionChange={selectionChanged}
                                inSelectContainer={inSelect}
                                selected={selected === element.id}
                            />
                        );
                    }}
                />
            </Container>
        );
    };

    const renderForm = () => {
        if (currentSelectedService === null) return null;
        return (
            <ServiceForm
                service={currentSelectedService}
                delete={onDelete}
                update={onUpdate}
                save={onSave}
                updateEnabled={updateEnabled}
            />
        );
    };

    return (
        <>
            <Grid w="calc(100vw - 250px)">
                <Grid.Col span="content">{renderServiceList()}</Grid.Col>
                <Grid.Col span="auto">{renderForm()}</Grid.Col>
            </Grid>
            <CreateService onCreateService={onCreateService} />
        </>
    );
};

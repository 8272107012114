import StoreProductList from '@/models/trans/storeProducts/StoreProductList';
import { CreateButton } from '../../../components/general/create-button/create-button.component';
import Nullable from '@/dataTypes/Nullable';
import { Button, Grid, Modal, NumberInput, Select, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { MdOutlineAdd } from 'react-icons/md';
import StoreProductVariant from '@/models/trans/storeProducts/StoreProductVariant';
import { resolve, useInjection } from 'inversify-react';
import type IStoreItemService from '@/services/IStoreItemService';
import IProductKeyService from '@/services/IProductKeyService';
import type IUIService from '@/services/IUIService';
import { Container } from '@mantine/core';
import ProductKeyGenerationForm from './product-key-form.component';
import StoreItemList from '../store-product-list/store-product-list.component';
import type ITransService from '@/services/ITransService';
import React from 'react';

export interface IGiveGiftModel {
    variantId: string;
    quantity: number;
}

interface StoreItemDirectoryListState {
    itemList: StoreProductList | null;
    page: number;
    selectedId: number | null;
    searchQuery: string;
}

interface StoreItemDirectoryState {
    list: StoreItemDirectoryListState;
    loading: boolean;
    giftModel: Nullable<IGiveGiftModel>
}

export default class ProductKeyDirectory extends React.Component<{}, StoreItemDirectoryState> {
    @resolve('IStoreItemService')
    private readonly storeItemService!: IStoreItemService;

    @resolve('ITransService')
    private readonly transService!: ITransService;

    @resolve('IUIService')
    private readonly uiService!: IUIService;

    constructor(props: {}) {
        super(props);

        this.state = {
            list: {
                itemList: null,
                selectedId: null,
                page: 1,
                searchQuery: '',
            },
            loading: false,
            giftModel: null
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (forceSelectFirst: boolean = false, selectedId?: number) => {
        const { list } = this.state;

        this.uiService.showLoading();
        const data = await this.storeItemService.getList(1, list.searchQuery);
        this.uiService.hideLoading();

        if (!data) {
            this.uiService.showErrorNotification('Failed to fetch store item data');
            return;
        }

        let setToFirst = (list.selectedId === null && data.items.length > 0) || forceSelectFirst;

        if (selectedId) {
            setToFirst = false;
        }

        const firstId = data.items[0].id;

        const finalSelection = setToFirst ? firstId : selectedId ? selectedId : list.selectedId;

        this.setState({
            ...this.state,
            list: {
                ...list,
                itemList: data,
                selectedId: finalSelection,
            },
        });
        if (setToFirst) {
            this.onListSelectionChanged(firstId);
        }
        if (selectedId) {
            this.onListSelectionChanged(selectedId);
        }
    };

    listPageChanged = (page: number) => {
        this.setState(
            {
                ...this.state,
                list: {
                    ...this.state.list,
                    page: page,
                },
            },
            () => this.getData(true)
        );
    };

    onListSelectionChanged = (id: number) => {
        this.setState({
            ...this.state,
            list: {
                ...this.state.list,
                selectedId: id,
            },
        });
    };

    onSetIsLoading = (loading: boolean) => {
        this.setState(
            {
                ...this.state,
                loading: loading,
            },
            () => {
                if (loading) this.uiService.showLoading();
                else this.uiService.hideLoading();
            }
        );
    };

    renderList = () => {
        const { list } = this.state;

        return (
            <StoreItemList
                onPageChange={this.listPageChanged}
                page={list.page}
                selectedId={list.selectedId!}
                selectionChange={this.onListSelectionChanged}
                versionList={list.itemList}
                searchChange={(q) => {
                    this.setState(
                        {
                            ...this.state,
                            list: {
                                ...list,
                                searchQuery: q,
                            },
                        },
                        () => {
                            this.getData(true);
                        }
                    );
                }}
            />
        );
    };

    render() {
        const { list, loading, giftModel } = this.state;

        return (
            <Grid w="calc(100vw - 250px)">
                <Grid.Col span="content">{this.renderList()}</Grid.Col>
                <Grid.Col span="auto">
                    {!!list.selectedId && (
                        <Stack>
                            <CustomerGiveGiftForm
                                isLoading={loading}
                                setIsLoading={this.onSetIsLoading}
                                productId={list.selectedId}
                                onGiftChange={e=>{
                                    this.setState({
                                        ...this.state,
                                        giftModel:e
                                    })
                                }}
                            />
                            <ProductKeyGenerationForm
                                isLoading={loading}
                                product={giftModel}
                            />
                        </Stack>
                    )}
                </Grid.Col>
            </Grid>
        );
    }
}

interface CustomerGiveGiftFormProps {
    onGiftChange?(model: IGiveGiftModel): void;
    isLoading: boolean;
    setIsLoading: (loading: boolean) => void;
    productId: number;
}

function CustomerGiveGiftForm({
    productId,
    onGiftChange: onGiveGift,
    isLoading,
    setIsLoading,
}: CustomerGiveGiftFormProps) {
    const storeItemService = useInjection<IStoreItemService>('IStoreItemService');

    const [variants, setVariants] = useState<Nullable<StoreProductVariant[]>>(null);

    const form = useForm<IGiveGiftModel>({
        initialValues: {
            variantId: '',
            quantity: 1,
        },

        validate: {
            variantId: (value) => (value === '' ? 'Variant is required' : null),
            quantity: (value) => (value <= 0 ? 'Quantity should be greater than 0' : null),
        },
    });

    useEffect(() => {}, [isLoading]);

    const variantIdValue = form.getInputProps('variantId').value;
    const quantityIdValue = form.getInputProps('quantity').value;

    useEffect(() => {
        if (!onGiveGift) return;
        onGiveGift({
            variantId: variantIdValue,
            quantity: parseInt(quantityIdValue),
        });
    }, [variantIdValue, quantityIdValue]);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const product = await storeItemService.getItem(productId);
            setIsLoading(false);

            if (!!product) {
                const variantsResponse = product.variants;
                if (!!variantsResponse && variantsResponse.length > 0) {
                    form.setFieldValue('variantId', variantsResponse[0].variant_id.toString());
                }
                setVariants(product.variants);
            }
        };

        if (productId === 0) return;

        getData();
    }, [productId]);

    const onSubmit = async (values: IGiveGiftModel) => {
        setIsLoading(true);
        if (!!onGiveGift) await onGiveGift(values);
        setIsLoading(false);
    };

    const variantItems = (function () {
        if (!variants)
            return [
                {
                    value: '',
                    label: 'Default',
                },
            ];

        return variants.map((variant) => ({
            value: variant.variant_id.toString(),
            label: variant.variant_title,
        }));
    })();

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack spacing="md" mt={30}>
                <Select
                    variant="filled"
                    label="Variant"
                    radius="md"
                    size="md"
                    {...form.getInputProps('variantId')}
                    searchable
                    nothingFound="Nothing Found"
                    data={variantItems}
                    disabled={isLoading}
                />
                {/* <Button type="submit" size="md" radius="md" loading={isLoading} loaderPosition="center">
                    {!isLoading && 'Gift'}
                </Button> */}
            </Stack>
        </form>
    );
}

import StoreProductList from "@/models/trans/storeProducts/StoreProductList";
import { CreateButton } from "../../../components/general/create-button/create-button.component";
import Nullable from "@/dataTypes/Nullable";
import { Button, Modal, NumberInput, Select, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { MdOutlineAdd } from "react-icons/md";
import StoreProductVariant from "@/models/trans/storeProducts/StoreProductVariant";
import { useInjection } from "inversify-react";
import IStoreItemService from "@/services/IStoreItemService";
import IProductKeyService from "@/services/IProductKeyService";
import IUIService from "@/services/IUIService";

interface GiveCustomerGiftProps {
    email: Nullable<string>;
    onGifted : ()=>void;
}
export default function GiveCustomerGift({ email, onGifted }: GiveCustomerGiftProps) {
    const productKeyService = useInjection<IProductKeyService>('IProductKeyService')
    const uiService = useInjection<IUIService>('IUIService')

    const [opened, setOpened] = useState(false);

    const closeModal = () => {
        setOpened(false);
    };

    return (
        <>
            <Modal
                opened={opened}
                centered
                closeOnClickOutside={false}
                onClose={() => setOpened(false)}
                title="Gift Customer"
                styles={{
                    title: {
                        fontSize: '1.2rem',
                        fontWeight: 600,
                    },
                }}
            >
                <CustomerGiveGiftForm closeModel={closeModal} onGiveGift={async (variantModel) => {
                    if (!email)
                        return;
                    const result = await productKeyService.addCustomerProductKey(email, parseInt(variantModel.variantId), variantModel.quantity)
                    if(result){
                        uiService.showSuccessNotification('Added a product key to user');
                        onGifted();
                        closeModal();
                    }else{
                        uiService.showErrorNotification('Failed to add a product key to user');
                    }
                }} />

            </Modal>

            <CreateButton icon={<MdOutlineAdd />} title='Add Product To Customer' click={() => {
                setOpened(true);
            }} />
        </>
    );
}

interface CustomerGiveGiftFormProps {
    onGiveGift(model: IGiveGiftModel): Promise<void>;
    closeModel(): void;
}

interface IGiveGiftModel {
    productId: string,
    variantId: string
    quantity: number
}

function CustomerGiveGiftForm({ onGiveGift, closeModel }: CustomerGiveGiftFormProps) {
    const storeItemService = useInjection<IStoreItemService>('IStoreItemService')

    const [isLoading, setIsLoading] = useState(false);

    const [products, setProducts] = useState<Nullable<StoreProductList>>(null)
    const [variants, setVariants] = useState<Nullable<StoreProductVariant[]>>(null)

    const form = useForm<IGiveGiftModel>({
        initialValues: {
            productId: '',
            variantId: '',
            quantity: 1
        },

        validate: {
            productId: (value) => (value === '' ? 'Product is required' : null),
            variantId: (value) => (value === '' ? 'Variant is required' : null),
            quantity: (value) => (value <= 0 ? 'Quantity should be greater than 0' : null),
        }
    });

    useEffect(() => { }, [isLoading])


    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const productResponse = await storeItemService.getAllList(false);
            if (!!productResponse) {
                form.setFieldValue('productId', productResponse.items[0].id.toString())
            }
            setProducts(productResponse);
            setIsLoading(false);
        }

        getData();
    }, [])


    const productIdValue = form.getInputProps('productId').value;

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const product = await storeItemService.getItem(productIdValueNumber);
            setIsLoading(false);

            if (!!product) {
                const variantsResponse = product.variants;
                if (!!variantsResponse && variantsResponse.length > 0) {
                    form.setFieldValue('variantId', variantsResponse[0].variant_id.toString())
                }
                setVariants(product.variants);
            }
        }

        if (productIdValue === '')
            return;

        const productIdValueNumber = parseInt(productIdValue);

        getData();

    }, [productIdValue])

    const onSubmit = async (values: IGiveGiftModel) => {
        setIsLoading(true);
        await onGiveGift(values);
        setIsLoading(false);
        closeModel();
    };

    const productItems = (function () {
        if (!products)
            return [{
                value: '',
                label: 'Default'
            }]

        return products.getItems().map(product => (
            {
                value: product.id.toString(),
                label: product.title
            }
        ))
    })();

    const variantItems = (function () {
        if (!variants)
            return [{
                value: '',
                label: 'Default'
            }]

        return variants.map(variant => (
            {
                value: variant.variant_id.toString(),
                label: variant.variant_title
            }
        ))
    })();

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack spacing="md" mt={30}>
                <Select
                    variant="filled"
                    label="Product"
                    radius="md"
                    size="md"
                    searchable
                    nothingFound="Nothing Found"
                    {...form.getInputProps('productId')}
                    data={productItems}
                    disabled={isLoading}
                />
                <Select
                    variant="filled"
                    label="Variant"
                    radius="md"
                    size="md"
                    {...form.getInputProps('variantId')}
                    searchable
                    nothingFound="Nothing Found"
                    data={variantItems}
                    disabled={isLoading}
                />
                <NumberInput
                    label="Quantity"
                    {...form.getInputProps('quantity')}
                />
                <Button type="submit" size="md" radius="md" loading={isLoading} loaderPosition="center">
                    {!isLoading && 'Gift'}
                </Button>
            </Stack>
        </form>
    );
}
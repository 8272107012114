import { RootState } from "@/redux/store";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

export default function ProductInit(){
    const navigate = useNavigate();
    const product = useSelector((state:RootState)=>state.devopsCategory.category);

    useEffect(()=>{
        navigate(`/devops/product/${product}`)
    });
   
    return <></>
}
import { resolve } from "inversify-react";
import React from "react";
import FailedTransaction from "../../models/trans/failedTransactions/FailedTransaction";
import FailedTransactionList from "../../models/trans/failedTransactions/FailedTransactionList";
import type IFailedTransactionService from "../../services/IFailedTransactionService";
import type IUIService from "../../services/IUIService";
import DefaultListOptionView from "../general/list-view-default-option/list-view-default-option.component";
import ListViewComponent from "../general/list-view/list-view.component";
import FailedTransactionForm from "./failed-transaction-form.component";
import { Box, Container, Grid, List } from "@mantine/core";
import { SearchBox } from "../general/search-box/search-box";


interface FailedTransactionDirectoryListState {
    itemList: FailedTransactionList | null;
    page: number;
    selectedId: number;
    query: string;
}

interface FailedTransactionDirectoryState {
    list: FailedTransactionDirectoryListState;
    selectedValue: FailedTransaction | null;
}

export default class FailedTransactionDirectory extends React.Component<{}, FailedTransactionDirectoryState>{
    @resolve("IUIService")
    private uiService!: IUIService;

    @resolve("IFailedTransactionService")
    private failedTransactionService!: IFailedTransactionService;

    constructor(props: {}) {
        super(props);
        this.state = {
            list: {
                itemList: null,
                page: 1,
                selectedId: -1,
                query: ''
            },
            selectedValue: null
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (forceSelectFirst: boolean = false) => {
        this.uiService.showLoading();
        const data = await this.failedTransactionService.getAllFailedTransactions(
            this.state.list.page,
            this.state.list.query
        );
        this.uiService.hideLoading();

        if (!data) {
            this.uiService.showErrorNotification(
                'Failed to fetch failed transaction data'
            );
            return;
        }

        const setToFirst =
            (this.state.list.selectedId === -1 && data.items.length > 0) ||
            forceSelectFirst;

        if(data.items.length===0){
            this.setState({
                ...this.state,
                list: {
                    ...this.state.list,
                    itemList: data
                },
            });
            return;
        }

        const firstId = data.items[0].order_id;
        if (setToFirst) {
            this.onListSelectionChanged(firstId);
        }

        this.setState({
            ...this.state,
            list: {
                ...this.state.list,
                itemList: data,
                selectedId: setToFirst ? firstId : this.state.list.selectedId,
            },
        });
    };

    onListPageChanged = (page: number) => {
        this.setState(
            {
                ...this.state,
                list: {
                    ...this.state.list,
                    page: page,
                },
            },
            () => {
                this.getData();
            }
        );
    };

    onListSelectionChanged = async (selectedId: number) => {
        this.setState({
            ...this.state,
            list: {
                ...this.state.list,
                selectedId: selectedId
            }
        }, () => {
            this.uiService.showLoading();
            this.failedTransactionService
                .getFailedTransaction(selectedId)
                .then((response) => {
                    if (response === null) {
                        this.uiService.showErrorNotification(
                            'Failed to get transaction data'
                        );
                        return;
                    }

                    this.setState({
                        ...this.state,
                        selectedValue: response,
                        list: {
                            ...this.state.list,
                            selectedId: selectedId,
                        },
                    });
                    this.uiService.hideLoading();
                })
                .catch(() => {
                    this.uiService.hideLoading();
                });
        })
    };


    onRefresh = () => {
        this.onListSelectionChanged(this.state.list.selectedId);
    };

    refreshListClicked = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        this.getData(false);
    }

    failedTransactionSave = async (failedTransaction: FailedTransaction) => {
        const { selectedValue } = this.state;
        if (!selectedValue) return;
        this.uiService.showLoading();
        const response = await this.failedTransactionService.updateFailedTransaction(failedTransaction);
        this.uiService.hideLoading();
        if (response) {
            this.uiService.showSuccessNotification('Item save successfuly!');
            this.getData();
            return;
        } else {
            this.uiService.showErrorNotification('Saving item failed!');
            return;
        }
    };

    renderList() {
        const { itemList, page, selectedId } = this.state.list;

        return (
            <Container px={0} pt="md">
                <Box px='xs'>
                    <SearchBox setSearchQuery={(query: string) => {
                        this.setState({
                            ...this.state,
                            list: {
                                ...this.state.list,
                                query: query
                            }
                        }, ()=>this.getData(true))
                    }} />
                </Box>
                <ListViewComponent
                    elements={itemList}
                    page={page}
                    pageChanged={this.onListPageChanged}
                    selectionChanged={this.onListSelectionChanged}
                    selectedValue={selectedId}
                    listElement={(entry, selectionChange, inSelect) => {
                        return (
                            <DefaultListOptionView
                                key={entry.order_id}
                                value={`${entry.status ? '✅ ' : '❌ '}${entry.order_id}`}
                                id={entry.order_id}
                                selectionChange={selectionChange}
                                selected={selectedId === entry.order_id}
                                inSelectContainer={inSelect}
                            />
                        );
                    }}
                >
                </ListViewComponent>
            </Container>

        );
    }

    renderFailedForm() {
        if (!this.state.selectedValue)
            return;
        return (
            <FailedTransactionForm failedTransaction={this.state.selectedValue} changeStatus={this.failedTransactionSave} />
        )
    }

    render(): React.ReactNode {
        return (
            <Grid w="calc(100vw - 250px)">
                <Grid.Col span="content">{this.renderList()}</Grid.Col>
                <Grid.Col span="auto"> {this.renderFailedForm()}</Grid.Col>
            </Grid>
        );
    }
}

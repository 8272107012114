import Nullable from '@/dataTypes/Nullable';
import DependencyBundle from '@/models/devops/build/dependencyBundles/DependencyBundle';
import DependencyBundles from '@/models/devops/build/dependencyBundles/DependencyBundles';
import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { NodeEditor, GetSchemes, ClassicPreset } from 'rete';
import { AreaPlugin, AreaExtensions } from 'rete-area-plugin';
import { ConnectionPlugin, Presets as ConnectionPresets } from 'rete-connection-plugin';
import { ReactPlugin, Presets, ReactArea2D } from 'rete-react-plugin';
import { AutoArrangePlugin, Presets as ArrangePresets, ArrangeAppliers } from 'rete-auto-arrange-plugin';
import Dependency from '@/models/devops/build/dependencyBundles/Dependency';
import type { SelectorEntity } from 'rete-area-plugin/_types/extensions/selectable';
import BundleConnection from '@/models/devops/build/ui/BundleConnection';

class Node extends ClassicPreset.Node {
    width = 180;
    height = 120;
}
class Connection<N extends Node> extends ClassicPreset.Connection<N, N> {}

type Schemes = GetSchemes<Node, Connection<Node>>;
type AreaExtra = ReactArea2D<Schemes>;

class MySelector<E extends SelectorEntity> extends AreaExtensions.Selector<E> {
    private selected!: (entity: E) => void;
    private unselected!: (entity: E) => void;

    add(entity: E, accumulate: boolean): void {
        super.add(entity, accumulate);
        if (!!this.selected) {
            this.selected(entity);
        }
    }
    remove(entity: E): void {
        super.remove(entity);
        if (!!this.selected) {
            this.unselected(entity);
        }
    }

    onSelected(callback: (entity: E) => void) {
        this.selected = callback;
    }

    onUnSelected(callback: (entity: E) => void) {
        this.unselected = callback;
    }
}

export async function createEditor(
    container: HTMLDivElement,
    bundles: DependencyBundles,
    selected: (bundleConnection: BundleConnection[]) => void,
    unselected: () => void
) {
    const socket = new ClassicPreset.Socket('Dependency');

    const editor = new NodeEditor<Schemes>();
    const area = new AreaPlugin<Schemes, AreaExtra>(container);
    const connection = new ConnectionPlugin<Schemes, AreaExtra>();
    const render = new ReactPlugin<Schemes, AreaExtra>({ createRoot });
    const arrange = new AutoArrangePlugin<Schemes>();

    const selector = new MySelector();
    const accumulator = AreaExtensions.accumulateOnCtrl();
    const nodeMap = new Map<number, ClassicPreset.Node>();
    const bundleMap = new Map<number, DependencyBundle>();
    const nodeIdToBundleId = new Map<string, number>();

    const getBundleConnections = (nodeId: string) => {
        console.log(editor.getConnections());
        const connections = editor.getConnections().filter((x) => x.source === nodeId);
        console.log(nodeId);
        console.log(connections);
        const results = connections.map((connection) => {
            const bundleConnection: BundleConnection = {
                id: connection.id,
                from: {
                    nodeId: connection.source,
                    id: nodeIdToBundleId.get(connection.source)!,
                    name: bundleMap.get(nodeIdToBundleId.get(connection.source)!)!.name,
                },
                to: {
                    nodeId: connection.target,
                    id: nodeIdToBundleId.get(connection.target)!,
                    name: bundleMap.get(nodeIdToBundleId.get(connection.target)!)!.name,
                },
            };
            return bundleConnection;
        });
        return results;
    };

    const removeConnection = async (connectionId: string) => {
        await editor.removeConnection(connectionId);
        if (!!selected && !!selector.pickId) {
            selected(getBundleConnections(selector.pickId));
        }
    };

    const askForUpdate = (nodeId: string) => {
        return getBundleConnections(nodeId);
    };

    selector.onSelected((e) => {
        const connections = getBundleConnections(e.id);
        console.log(e.id);
        if (!!selected) {
            selected(connections);
        }
    });

    selector.onUnSelected((e) => {
        if (!!unselected) {
            unselected();
        }
    });

    AreaExtensions.selectableNodes(area, selector, {
        accumulating: accumulator,
    });
    render.addPreset(Presets.classic.setup());

    connection.addPreset(ConnectionPresets.classic.setup());

    const applier = new ArrangeAppliers.TransitionApplier<Schemes, never>({
        duration: 500,
        timingFunction: (t) => t,
        async onTick() {
            await AreaExtensions.zoomAt(area, editor.getNodes());
        },
    });

    arrange.addPreset(ArrangePresets.classic.setup());

    editor.use(area);

    editor.addPipe((context) => {
        //console.log(context.type);
        if (
            !!selected &&
            !!selector.pickId &&
            context.type === 'connectioncreated' &&
            !!selector.entities.has(selector.pickId)
        ) {
            selected(getBundleConnections(selector.entities.get(selector.pickId)!.id));
        }
        return context;
    });

    area.use(connection);
    area.use(render);
    area.use(arrange);

    AreaExtensions.simpleNodesOrder(area);

    const createNode = async (bundle: DependencyBundle) => {
        const bundleNode = new Node(bundle.name);
        bundleNode.addOutput(`${bundle.id}_out`, new ClassicPreset.Output(socket));
        bundleNode.addInput(`${bundle.id}_in`, new ClassicPreset.Input(socket, undefined, true));
        await editor.addNode(bundleNode);

        nodeMap.set(bundle.id, bundleNode);
        bundleMap.set(bundle.id, bundle);
        nodeIdToBundleId.set(bundleNode.id, bundle.id);

        return bundleNode;
    };

    for (let bundle of bundles.bundles) {
        await createNode(bundle);
    }

    for (let dependency of bundles.dependencies) {
        const fromNode = nodeMap.get(dependency.bundle_id);
        const toNode = nodeMap.get(dependency.dependency_id);

        if (!!fromNode && !!toNode) {
            try {
                await editor.addConnection(
                    new ClassicPreset.Connection(
                        fromNode,
                        `${dependency.bundle_id}_out`,
                        toNode,
                        `${dependency.dependency_id}_in`
                    )
                );
            } catch {
                console.log('possible issue');
            }
        }
    }

    await arrange.layout({ applier });

    setTimeout(() => {
        // wait until nodes rendered because they dont have predefined width and height
        AreaExtensions.zoomAt(area, editor.getNodes());
    }, 10);

    const getDependencyBundles = () => {
        const connections = editor.getConnections();

        const dependants: Dependency[] = [];

        for (let connection of connections) {
            const from = nodeIdToBundleId.get(connection.source)!;
            const to = nodeIdToBundleId.get(connection.target)!;
            dependants.push({
                bundle_id: from,
                dependency_id: to,
            });
        }

        const dependencyBundlesResult: DependencyBundles = {
            bundles: [...bundles.bundles],
            dependencies: dependants,
        };

        return dependencyBundlesResult;
    };

    return {
        layout: async (animate: boolean) => {
            await arrange.layout({ applier: animate ? applier : undefined });
            AreaExtensions.zoomAt(area, editor.getNodes());
        },
        destroy: () => area.destroy(),
        clear: () => editor.clear(),
        getDependencyBundles,
        removeConnection,
        askForUpdate,
    };
}

export class BundleGetProxy {
    public getBundles?: () => DependencyBundles;
    public removeConnection?: (connectionId: string) => Promise<void>;
    public askForUpdate?: (nodeId: string) => BundleConnection[];
}

interface BundleDependencyFormProps {
    bundles: DependencyBundles;
    bundleGetProxy?: BundleGetProxy;
    selected: (selection: BundleConnection[]) => void;
    unSelected: () => void;
}

export default function BundleDependencyForm({
    bundles,
    bundleGetProxy,
    selected,
    unSelected,
}: BundleDependencyFormProps) {
    const container = useRef<HTMLDivElement>(null);

    const destroy = useRef<Nullable<() => Promise<boolean>>>(null);

    const deployGraph = async () => {
        if (!!destroy && !!destroy.current) {
            console.log('destroy');
            try {
                await destroy.current();
            } catch {}
        }

        if (container === null) return;
        if (container.current === null) return;

        container.current.innerHTML = '';

        try {
            console.log('create');

            createEditor(container.current!, bundles, selected, unSelected)
                .then((x) => {
                    if (!!bundleGetProxy) {
                        bundleGetProxy.getBundles = x.getDependencyBundles;
                        bundleGetProxy.removeConnection = x.removeConnection;
                        bundleGetProxy.askForUpdate = x.askForUpdate;
                    }

                    destroy.current = x.clear;
                })
                .catch((x) => {
                    console.log(x);
                });
        } catch {
            console.log('possible error');
        }
    };

    useEffect(() => {
        deployGraph();
    }, [container]);

    useEffect(() => {
        deployGraph();
    }, [bundles]);

    return <div style={{ minHeight: '800px', height: 'calc(100vh - 60px)' }} ref={container}></div>;
}

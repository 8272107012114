import { Button, Flex, Grid, Select, TextInput } from '@mantine/core';
import RepositoryModel from '../../../../models/devops/build/RepositoryModel';
import styles from './repository-selection.module.scss';
import classes from '../../../../utils/classes';
import AzureRepositoryModel from '@/models/devops/azure/AzureRepositoryModel';
import { useState } from 'react';
import AzureBranchModel from '@/models/devops/azure/AzureBranchModel';
import { useInjection } from 'inversify-react';
import IAzureService from '@/services/IAzureService';
import IUIService from '@/services/IUIService';

interface AzureRepositorySelectionProps {
    repository: RepositoryModel;
    updateModel: (repository: RepositoryModel) => void;
    select?: React.ReactNode,
    minimize?: boolean
}

export default function AzureRepositorySelection({ repository, updateModel, select, minimize }: AzureRepositorySelectionProps) {
    const azureService = useInjection<IAzureService>('IAzureService');
    const uiService = useInjection<IUIService>('IUIService');

    const [repos, setRepos] = useState<AzureRepositoryModel[]>();
    const [branches, setBranches] = useState<AzureBranchModel[]>([]);

    const refresh = async () => {
        uiService.showLoading();
        const response = await azureService.getRepositories(repository.token);
        uiService.hideLoading();
        if (!response) {
            uiService.showErrorNotification('Failed to retrieve any repositories');
            return;
        }
        if (response.length === 0) {

            uiService.showErrorNotification('Failed to retrieve any repositories');
            return;
        }

        setRepos(response);

        let index = response.findIndex(x => x.url === repository.base_url);
        if (index === -1) {
            repository.base_url = response[0].url;
            index = 0;
        }

        uiService.showLoading();
        const branchResponse = await azureService.getRepositoryBranch(repository.token, repository.base_url);
        uiService.hideLoading();
        setBranches(branchResponse);

        if (branchResponse.length > 0) {
            let branchIndex = branchResponse.findIndex(x => azureService.getBranchNameFormatted(x.name) === repository.branch);

            if (branchIndex === -1) {
                branchIndex = 0;
            }

            repository.branch = azureService.getBranchNameFormatted(branchResponse[branchIndex].name);
        }

        updateModel(repository)

        uiService.hideLoading();
    }
    return (
        <>
            <div className='row'>
                <Grid>
                    <Grid.Col span={10}>
                        {select}
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Flex
                            justify="end"
                            align="flex-end"
                            direction="row"
                            wrap="wrap"
                            mih="100%"
                        >
                            <Button variant='subtle'
                                onClick={async e => {
                                    e.preventDefault();
                                    const personalToken = await uiService.showNewVersionDialog('Azure Personal Token', 'Personal Token', 'Authorize');
                                    if (!personalToken)
                                        return;
                                    repository.token = personalToken;
                                    updateModel(repository);
                                    refresh();
                                }}
                                className={classes(styles.btn, styles.connectWithGithubContainer)}
                                style={{ padding: !!minimize ? '0px' : undefined }}>
                                <div className={classes(styles.buttonContent, !!minimize ? styles.minimize : undefined)}>
                                    <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 33.866668 33.866666"
                                        version="1.1"
                                        id="svg5"
                                        xmlSpace="preserve"
                                        xmlns="http://www.w3.org/2000/svg"><defs
                                            id="defs2" /><g
                                                id="layer1"><path
                                                style={{ fill: '#0289d5', stroke: 'none' }}
                                                d="M 18.799486,3.4600399 C 16.608964,5.3722495 14.344885,7.2101849 12.112503,9.0735391 11.117168,9.9043288 9.9874689,10.691433 9.0857517,11.622171 8.6323298,12.090126 8.340153,12.836706 8.0178402,13.401354 7.3545362,14.563506 6.6750944,15.716432 6.0117455,16.878585 4.8219933,18.963006 3.624502,21.043951 2.4197485,23.119769 1.8387657,24.12081 0.95979255,25.22697 0.61089292,26.329521 c 1.85375638,0 3.71504678,0.04458 5.57248548,0.04458 0.4771385,0 0.9485263,-0.04458 1.4265563,-0.04458 0.161691,0 0.4556953,0.05577 0.5929568,-0.04583 0.3252995,-0.24082 0.5569369,-1.098983 0.7187615,-1.469889 0.4911812,-1.125597 1.0426791,-2.227343 1.55517,-3.343491 1.93432,-4.212665 3.848402,-8.450472 5.881691,-12.6161061 0.618145,-1.2664125 1.171337,-2.5647076 1.783775,-3.8338702 0.180103,-0.3731647 0.724913,-1.1536115 0.657197,-1.560296 m 1.159077,1.8277752 -3.005754,8.2918581 -0.825754,2.273574 -0.279962,0.936177 0.64525,0.847017 1.677229,2.006095 3.664911,4.368829 2.00253,2.407314 c -3.306624,0.623005 -6.629921,1.160505 -9.941315,1.757519 -1.816006,0.327438 -3.688539,0.816479 -5.5279053,0.961855 v 0.04458 H 33.778326 c -0.182153,-0.619214 -0.66629,-1.223717 -0.987444,-1.783194 -0.717291,-1.24962 -1.435561,-2.49857 -2.158825,-3.744711 -2.203852,-3.79736 -4.402175,-7.599488 -6.578833,-11.41245 C 23.117404,10.602985 22.181583,8.9602609 21.21576,7.3384893 20.845568,6.7169043 20.510015,5.7570543 19.958563,5.287815 Z"
                                                id="path857" /></g></svg>
                                    {/* <i className='material-icons'>login</i> */}
                                    {!minimize && 'Refresh'}
                                </div>
                            </Button>
                        </Flex>

                    </Grid.Col>
                </Grid>
            </div>
            <div className="row">
                <Select
                    label="Azure Repository Project"

                    value={repository.base_url}
                    disabled={!repos || repos.length === 0}
                    onChange={(value) => {
                        if (!value) return;
                        repository.base_url = value;
                        azureService.getRepositoryBranch(repository.token, repository.base_url).then((b) => {
                            if (b.length > 0) {
                                let branchIndex = b.findIndex(x => x.name === repository.branch);

                                if (branchIndex === -1) {
                                    branchIndex = 0;
                                }

                                repository.branch = b[branchIndex].name;
                            }
                            setBranches(b);
                            updateModel(repository);
                        });
                        updateModel(repository);
                    }}

                    data={
                        !!repos && repos.length > 0
                            ? repos.map((r) => ({
                                label: r.name,
                                value: r.url,
                            }))
                            : [
                                {
                                    label: repository.base_url,
                                    value: repository.base_url
                                }
                            ]
                    }
                />
            </div>
            <div className="row">
                <Select
                    label="Branch"
                    value={repository.branch}
                    disabled={!branches || branches.length === 0}
                    onChange={(value) => {
                        if (!value) return;
                        repository.branch = value;
                        updateModel(repository);
                    }}
                    data={
                        !!branches && branches.length > 0 ?
                            branches.map((b) => ({ label: b.name, value: b.name }))
                            : [
                                {
                                    label: repository.branch,
                                    value: repository.branch
                                }
                            ]
                    }
                />
            </div>

        </>
    );
}

import { useInjection } from 'inversify-react';
import { Button, Select, TextInput, Container, Stack, Flex, Text } from '@mantine/core';

import UserModel from '@/models/auth/users/UserModel';
import IUserManagementService from '@/services/IUserManagementService';
import { IAuthService } from '@/services/IAuthService';
import { IScopeService } from '@/services/IScopeService';
import { useEffect, useState } from 'react';
import { ScopeModel } from '@/models/scope/ScopeModel';
import IUIService from '@/services/IUIService';
import IScopeRepositoryService from '@/services/repositories/IScopeRepositoryService';
import { ScopeList } from '@/models/scope/ScopeList';
import Nullable from '@/dataTypes/Nullable';
import type IDateTimeService from '@/services/IDateTimeService';

export interface IUserFormProps {
    user: UserModel;
    update: () => void;
    updateEnabled: boolean;
    save: (user: UserModel) => void;
    delete: () => void;
}

export function UserForm(props: IUserFormProps) {
    const userService = useInjection<IUserManagementService>('IUserManagementService');
    const authService = useInjection<IAuthService>('IAuthService');
    const uiService = useInjection<IUIService>('IUIService');
    const dtService = useInjection<IDateTimeService>('IDateTimeService');

    const { user, update, updateEnabled, save } = props;

    const [selectedScope, setSelectedScope] = useState(user.scope);

    useEffect(() => {
        setSelectedScope(user.scope);
    }, [user]);

    const hideDeleteButton = () => {
        return authService.getAccessLevel('auth') !== 3 || user.scope === 'admin' || user.scope === 'sysadmin';
    };

    const handleSave = () => {
        save({ ...user, scope: selectedScope });
    };

    return (
        <Container fluid mt="lg">
            <Stack>
                <TextInput value={user.email} disabled label="Email" />
                <TextInput
                    value={user.registration_date}
                    disabled
                    label="Joined"
                    rightSectionWidth={60}
                    rightSection={
                        <Text size="xs" color="gray">
                            {dtService.getLocalTimezone()}
                        </Text>
                    }
                />
                <ActivationStatus uiService={uiService} userService={userService} user={user} update={update} />
                <UserRole
                    selectedScope={selectedScope}
                    setSelectedScope={setSelectedScope}
                    user={user}
                    update={update}
                    changePending={updateEnabled}
                />
            </Stack>
            <Flex w="max-content" ml="auto" mt="lg" gap="md">
                <Button size="md" radius="md" onClick={handleSave} disabled={!updateEnabled}>
                    Update
                </Button>

                {!hideDeleteButton() && (
                    <Button
                        size="md"
                        radius="md"
                        color="red"
                        variant="outline"
                        onClick={(e) => {
                            e.preventDefault();
                            props.delete();
                        }}
                    >
                        Delete
                    </Button>
                )}
            </Flex>
        </Container>
    );
}

interface IUserRole {
    user: UserModel;
    update: () => void;
    selectedScope: string;
    setSelectedScope: (scope: string) => void;
    changePending: boolean;
}

interface ScopeModelDisabled extends ScopeModel {
    disabled?: boolean;
}

const UserRole = (props: IUserRole) => {
    const { update, user, setSelectedScope, selectedScope, changePending } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [scopes, setScopes] = useState<ScopeModelDisabled[]>([]);

    const [allScopes, setAllScopes] = useState<Nullable<ScopeList>>(null);

    const scopeService = useInjection<IScopeService>('IScopeService');
    const scopeRepositoryService = useInjection<IScopeRepositoryService>('IScopeRepositoryService');

    const roleChanged = (newScope: string) => {
        setSelectedScope(newScope);
        update();
    };

    useEffect(() => {
        const getScopes = async () => {
            setIsLoading(true);
            const allScopes = await scopeService.getScopes('user');
            setAllScopes(allScopes);
            const scopeTotal = scopeRepositoryService.getScopeTotal();

            if (!allScopes || !scopeTotal) return;

            let targetUserScopeTotal = allScopes.scopes.find((x) => x.id === user.scope)?.total;

            if (!targetUserScopeTotal) targetUserScopeTotal = 0;

            const scopeTotalInt = parseInt(scopeTotal);

            const hasHigherScope = scopeTotalInt > targetUserScopeTotal;

            if (hasHigherScope) {
                const allowedScopes = allScopes
                    .getItems()
                    .filter((scope) => scope.total <= scopeTotalInt && scope.id !== 'sysadmin' && scope.id !== 'admin')
                    .map<ScopeModelDisabled>((scope) => {
                        if (scope.total < scopeTotalInt) {
                            return {
                                ...scope,
                                disabled: false,
                            };
                        } else {
                            return {
                                ...scope,
                                disabled: true,
                            };
                        }
                    });
                setScopes(allowedScopes);
            } else {
                const allowedScopes = allScopes
                    .getItems()
                    .filter((scope) => scope.id === user.scope)
                    .map<ScopeModelDisabled>((scope) => {
                        return {
                            ...scope,
                            disabled: true,
                        };
                    });
                setScopes(allowedScopes);
            }
            setIsLoading(false);
        };
        getScopes();
    }, [user]);

    const isDisabled = () => {
        const scopeTotal = scopeRepositoryService.getScopeTotal();
        if (scopeTotal === null || !allScopes) return true;
        let targetUserScopeTotal = allScopes.scopes.find((x) => x.id === user.scope)?.total;

        if (!targetUserScopeTotal) targetUserScopeTotal = 0;

        const higherScopeTotal = parseInt(scopeTotal) >= targetUserScopeTotal;
        if (!!changePending) return false;
        return isLoading || !higherScopeTotal || user.scope === 'admin' || user.scope === 'sysadmin';
    };

    return (
        <Select
            label="Database Access"
            defaultValue={user.scope}
            value={selectedScope}
            data={scopes.map((scope) => {
                return {
                    label: scope.display,
                    value: scope.id,
                    disabled: scope.disabled,
                };
            })}
            onChange={roleChanged}
            disabled={isDisabled()}
        />
    );
};

interface IActivationStatus {
    userService: IUserManagementService;
    uiService: IUIService;
    user: UserModel;
    update: () => void;
}

const ActivationStatus = (props: IActivationStatus) => {
    const { user, userService, uiService } = props;

    const handleActivateAccount = async () => {
        const response = await userService.forceActivateUser(user.email);
        if (response) {
            uiService.showSuccessNotification('User activated');
        } else {
            uiService.showErrorNotification('Failed to activate user');
        }
    };

    return (
        <Flex align="center" gap="md">
            <TextInput
                variant="filled"
                size="md"
                w="100%"
                value={user.active === '1' ? 'Active' : 'Pending'}
                disabled
                label="Activation Status"
            />
            {user.active !== '1' && (
                <Button radius="md" mt="lg" variant="outline" onClick={handleActivateAccount}>
                    Activate
                </Button>
            )}
        </Flex>
    );
};

import axios from 'axios';
import { injectable } from 'inversify';
import { IAuthRESTClient } from '../IAuthRESTClient';
import { RESTClient } from './RESTClient';
import { JWTAccessToken } from '../../models/JWTAccessToken';
import Nullable from '@/dataTypes/Nullable';


@injectable()
export class AuthRESTClient extends RESTClient implements IAuthRESTClient {
    private jwtAccessToken: JWTAccessToken;

    constructor() {
        super();
        this.jwtAccessToken = {
            jwt: '',
            expires: 0,
            refreshToken: '',
        };
        this.setBaseURL('https://wod-auth-uux56memxa-uc.a.run.app/api/v1/');
    }
    private refreshTokenPromise : Nullable<Promise<Nullable<JWTAccessToken>>> = null;

    refresh(refreshToken: string): Promise<JWTAccessToken | null> {
        if(this.refreshTokenPromise !== null){
            return this.refreshTokenPromise;
        }
        this.refreshTokenPromise = this.refreshTokenInternal(refreshToken);
        return this.refreshTokenPromise;
    }

    async refreshTokenInternal(refreshToken: string): Promise<JWTAccessToken | null> {
        const form = new FormData();
        form.append('refresh_token', refreshToken);

        var path = this.getFullURL('auth/user/token/refresh');

        try {
            const response = await axios.post(path, form);

            this.jwtAccessToken.jwt = response.data.jwt;
            this.jwtAccessToken.refreshToken = response.data.refresh;
            this.jwtAccessToken.expires = Date.now() + response.data.expires * 1000 - 600;

            return this.jwtAccessToken;
        } catch {
            return null;
        }
        finally{
            this.refreshTokenPromise = null;
        }
    }

    

    async login(email: string, password: string): Promise<JWTAccessToken | null> {
        const form = new FormData();
        form.append('id', email);
        form.append('secret', password);

        var path = this.getFullURL('auth/user/token');
        try {
            const response = await axios.post(path, form);

            this.jwtAccessToken.jwt = response.data.jwt;
            this.jwtAccessToken.refreshToken = response.data.refresh;
            this.jwtAccessToken.expires = Date.now() + response.data.expires * 1000 - 600;

            return this.jwtAccessToken;
        } catch {
            return null;
        }
    }

    parseJwt(token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }

    getLoggedInUserScope(token: string): string {
        const parsedJwt = this.parseJwt(token);
        return parsedJwt.scope;
    }

    async getTokenAccessLevels(scope: string): Promise<Map<string, string> | null> {
        try {
            const response = await axios.get(scope);
            return new Map(Object.entries(response.data));
        } catch {
            return null;
        }
    }
}

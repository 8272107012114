import Nullable from '@/dataTypes/Nullable';
import HouseSave from '@/models/gdm/HouseSave';
import { Button, Checkbox, Modal, Select, Space, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';

interface DataStoreUserSaveCopyConfirmationProps {
    save: Nullable<HouseSave>;
    onCopy: (targetPipeline: string, copyPolaroids: boolean, copyUnboxed: boolean) => void;
    onClose: () => void;
    open: boolean;
}

export default function DataStoreUserSaveCopyConfirmation({
    save,
    onCopy,
    onClose,
    open,
}: DataStoreUserSaveCopyConfirmationProps) {
    const [targetPipeline, setTargetPipeline] = useState<Nullable<string>>(!!save ? save.pipeline : null);
    const [copyUnboxed, setCopyUnboxed] = useState(false);
    const [copyPolaroids, setCopyPolaroids] = useState(false);

    useEffect(() => {
        if (!!save) setTargetPipeline(save.pipeline);
    }, [save]);

    const pipelines = [
        { label: 'Development', value: 'development' },
        { label: 'Production', value: 'production' },
    ];

    return (
        <Modal
            opened={open}
            onClose={onClose}
            centered
            closeOnClickOutside={false}
            title="Copy Save"
            styles={{
                title: {
                    fontSize: '1.2rem',
                    fontWeight: 600,
                    overflow: 'visible',
                },
            }}
        >
            <form>
                <Stack spacing="md" mt={30}>
                    <Select
                        variant="filled"
                        label="Target Pipeline"
                        radius="md"
                        size="md"
                        data={pipelines}
                        value={targetPipeline}
                        onChange={(e) => {
                            setTargetPipeline(e);
                        }}
                    />

                    <Text c="dimmed" size={'sm'}>
                        Please select the target pipeline you'd like to convert the save. Saves are currently copied to
                        the account you are logged in with.
                    </Text>

                    <Checkbox
                        label="Copy Unboxed"
                        checked={copyUnboxed}
                        onChange={(e) => {
                            setCopyUnboxed(e.target.checked);
                        }}
                    />
                    <Checkbox
                        label="Copy Polaroids"
                        checked={copyPolaroids}
                        onChange={(e) => {
                            setCopyPolaroids(e.target.checked);
                        }}
                    />

                    <Button
                        disabled={!targetPipeline}
                        onClick={(e) => {
                            if (!targetPipeline) return;
                            e.preventDefault();
                            onCopy(targetPipeline, copyPolaroids, copyUnboxed);
                        }}
                    >
                        Copy
                    </Button>
                </Stack>
            </form>
        </Modal>
    );
}

import { Grid } from '@mantine/core';
import { resolve } from 'inversify-react';
import React from 'react';
import { DevOpsCategoryEnum } from '../../../models/devops/DevopsCategoryEnum';
import VersionList from '../../../models/devops/VersionList';
import type IDevOpsService from '../../../services/IDevOpsService';
import type IUIService from '../../../services/IUIService';
import DevOpsFormContainer from '../devops-form-container/devops-form-container.component';
import DevOpsVersionList from '../devops-version-list/devops-version-list.component';

interface DevOpsCPDirectoryListState {
    versionList: VersionList | null;
    page: number;
}

interface DevOpsDefaultViewProps {
    category: string;
}

interface DevopsDefaultViewState {
    selectedId: number;
    list: DevOpsCPDirectoryListState;
}

export default class DevopsDefaultView extends React.Component<DevOpsDefaultViewProps, DevopsDefaultViewState> {
    @resolve('IUIService')
    private uiService!: IUIService;

    @resolve('IDevOpsService')
    private devopsService!: IDevOpsService;

    constructor(props: DevOpsDefaultViewProps) {
        super(props);
        this.state = {
            selectedId: -1,
            list: {
                page: 1,
                versionList: null,
            },
        };
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(
        prevProps: Readonly<DevOpsDefaultViewProps>,
        prevState: Readonly<DevopsDefaultViewState>,
        snapshot?: any
    ): void {
        if (prevProps.category !== this.props.category) {
            this.getData(true);
        }
    }

    getCategory(): DevOpsCategoryEnum {
        if (!!this.props.category) if (this.props.category === 'simulator') return DevOpsCategoryEnum.Simulator;
        return DevOpsCategoryEnum.Launcher;
    }

    getData = async (forceSelectFirst: boolean = false) => {
        const { category } = this.props;
        let versionList: VersionList | null = null;
        const page = this.state.list.page;
        this.uiService.showLoading();
        versionList = await this.devopsService.getProductVersionList(category, page);
        this.uiService.hideLoading();
        this.setState(
            {
                ...this.state,
                list: {
                    ...this.state.list,
                    versionList: versionList,
                },
            },
            () => {
                if (this.state.list.versionList === null) {
                    this.onSelectionChange(-1);
                    return;
                }
                if (forceSelectFirst || this.state.selectedId === -1) {
                    this.onSelectionChange(this.state.list.versionList.versions[0].id);
                }
            }
        );
    };

    onCreate = async (name: string, buildAll : boolean) => {
        this.uiService.showLoading();
        let response = false;
        response = await this.devopsService.createProductVersion(name, this.props.category, buildAll);
        this.setState(
            {
                ...this.state,
                list: {
                    ...this.state.list,
                    page: 1,
                },
            },
            () => {
                this.uiService.hideLoading();
                this.getData(true);
            }
        );

        if (response) {
            this.uiService.showSuccessNotification('Version created successfuly');
        } else {
            this.uiService.showErrorNotification('Failed to create');
        }
    };

    onSelectionChange = (id: number) => {
        let prevVersion: string | undefined = undefined;
        if (!!this.state.list.versionList) {
        }
        this.setState({
            ...this.state,
            selectedId: id,
        });
    };

    onPageChanged = (page: number) => {
        this.setState(
            {
                ...this.state,
                list: {
                    ...this.state.list,
                    page: page,
                },
            },
            this.getData
        );
    };

    onRefresh = (forceSetFirst: boolean) => {
        this.getData(forceSetFirst);
    };

    render() {
        return (
            <Grid w="calc(100vw - 260px)">
                <Grid.Col span="content">{this.renderList()}</Grid.Col>
                <Grid.Col span="auto">{this.renderForm()}</Grid.Col>
            </Grid>
        );
    }

    renderForm(): React.ReactNode {
        const prevVersion = () => {
            if (!!this.state.list.versionList) {
                const currentVersionIndex = this.state.list.versionList.versions.findIndex(
                    (x) => x.id === this.state.selectedId
                );
                if (currentVersionIndex !== -1) {
                    return this.state.list.versionList.versions[currentVersionIndex + 1].version;
                }
            }

            return '-1';
        };

        return (
            <DevOpsFormContainer
                id={this.state.selectedId}
                category={this.props.category}
                refresh={this.onRefresh}
                prevVersion={prevVersion()}
                allVersions={this.state.list.versionList}
            />
        );
    }

    renderList(): React.ReactNode {
        return (
            <DevOpsVersionList
                create={this.onCreate}
                selectionChange={this.onSelectionChange}
                selectedId={this.state.selectedId}
                versionList={this.state.list.versionList}
                page={this.state.list.page}
                onPageChange={this.onPageChanged}
            />
        );
    }
}

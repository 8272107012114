import React from 'react';
import { Button, Flex, Select, Stack, Textarea, Container, MultiSelect } from '@mantine/core';
import { useInjection } from 'inversify-react';

import ProductVersionModel from '../../../models/devops/ProductVersionModel';
import CommonProductVersion from '../common-product-version/common-product-version.component';
import VersionList from '@/models/devops/VersionList';

interface ApplicationVersionFormProps {
    versionInfo: ProductVersionModel;
    updateModel: (version: ProductVersionModel) => void;
    delete: () => void;
    update: () => void;
    updateEnabled: boolean;
    refreshArtifacts: () => void;
    previousVersion? : string,
    allVersions: VersionList | null;
}

export default function ApplicationVersionForm(props: ApplicationVersionFormProps) {

    const { versionInfo, updateModel, update, updateEnabled } = props;

    const updateVersion = () => {
        update();
    };

    const deleteVersion = () => {
        props.delete();
    };

    const activeProductValue = () => {
        const values: string[] = [];
        if (versionInfo.active_status.development)
            values.push('development');
        if (versionInfo.active_status.production)
            values.push('production');
        if (versionInfo.active_status.maintenance)
            values.push('maintenance');

        return values;
    }

    return (
        <Container mt="lg">
            <Stack spacing="md">
                <CommonProductVersion
                    versionInfo={versionInfo}
                    updateModel={updateModel}
                    refreshArtifacts={props.refreshArtifacts}
                    previousVersion={props.previousVersion}
                    allVersions={props.allVersions}
                />
                <MultiSelect
                    label="Active Pipelines"
                    onChange={(value) => {
                        const activeStatus = { ...versionInfo.active_status };
                        if (value?.includes('development')) {
                            activeStatus.development = true;
                        } else {
                            activeStatus.development = false;
                        }
                        if (value?.includes('production')) {
                            activeStatus.production = true;
                        } else {
                            activeStatus.production = false;
                        }
                        if (value?.includes('maintenance')) {
                            activeStatus.maintenance = true;
                        } else {
                            activeStatus.maintenance = false;
                        }
                        updateModel({ ...versionInfo, active_status: activeStatus });
                    }}
                    value={activeProductValue()}
                    data={[
                        { label: 'Choose the active pipelines ', value: '', disabled: true },
                        { label: 'Development', value: 'development' },
                        { label: 'Production', value: 'production' },
                        { label: 'Maintenance', value: 'maintenance' },
                    ]}
                />
                <Textarea
                    id="changeLog"
                    minRows={5}
                    onChange={(e) => {
                        versionInfo.changelog = e.target.value;
                        updateModel(versionInfo);
                    }}
                    value={versionInfo.changelog}
                    label="Change Log"
                />
                <Flex ml="auto" w="max-content" gap="sm">
                    <Button id="update_version" onClick={updateVersion} disabled={!updateEnabled}>
                        Update
                    </Button>
                    <Button color="red" variant="outline" id="delete_version" onClick={deleteVersion}>
                        Delete
                    </Button>
                </Flex>
            </Stack>
        </Container>
    );
}

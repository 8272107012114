import BundleInfoModel from '@/models/devops/build/BundleInfoModel';
import { Button, Flex, Grid, Group, Stack } from '@mantine/core';
import { useRef } from 'react';
import ProductBundle from '../product-bundle/product-bundle.component';
import BuildProductVersionInfoList from '@/models/devops/build/product/BuildProductVersionInfoList';
import Nullable from '@/dataTypes/Nullable';

interface IProductBundleListProps {
    bundles: BundleInfoModel[];
    update: (index: number, bundles: BundleInfoModel[]) => void;
    createBundle: (ready: () => void) => void;
    deleteBundle: (index: number) => void;
    dirtyBundles?: number[];
    newBundles?: number[];
    deletedBundles?: number[];
    productList: Nullable<BuildProductVersionInfoList>;
}
export default function ProductBundleList({
    bundles,
    update,
    createBundle,
    deleteBundle,
    deletedBundles,
    dirtyBundles,
    newBundles,
    productList,
}: IProductBundleListProps) {
    const lastItemRef = useRef(null);

    const scrollToBottom = () => {
        //@ts-ignore
        lastItemRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    if (!productList) return <>Loading</>;

    return (
        <Stack>
            <Grid>
                <Grid.Col span={8}>
                    <h3>Bundles</h3>
                </Grid.Col>
            </Grid>
            <Flex
                miw="calc(100vw - 300px)"
                mah="500px"
                bg="rgba(0, 0, 0, .05)"
                gap="md"
                direction="column"
                wrap="nowrap"
                sx={(theme) => ({ overflow: 'scroll', overflowX: 'hidden' })}
            >
                {bundles &&
                    bundles.map((bundle, index) => {
                        if (!!deletedBundles && deletedBundles.includes(index)) {
                            return <></>;
                        }
                        return (
                            <ProductBundle
                                productList={productList}
                                isDirty={!!dirtyBundles && dirtyBundles.includes(index)}
                                isNew={!!newBundles && newBundles.includes(index)}
                                key={index}
                                bundle={bundle}
                                updateModel={(b) => {
                                    bundles[index] = b;
                                    update(index, bundles);
                                }}
                                delete={() => {
                                    deleteBundle(index);
                                }}
                            />
                        );
                    })}
                <div ref={lastItemRef} />
            </Flex>
            <Flex mih={50} gap="md" justify="flex-end" align="center" direction="row" wrap="wrap">
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        createBundle(scrollToBottom);
                    }}
                >
                    Create Bundle
                </Button>
            </Flex>
        </Stack>
    );
}
